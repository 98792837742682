import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Card from "../../../../components/UI/Card";
import { Error, GradientDiv } from "../../../../components/UI/Styles";

import Input from "../../../../components/UI/Forms/Input";
import CheckBox from "../../../../components/UI/Forms/Checkbox";
import Button from "../../../../components/UI/Buttons/LargeButton";

import hidePassword from "../../../../assets/functional_icons/eye-hide-svgrepo-com.svg";
import showPassword from "../../../../assets/functional_icons/eye-show-svgrepo-com.svg";

import { BottomPart, CMMLogo, TopMainText } from "./CommonStyles";
import * as actions from "../../../../store/actions/index";
import { LargeButtonDarkGreen } from "../../../../components/UI/Buttons/LargeButton";

const DarkGreenButton = styled(LargeButtonDarkGreen)`
  min-height: 100%;
`;

const TopPart = styled.div`
  padding-top: 25px;
  padding-bottom: 35px;
  min-width: 320px;
  color: white;
  justify-content: center;
  text-align: center;
`;

const BottomContainer = styled.div`
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  min-height: 690px;
`;

const SignUpWithEmail = styled.p`
  color: #170f30;
  opacity: 0.45;
  text-align: center;
  margin-top: 37px;
  margin-bottom: 15px;
`;

const AlreadyHaveAccount = styled.p`
  height: 19px;
  color: #a0a0a0;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
`;

const SupportMsg = styled.p`
  height: 19px;
  color: #a0a0a0;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
`;

const FormInputContainer = styled.div`
  margin-top: ${(props) => (props.firstEle ? "15px" : "22px")};
  margin-bottom: 22px;
  position: relative;
`;

const ButtonContainer = styled.div`
  margin-top: 26px;
`;

const DisplayError = styled(Error)`
  margin: 39px 0 15px 10px;
`;

class Signup extends Component {
  state = {
    signupFormValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      isPasswordMatch: false,
      verificationCode: "",
      agreeToPrivacy: false,
    },
    plan: "",
    hidePassword: true,
    hideConfirmPassword: true,
    displaySendCodeButton: "inline",
    displayCodeSentMsg: "none",
  };

  componentDidMount() {
    // Use this.props.location.search to access the query string of the URL
    const queryParams = new URLSearchParams(this.props.location.search);
    const plan = queryParams.get("plan");

    this.setState({ plan });
  }

  changeHandle = (event, inputIdentifier) => {
    const value =
      inputIdentifier !== "agreeToPrivacy"
        ? event.target.value
        : !this.state.signupFormValues.agreeToPrivacy;
    this.setState((prevState) => {
      const updatedFormValues = {
        ...prevState.signupFormValues, // Copy the previous signupFormValues
        [inputIdentifier]: value,
      };

      // Calculate isPasswordMatch based on newPassword and confirmPassword
      updatedFormValues["isPasswordMatch"] =
        updatedFormValues["password"] &&
        updatedFormValues["confirmPassword"] &&
        updatedFormValues["password"] === updatedFormValues["confirmPassword"];

      // Update the entire state object while preserving other properties
      return {
        ...prevState,
        signupFormValues: updatedFormValues,
      };
    });
  };

  changeVisuality = (value, inputIdentifier) => {
    this.setState((prevState) => {
      const updatedFormValues = {
        ...prevState, // Copy the previous signupFormValues
        [inputIdentifier]: value,
      };
      // Update the entire state object while preserving other properties
      return updatedFormValues;
    });
  };

  onSendCodeButtonClick = () => {
    if (!this.state.signupFormValues.email) {
      alert(
        "Please fill out the My Email field before sending a verification code."
      );
    } else if (!this.state.signupFormValues.isPasswordMatch) {
      alert("Your passwords do not match.");
    } else {
      this.props
        .startEmailVerification(this.state.signupFormValues.email)
        .then(() => {
          this.setState({
            displaySendCodeButton: "none",
            displayCodeSentMsg: "inline",
          });
        })
        .catch((error) => {
          if (this.props.user.error) {
            if (
              this.inferErrorMessages(this.props.user.error).includes(
                "You have made too many attempts to send a verification code. Please try again after 10 minutes."
              )
            ) {
              this.setState({
                displaySendCodeButton: "none",
                displayCodeSentMsg: "none",
              });
            }
          }
        });
    }
  };

  onSendAnotherCode = () => {
    this.setState({
      displaySendCodeButton: "inline",
      displayCodeSentMsg: "none",
    });
  };

  submitHandler = (event) => {
    // we want to avoid the default behavior of form submission (reloading the page)
    event.preventDefault();

    const firstName = this.state.signupFormValues.firstName;
    const lastName = this.state.signupFormValues.lastName;
    const email = this.state.signupFormValues.email;
    const password = this.state.signupFormValues.password;
    const plan = this.state.plan;

    const agreeToPrivacy = this.state.signupFormValues.agreeToPrivacy;
    const code = this.state.signupFormValues.verificationCode;

    this.props
      .onSignup(
        firstName,
        lastName,
        email,
        password,
        agreeToPrivacy,
        code,
        plan
      )
      .then(() => {
        if (this.props.user.error) {
          const updatedFormValues = {
            ...this.state.signupFormValues,
          };
          updatedFormValues["verificationCode"] = "";
          this.setState({
            displaySendCodeButton: "inline",
            displayCodeSentMsg: "none",
            signupFormValues: updatedFormValues,
          });
        }
      });
  };

  /*
        Given the response of the failing http request, parses the payload and extracts error strings as an Array. Each
        index in the array is either a string containing the error response or another array containing multiple errors for a single field.
     */
  inferErrorMessages = (error) => {
    console.log("Inside inferErrorMessages, error is", error);
    if (error) {
      var errors = [];
      console.log(
        "InferErrorMessage - ",
        error,
        "error response is",
        error.response
      );
      const response = error.response;
      if (response.status === 400) {
        for (const error of Object.values(response.data)) {
          if (error && error instanceof Object) {
            const subErrors = Object.values(error);
            errors = errors.concat(subErrors);
          } else {
            errors = errors.concat(error);
          }
        }
        return errors;
      } else if (response.status === 503) {
        errors.push(
          "Sorry, the service is currently unavailable for a short duration." +
            " If you need to use it, please contact support at support@capturemymeeting.com."
        );
        return errors;
      } else {
        errors.push("Hmmm...something went wrong, please try again.");
        return errors;
      }
    }
  };

  render() {
    const error = this.props.user.error;

    return (
      <Card>
        <GradientDiv>
          <TopPart>
            <CMMLogo />
            <TopMainText>Get Started!</TopMainText>
            {/*<TopSubText>Use your preferred business account.</TopSubText>*/}
            {/*<ExtLogoContainer><GoogleLogo width="50px"/></ExtLogoContainer>*/}
            {/*<ExtLogoContainer><MicrosoftLogo width="50px"/></ExtLogoContainer>*/}
            {/*<ExtLogoContainer><LinkedInLogo width="50px"/></ExtLogoContainer>*/}
          </TopPart>
        </GradientDiv>
        {/*<CircleDivider/>*/}
        <BottomPart>
          <BottomContainer>
            {
              /*<DisplayError>{this.inferErrorMessages(error)[0]}</DisplayError>:*/
              error != null ? (
                <DisplayError>
                  {this.inferErrorMessages(error) &&
                  this.inferErrorMessages(error).length > 0
                    ? this.inferErrorMessages(error)[0]
                    : null}
                </DisplayError>
              ) : (
                <SignUpWithEmail>Sign up with your email.</SignUpWithEmail>
              )
            }
            <form onSubmit={this.submitHandler}>
              <FormInputContainer firstEle={true}>
                <Input
                  placeholder="First Name"
                  type="text"
                  value={this.state.signupFormValues.firstName}
                  onChange={(event) => this.changeHandle(event, "firstName")}
                  required
                />
              </FormInputContainer>
              <FormInputContainer>
                <Input
                  placeholder="Last Name"
                  type="text"
                  value={this.state.signupFormValues.lastName}
                  onChange={(event) => this.changeHandle(event, "lastName")}
                  required
                />
              </FormInputContainer>
              <FormInputContainer>
                <Input
                  placeholder="My Email"
                  type="email"
                  value={this.state.signupFormValues.email}
                  onChange={(event) => this.changeHandle(event, "email")}
                  required
                />
              </FormInputContainer>
              <FormInputContainer>
                <Input
                  placeholder="My Password"
                  type={this.state.hidePassword ? "password" : "text"}
                  value={this.state.signupFormValues.password}
                  onChange={(event) => this.changeHandle(event, "password")}
                  required
                />
                <img
                  alt="Hide Password"
                  onClick={() => {
                    this.changeVisuality(
                      !this.state.hidePassword,
                      "hidePassword"
                    );
                  }}
                  style={{
                    position: "absolute",
                    width: "30px",
                    top: "5px",
                    bottom: "0px",
                    right: "5px",
                  }}
                  src={this.state.hidePassword ? hidePassword : showPassword}
                />
              </FormInputContainer>
              <FormInputContainer>
                <Input
                  placeholder="Confirm Password"
                  type={this.state.hideConfirmPassword ? "password" : "text"}
                  value={this.state.signupFormValues.confirmPassword}
                  onChange={(event) =>
                    this.changeHandle(event, "confirmPassword")
                  }
                  required
                />
                <img
                  alt="Hide Confirm Password"
                  onClick={() => {
                    this.changeVisuality(
                      !this.state.hideConfirmPassword,
                      "hideConfirmPassword"
                    );
                  }}
                  style={{
                    position: "absolute",
                    width: "30px",
                    top: "5px",
                    bottom: "0px",
                    right: "5px",
                  }}
                  src={
                    this.state.hideConfirmPassword ? hidePassword : showPassword
                  }
                />

                <div style={{ marginTop: "10px" }}>
                  {this.state.signupFormValues.isPasswordMatch ? (
                    <div
                      style={{
                        display: this.state.signupFormValues.password
                          ? "inline"
                          : "none",
                        color: "green",
                      }}
                    >
                      Passwords match!
                    </div>
                  ) : (
                    <div
                      style={{
                        display: this.state.signupFormValues.password
                          ? "inline"
                          : "none",
                        color: "red",
                      }}
                    >
                      Passwords do not match.
                    </div>
                  )}
                </div>
              </FormInputContainer>

              <DarkGreenButton
                type="button"
                style={{ display: this.state.displaySendCodeButton }}
                loading={this.props.user.loading}
                disabled={this.props.user.loading}
                onClick={this.onSendCodeButtonClick}
              >
                Send Email Verification Code
              </DarkGreenButton>
              <div
                style={{
                  display: this.state.displayCodeSentMsg,
                  color: "green",
                }}
              >
                Verification code sent. Please check your email (including your
                spam folder), and then enter it below. Finally, click the Sign
                Me Up button. If you did not receive the code, check that the
                email address you provided is correct, or try to{" "}
                <Link onClick={this.onSendAnotherCode}>send another code</Link>.
              </div>
              <FormInputContainer>
                <Input
                  placeholder="Enter the verification code you receive by email"
                  type="text"
                  value={this.state.signupFormValues.verificationCode}
                  onChange={(event) =>
                    this.changeHandle(event, "verificationCode")
                  }
                  required
                />
              </FormInputContainer>
              <CheckBox
                fontSize="12px"
                required={true}
                isChecked={this.state.signupFormValues.agreeToPrivacy}
                onChange={(event) => this.changeHandle(event, "agreeToPrivacy")}
              >
                I have read and agree to the &nbsp;{" "}
                <a href="https://capturemymeeting.com/terms-conditions/">
                  Terms of Service
                </a>
              </CheckBox>
              <ButtonContainer>
                <Button
                  type="submit"
                  disabled={this.props.user.loading}
                  loading={this.props.user.loading}
                >
                  Sign me up!
                </Button>
              </ButtonContainer>
            </form>
            <AlreadyHaveAccount>
              Already have an account?{" "}
              <Link to="/auth/login">
                <u>Sign in here</u>
              </Link>
            </AlreadyHaveAccount>
            <SupportMsg>
              For questions or concerns, please contact
              support@capturemymeeting.com.
            </SupportMsg>
          </BottomContainer>
        </BottomPart>
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSignup: (
      firstName,
      lastName,
      email,
      password,
      agreeToPrivacy,
      code,
      plan
    ) =>
      dispatch(
        actions.signupUser(
          firstName,
          lastName,
          email,
          password,
          agreeToPrivacy,
          code,
          plan
        )
      ),
    startEmailVerification: (email) =>
      dispatch(actions.startEmailVerification(email)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);

import React from "react";
import MediaQuery from "react-responsive";
import Paginator from "../../../UI/Paginator/Paginator";

const MeetingsPaginator = (props) => {
  return (
    <MediaQuery query="(min-width: 769px)">
      {(matches) => {
        if (matches) {
          return (
            <Paginator
              pageCount={props.pageCount}
              onPageChange={props.onPageChange}
            />
          );
        } else {
          return (
            <MediaQuery query="(min-width: 361px)">
              {(matches) => {
                if (matches) {
                  return (
                    <Paginator
                      pageCount={props.pageCount}
                      onPageChange={props.onPageChange}
                      marginPagesDisplayed={0}
                      pageRangeDisplayed={2}
                    />
                  );
                } else {
                  return (
                    <Paginator
                      pageCount={props.pageCount}
                      onPageChange={props.onPageChange}
                      showEllipses={false}
                      marginPagesDisplayed={0}
                      pageRangeDisplayed={2}
                    />
                  );
                }
              }}
            </MediaQuery>
          );
        }
      }}
    </MediaQuery>
  );
};

export default MeetingsPaginator;

import React, { Component } from "react";
import styled from "styled-components";

const CircularProgressContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ProgressPercentatge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  color: black;
  width: fit-content;
  height: fit-content;
  font-weight: 500;
  word-spacing: 0.2%;
`;
class CircularProgressBar extends Component {
  render() {
    const { progress, heading } = this.props;
    // Calculate the circumference of the circle (2 * π * radius)
    const circumference = 2 * Math.PI * 65; // Assuming a radius of 25px

    // Calculate the dash offset to represent the progress
    const dashOffset = circumference - (progress / 100) * circumference;

    return (
      <div style={{ textAlign: "center", margin: "5px" }}>
        <h3>{heading}</h3>
        <CircularProgressContainer>
          <svg width="140" height="140" xmlns="http://www.w3.org/2000/svg">
            {/* <circle
              cx="70"
              cy="70"
              r="60"
              fill="lightgrey"
           
              stroke="#0074d9" Change this color to your desired fill color 
              strokeWidth="10"
               Adjust the stroke width as needed 
              strokeDasharray={circumference}
              strokeDashoffset={dashOffset}
            ></circle>  */}

            {/* <circle
              cx="70"
              cy="70"
              r="70"
              fill="#764ef2"
              stroke="none"
              strokeWidth="0"
            ></circle> */}
            <circle
              cx="70"
              cy="70"
              r="65"
              fill="#fff"
              stroke="#764ef2"
              strokeWidth="8"
              strokeDasharray={circumference}
              strokeDashoffset={dashOffset}
              strokeLinecap="round" // Add rounded corners to the border
              filter="drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4))" // Add a drop shadow to the border
            ></circle>
          </svg>
          <ProgressPercentatge>{progress}%</ProgressPercentatge>
        </CircularProgressContainer>
      </div>
    );
  }
}

export default CircularProgressBar;

import styled from "styled-components";

const Input = styled.input`
  &::placeholder {
    color: #170f30;
    opacity: 0.25;
  }

  height: 40px;
  border: 1px solid rgba(23, 15, 48, 0.25);
  border-radius: 3px;
  width: 100%;
  padding-left: 8px;
`;

export default Input;

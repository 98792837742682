import React from "react";
import styled from "styled-components";

import { MainText, SubText } from "../MeetingStyles";
import { media } from "../../../../UI/StyleUtils";

const RecordingAvailabilityContainer = styled.div`
  flex-basis: 20%;
  display: none;

  ${media.gtSm`
        display: block;    
    `};
`;

const LGreenSubText = styled(SubText)`
  color: #21d361;
  opacity: 1;
`;

const RecordingAvailability = (props) => {
  return (
    <RecordingAvailabilityContainer>
      <MainText>Captured Recording</MainText>
      {true === props.recordingAvailable ? (
        <LGreenSubText>Available</LGreenSubText>
      ) : (
        <SubText>Not Available</SubText>
      )}
    </RecordingAvailabilityContainer>
  );
};

export default RecordingAvailability;

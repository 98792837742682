import styled from "styled-components";

export const MainText = styled.div`
  margin: 19px 0;
  font-size: 58px;
  letter-spacing: -0.56px;
  line-height: 69px;
`;

export const SubText = styled.div`
  font-size: 29px;
  letter-spacing: -0.56px;
  line-height: 42px;
`;

export const Body = styled.div`
  margin: 7% 0 0 11.5%;
  max-width: 618px;
`;

import React, { Component } from "react";
import styled from "styled-components";
import zoomIcon from "../../../assets/images/zoom/zoom_icon.png";

const ZoomLogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

/**
 *
 * A component that places the Zoom icon next to the desired text. The desired text is optional.
 *  props
 *  {
 *      height: the height of the Zoom icon
 *      width: the width of the Zoom icon
 *      logoText: the logo text, which will appear to the right of the icon if given
 * }
 */
class ZoomLogo extends Component {
  state = {};

  height = () => (this.props.height ? this.props.height : "25px");
  width = () => (this.props.width ? this.props.width : "25px");

  render() {
    return (
      <ZoomLogoContainer>
        <img
          src={zoomIcon}
          alt="Zoom Logo"
          style={{ height: this.height(), width: this.width() }}
        />
        {this.props.logoText ? (
          <div style={{ marginLeft: "5px" }}>{this.props.logoText}</div>
        ) : null}
      </ZoomLogoContainer>
    );
  }
}

export default ZoomLogo;

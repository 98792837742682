import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const NavigationItemStyled = styled.li`
  padding: auto 11px;
  height: 40px;
  line-height: 40px;

  & a {
    text-decoration: none;
    padding-left: 16px;
    color: rgba(255, 255, 255, 0.7);
    height: 100%;
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  & a:hover {
    color: white;
  }

  &:hover {
    background-color: rgba(1, 1, 1, 0.15);
  }
`;

export const NonLinkNavigationItem = styled.li`
  padding: auto 11px;
  height: 40px;
  line-height: 40px;

  text-decoration: none;
  padding-left: 16px;
  color: rgba(255, 255, 255, 0.7);
  height: 100%;
  display: block;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    color: white;
    background-color: rgba(1, 1, 1, 0.15);
  }
`;

const navigationItem = (props) => {
  return (
    <NavigationItemStyled>
      <Link to={props.link}>{props.children}</Link>
    </NavigationItemStyled>
  );
};
export default navigationItem;

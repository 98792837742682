import React, { Component } from "react";
import styled from "styled-components";

import Tile from "../Tile/Tile";
import { Panel } from "../Tabs/Tabs";
import * as actions from "../../../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ZoomLogo from "../../../UI/Logos/ZoomLogo.js";
import WebexLogo from "../../../UI/Logos/WebexLogo.js";
import TodoistLogo from "../../../UI/Logos/TodoistLogo.js";
import BasicOpsLogo from "../../../UI/Logos/BasicOpsLogo.js";
import HorizontalLine from "../../../UI/Lines/Lines.js";
import WebexSettings from "../WebexSettings/WebexSettings.js";
import TodoistSettings from "../TodoistSettings/TodoistSettings.js";

const Body = styled(Panel)`
  padding-top: 15px;
  padding-bottom: 15px;
`;

const IntegrationsContainer = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: column;
`;

const Integration = styled.div`
  display: flex;
  justify-content: space-between;
  color: #a0a0a0;
  font-weight: bolder;
  margin: 2.5% 2.5% 2.5% 2.5%;
`;

const Link = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

const ZoomSubtext = styled.div`
  margin-left: 10px;
  text-align: left;
  font-size: 12.5px;
  color: #a0a0a0;
  font-weight: 300;
`;

const WebexSubtext = styled.div`
  margin-left: 10px;
  text-align: left;
  font-size: 12.5px;
  color: #a0a0a0;
  font-weight: 300;
`;

class IntegrationsPanel extends Component {
  state = {};

  componentDidMount() {
    console.log("IntegrationsPanel component did mount");
  }

  webexReauthHandler = () => {
    this.props
      .requestWebexReauth(this.props.accessToken, this.props.user.userId)
      .then(() => {
        this.props.history.push("/");
      })
      .catch((error) => {
        alert(
          "There was problem when trying to remove your Webex integration. Please contact support@capturemymeeting.com."
        );
      });
  };

  todoistReauthHandler = () => {
    this.props
      .requestTodoistReauth(this.props.accessToken, this.props.user.userId)
      .then(() => {
        this.props.history.push("/");
      })
      .catch((error) => {
        alert(
          "There was problem when trying to remove your Todoist integration. Please contact support@capturemymeeting.com."
        );
      });
  };

  removeBasicOpsHandler = () => {
    this.props
      .removeBasicOps(this.props.accessToken, this.props.user.userId)
      .then(() => {
        this.props.history.push("/");
      })
      .catch((error) => {
        alert(
          "There was problem when trying to remove your BasicOps integration. Please contact support@capturemymeeting.com."
        );
      });
  };

  render() {
    return (
      <Body>
        <Tile title={"You have integrated the following apps."}>
          <IntegrationsContainer>
            {this.props.zoom_integrated &&
            this.props.zoom_refresh_token_exists ? (
              <div>
                <Integration>
                  <ZoomLogo
                    logoText={"Zoom"}
                    height={"19px"}
                    width={"19px"}
                  ></ZoomLogo>
                </Integration>
                <ZoomSubtext>
                  To get speaker names automatically in your notes and
                  transcripts,
                  <a
                    style={{ margin: "0px 3px 0px 3px" }}
                    href="https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0065911"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    enable transcription
                  </a>
                  for your Zoom cloud recordings.
                </ZoomSubtext>
                <HorizontalLine
                  style={{ marginTop: "17px", marginBottom: "10px" }}
                ></HorizontalLine>
              </div>
            ) : null}
            {this.props.webex_integrated &&
            this.props.webex_refresh_token_exists ? (
              <div>
                <Integration>
                  <WebexLogo
                    logoText={"Webex"}
                    height={"19px"}
                    width={"24px"}
                  ></WebexLogo>
                  <Link onClick={this.webexReauthHandler}>
                    <b>Remove Integration</b>
                  </Link>
                </Integration>
                <WebexSubtext>
                  To get speaker names automatically in your notes and
                  transcripts, turn on Webex Assistant for your
                  <a
                    style={{ margin: "0px 3px 0px 3px" }}
                    href="https://help.webex.com/en-us/article/nwl9xii/Enable-Webex-Assistant-for-Meetings-in-Control-Hub"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    org
                  </a>
                  and
                  <a
                    style={{ margin: "0px 0px 0px 3px" }}
                    href="https://help.webex.com/en-us/article/4pj83q/Set-your-meeting-scheduling-preferences"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    user
                  </a>
                  , or turn on
                  <a
                    style={{ margin: "0px 3px 0px 3px" }}
                    href="https://help.webex.com/en-us/article/lzi8h2/Show-or-hide-automated-closed-captions-during-a-Webex-meeting-or-webinar"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Closed Captions
                  </a>
                  . Cisco AI Assistant (if available) must be
                  <a
                    style={{ margin: "0px 3px 0px 3px" }}
                    href="https://help.webex.com/en-us/article/9dofjcb/Administer-Cisco-AI-Assistant-in-Control-Hub"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    OFF
                  </a>
                  to use Webex Assistant.
                </WebexSubtext>
                <br />
                <WebexSettings></WebexSettings>
                <HorizontalLine style={{ marginTop: "17px" }}></HorizontalLine>
              </div>
            ) : null}
            {this.props.user.todoistIntegrated &&
            this.props.user.todoistAccessTokenExists ? (
              <div>
                <Integration>
                  <TodoistLogo
                    logoText={"Todoist"}
                    height={"19px"}
                    width={"19px"}
                  ></TodoistLogo>
                  <Link onClick={this.todoistReauthHandler}>
                    <b>Remove Integration</b>
                  </Link>
                </Integration>
                <br />
                <TodoistSettings></TodoistSettings>
                <HorizontalLine style={{ marginTop: "17px" }}></HorizontalLine>
              </div>
            ) : null}
            {this.props.user.basicOpsIntegrated &&
            this.props.user.basicOpsRefreshTokenExists ? (
              <div>
                <Integration>
                  <BasicOpsLogo
                    logoText={"BasicOps"}
                    height={"19px"}
                    width={"19px"}
                  ></BasicOpsLogo>
                  <Link onClick={this.removeBasicOpsHandler}>
                    <b>Remove Integration</b>
                  </Link>
                </Integration>
                <HorizontalLine style={{ marginTop: "17px" }}></HorizontalLine>
              </div>
            ) : null}
          </IntegrationsContainer>
        </Tile>
      </Body>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestWebexReauth: (accessToken, userId) =>
      dispatch(actions.requestWebexReauth(accessToken, userId)),
    requestTodoistReauth: (accessToken, userId) =>
      dispatch(actions.requestTodoistReauth(accessToken, userId)),
    removeBasicOps: (accessToken, userId) =>
      dispatch(actions.removeBasicOps(accessToken, userId)),
  };
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    user: state.user,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IntegrationsPanel)
);

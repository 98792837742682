import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import * as actions from "../../../../store/actions/index";
import Capture from "../../../../services/Capture";
import DarkRoundButton from "../../../../components/UI/Buttons/DarkRoundButton";
import LightLogoBug from "../../../../components/UI/Logos/LightLogoBug";
import ReadyToCollaborate from "./BackgroundTexts/ReadyToCollaborate";
import Attendees from "./Attendees/Attendees";
import MeetingSurvey from "../../../../components/Surveys/MeetingSurvey";
import GetUserMediaTemp from "../GetUserMedia/GetUserMediaTemp";
import { media } from "../../../../components/UI/StyleUtils";
import InviteOthers from "./InviteParticipants/InviteOthers";
import { wait } from "../../../../utility";
import PersonRemovalToggle from "./PersonRemovalToggle/PersonRemovalToggle";

const VideoContainer = styled.div`
  background-color: rgb(31, 30, 33);
  margin: 15px auto;
  display: ${(props) => (props.showStream ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  height: inherit;
  width: 100%;
  color: #ffffffd1;
  font-weight: bolder;
  font-size: 22px;
`;

const CoreBody = styled.div`
  height: 82vh;
`;

const TopToolbarButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 311px;
  margin: 12px auto 0 auto;
`;

const LogoContainer = styled.div`
  display: none;
  ${media.gtSm`
    display: inline-block;
    position: absolute;
    top: 0px;
    left: 18px;
  `};
`;

export class AttendeeTypes {
  static HOST = "HOST";
  static PARTICIPANT = "PARTICIPANT";
}

class MeetingRoom extends Component {
  captureSvc;
  videoElement;

  //TODO: potentially implement a FSM to capture the various "stages" of a meeting. Alternatively, implement Capture service using the pub/sub model.
  state = {
    meetingInSession: false,
    displayVideoStream: false,
    haveMediaAccess: false,
    displaySurvey: false,
    meetingLink: `${process.env.REACT_APP_HOST}/joinmeeting/${this.props.session.meetingId}`,
    inviteOthersModalOpen: false,
    endedByHost: false,
    micEnabled: true,
    personRemovalEnabled: false,
  };

  componentDidMount() {
    this.captureSvc = new Capture(
      this.props.updateRemoteAttendees,
      () => this.props.session.remoteAttendees,
      this.sessionTerminated,
      this.setPersonRemovalState
    );
  }

  componentWillUnmount() {
    this.captureSvc.stop();
  }

  shareBoard = () => {
    // TODO: We need to start the meeting as soon as the user enters the room
    if (this.props.userIsHost)
      this.captureSvc.startAsPresenter(
        this.props.session.meetingId,
        this.props.session.localUser,
        this.videoElement,
        this.permissionsScreenToggle
      );
    else
      this.captureSvc.startAsViewer(
        this.props.session.meetingId,
        this.props.session.localUser,
        this.videoElement,
        this.permissionsScreenToggle
      );
    this.setState({ meetingInSession: true });
  };

  sessionTerminated = (terminatedBy) => {
    if (terminatedBy === AttendeeTypes.HOST)
      this.setState({ endedByHost: true }, (_) =>
        wait(3000).then((_) => this.setState({ displaySurvey: true }))
      );
    else this.setState({ displaySurvey: true });
  };

  toggleInviteOthersModal = () => {
    if (this.captureSvc.getMeetingId())
      this.setState({
        inviteOthersModalOpen: !this.state.inviteOthersModalOpen,
      });
  };

  stopClickHandler = () => {
    this.captureSvc.stop();
  };

  personRemovalToggleHandler = () => {
    this.captureSvc.initiatePersonRemovalToggleFlip();
  };

  displayVideoToggle = () =>
    this.setState({ displayVideoStream: !this.state.displayVideoStream });

  permissionsScreenToggle = () => this.setState({ haveMediaAccess: true }); // will only need to set it to true

  toggleMic = () => {
    this.setState({
      micEnabled: this.captureSvc.toggleMic(),
    });
  };

  setPersonRemovalState = (newState) => {
    console.log("new person removal toggle state is: " + newState);
    this.setState({
      personRemovalEnabled: newState,
    });
  };

  render() {
    const shareBoardButton = (
      <DarkRoundButton
        title={this.props.userIsHost ? "Start" : "Join"}
        onClick={this.shareBoard}
        mainIcon={"merge_whiteboard_static_icon"}
        hoverIcon={"merge_whiteboard_hover_icon"}
        alt={"merge_whiteboard"}
        disabled={this.state.meetingInSession}
        ripple={!this.state.meetingInSession}
      />
    );
    const inviteButton = (
      <DarkRoundButton
        title={"Share"}
        onClick={this.toggleInviteOthersModal}
        mainIcon={"participants_static_icon"}
        hoverIcon={"participants_hover_icon"}
        alt={"invite"}
        disabled={!this.state.meetingInSession}
      />
    );
    const micButton = (
      <DarkRoundButton
        title={this.state.micEnabled ? "Mute" : "Unmute"}
        onClick={this.toggleMic}
        mainIcon={this.state.micEnabled ? "mute_off_icon" : "mute_on_icon"}
        alt={"microphone control"}
        disabled={!this.state.meetingInSession}
      />
    );

    const stopMeetingButton = (
      <DarkRoundButton
        title={"Stop"}
        onClick={this.stopClickHandler}
        mainIcon={"end_meeting_static_icon"}
        hoverIcon={"end_meeting_hover_icon"}
        alt={"stop"}
      />
    );

    const personRemovalToggle = (
      <PersonRemovalToggle
        title={
          "This feature will remove the presenter from the screen" +
          " to allow for better focus on the whiteboard presentation and to improve content search results. For best results," +
          " ensure that you have consistent lighting on your whiteboard."
        }
        onClick={this.personRemovalToggleHandler}
        personRemovalEnabled={this.state.personRemovalEnabled}
        disabled={!this.state.meetingInSession}
      />
    );

    let instructions = null;
    if (!this.state.meetingInSession)
      instructions = <ReadyToCollaborate userIsHost={this.props.userIsHost} />;
    else if (!this.state.haveMediaAccess)
      instructions = <GetUserMediaTemp userIsHost={this.props.userIsHost} />;

    return (
      <div style={{ position: "relative" }}>
        <TopToolbarButtons>
          {shareBoardButton} {inviteButton} {micButton} {personRemovalToggle}{" "}
          {stopMeetingButton}{" "}
        </TopToolbarButtons>

        <LogoContainer>
          <LightLogoBug width={"52.5px"} />
        </LogoContainer>
        <CoreBody>
          <MeetingSurvey isOpen={this.state.displaySurvey} />
          <InviteOthers
            isOpen={this.state.inviteOthersModalOpen}
            onRequestClose={this.toggleInviteOthersModal}
            meetingLink={this.state.meetingLink}
          />

          {instructions}
          <VideoContainer showStream={this.state.displayVideoStream}>
            {this.state.endedByHost ? (
              "Host ended the meeting."
            ) : (
              <video
                style={{ height: "inherit" }}
                ref={(component) => (this.videoElement = component)}
                onLoadedMetadata={this.displayVideoToggle}
                autoPlay
              />
            )}
          </VideoContainer>
        </CoreBody>
        <Attendees list={this.props.session.remoteAttendees} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateRemoteAttendees: (attendees, typeOfEdit) =>
      dispatch(actions.updateRemoteAttendees(attendees, typeOfEdit)),
  };
};

const mapStateToProps = (state) => {
  return {
    session: state.meetingSession,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MeetingRoom)
);

import React, { Component, createRef } from "react";
import styled from "styled-components";

import HybridModal from "../UI/Modals/Hybrid/HybridModal";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Link } from "../UI/Styles";
import SentimentLineChartTooltip from "./SentimentLineChartTooltip";
import SentimentLineChartDot from "./SentimentLineChartDot";
import moment from "moment";
import CheckBox from "../UI/Forms/Checkbox";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceArea,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import { rgbToColorString } from "polished";

const ModalBody = styled.div`
  background-color: #f1f7fb;
`;

const Sentiments = styled.div`
  background-color: white;
  padding: 35px 41px 20px 41px;
  line-height: 24px;
  font-size: 16px;
  text-align: center;
`;

const SentimentsTitle = styled.div`
  margin: 0 20px 20px 20px;
  letter-spacing: 0.5px;
  font-size: 28px;
  line-height: 37px;
`;

const SentimentsContent = styled.div`
  font-size: 14px; //per UI mockup-up 16px.
  line-height: 20px; //per UI mock-up, 24px.
  text-align: left;
  overflow-y: auto;
  height: 550px;
  min-width: 400px;
  white-space: pre-line;
`;

const BarChartTitle = styled.div`
  margin: 0px 0px 8px 0px;
  letter-spacing: 0.5px;
  font-size: 16px;
  line-height: 37px;
  text-align: center;
`;

const LineChartTitle = styled.div`
  margin: 5px 0px 3px 0px;
  letter-spacing: 0.5px;
  font-size: 16px;
  text-align: center;
`;

const LineChartSubHeading = styled.div`
  margin: 0px 0px 3px 0px;
  letter-spacing: 0.5px;
  font-size: 12px;
  text-align: center;
`;

const ChartOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
`;

const SpeakerNamesFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SpeakerNames = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
`;

const SpeakerNameInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SpeakerLabel = styled.div`
  display: flex;
  margin-left: 5px;
`;

const SpeakerLegendCircle = styled.div`
  margin-left: 5px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
`;

const MomentsContainer = styled.div``;

const MomentsTitle = styled.div`
  margin: 0px 0px 8px 0px;
  letter-spacing: 0.5px;
  font-size: 16px;
  text-align: center;
`;

const Moments = styled.div`
  display: flex;
`;

const PositiveMoments = styled.div`
  margin: 0px 20px 20px 20px;
  max-width: 430px;
`;

const NegativeMoments = styled.div`
  margin: 0px 20px 20px 0px;
  max-width: 430px;
`;

const MomentsSectionTitle = styled.div`
  margin-bottom: 10px;
  text-decoration: underline;
`;

const Moment = styled.div`
  display: flex;
  flex-direction: row;
`;

const MomentContent = styled.div`
  margin-left: 10px;
`;

class SentimentsModal extends Component {
  state = {
    data: null,
    left: null,
    right: null,
    refAreaLeft: null,
    refAreaRight: null,
    top: null,
    bottom: null,
    activeIndex: null,
    speakerNamesCheckedObj: {},
    displaySpeakerNames: "none",
    displayMoments: "none",
    speakerNameColors: {},
    sentimentDistribution: {
      positive: 0,
      negative: 0,
      neutral: 0,
      total: 0,
      title: "Distribution",
    },
    zoomedIn: false,
    momentsRef: null,
  };

  componentDidMount() {
    if (this.props.meetingNotes.sentimentsData) {
      let [left, right] = this.getXAxisDomain(
        this.props.meetingNotes.sentimentsData
      );
      let [bottom, top] = this.getYAxisDomain(
        this.props.meetingNotes.sentimentsData,
        left,
        right
      );

      let newSpeakerNamesCheckedObj = {};
      let newSpeakerNameColors = {};
      if (this.props.meetingTranscript.speakerNames) {
        Object.entries(this.props.meetingTranscript.speakerNames).forEach(
          ([speakerLabel, speakerName]) => {
            const speakerLabelKey = this.getSpeakerLabelKey(speakerLabel);
            newSpeakerNamesCheckedObj[speakerLabelKey] = true;
            newSpeakerNameColors[speakerLabelKey] = this.getRandomColor();
          }
        );
      }

      let newSentimentDistribution = this.calculateSentimentDistribution(
        this.props.meetingNotes.sentimentsData
      );

      let momentsRef = createRef();

      this.setState({
        data: this.props.meetingNotes.sentimentsData.slice(),
        left: left,
        right: right,
        top: top,
        bottom: bottom,
        speakerNamesCheckedObj: newSpeakerNamesCheckedObj,
        speakerNameColors: newSpeakerNameColors,
        sentimentDistribution: newSentimentDistribution,
        momentsRef: momentsRef,
      });
    }
  }

  calculateSentimentDistribution = (data) => {
    let sentimentDistribution = {
      positive: 0,
      negative: 0,
      neutral: 0,
      total: 0,
      title: "Distribution",
    };

    if (data) {
      data.forEach((dataPoint) => {
        if (dataPoint["sentiment"] === "positive") {
          sentimentDistribution["positive"] =
            sentimentDistribution["positive"] + 1;
        } else if (dataPoint["sentiment"] === "negative") {
          sentimentDistribution["negative"] =
            sentimentDistribution["negative"] + 1;
        } else {
          sentimentDistribution["neutral"] =
            sentimentDistribution["neutral"] + 1;
        }
        sentimentDistribution["total"] = sentimentDistribution["total"] + 1;
      });
    }

    return sentimentDistribution;
  };

  getDataMinAndMax = (refData, ref) => {
    let dataMin = null;
    let dataMax = null;

    if (refData && refData.length > 0) {
      if (ref in refData[0]) {
        dataMin = parseFloat(refData[0][ref]);
        dataMax = parseFloat(refData[0][ref]);
      }

      refData.forEach((dataPoint) => {
        if (ref in dataPoint) {
          let refValue = parseFloat(dataPoint[ref]);
          if (refValue > dataMax) {
            dataMax = refValue;
          }

          if (refValue < dataMin) {
            dataMin = refValue;
          }
        }
      });
    }

    return [dataMin, dataMax];
  };

  filterDataByXDomain = (data, left, right) => {
    let filteredData = data.filter((segment) => {
      const startTime = parseFloat(segment["start_time"]);
      return startTime >= left && startTime <= right;
    });
    return filteredData;
  };

  zoomIn = () => {
    let refAreaLeft = parseFloat(this.state.refAreaLeft);
    let refAreaRight = parseFloat(this.state.refAreaRight);
    let data = this.getDataFilteredBySpeakers(
      this.state.speakerNamesCheckedObj
    );

    if (refAreaLeft === refAreaRight || !refAreaRight) {
      this.setState({
        refAreaLeft: null,
        refAreaRight: null,
      });
      return;
    }

    if (refAreaLeft > refAreaRight) {
      // Cursor was dragged leftward
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
    }

    const refData = this.filterDataByXDomain(data, refAreaLeft, refAreaRight);
    const [bottom, top] = this.getYAxisDomain(data, refAreaLeft, refAreaRight);
    let newSentimentDistribution = this.calculateSentimentDistribution(refData);

    this.setState({
      data: refData,
      sentimentDistribution: newSentimentDistribution,
      refAreaLeft: null,
      refAreaRight: null,
      left: refAreaLeft,
      right: refAreaRight,
      bottom: bottom,
      top: top,
      zoomedIn: true,
    });
  };

  zoomOut = () => {
    let data = this.getDataFilteredBySpeakers(
      this.state.speakerNamesCheckedObj
    );
    const [left, right] = this.getXAxisDomain(data);
    const [bottom, top] = this.getYAxisDomain(data, left, right);

    let newSentimentDistribution = this.calculateSentimentDistribution(data);

    this.setState(() => ({
      data: data,
      sentimentDistribution: newSentimentDistribution,
      refAreaLeft: null,
      refAreaRight: null,
      left: left,
      right: right,
      bottom: bottom,
      top: top,
      zoomedIn: false,
    }));
  };

  getChartData = () => {
    if (this.state.data) {
      return this.state.data.slice();
    } else if (this.props.meetingNotes.sentimentsData) {
      return this.props.meetingNotes.sentimentsData.slice();
    } else {
      return null;
    }
  };

  getXAxisDomain = (data) => {
    let [left, right] = this.calculateAxisXDomain(data, "start_time", 10);
    return [left, right];
  };

  getYAxisDomain = (data, left, right) => {
    let [bottom, top] = this.calculateAxisYDomain(
      data,
      left,
      right,
      "sentiment_score",
      0.2
    );
    return [bottom, top];
  };

  calculateAxisXDomain = (data, ref, offset) => {
    let left = null;
    let right = null;

    if (data) {
      [left, right] = this.getDataMinAndMax(data, ref);
    }

    if (left === null) {
      left = 10;
    }

    if (right === null) {
      right = 1990;
    }

    left = left - offset;
    right = right + offset;

    if (left < 0) {
      left = 0;
    }

    return [parseFloat(left.toFixed(1)), parseFloat(right.toFixed(1))];
  };

  calculateAxisYDomain = (data, left, right, ref, offset) => {
    let bottom = null;
    let top = null;

    if (data) {
      let refData = this.filterDataByXDomain(data, left, right);
      [bottom, top] = this.getDataMinAndMax(refData, ref);
    }

    if (bottom === null) {
      bottom = -0.8;
    }

    if (top === null) {
      top = 0.8;
    }

    bottom = bottom - offset;
    top = top + offset;

    return [parseFloat(bottom.toFixed(1)), parseFloat(top.toFixed(1))];
  };

  handleChartClick = (data, index) => {
    if (index !== this.state.activeIndex) {
      this.setState({ activeIndex: index });
    }
  };

  getDataFilteredBySpeakers = (speakerNamesCheckedObj) => {
    let data = this.props.meetingNotes.sentimentsData.slice();
    let filteredData = {};
    if (data) {
      let selectedSpeakerNames = [];
      Object.entries(speakerNamesCheckedObj).forEach(
        ([speakerLabel, checked]) => {
          if (checked) {
            selectedSpeakerNames.push(speakerLabel);
          }
        }
      );
      filteredData = data.filter((segment) => {
        return selectedSpeakerNames.includes(segment["speaker_label"]);
      });
    }

    return filteredData;
  };

  onSpeakerNameChecked = (speakerLabel) => {
    let newSpeakerNamesCheckedObj = this.state.speakerNamesCheckedObj;
    if (speakerLabel in this.state.speakerNamesCheckedObj) {
      newSpeakerNamesCheckedObj[speakerLabel] =
        !this.state.speakerNamesCheckedObj[speakerLabel];
      let data = this.getDataFilteredBySpeakers(newSpeakerNamesCheckedObj);
      if (this.state.zoomedIn) {
        data = this.filterDataByXDomain(
          data,
          this.state.left,
          this.state.right
        );
      }
      let [left, right] = this.getXAxisDomain(data);
      let [bottom, top] = this.getYAxisDomain(data, left, right);
      let newSentimentDistribution = this.calculateSentimentDistribution(data);
      this.setState({
        speakerNamesCheckedObj: newSpeakerNamesCheckedObj,
        data: data,
        sentimentDistribution: newSentimentDistribution,
        left: left,
        right: right,
        bottom: bottom,
        top: top,
      });
    }
  };

  toggleSpeakerNamesDisplay = () => {
    if (this.state.displaySpeakerNames === "none") {
      this.setState({ displaySpeakerNames: "inline" });
    } else {
      this.setState({ displaySpeakerNames: "none" });
    }
  };

  toggleMomentsDisplay = () => {
    if (this.state.displayMoments === "none") {
      this.setState({ displayMoments: "inline" }, () => {
        if (this.state.momentsRef) {
          this.state.momentsRef.current.scrollIntoView({ behavior: "instant" });
        }
      });
    } else {
      this.setState({ displayMoments: "none" });
    }
  };

  getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  getRandomColor = () => {
    const red = this.getRandomNumber(0, 255);
    const green = this.getRandomNumber(0, 255);
    const blue = this.getRandomNumber(0, 255);

    const hexColor = rgbToColorString({ red: red, green: green, blue: blue });

    return hexColor;
  };

  getDotColor = (dataPoint) => {
    return this.state.speakerNameColors[dataPoint["speaker_label"]];
  };

  onTimestampClick = (event, seconds) => {
    event.stopPropagation();
    this.props.openTranscriptModal(seconds);
  };

  hideTooltip = () => {
    this.setState({ activeIndex: null });
  };

  getSignificantMoments = (sentiment) => {
    let moments = [];

    if (this.state.data) {
      let data = this.state.data.slice();
      if (sentiment === "positive") {
        data.sort(
          (a, b) =>
            parseFloat(b["sentiment_score"]) - parseFloat(a["sentiment_score"])
        );
      } else {
        data.sort(
          (a, b) =>
            parseFloat(a["sentiment_score"]) - parseFloat(b["sentiment_score"])
        );
      }
      data.forEach((dataPoint, idx) => {
        if (dataPoint["sentiment"] === sentiment) {
          moments.push(
            <Moment key={"sm" + idx}>
              <Link
                onClick={(event) => {
                  this.onTimestampClick(
                    event,
                    parseFloat(dataPoint["start_time"])
                  );
                }}
              >
                {this.getFormattedTimestamp(dataPoint["start_time"])}
              </Link>
              <MomentContent>
                {dataPoint["emotions"] ? (
                  <b>{"[" + dataPoint["emotions"] + "] "}</b>
                ) : null}
                {dataPoint["content"]}
              </MomentContent>
            </Moment>
          );
        }
      });
    }

    return moments;
  };

  getFormattedTimestamp = (seconds) => {
    const timestamp = moment("2020-01-01 00:00:00").add(
      moment.duration(parseFloat(seconds), "seconds")
    );
    const timestampFormatted = timestamp.format("H:mm:ss");
    return timestampFormatted;
  };

  getSpeakerLabelKey = (speakerLabel) => {
    // Convert speakerLabel (Speaker: <insert number>) to this format: spk_<insert number>
    return `spk_${parseInt(speakerLabel.split(" ")[1]) - 1}`;
  };

  render() {
    return (
      <div>
        <HybridModal
          isOpen={this.props.isOpen}
          shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
          onRequestClose={this.props.onRequestClose}
          padding={"0px"}
          maxHeight={"100vh"}
          width={"1000px"}
        >
          <ModalBody>
            <Sentiments>
              <SentimentsTitle>{this.props.meetingName}</SentimentsTitle>
              <SentimentsContent>
                <BarChartTitle>
                  {" "}
                  Sentiment Distribution Over{" "}
                  {this.state.sentimentDistribution
                    ? this.state.sentimentDistribution["total"]
                    : 0}{" "}
                  Audio Snippets{" "}
                </BarChartTitle>
                <ResponsiveContainer width="100%" height="13%">
                  <BarChart
                    width={500}
                    height={300}
                    data={[this.state.sentimentDistribution]}
                    margin={{
                      top: 0,
                      right: 40,
                      left: 20,
                      bottom: 0,
                    }}
                    layout="vertical"
                  >
                    <XAxis
                      type="number"
                      display="none"
                      domain={[
                        0,
                        this.state.sentimentDistribution
                          ? this.state.sentimentDistribution["total"]
                          : 0,
                      ]}
                      ticks={[
                        0,
                        this.state.sentimentDistribution
                          ? this.state.sentimentDistribution["total"]
                          : 0,
                      ]}
                      axisLine={false}
                      tickLine={false}
                    />
                    <YAxis
                      type="category"
                      dataKey="title"
                      axisLine={false}
                      tickLine={false}
                    />
                    <Legend wrapperStyle={{ top: 32 }} />
                    <Bar
                      name={`negative (${
                        this.state.sentimentDistribution
                          ? this.state.sentimentDistribution["negative"]
                          : 0
                      })`}
                      dataKey="negative"
                      stackId="a"
                      fill="#ff1a1a"
                      radius={[5, 0, 0, 5]}
                    />
                    <Bar
                      name={`neutral (${
                        this.state.sentimentDistribution
                          ? this.state.sentimentDistribution["neutral"]
                          : 0
                      })`}
                      dataKey="neutral"
                      stackId="a"
                      fill="#ffae1a"
                    />
                    <Bar
                      name={`positive (${
                        this.state.sentimentDistribution
                          ? this.state.sentimentDistribution["positive"]
                          : 0
                      })`}
                      dataKey="positive"
                      stackId="a"
                      fill="#1a8d1a"
                      radius={[0, 5, 5, 0]}
                    />
                  </BarChart>
                </ResponsiveContainer>
                <LineChartTitle>
                  Sentiment Scores of Audio Snippets Over Time
                </LineChartTitle>
                <LineChartSubHeading>
                  To view a list of key moments and emotions, click "List
                  Significant Moments" on the bottom right corner.{" "}
                </LineChartSubHeading>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart
                    width={800}
                    height={400}
                    data={this.state.data}
                    margin={{ top: 0, right: 40, left: 20, bottom: 40 }}
                    onClick={this.handleChartClick}
                    onMouseDown={(e) => {
                      if (e) {
                        this.setState({ refAreaLeft: e.activeLabel });
                      }
                    }}
                    onMouseMove={(e) => {
                      if (e && this.state.refAreaLeft) {
                        this.setState({ refAreaRight: e.activeLabel });
                      }
                    }}
                    onMouseUp={this.zoomIn.bind(this)}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      type="number"
                      allowDataOverflow
                      dataKey="start_time"
                      domain={[this.state.left, this.state.right]}
                      label={{
                        value: "Timestamp (Hours:Minutes:Seconds)",
                        position: "insideBottomRight",
                        offset: -35,
                      }}
                      tickFormatter={(value) => {
                        return this.getFormattedTimestamp(value);
                      }}
                      angle={-45}
                      textAnchor="end"
                      interval={0}
                    />
                    <YAxis
                      type="number"
                      allowDataOverflow
                      domain={[this.state.bottom, this.state.top]}
                      yAxisId="1"
                      label={{
                        value: "Sentiment Score (Between -1 and 1)",
                        position: "insideBottomLeft",
                        angle: -90,
                      }}
                    />
                    <Tooltip
                      trigger="click"
                      content={
                        <SentimentLineChartTooltip
                          onTimestampClick={this.onTimestampClick}
                          speakerNames={
                            this.props.meetingTranscript.speakerNames
                          }
                          getFormattedTimestamp={this.getFormattedTimestamp}
                        />
                      }
                      wrapperStyle={{
                        display: this.state.activeIndex ? "block" : "none",
                        pointerEvents: "auto",
                      }}
                    />
                    <Line
                      type="monotone"
                      dataKey="sentiment_score"
                      name="Sentiment Score"
                      stroke="#8884d8"
                      dot={
                        <SentimentLineChartDot
                          getColor={this.getDotColor}
                          r={4}
                        />
                      }
                      activeDot={
                        <SentimentLineChartDot
                          getColor={this.getDotColor}
                          r={8}
                        />
                      }
                      yAxisId="1"
                    />
                    {this.state.refAreaLeft && this.state.refAreaRight ? (
                      <ReferenceArea
                        yAxisId="1"
                        x1={this.state.refAreaLeft}
                        x2={this.state.refAreaRight}
                        strokeOpacity={0.3}
                      />
                    ) : null}
                  </LineChart>
                </ResponsiveContainer>
                <ChartOptions>
                  <Link onClick={this.zoomOut.bind(this)}> Zoom Out </Link>
                  <Link onClick={this.hideTooltip}> Hide Tooltip </Link>
                  <SpeakerNamesFilterContainer>
                    <Link onClick={this.toggleSpeakerNamesDisplay}>
                      {" "}
                      Filter By Speaker{" "}
                    </Link>
                    <SpeakerNames
                      style={{ display: this.state.displaySpeakerNames }}
                    >
                      {Object.entries(
                        this.props.meetingTranscript.speakerNames
                      ).map(([speakerLabel, speakerName], idx) => {
                        const speakerLabelKey =
                          this.getSpeakerLabelKey(speakerLabel);
                        return (
                          <SpeakerNameInput key={"snc" + idx}>
                            <CheckBox
                              fontSize="12px"
                              required={true}
                              isChecked={
                                speakerLabelKey in
                                this.state.speakerNamesCheckedObj
                                  ? this.state.speakerNamesCheckedObj[
                                      speakerLabelKey
                                    ]
                                  : false
                              }
                              onChange={(event) =>
                                this.onSpeakerNameChecked(speakerLabelKey)
                              }
                              title="Select Speaker"
                            />
                            <SpeakerLabel>
                              {speakerLabel} ({speakerName}){" "}
                            </SpeakerLabel>
                            <SpeakerLegendCircle
                              style={{
                                backgroundColor: this.getDotColor({
                                  speaker_label: speakerLabelKey,
                                }),
                              }}
                            ></SpeakerLegendCircle>
                          </SpeakerNameInput>
                        );
                      })}
                    </SpeakerNames>
                  </SpeakerNamesFilterContainer>
                  <Link onClick={this.toggleMomentsDisplay}>
                    {" "}
                    List Significant Moments (Below){" "}
                  </Link>
                </ChartOptions>
                <MomentsContainer
                  style={{ display: this.state.displayMoments }}
                >
                  <MomentsTitle ref={this.state.momentsRef}>
                    {" "}
                    Significant Moments{" "}
                  </MomentsTitle>
                  <Moments>
                    <PositiveMoments>
                      <MomentsSectionTitle>
                        {" "}
                        Positive Moments{" "}
                      </MomentsSectionTitle>
                      {this.getSignificantMoments("positive")}
                    </PositiveMoments>
                    <NegativeMoments>
                      <MomentsSectionTitle>
                        {" "}
                        Negative Moments{" "}
                      </MomentsSectionTitle>
                      {this.getSignificantMoments("negative")}
                    </NegativeMoments>
                  </Moments>
                </MomentsContainer>
              </SentimentsContent>
            </Sentiments>
          </ModalBody>
        </HybridModal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    meetingNotes: state.meetingNotes,
    meetingTranscript: state.meetingTranscript,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SentimentsModal)
);

import React from "react";

import styles from "./SwitchButton.module.css";

class SwitchButton extends React.Component {
  render() {
    const { height, width, margin } = this.props;

    return (
      <div className={styles.inputDiv} style={{ height, width, margin }}>
        <label className={styles.toggle}>
          <input
            type="checkbox"
            checked={this.props.buttonState}
            onChange={this.props.onClick}
          />

          <span className={styles.slider}></span>
          <span
            className={styles.switchText}
            data-on={this.props.option2}
            data-off={this.props.option1}
          ></span>
          <span className={styles.highlightedText}>
            {this.props.highlightText}
          </span>
        </label>
      </div>
    );
  }
}

export default SwitchButton;

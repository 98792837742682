import React from "react";
import styled from "styled-components";
import { HorizontalLine } from "../../../UI/Lines/Lines";

const TileContainer = styled.div`
  margin: auto;
  width: 95%;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(12, 0, 51, 0.1);
`;

const Title = styled.div`
  height: 46px;
  color: #a0a0a0;
  line-height: 13px;
  font-size: 12px;
  font-weight: bolder;
  letter-spacing: 0.5px;
  padding: 16px 0 0 19px;
`;

const Body = styled.div`
  padding: 10px;
`;

const Tile = (props) => {
  return (
    <TileContainer>
      <Title>{props.title}</Title>
      <HorizontalLine />
      <Body>{props.children}</Body>
    </TileContainer>
  );
};

export default Tile;

import React, { Component } from "react";
import styled from "styled-components";

import GradientHybridModalNarrow from "../UI/Modals/Hybrid/GradientHybridModalNarrow";
import LargeButtonPurple from "../UI/Buttons/LargeButton";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../store/actions";
import { wait } from "../../utility";
import basicOpsIntegrationLogo from "../../assets/images/basicops/basicops_integration_logo.png";

const ModalBody = styled.div`
  background-color: #f1f7fb;
`;

const Instructions = styled.div`
  background-color: white;
  padding: 35px 41px 20px 41px; //per UI mock-up, padding: 41px 41px 37px 41px;
  line-height: 24px;
  font-size: 16px;
  opacity: 0.8;
  text-align: center;
`;

const Title = styled.div`
  margin: 0 20px 8px 20px;
  letter-spacing: 0.5px;
  font-size: 28px;
  line-height: 37px;
  margin-bottom: 20px;
`;

const Explanation = styled.div`
  font-size: 14px; //per UI mockup-up 16px.
  opacity: 0.7;
  line-height: 20px; //per UI mock-up, 24px.
  text-align: left;
  margin-top: 20px;
`;

const AuthorizePanel = styled.form`
  padding: 33px 26px 26px 26px;
  color: #170f30;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
`;

class BasicOpsModal extends Component {
  state = {
    disabled: false,
  };

  onSubmitHandler = (event) => {
    event.preventDefault();
    this.setState({ disabled: true });
    this.props
      .getBasicOpsAuthorizationUrl(this.props.accessToken)
      // induce a two second delay before doing the redirect to make it feel more natural
      .then(() => wait(2000))
      .then(() => {
        window.location.assign(this.props.basicOpsAuthorizationUrl);
      })
      .catch((error) => {
        this.setState({ disabled: false });
      });
  };

  render() {
    return (
      <GradientHybridModalNarrow
        isOpen={this.props.isOpen}
        shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
        onRequestClose={this.props.onRequestClose}
        padding={"0px"}
        maxHeight={"90vh"}
        top={"100px"}
      >
        <ModalBody>
          <Instructions>
            <Title>Integrate BasicOps</Title>
            <img
              alt="BasicOps Logo"
              src={basicOpsIntegrationLogo}
              style={{ height: "130px", width: "370px" }}
            />
            <Explanation>
              Integrate your BasicOps account with your CaptureMyMeeting
              account. Then, CaptureMyMeeting will automatically store the
              summaries and action items from each of your meeting recordings
              into a personal Note in BasicOps going forward. You can then add
              your Note to a project, and then collaborate on your notes with
              your team and turn action items into BasicOps tasks in one click.
            </Explanation>
          </Instructions>
          <AuthorizePanel onSubmit={this.onSubmitHandler}>
            <LargeButtonPurple
              type="submit"
              loading={this.state.disabled}
              disabled={this.state.disabled}
            >
              Integrate BasicOps
            </LargeButtonPurple>
          </AuthorizePanel>
        </ModalBody>
      </GradientHybridModalNarrow>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBasicOpsAuthorizationUrl: (accessToken) =>
      dispatch(actions.getBasicOpsAuthorizationUrl(accessToken)),
  };
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    basicOpsAuthorizationUrl: state.user.basicOpsAuthorizationUrl,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BasicOpsModal)
);

import api from "../../services/api";
import * as actionTypes from "./actionTypes";

import { paginateResults } from "../utility";

/****************************************
             GET MEETINGS
 *****************************************/

//TODO: re-purpose these for searching!
const getMeetingsStart = (query = "") => {
  return {
    type: actionTypes.GET_MEETINGS_START,
    query: query,
  };
};

const getMeetingsSuccess = (
  meetingsData,
  meetingId,
  operation,
  currentPageNumber
) => {
  return {
    type: actionTypes.GET_MEETINGS_SUCCESS,
    totalMeetings: meetingsData.totalMeetings,
    data: meetingsData.pagedData,
    currentPageNumber: currentPageNumber,
    meetingId: meetingId,
    meetingName:
      meetingId && meetingsData.pagedData.length > 0
        ? meetingsData.pagedData[0].name
        : null,
    operation: operation,
  };
};

const getMeetingsFail = (error) => {
  return {
    type: actionTypes.GET_MEETINGS_FAIL,
    error: error,
  };
};

const getNonFilteredMeetings = (accessToken, query, pageNumber) => {
  const cleanResponse = (response) => {
    const body = response.data;
    return {
      totalMeetings: body.count,
      pagedData: body.results.map((meetingInfo) => ({
        id: meetingInfo.id,
        name: meetingInfo.name,
        startTime: meetingInfo.start_time,
        endTime: meetingInfo.end_time,
        numAttendees: meetingInfo.attendee_count,
        numBoards: meetingInfo.boards_shared,
        recordingAvailable: meetingInfo.recording_available,
        attendees: meetingInfo.attendees.map((attendeeInfo) => ({
          id: attendeeInfo.emails ? attendeeInfo.emails.id : null,
          email: attendeeInfo.emails ? attendeeInfo.emails.email : null,
          name: attendeeInfo.name,
          role: attendeeInfo.role,
        })),
        hosts: meetingInfo.attendees
          .filter((attendeeInfo) => {
            return attendeeInfo.role === "host";
          })
          .map((attendeeInfo) => ({
            id: attendeeInfo.emails ? attendeeInfo.emails.id : null,
            email: attendeeInfo.emails ? attendeeInfo.emails.email : null,
            name: attendeeInfo.name,
            role: attendeeInfo.role,
          })),
      })),
    };
  };

  return (dispatch) => {
    dispatch(getMeetingsStart(query));
    return api.meetings
      .getPagedMeetings(accessToken, pageNumber)
      .then((response) => {
        console.log("Meeting API call was successful for page:" + pageNumber);
        dispatch(
          getMeetingsSuccess(cleanResponse(response), null, null, pageNumber)
        );
      })
      .catch((error) => {
        console.log("Meeting API call failed:");
        console.log(error);
        dispatch(getMeetingsFail(error));
      });
  };
};

const searchMeetingsByName = (accessToken, query, pageNumber, pageSize) => {
  const cleanResponse = (body) => {
    return {
      totalMeetings: body.totalMatches,
      pagedData: body.pagedData.map((meetingInfo) => ({
        id: meetingInfo.id,
        name: meetingInfo.name,
        startTime: meetingInfo.start_time,
        endTime: meetingInfo.end_time,
        numAttendees: meetingInfo.attendee_count,
        numBoards: meetingInfo.boards_shared,
        recordingAvailable: meetingInfo.recording_available,
        attendees: meetingInfo.attendees.map((attendeeInfo) => ({
          id: attendeeInfo.emails ? attendeeInfo.emails.id : null,
          email: attendeeInfo.emails ? attendeeInfo.emails.email : null,
          name: attendeeInfo.name,
          role: attendeeInfo.role,
        })),
        hosts: meetingInfo.attendees
          .filter((attendeeInfo) => {
            return attendeeInfo.role === "host";
          })
          .map((attendeeInfo) => ({
            id: attendeeInfo.emails ? attendeeInfo.emails.id : null,
            email: attendeeInfo.emails ? attendeeInfo.emails.email : null,
            name: attendeeInfo.name,
            role: attendeeInfo.role,
          })),
      })),
    };
  };

  return (dispatch) => {
    dispatch(getMeetingsStart(query));
    return api.search
      .meetingsByName(accessToken, query, pageNumber)
      .then((response) => {
        console.log("Search API call was successful for page:" + pageNumber);
        const pagedResponse = paginateResults(response, pageNumber, pageSize);
        const cleanedResponse = cleanResponse(pagedResponse);
        dispatch(getMeetingsSuccess(cleanedResponse, null, null, pageNumber));
      })
      .catch((error) => {
        console.log("Search API call failed:");
        console.log(error);
        dispatch(getMeetingsFail(error));
      });
  };
};

const getMeeting = (accessToken, query, meetingId, operation) => {
  const cleanResponse = (response) => {
    const meetingInfo = response.data;
    return {
      totalMeetings: 1,
      pagedData: [
        {
          id: meetingInfo.id,
          name: meetingInfo.name,
          startTime: meetingInfo.start_time,
          endTime: meetingInfo.end_time,
          numAttendees: meetingInfo.attendee_count,
          numBoards: meetingInfo.boards_shared,
          recordingAvailable: meetingInfo.recording_available,
          attendees: meetingInfo.attendees.map((attendeeInfo) => ({
            id: attendeeInfo.emails ? attendeeInfo.emails.id : null,
            email: attendeeInfo.emails ? attendeeInfo.emails.email : null,
            name: attendeeInfo.name,
            role: attendeeInfo.role,
          })),
          hosts: meetingInfo.attendees
            .filter((attendeeInfo) => {
              return attendeeInfo.role === "host";
            })
            .map((attendeeInfo) => ({
              id: attendeeInfo.emails ? attendeeInfo.emails.id : null,
              email: attendeeInfo.emails ? attendeeInfo.emails.email : null,
              name: attendeeInfo.name,
              role: attendeeInfo.role,
            })),
        },
      ],
    };
  };

  return (dispatch) => {
    dispatch(getMeetingsStart(query));
    return api.meetings
      .getMeeting(accessToken, meetingId)
      .then((response) => {
        console.log("Meeting API call for a single meeting was successful");
        dispatch(
          getMeetingsSuccess(cleanResponse(response), meetingId, operation, 1)
        );
      })
      .catch((error) => {
        console.log("Meeting API call failed:");
        console.log(error);
        dispatch(getMeetingsFail(error));
      });
  };
};

//TODO: rename it to searchMeetings and make it generic for all query types.
export const getMeetings = (
  accessToken,
  query,
  meetingId,
  operation,
  pageNumber,
  pageSize
) => {
  return (dispatch) => {
    console.log("getMeetings() called with pageNumber", pageNumber);
    if (query === "" && !meetingId)
      return getNonFilteredMeetings(accessToken, query, pageNumber)(dispatch);
    else if (meetingId)
      return getMeeting(accessToken, query, meetingId, operation)(dispatch);
    else
      return searchMeetingsByName(
        accessToken,
        query,
        pageNumber,
        pageSize
      )(dispatch);
  };
};

/*****************************************************
             SEARCH MEETINGS FOR TEXT CONTENT
 *****************************************************/

export const updateQueryType = (newQueryType) => {
  return (dispatch) =>
    dispatch({
      type: actionTypes.UPDATE_SEARCH_QUERY_TYPE,
      queryType: newQueryType,
    });
};

const searchMeetingsTextContentStart = (query = "") => {
  return {
    type: actionTypes.SEARCH_MEETINGS_TEXT_CONTENT_START,
    query: query,
  };
};

const searchMeetingsTextContentSuccess = (
  result,
  meetingId,
  currentPageNumber
) => {
  return {
    type: actionTypes.SEARCH_MEETINGS_TEXT_CONTENT_SUCCESS,
    totalMatches: result.totalMatches,
    matchedData: result.matchedData,
    currentPageNumber: currentPageNumber,
    meetingId: meetingId,
    meetingName:
      meetingId && result.matchedData.length > 0
        ? result.matchedData[0].meeting_name
        : null,
  };
};

const searchMeetingsTextContentFail = (error) => {
  return {
    type: actionTypes.SEARCH_MEETINGS_TEXT_CONTENT_FAIL,
    error: error,
  };
};

export const searchMeetingsForTextContent = (
  accessToken,
  query,
  meetingId,
  pageNumber,
  pageSize = 12
) => {
  const cleanResponse = (body) => {
    console.log("Raw results are: ", body);
    return {
      totalMatches: body.totalMatches,
      matchedData: body.pagedData.map((result) => ({
        meetingName: result.meeting_name,
        meetingId: result.meeting_id,
        imageUrl: result.image_path,
        thumbnailUrl: result.thumbnail_path,
        scale: result.scale,
        timestamp: result.timestamp,
        words: result.matches,
      })),
    };
  };

  return (dispatch) => {
    dispatch(searchMeetingsTextContentStart(query));

    return api.search
      .meetingTextContent(accessToken, query, meetingId, pageNumber)
      .then((response) => {
        console.log(
          "Search API call was successful text content for page:" + pageNumber
        );
        const pagedResponse = paginateResults(response, pageNumber, pageSize);
        const cleanData = cleanResponse(pagedResponse);
        dispatch(
          searchMeetingsTextContentSuccess(cleanData, meetingId, pageNumber)
        );
      })
      .catch((error) => {
        console.log("Search API call failed for text content:");
        console.log(error);
        dispatch(searchMeetingsTextContentFail(error));
      });
  };
};

/*****************************************************
            SEARCH MEETINGS FOR VOICE CONTENT
 *****************************************************/

const searchMeetingsForVoiceContentStart = (query = "") => {
  return {
    type: actionTypes.SEARCH_MEETINGS_VOICE_CONTENT_START,
    query: query,
  };
};

const searchMeetingsForVoiceContentSuccess = (
  result,
  meetingId,
  currentPageNumber
) => {
  return {
    type: actionTypes.SEARCH_MEETINGS_VOICE_CONTENT_SUCCESS,
    totalMatches: result.totalMatches,
    matchedData: result.matchedData,
    currentPageNumber: currentPageNumber,
    meetingId: meetingId ? meetingId : null,
    meetingName:
      meetingId && result.matchedData.length > 0
        ? result.matchedData[0].meeting_name
        : null,
  };
};

const searchMeetingsForVoiceContentFail = (error) => {
  return {
    type: actionTypes.SEARCH_MEETINGS_VOICE_CONTENT_FAIL,
    error: error,
  };
};

export const searchMeetingsForVoiceContent = (
  accessToken,
  query,
  meetingId,
  pageNumber,
  pageSize
) => {
  const cleanResponse = (body) => {
    return {
      totalMatches: body.totalMatches,
      matchedData: body.pagedData.map((result) => ({
        meetingName: result.meeting_name,
        meetingId: result.meeting_id,
        timestamp: result.timestamp,
        matchedContent: result.matched_content,
      })),
    };
  };

  return (dispatch) => {
    console.log("The right function for voice recognition was called");
    dispatch(searchMeetingsForVoiceContentStart(query));
    return api.search
      .meetingVoiceContent(accessToken, query, meetingId, pageNumber)
      .then((response) => {
        console.log(
          "Search API call was successful voice content for page:" + pageNumber
        );
        const pagedResponse = paginateResults(response, pageNumber, pageSize);
        const cleanData = cleanResponse(pagedResponse);
        dispatch(
          searchMeetingsForVoiceContentSuccess(cleanData, meetingId, pageNumber)
        );
      })
      .catch((error) => {
        console.log("Search API call failed for voice content:");
        console.log(error);
        dispatch(searchMeetingsForVoiceContentFail(error));
      });
  };
};

/************************************
            DELETE MEETINGS
 ************************************/

const deleteMeetingStart = () => {
  return {
    type: actionTypes.DELETE_MEETING_START,
  };
};

const deleteMeetingSuccess = () => {
  return {
    type: actionTypes.DELETE_MEETING_SUCCESS,
  };
};

const deleteMeetingFail = (error) => {
  return {
    type: actionTypes.DELETE_MEETING_FAIL,
    error: error,
  };
};

export const deleteMeeting = (accessToken, meetingId, meetings) => {
  return (dispatch) => {
    dispatch(deleteMeetingStart());
    return api.meetings
      .deleteMeeting(accessToken, meetingId)
      .then((response) => {
        console.log("API call to delete the meeting was successful");
        console.log("GetMeetings after deleting a meeting");
        getMeetings(
          accessToken,
          meetings.query,
          null,
          null,
          meetings.currentPageNumber,
          meetings.pageSize
        )(dispatch);
        // It is not strictly necessary to dispatch deleteMeetingSuccess action here but it is done to be more consistent
        // with the pattern of how other async (api based) actions are dispatched. This will also help with debugging when necessary.
        dispatch(deleteMeetingSuccess());
      })
      .catch((error) => {
        console.log("Delete meeting API call failed");
        console.log(error);
        dispatch(deleteMeetingFail(error));
      });
  };
};

/****************************************
             Update meeting name
 *****************************************/

const updateMeetingNameStart = () => {
  return {
    type: actionTypes.UPDATE_MEETING_NAME_START,
  };
};

const updateMeetingNameSuccess = () => {
  return {
    type: actionTypes.UPDATE_MEETING_NAME_SUCCESS,
  };
};

const updateMeetingNameFail = (error) => {
  return {
    type: actionTypes.UPDATE_MEETING_NAME_FAIL,
    error: error,
  };
};

export const updateMeetingName = (accessToken, meetingId, meetingName) => {
  // todo: load other useful meeting data as well.

  return (dispatch) => {
    dispatch(updateMeetingNameStart());

    return api.meetings
      .updateMeetingName(accessToken, meetingId, meetingName)
      .then((response) => {
        console.log("Meeting name updated successfully.");
        dispatch(updateMeetingNameSuccess());
      })
      .catch((error) => {
        console.log("There was an error updating the meeting!", error);
        dispatch(updateMeetingNameFail(error));
      });
  };
};

/************************************
            SHARE MEETINGS
 ************************************/

const shareMeetingStart = () => {
  return {
    type: actionTypes.SHARE_MEETING_START,
  };
};

const shareMeetingSuccess = () => {
  return {
    type: actionTypes.SHARE_MEETING_SUCCESS,
  };
};

const shareMeetingFail = (error) => {
  return {
    type: actionTypes.SHARE_MEETING_FAIL,
    error: error,
  };
};

export const shareMeeting = (
  accessToken,
  meetingIds,
  attendees,
  messageString
) => {
  return (dispatch) => {
    dispatch(shareMeetingStart());

    return api.meetings
      .addNewMeetingAttendees(accessToken, meetingIds, attendees, messageString)
      .then((response) => {
        console.log("Meeting shared with attendees successfully.");
        dispatch(shareMeetingSuccess());
      })
      .catch((error) => {
        console.log(
          "There was an error sharing the meeting with the attendees!",
          error
        );
        dispatch(shareMeetingFail(error));
        throw error;
      });
  };
};
/********************************************************
            Delete ATTENDEE FOR SPECIFIC MEETING
 ********************************************************/

const deleteAttendeeStart = () => {
  return {
    type: actionTypes.DELETE_ATTENDEE_START,
  };
};

const deleteAttendeeSuccess = (response) => {
  return {
    type: actionTypes.DELETE_ATTENDEE_SUCCESS,
    meetingData: response.meeting_data,
  };
};

const deleteAttendeeFail = (error) => {
  return {
    type: actionTypes.DELETE_ATTENDEE_FAIL,
    error: error,
  };
};

export const deleteAttendee = (accessToken, meetingId, attendeeEmail) => {
  return async (dispatch) => {
    dispatch(deleteAttendeeStart());
    return api.meetings
      .deleteMeetingAttendee(accessToken, meetingId, attendeeEmail)
      .then((response) => {
        console.log("Attendee deleted successfully.");
        dispatch(deleteAttendeeSuccess(response.data));
      })
      .catch((error) => {
        console.log("There was an error deleting the attendee: ", error);
        dispatch(deleteAttendeeFail(error));
        throw error;
      });
  };
};

import React from "react";

import { getIconRef } from "./IconUtils";

/**
 *
 * @param props
 * {
 *      name: the name of the icon available inside src/functional_icons/ folder
 *      alt: alternative name to display if the image is not available
 * }
 * @returns {*}
 * @constructor
 */
const Icon = (props) => {
  return (
    <img
      className={props.className}
      src={getIconRef(props.name)}
      alt={props.alt}
    />
  );
};

export default Icon;

import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isStripeModalOpen: false,
  isSubscribed: false,
  currentSubscription: null,
  subscriptionProducts: null,
  loadingFlag: "not initially loaded",
};

const subscription = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_STRIPE_MODAL:
      return { ...state, isStripeModalOpen: true };
    case actionTypes.CLOSE_STRIPE_MODAL:
      return { ...state, isStripeModalOpen: false };
    case actionTypes.SET_SUBSCRIPTION_STATUS:
      return {
        ...state,
        isSubscribed: action.payload,
      };
    case actionTypes.GET_CURRENT_SUBSCRIPTION_START:
      return {
        ...state,
        loadingFlag: "loading",
      };
    case actionTypes.GET_CURRENT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loadingFlag: "loaded",
        currentSubscription: action.currentSubscription,
        error: false,
      };
    case actionTypes.GET_CURRENT_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.GET_ALL_SUBSCRIPTION_PRODUCTS_START:
      return {
        ...state,
      };
    case actionTypes.GET_ALL_SUBSCRIPTION_PRODUCTS_SUCCESS:
      return {
        ...state,
        subscriptionProducts: action.subscriptionProducts,
        error: false,
      };
    case actionTypes.GET_ALL_SUBSCRIPTION_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.CREATE_PAYMENT_INTENT_START:
      return {
        ...state,
      };
    case actionTypes.CREATE_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,

        error: false,
      };
    case actionTypes.CREATE_PAYMENT_INTENT_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.GET_CUSTOMER_PORTAL_START:
      return {
        ...state,
      };
    case actionTypes.GET_CUSTOMER_PORTAL_SUCCESS:
      return {
        ...state,

        error: false,
      };
    case actionTypes.GET_CUSTOMER_PORTAL_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.CANCEL_SUBSCRIPTION_START:
      return {
        ...state,
      };
    case actionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,

        error: false,
      };
    case actionTypes.CANCEL_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.FREE_TRIAL_SUBSCRIPTION_START:
      return {
        ...state,
      };
    case actionTypes.FREE_TRIAL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,

        error: false,
      };
    case actionTypes.FREE_TRIAL_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.CLEAR_SUBSCRIPTION:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default subscription;

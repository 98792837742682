import * as actionTypes from "../actions/actionTypes";

const initialState = {
  firstName: null,
  lastName: null,
  company: null,
  email: null,
  userId: null,
  settings: {
    emailNotes: null,
  },
  zoom_refresh_token_exists: null,
  zoom_integrated: null,
  zoom_authorization_url: null,
  webex_refresh_token_exists: null,
  webex_integrated: null,
  webex_authorization_url: null,
  webex_auto_share_on: null,
  webex_auto_record_on: null,
  todoistAuthorizationUrl: null,
  todoistIntegrated: null,
  todoistAccessTokenExists: null,
  todoistAutoStoreToInbox: null,
  basicOpsAuthorizationUrl: null,
  basicOpsIntegrated: null,
  basicOpsRefreshTokenExists: null,
  preffered_plan: null,
  error: null,
  loading: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        company: action.company,
        email: action.email,
        userId: action.userId,
        settings: action.settings,
        zoom_refresh_token_exists: action.zoom_refresh_token_exists,
        zoom_integrated: action.zoom_integrated,
        webex_refresh_token_exists: action.webex_refresh_token_exists,
        webex_integrated: action.webex_integrated,
        webex_auto_share_on: action.webex_auto_share_on,
        webex_auto_record_on: action.webex_auto_record_on,
        todoistIntegrated: action.todoistIntegrated,
        todoistAccessTokenExists: action.todoistAccessTokenExists,
        todoistAutoStoreToInbox: action.todoistAutoStoreToInbox,
        basicOpsIntegrated: action.basicOpsIntegrated,
        basicOpsRefreshTokenExists: action.basicOpsRefreshTokenExists,
        loading: false,
        minutes: action.minutes,
      };
    case actionTypes.GET_USER_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case actionTypes.SIGNUP_USER_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.SIGNUP_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.SIGNUP_USER_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_USER_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case actionTypes.UPDATE_PASSWORD_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_PASSWORD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case actionTypes.FORGET_PASSWORD_START:
      return { ...state, loading: true, error: null };
    case actionTypes.FORGET_PASSWORD_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.FORGET_PASSWORD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case actionTypes.SETTING_NEWPASSWORD_START:
      return { ...state, loading: true, error: null };
    case actionTypes.SETTING_NEWPASSWORD_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.SETTING_NEWPASSWORD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case actionTypes.SET_PREFFERED_PLAN:
      return {
        ...state,
        preffered_plan: action.preffered_plan,
      };
    case actionTypes.UNSET_PREFFERED_PLAN:
      return {
        ...state,
        preffered_plan: null,
      };
    case actionTypes.START_EMAIL_VERIFICATION_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.START_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.START_EMAIL_VERIFICATION_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case actionTypes.UPDATE_USER_SETTINGS_START:
      return {
        ...state,
      };
    case actionTypes.UPDATE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          emailNotes: action.emailNotes,
        },
        error: false,
      };
    case actionTypes.UPDATE_USER_SETTINGS_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.GET_ZOOM_AUTHORIZATION_URL_START:
      return {
        ...state,
      };
    case actionTypes.GET_ZOOM_AUTHORIZATION_URL_SUCCESS:
      return {
        ...state,
        zoom_authorization_url: action.authorization_url,
        error: false,
      };
    case actionTypes.GET_ZOOM_AUTHORIZATION_URL_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.STORE_ZOOM_USER_INFO_START:
      return {
        ...state,
      };
    case actionTypes.STORE_ZOOM_USER_INFO_SUCCESS:
      return {
        ...state,
        zoom_refresh_token_exists: action.zoom_refresh_token_exists,
        zoom_integrated: action.zoom_integrated,
        error: false,
      };
    case actionTypes.STORE_ZOOM_USER_INFO_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.GET_WEBEX_AUTHORIZATION_URL_START:
      return {
        ...state,
      };
    case actionTypes.GET_WEBEX_AUTHORIZATION_URL_SUCCESS:
      return {
        ...state,
        webex_authorization_url: action.authorization_url,
        error: false,
      };
    case actionTypes.GET_WEBEX_AUTHORIZATION_URL_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.STORE_WEBEX_USER_INFO_START:
      return {
        ...state,
      };
    case actionTypes.STORE_WEBEX_USER_INFO_SUCCESS:
      return {
        ...state,
        webex_refresh_token_exists: action.webex_refresh_token_exists,
        webex_integrated: action.webex_integrated,
        webex_auto_share_on: action.webex_auto_share_on,
        webex_auto_record_on: action.webex_auto_record_on,
        error: false,
      };
    case actionTypes.STORE_WEBEX_USER_INFO_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.REQUEST_WEBEX_REAUTH_START:
      return {
        ...state,
      };
    case actionTypes.REQUEST_WEBEX_REAUTH_SUCCESS:
      return {
        ...state,
        error: false,
      };
    case actionTypes.REQUEST_WEBEX_REAUTH_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.UPDATE_WEBEX_USER_SETTINGS_START:
      return {
        ...state,
      };
    case actionTypes.UPDATE_WEBEX_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        webex_auto_share_on: action.webex_auto_share_on,
        webex_auto_record_on: action.webex_auto_record_on,
        error: false,
      };
    case actionTypes.UPDATE_WEBEX_USER_SETTINGS_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.GET_TODOIST_AUTHORIZATION_URL_START:
      return {
        ...state,
      };
    case actionTypes.GET_TODOIST_AUTHORIZATION_URL_SUCCESS:
      return {
        ...state,
        todoistAuthorizationUrl: action.authorizationUrl,
        error: false,
      };
    case actionTypes.GET_TODOIST_AUTHORIZATION_URL_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.STORE_TODOIST_USER_INFO_START:
      return {
        ...state,
      };
    case actionTypes.STORE_TODOIST_USER_INFO_SUCCESS:
      return {
        ...state,
        todoistAccessTokenExists: action.todoistAccessTokenExists,
        todoistIntegrated: action.todoistIntegrated,
        todoistAutoStoreToInbox: action.todoistAutoStoreToInbox,
        error: false,
      };
    case actionTypes.STORE_TODOIST_USER_INFO_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.REQUEST_TODOIST_REAUTH_START:
      return {
        ...state,
      };
    case actionTypes.REQUEST_TODOIST_REAUTH_SUCCESS:
      return {
        ...state,
        error: false,
      };
    case actionTypes.REQUEST_TODOIST_REAUTH_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.UPDATE_TODOIST_USER_SETTINGS_START:
      return {
        ...state,
      };
    case actionTypes.UPDATE_TODOIST_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        todoistAutoStoreToInbox: action.todoistAutoStoreToInbox,
        error: false,
      };
    case actionTypes.UPDATE_TODOIST_USER_SETTINGS_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.GET_BASICOPS_AUTHORIZATION_URL_START:
      return {
        ...state,
      };
    case actionTypes.GET_BASICOPS_AUTHORIZATION_URL_SUCCESS:
      return {
        ...state,
        basicOpsAuthorizationUrl: action.authorizationUrl,
        error: false,
      };
    case actionTypes.GET_BASICOPS_AUTHORIZATION_URL_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.AUTHORIZE_BASICOPS_USER_START:
      return {
        ...state,
      };
    case actionTypes.AUTHORIZE_BASICOPS_USER_SUCCESS:
      return {
        ...state,
        basicOpsRefreshTokenExists: action.basicOpsRefreshTokenExists,
        basicOpsIntegrated: action.basicOpsIntegrated,
        error: false,
      };
    case actionTypes.AUTHORIZE_BASICOPS_USER_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.REMOVE_BASICOPS_START:
      return {
        ...state,
      };
    case actionTypes.REMOVE_BASICOPS_SUCCESS:
      return {
        ...state,
        error: false,
      };
    case actionTypes.REMOVE_BASICOPS_FAIL:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default userReducer;

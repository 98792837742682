import styled from "styled-components";
import { media } from "../../../UI/StyleUtils";

export const MainText = styled.p`
  margin: 0 0 3px 0;
  color: #170f30;
  font-size: 15px;
  font-weight: bolder;
  line-height: 22px;
  text-overflow: ellipsis;

  ${media.gtSm`
		font-size: 16px;
		margin: 0 0 4px 0;
	`};
`;

export const SubText = styled.p`
  margin: 0;
  color: rgba(23, 15, 48, 0.25);
  font-size: 14px;
  font-weight: bolder;
  line-height: 25px;

  ${media.gtSm`
		line-height: 20px;
	`}
`;

export const MainInput = styled.input`
  &&::placeholder {
    color: #170f30;
  }
  &:active {
    border: 1px solid rgba(23, 15, 48, 0.25);
  }

  :focus::placeholder {
    opacity: 50%;
  }
  :hover::placeholder {
    opacity: 50%;
  }
  margin: 0 0 3px 0;
  color: #170f30;
  font-size: 15px;
  font-weight: bolder;
  line-height: 22px;
  text-overflow: ellipsis;
  border: none;
`;

import React from "react";
import styled from "styled-components";
import { getIconRef } from "../../../../../../components/UI/Icon/IconUtils";

/**
 * This component renders the input element inside a parrot green border if isRemoteUser is true otherwise uses a white border.
 */
const AttendeeContainer = styled.div`
  border-radius: 5px;
  display: inline-block;
  height: 159px;
  min-width: 170px;
  box-shadow: 0 2px 4px 0 rgba(12, 0, 51, 0.1);
  margin: 0 1.8% 0 0;
  overflow: hidden;
`;

//TODO: replace with getRefIcon('profile_icon');
const ImagePortion = styled.div`
  height: 66%;
  position: relative;
  background: white url(${getIconRef("profile_icon")}) no-repeat center;
  background-size: contain;
`;

const ImageOverlay = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, #170f304d 0%, #ffffff54 75%);
`;

const Role = styled.span`
  color: white;
  font-size: 11px;
  letter-spacing: 0.9px;
  line-height: 13px;
`;

//
// const Content = styled.div`
// 	position: absolute;
// 	bottom: 0;
// 	height: 91%;
// 	width: 100%;
//
// `;

const ToolBar = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  padding: 0 18px 9px 11px;
  bottom: 0;
  justify-content: space-between;
`;

const TextPortion = styled.div`
  height: 34%;
  background-color: #2d2349;
  padding: 9px 13px 0 10.5px;
`;

const PersonName = styled.span`
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
`;

/**
 *  Takes in a [[MeetingAttendee]] object and renders it correctly in a JSX component.
 * @param attendee - a [[MeetingAttendee]] object
 * @returns a JSX component
 */
const Attendee = ({ attendee }) => {
  console.log("Attendee obtained is", attendee);
  console.log("Is host", attendee._host ? "yes" : "no");
  return (
    <AttendeeContainer isremoteUser={attendee.isRemoteUser()}>
      <ImagePortion>
        <ImageOverlay>
          <ToolBar>
            <Role>{attendee.isHost() ? "ORGANIZER" : "PARTICIPANT"}</Role>
            {/*<RotatedIcon name={"overflow_small_white_icon"} alt={"dropdown"}/>*/}
          </ToolBar>
        </ImageOverlay>
      </ImagePortion>
      <TextPortion>
        <PersonName>
          {!attendee.isRemoteUser() ? "You" : attendee.name}
        </PersonName>
      </TextPortion>
    </AttendeeContainer>
  );
};

export default Attendee;

import React, { Component } from "react";
import styled from "styled-components";

import Tile from "../Tile/Tile";
import { Panel } from "../Tabs/Tabs";
import { default as DefaultInput } from "../../../UI/Forms/Input";
import { SmallButtonPurple } from "../../../UI/Buttons/SmallButton";
import * as actions from "../../../../store/actions";
import { connect } from "react-redux";
import { Error } from "../../../UI/Styles";
import { wait } from "../../../../utility";
import { withRouter } from "react-router-dom";

const Input = styled(DefaultInput)`
  margin-top: 10px;
`;

const Body = styled(Panel)`
  padding-top: 15px;
`;

const SubmitButton = styled(SmallButtonPurple)`
  margin: 20px auto;
  height: 36px;
`;

const ButtonContainer = styled.div`
  padding: 0 15px;
`;

class AccountPanel extends Component {
  newPasswordInput;
  passwordConfirmationInput;

  state = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  submitHandler = (event) => {
    // we want to avoid the default behavior of form submission (reloading the page)
    event.preventDefault();
    this.props
      .updatePassword(
        this.props.accessToken,
        this.props.user.userId,
        this.state.oldPassword,
        this.state.newPassword,
        this.state.confirmPassword
      )
      // induce a half second delay before doing the redirect to make it feel slightly more natural
      .then(() => wait(500))
      .then(() => this.props.history.push("/"));
  };

  changeHandler = (event, fieldIdentifier) => {
    let newState = this.state;
    newState[fieldIdentifier] = event.target.value;
    this.setState(newState);
  };

  validatePassword = (_) => {
    if (this.state.oldPassword === this.state.newPassword)
      this.newPasswordInput.setCustomValidity(
        "New password can not be the same as the existing one."
      );
    else if (this.state.newPassword.length < 8)
      this.newPasswordInput.setCustomValidity(
        "Password must be at-least 8 characters long."
      );
    else if (/^\d+$/.test(this.state.newPassword))
      this.newPasswordInput.setCustomValidity(
        "Password can not be all numeric."
      );
    else this.newPasswordInput.setCustomValidity("");

    if (this.state.newPassword !== this.state.confirmPassword)
      this.passwordConfirmationInput.setCustomValidity(
        "Passwords do not match."
      );
    else this.passwordConfirmationInput.setCustomValidity("");
  };

  /*
    Given the response of the failing http request, parses the payload and extracts error strings as an Array. Each
    index in the array is either a string containing the error response or another array containing multiple errors for a single field.
    //TODO: Update error handling once the API has been updated to ensure errors are formatted in a consistent format, CMM-253.
 */
  inferErrorMessages = (error) => {
    if (error) {
      var errors = [];
      const response = error.response;
      if (response.status === 400) {
        for (const error of Object.values(response.data)) {
          if (error && error instanceof Object) {
            const subErrors = Object.values(error);
            errors = errors.concat(subErrors);
          } else {
            errors = errors.concat(error);
          }
        }
        return errors;
      } else if (response.status === 503)
        return errors.push("Sorry, the service is currently unavailable.");
      else return errors.push("Hmmm...something went wrong, please try again.");
    }
  };

  render() {
    return (
      <Body>
        <form onSubmit={this.submitHandler}>
          <Tile title={"Change Password"}>
            <Error>{this.inferErrorMessages(this.props.user.error)}</Error>
            <Input
              placeholder={"Current Password"}
              type={"password"}
              value={this.state.oldPassword}
              onChange={(event) => this.changeHandler(event, "oldPassword")}
              onKeyUp={this.validatePassword}
              required
            />
            <Input
              placeholder={"New Password"}
              type={"password"}
              value={this.state.newPassword}
              onChange={(event) => this.changeHandler(event, "newPassword")}
              onKeyUp={this.validatePassword}
              ref={(component) => (this.newPasswordInput = component)}
              required
            />
            <Input
              placeholder={"Confirm Password"}
              type={"password"}
              value={this.state.confirmPassword}
              onChange={(event) => this.changeHandler(event, "confirmPassword")}
              onKeyUp={this.validatePassword}
              ref={(component) => (this.passwordConfirmationInput = component)}
              required
            />
          </Tile>
          <ButtonContainer>
            <SubmitButton type="submit" loading={this.props.user.loading}>
              Update
            </SubmitButton>
          </ButtonContainer>
        </form>
      </Body>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updatePassword: (
      accessToken,
      userId,
      oldPassword,
      newPassword,
      confirmPassword
    ) =>
      dispatch(
        actions.updatePassword(
          accessToken,
          userId,
          oldPassword,
          newPassword,
          confirmPassword
        )
      ),
  };
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    user: state.user,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountPanel)
);

import * as actionTypes from "../actions/actionTypes";

const initialState = {
  meetingId: null,
  notesId: null,
  overview: null,
  summary: null,
  actionItems: null,
  sentimentsData: null,
  timestampRanges: null,
  error: null,
  loading: false,
};

const meetingNotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MEETING_NOTES_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_MEETING_NOTES_SUCCESS:
      return {
        ...state,
        overview: action.overview,
        summary: action.summary,
        actionItems: action.actionItems,
        timestampRanges: action.timestampRanges,
        meetingId: action.meetingId,
        notesId: action.notesId,
        loading: false,
      };
    case actionTypes.GET_MEETING_NOTES_FAIL:
      return {
        ...state,
        overview: null,
        summary: null,
        actionItems: null,
        timestampRanges: null,
        meetingId: null,
        notesId: null,
        error: action.error,
        loading: false,
      };
    case actionTypes.STORE_ACTION_ITEMS_IN_TODOIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STORE_ACTION_ITEMS_IN_TODOIST_SUCCESS:
      return {
        ...state,
        actionItems: action.actionItems,
        error: null,
        loading: false,
      };
    case actionTypes.STORE_ACTION_ITEMS_IN_TODOIST_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case actionTypes.GET_SENTIMENTS_DATA_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_SENTIMENTS_DATA_SUCCESS:
      return {
        ...state,
        sentimentsData: action.sentimentsData,
        error: null,
        loading: false,
      };
    case actionTypes.GET_SENTIMENTS_DATA_FAIL:
      return {
        ...state,
        sentimentsData: null,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default meetingNotesReducer;

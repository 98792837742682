const reqSvgs = require.context(
  "../../../assets/functional_icons/",
  true,
  /\.svg$/
);
const paths = reqSvgs.keys();
const svgs = paths.map((path) => reqSvgs(path));

export const getIconRef = (iconName) => {
  const iconList = svgs.filter((svgPath) => svgPath.indexOf(iconName) !== -1);
  if (iconList.length > 0) {
    return iconList[0];
  } else {
    console.error(`SVG cannot be found ${iconName}`);
    return null;
  }
};

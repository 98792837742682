import React, { Component } from "react";
import styled from "styled-components";

import Tile from "../Tile/Tile";
import { Panel } from "../Tabs/Tabs";
import * as actions from "../../../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ToggleSetting from "../ToggleSetting/ToggleSetting.js";
import HorizontalLine from "../../../UI/Lines/Lines.js";

const Body = styled(Panel)`
  padding-top: 15px;
  padding-bottom: 15px;
`;

const GeneralContainer = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: column;
`;

class GeneralPanel extends Component {
  state = {
    emailNotes: false,
  };

  componentDidMount() {
    if (this.props.user && this.props.user.settings.emailNotes !== null) {
      this.setState({
        emailNotes: this.props.user.settings.emailNotes,
      });
    }
  }

  onEmailNotesToggleChange = () => {
    this.props
      .updateUserSettings(this.props.accessToken, this.props.user.userId, {
        emailNotes: !this.state.emailNotes,
      })
      .then(() => {
        this.setState({
          emailNotes: !this.state.emailNotes,
        });
      })
      .catch((error) => {
        alert(
          "There was a problem while updating your settings. Please contact support@capturemymeeting.com."
        );
      });
  };

  render() {
    return (
      <Body>
        <Tile title={"General Settings"}>
          <GeneralContainer>
            <ToggleSetting
              settingText={"Email me my notes"}
              settingTooltipText={
                "If on, when each of your meeting recordings has finished processing, CaptureMyMeeting will send " +
                "you an email notification, and the email will contain your meetings notes, including a summary, " +
                "action items, and a sentiment overview."
              }
              settingChecked={this.state.emailNotes}
              onToggleChange={this.onEmailNotesToggleChange}
            ></ToggleSetting>
            <HorizontalLine style={{ marginTop: "17px" }}></HorizontalLine>
          </GeneralContainer>
        </Tile>
      </Body>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserSettings: (accessToken, userId, settingsDict) =>
      dispatch(actions.updateUserSettings(accessToken, userId, settingsDict)),
  };
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    user: state.user,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GeneralPanel)
);

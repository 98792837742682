import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import CMMLogo from "./ToolbarLogo/ToolbarLogo";
import Hamburger from "../../UI/Hamburger/Hamburger";

const ToolbarContainer = styled.header`
  background-color: white;
`;

const ToolbarInnerContainer = styled.div`
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  padding: 3px 12px 3px 6px;

  @media screen and (min-width: 769px) {
    padding: 10px;
  }
`;

const DropDownContentItem = styled.li`
  height: 40px;
  line-height: 40px;
  width: 152px;
  vertical-align: middle;
  opacity: 0.85;
  list-style-type: none;
  text-decoration: none;

  &:hover {
    background-color: #f1f7fb;
  }
`;

const DropdownContentWrapper = styled.div`
  position: absolute;
`;

const DropDownContent = styled.div`
  display: none;
  position: relative;
  right: 31px;
  top: 8px;
  max-width: 154px;
  border: 1px solid #ced0da;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgba(12, 0, 51, 0.1);
  z-index: 5;
`;

const DropDown = styled.div`
  height: 50px;
  text-align: center;
  padding: 13px;
  margin: 4px;
  cursor: pointer;
  display: none;

  &:hover ${DropDownContent} {
    display: block;
  }
  @media screen and (min-width: 769px) {
    display: block;
  }
`;
const toolbar = (props) => {
  return (
    <ToolbarContainer>
      <ToolbarInnerContainer>
        <CMMLogo />
        <DropDown>
          {props.name}
          <DropdownContentWrapper>
            <DropDownContent>
              {/*<DropDownContentItem onClick={props.showSettings}>Edit Profile</DropDownContentItem>*/}
              <DropDownContentItem onClick={props.showSettings}>
                Settings
              </DropDownContentItem>
              {/*<DropDownContentItem> Feedback </DropDownContentItem>*/}
              <DropDownContentItem>
                <Link to="/auth/logout" style={{ textDecoration: "none" }}>
                  Sign Out
                </Link>
              </DropDownContentItem>
            </DropDownContent>
          </DropdownContentWrapper>
        </DropDown>

        <Hamburger toggle={props.toggle} />
      </ToolbarInnerContainer>
    </ToolbarContainer>
  );
};

export default toolbar;

import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Meetings from "./Meetings/Meetings";
import MeetingStats from "./MeetingStats/MeetingStats";
import { media } from "../../components/UI/StyleUtils";
import StripeModal from "../Stripe/StripeModal";
import * as actions from "../../store/actions";
import { ToastContainer } from "react-toastify";

const DashboardContainer = styled.div`
  ${media.gtSm`
    width: 78.75%;
    margin-top: 20px;
    overflow-y: hidden;
 `}
`;

class Dashboard extends Component {
  state = {
    displayStripeModal: "true",
    isSubscribed: true,
    headingColor: "black",
  };
  componentDidMount() {
    const { preffered_plan } = this.props;

    if (
      preffered_plan &&
      preffered_plan !== process.env.REACT_APP_FREE_PLAN_PRICE_ID
    ) {
      this.props.openStripeModal();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    // This will run whenever props or state change
    const { currentSubscription } = this.props;
    // Check if the dependencies have changed
    if (
      currentSubscription?.subscription !==
        prevProps.currentSubscription?.subscription &&
      !currentSubscription?.subscription
    ) {
      // Perform actions based on the changed dependency
      this.props.openStripeModal();
    }
  }

  render() {
    return (
      <DashboardContainer className="Dashboard">
        <ToastContainer />
        {`${process.env.REACT_APP_STRIPE_UI_ENABLED}` === "true" ||
        `${process.env.REACT_APP_STRIPE_WHITELIST_ACCOUNTS}`.includes(
          this.props.userEmail
        ) ? (
          this.props.isStripeModalOpen && (
            <StripeModal
              isOpen={this.props.isStripeModalOpen}
              shouldCloseOnOverlayClick={true}
              onRequestClose={() => {
                this.props.closeStripeModal();
                this.props.setPrefferedPlan(undefined);
              }}
              headingColor={this.state.headingColor}
            />
          )
        ) : (
          <></>
        )}

        <MeetingStats />
        <Meetings meetingPathSegment={this.props.meetingPathSegment} />
      </DashboardContainer>
    );
  }
}

// Map dispatch functions to component props
const mapDispatchToProps = (dispatch) => {
  return {
    openStripeModal: () => dispatch(actions.openStripeModal()),
    closeStripeModal: () => dispatch(actions.closeStripeModal()),
    setPrefferedPlan: (prefferedPlan) =>
      dispatch(actions.setPrefferedPlan(prefferedPlan)),
  };
};
// Map Redux state to component props
const mapStateToProps = (state) => {
  return {
    preffered_plan: state.user.preffered_plan,
    isStripeModalOpen: state.subscription.isStripeModalOpen,
    isSubscribed: state.subscription.isSubscribed,
    currentSubscription: state.subscription.currentSubscription,
    loadingFlag: state.subscription.loadingFlag,
    userEmail: state.user.email,
  };
};
// Connect the component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

import axios from "axios";
import qs from "query-string";

//TODO: We want to eventually refactor this object to a class instead. This will also eliminate the need set self = this.a

const apiService = {
  hostname: `${process.env.REACT_APP_API_SERVER}`,
  rootUrl: "api",
  post: (url, body, headers) => {
    return axios.post(url, body, { headers: headers });
  },

  postWithConfig: (url, body, headers, onUploadProgress = null) => {
    const config = {
      headers: headers,
    };

    if (onUploadProgress) {
      config.onUploadProgress = onUploadProgress;
    }

    return axios.post(url, body, config);
  },

  get: (url, headers, params) => {
    return axios.get(url, { headers: headers, params: params });
  },

  put: (url, headers, body) => {
    return axios.put(url, body, { headers: headers });
  },

  delete: (url, headers) => {
    return axios.delete(url, { headers: headers });
  },
};

export default {
  auth: {
    signIn: (email, password) => {
      return apiService.post(
        `${apiService.hostname}/${apiService.rootUrl}/auth/token/obtain/`,
        {
          username: email,
          password: password,
        },
        null
      );
    },
  },

  user: {
    getUser: (accessToken) => {
      return apiService.get(
        `${apiService.hostname}/${apiService.rootUrl}/users/`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    signupUser: (
      firstName,
      lastName,
      email,
      password,
      agreeToPrivacy,
      code,
      plan
    ) => {
      return apiService.post(
        `${apiService.hostname}/${apiService.rootUrl}/users/`,
        {
          first_name: firstName,
          last_name: lastName,
          username: email,
          password: password,
          consent: agreeToPrivacy,
          verification_code: code,
          selected_plan: plan,
        },
        null
      );
    },
    updateUser: (accessToken, firstName, lastName, company, userId) => {
      return apiService.put(
        `${apiService.hostname}/${apiService.rootUrl}/users/${userId}/`,
        {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        qs.stringify({
          first_name: firstName,
          last_name: lastName,
          company: company,
        })
      );
    },
    updatePassword: (
      accessToken,
      userId,
      oldPassword,
      newPassword,
      confirmPassword
    ) => {
      return apiService.post(
        `${apiService.hostname}/${apiService.rootUrl}/users/${userId}/change_password/`,
        {
          old_password: oldPassword,
          new_password: newPassword,
          confirm_new_password: confirmPassword,
        },
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    startEmailVerification: (email) => {
      return apiService.post(
        `${apiService.hostname}/${apiService.rootUrl}/users/start_email_verification/`,
        {
          email: email,
        },
        null
      );
    },
    forgetPassword: (email) => {
      return apiService.post(
        `${apiService.hostname}/${apiService.rootUrl}/users/forgot_password/`,
        {
          email: email,
        },
        null
      );
    },
    settingNewPassword: (newPassword, confirmPassword, key, token) => {
      return apiService.post(
        `${apiService.hostname}/${apiService.rootUrl}/users/reset_password/`,
        {
          new_password: newPassword,
          confirm_new_password: confirmPassword,
          key: key,
        },
        null
      );
    },
    updateUserSettings: (accessToken, userId, settingsDict) => {
      return apiService.put(
        `${apiService.hostname}/${apiService.rootUrl}/users/${userId}/user_settings/`,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        settingsDict
      );
    },
    getZoomAuthorizationUrl: (accessToken) => {
      return apiService.get(
        `${apiService.hostname}/${apiService.rootUrl}/users/z_authorization_url/`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    getZoomUserAccessToken: (accessToken, userId, zoomAuthCode) => {
      return apiService.get(
        `${apiService.hostname}/${apiService.rootUrl}/users/${userId}/z_user_access_token/`,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        {
          authorization_code: zoomAuthCode,
        }
      );
    },
    setZoomUserId: (accessToken, userId) => {
      return apiService.put(
        `${apiService.hostname}/${apiService.rootUrl}/users/${userId}/z_user/`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    setZoomAuthStatus: (accessToken, userId) => {
      return apiService.put(
        `${apiService.hostname}/${apiService.rootUrl}/users/${userId}/z_authorization_last_completed/`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    getWebexAuthorizationUrl: (accessToken) => {
      return apiService.get(
        `${apiService.hostname}/${apiService.rootUrl}/users/w_authorization_url/`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    getWebexUserAccessToken: (accessToken, userId, webexAuthCode) => {
      return apiService.get(
        `${apiService.hostname}/${apiService.rootUrl}/users/${userId}/w_user_access_token/`,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        {
          authorization_code: webexAuthCode,
        }
      );
    },
    storeWebexUserInfo: (accessToken, userId) => {
      return apiService.put(
        `${apiService.hostname}/${apiService.rootUrl}/users/${userId}/w_user/`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    requestWebexReauth: (accessToken, userId) => {
      return apiService.put(
        `${apiService.hostname}/${apiService.rootUrl}/users/${userId}/w_reauthorization_requested/`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    updateWebexUserSettings: (accessToken, userId, settingsDict) => {
      return apiService.put(
        `${apiService.hostname}/${apiService.rootUrl}/users/${userId}/w_user_settings/`,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        settingsDict
      );
    },
    getTodoistAuthorizationUrl: (accessToken) => {
      return apiService.get(
        `${apiService.hostname}/${apiService.rootUrl}/users/t_authorization_url/`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    getTodoistUserAccessToken: (accessToken, userId, todoistAuthCode) => {
      return apiService.get(
        `${apiService.hostname}/${apiService.rootUrl}/users/${userId}/t_user_access_token/`,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        {
          authorization_code: todoistAuthCode,
        }
      );
    },
    storeTodoistUserInfo: (accessToken, userId) => {
      return apiService.put(
        `${apiService.hostname}/${apiService.rootUrl}/users/${userId}/t_user/`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    requestTodoistReauth: (accessToken, userId) => {
      return apiService.put(
        `${apiService.hostname}/${apiService.rootUrl}/users/${userId}/t_reauthorization_requested/`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    updateTodoistUserSettings: (accessToken, userId, settingsDict) => {
      return apiService.put(
        `${apiService.hostname}/${apiService.rootUrl}/users/${userId}/t_user_settings/`,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        settingsDict
      );
    },
    getBasicOpsAuthorizationUrl: (accessToken) => {
      return apiService.get(
        `${apiService.hostname}/${apiService.rootUrl}/users/b_authorization_url/`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    authorizeBasicOpsUser: (accessToken, userId, basicOpsAuthCode) => {
      return apiService.post(
        `${apiService.hostname}/${apiService.rootUrl}/users/${userId}/b_authorize_user/`,
        {
          authorization_code: basicOpsAuthCode,
        },
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    removeBasicOps: (accessToken, userId) => {
      return apiService.put(
        `${apiService.hostname}/${apiService.rootUrl}/users/${userId}/b_remove_integration/`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
  },

  meetings: {
    getMeeting: (accessToken, meetingId) => {
      return apiService.get(
        `${apiService.hostname}/${apiService.rootUrl}/meetings/${meetingId}/`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    getPagedMeetings: (accessToken, page) => {
      return apiService.get(
        `${apiService.hostname}/${apiService.rootUrl}/meetings/?page=${page}`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    getMeetingRecording: (accessToken, meetingId) => {
      return apiService.get(
        `${apiService.hostname}/${apiService.rootUrl}/meetings/${meetingId}/video/`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    getMeetingTranscript: (accessToken, meetingId) => {
      return apiService.get(
        `${apiService.hostname}/${apiService.rootUrl}/meetings/${meetingId}/transcript/`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    getTranscriptSpeakerNames: (accessToken, meetingId) => {
      return apiService.get(
        `${apiService.hostname}/${apiService.rootUrl}/meetings/${meetingId}/speaker_names/`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    updateSpeakerNames: (accessToken, meetingId, speakerNames) => {
      return apiService.put(
        `${apiService.hostname}/${apiService.rootUrl}/meetings/${meetingId}/update_speaker_names/`,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        {
          speakerNames: speakerNames,
        }
      );
    },
    getSentimentsData: (accessToken, meetingId) => {
      return apiService.get(
        `${apiService.hostname}/${apiService.rootUrl}/meetings/${meetingId}/sentiments_data/`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    getMeetingNotes: (accessToken, meetingId) => {
      return apiService.get(
        `${apiService.hostname}/${apiService.rootUrl}/meetings/${meetingId}/generated_notes/`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    storeActionItemsInTodoist: (accessToken, notesId, actionItemNumbers) => {
      var headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      return apiService.post(
        `${apiService.hostname}/${apiService.rootUrl}/notes/${notesId}/store_action_items_in_t/`,
        {
          actionItemNumbers: actionItemNumbers,
        },
        headers
      );
    },
    createMeeting: (accessToken, meetingName) => {
      var headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      return apiService.post(
        `${apiService.hostname}/${apiService.rootUrl}/meetings/`,
        {
          name: meetingName,
        },
        headers
      );
    },
    getVideoUploadUrl: (accessToken, meetingId) => {
      return apiService.get(
        `${apiService.hostname}/${apiService.rootUrl}/meetings/${meetingId}/video_upload_url/`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    uploadVideo: (url, formData, onUploadProgressUpdate) => {
      const headers = { "Content-Type": "multipart/form-data" };
      return apiService.postWithConfig(
        url,
        formData,
        headers,
        onUploadProgressUpdate
      );
    },
    kickOffTextRecognition: (accessToken, meetingId) => {
      return apiService.get(
        `${apiService.hostname}/${apiService.rootUrl}/meetings/${meetingId}/index_meeting_request/`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    deleteMeeting: (accessToken, meetingId) => {
      return apiService.delete(
        `${apiService.hostname}/${apiService.rootUrl}/meetings/${meetingId}`,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    addNewMeetingAttendees: (
      accessToken,
      meetingIds,
      attendees,
      messageString
    ) => {
      return apiService.post(
        `${apiService.hostname}/${apiService.rootUrl}/meetings/attendees/`,
        {
          meeting_ids: meetingIds,
          attendees: attendees,
          message_string: messageString,
        },
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    deleteMeetingAttendee: (accessToken, meetingId, attendeeEmail) => {
      let data = {
        meeting_id: meetingId,
        attendee_email: attendeeEmail,
      };

      return apiService.put(
        `${apiService.hostname}/${apiService.rootUrl}/meetings/delete_attendee/`,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        data
      );
    },
    updateMeetingName: (accessToken, meetingId, meetingName) => {
      return apiService.put(
        `${apiService.hostname}/${apiService.rootUrl}/meetings/${meetingId}/`,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        {
          name: meetingName,
        }
      );
    },
    updateMeeting: (accessToken, meetingId, updates) => {
      return apiService.put(
        `${apiService.hostname}/${apiService.rootUrl}/meetings/${meetingId}/`,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        updates
      );
    },
  },
  search: {
    meetingsByName: (accessToken, query, page) => {
      return apiService.post(
        `${apiService.hostname}/${apiService.rootUrl}/search/`,
        qs.stringify({
          query: query,
        }),
        {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/x-www-form-urlencoded",
        }
      );
    },
    meetingTextContent: (accessToken, query, meetingId, page) => {
      return apiService.post(
        `${apiService.hostname}/${apiService.rootUrl}/search/search_content/`,
        {
          query: query,
          meeting_id: meetingId,
        },
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    meetingVoiceContent: (accessToken, query, meetingId, page) => {
      return apiService.post(
        `${apiService.hostname}/${apiService.rootUrl}/search/search_audio/`,
        {
          query: query,
          meeting_id: meetingId,
        },
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
  },
  survey: {
    submitMeetingResults: (email, meetingId, givenRating, comment) => {
      return apiService.post(
        `${apiService.hostname}/${apiService.rootUrl}/surveys/meeting/`,
        {
          email: email,
          meeting_id: meetingId,
          rating: givenRating,
          maximum_rating: 10,
          note: comment,
        }
      );
    },
  },
  subscription: {
    getCurrentSubscription: async (accessToken) => {
      return apiService.get(
        `${apiService.hostname}/${apiService.rootUrl}/users/get_subscription/`,

        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    getAllSubscriptionProducts: async () => {
      return apiService.get(
        `${apiService.hostname}/${apiService.rootUrl}/users/get_stripe_products/`
      );
    },

    createPaymentIntent: async (accessToken, paymentParams) => {
      return apiService.post(
        `${apiService.hostname}/${apiService.rootUrl}/users/create_payment_intent/`,
        paymentParams,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    getCustomerPortal: async (accessToken) => {
      return apiService.post(
        `${apiService.hostname}/${apiService.rootUrl}/users/get_customer_portal/`,
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
    cancelSubscription: async (accessToken) => {
      return apiService.post(
        `${apiService.hostname}/${apiService.rootUrl}/users/cancel_subscription/`,
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },

    freeTrialSubscription: async (accessToken) => {
      return apiService.post(
        `${apiService.hostname}/${apiService.rootUrl}/users/subscribe_trial/`,
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
    },
  },
};

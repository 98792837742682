import React from "react";
import { Button, Wrapper, Menu, MenuItem } from "react-aria-menubutton";
import "./Menu.css";

/**
 * Returns an accessible React menu component with built in keyboard interactions
 * @param props
 * {
 *      menuButton: A component that will be used as a button for the menu. This component will be wrapped inside a button element and hence should not be of a button type.
 *      menuItems: List of items to display in the menu. Each item should be either a text string or component that has `text` attribute that returns the text to display and use for searching.
 *      handleSelection: The function that is called when a menu item is selected. Item value and event are passed in to the function.
 * }
 * @returns JSX component
 *
 */

//TODO: add CSS transitions for opening/closing menu options

const SimpleMenu = (props) => {
  const menuItems = props.menuItems.map((word, i) => (
    <MenuItem key={i} className="menu-item">
      {word}
    </MenuItem>
  ));

  return (
    <Wrapper className="menu-wrapper" onSelection={props.handleSelection}>
      <Button className="menu-button">{props.menuButton}</Button>
      <Menu className="menu" style={{ overflowY: "auto", maxHeight: "104px" }}>
        {menuItems}
      </Menu>
    </Wrapper>
  );
};

export default SimpleMenu;

import React, { Component } from "react";
import styled from "styled-components";

import * as actions from "../../../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ToggleSetting from "../ToggleSetting/ToggleSetting.js";

const Settings = styled.div`
  display: flex;
  flex-direction: column;
  color: #a0a0a0;
`;

const SettingsTitle = styled.div`
  display: flex;
  color: #a0a0a0;
  font-weight: bolder;
  text-decoration: underline;
  margin: 1% 1% 1% 1%;
`;

const AutoStoreSetting = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const AutoStoreSettingSubtext = styled.div`
  margin-left: 10px;
  font-size: 12px;
`;

class TodoistSettings extends Component {
  state = {
    autoStoreToInboxChecked: false,
  };

  componentDidMount() {
    if (
      this.props.user &&
      this.props.user.todoist_auto_store_to_inbox !== null
    ) {
      this.setState({
        autoStoreToInboxChecked: this.props.user.todoistAutoStoreToInbox,
      });
    }
  }

  onAutoStoreToInboxToggleChange = () => {
    this.props
      .updateTodoistUserSettings(
        this.props.accessToken,
        this.props.user.userId,
        { autoStoreToInbox: !this.state.autoStoreToInboxChecked }
      )
      .then(() => {
        this.setState({
          autoStoreToInboxChecked: !this.state.autoStoreToInboxChecked,
        });
      })
      .catch((error) => {
        alert(
          "There was problem while updating your Todoist integration settings. Please contact support@capturemymeeting.com."
        );
      });
  };

  render() {
    return (
      <Settings>
        <SettingsTitle>Settings:</SettingsTitle>
        <AutoStoreSetting>
          <ToggleSetting
            settingText={"Automatically store to my inbox"}
            settingTooltipText={
              "If on, up to 50 action items detected from each of your meeting recordings will be " +
              "automatically stored into your personal Todoist inbox. Action items will only be stored " +
              "into the meeting host's (your) inbox. Note: Your Todoist inbox can hold " +
              "a maximum of 300 tasks at once. If your Todoist inbox fills up, CaptureMyMeeting will no longer be " +
              "able to store action items into it."
            }
            settingChecked={this.state.autoStoreToInboxChecked}
            onToggleChange={this.onAutoStoreToInboxToggleChange}
          ></ToggleSetting>
          <AutoStoreSettingSubtext>
            Note: Your Todoist inbox can hold a maximum of 300 tasks at once.
          </AutoStoreSettingSubtext>
        </AutoStoreSetting>
      </Settings>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateTodoistUserSettings: (accessToken, userId, settingsDict) =>
      dispatch(
        actions.updateTodoistUserSettings(accessToken, userId, settingsDict)
      ),
  };
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    user: state.user,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TodoistSettings)
);

import React from "react";

/**
 * A hidden IFrame React JSX component that can be used to download content of URLs. The provided URL (when invoked) MUST
 * return Content-Disposition header with download attribute set for the url content to be downloaded. Note however,
 * only works for non-mobile devices.
 * @param props
 * {
 *      url: the url to fetch content from.
 * }
 * @returns React JSX component
 * @constructor
 */
const DownloadIFrame = (props) => {
  return (
    <div style={{ display: "none" }}>
      <iframe title="download" src={props.url} />
    </div>
  );
};

/** A <a>  JSX component that can be used to download the content of a link provided in the url property.
 *  visit https://www.w3schools.com/tags/att_a_download.asp to learn more about browser compatibility.
 * @param props
 * {
 *      url: The url of the content that needs to be downloaded.
 * }
 * @returns JSX component
 * @constructor
 */

export const downloadFile = (
  fileUrl,
  params,
  fileName,
  onDownloadFinished = null
) => {
  fetch(fileUrl, params)
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      // Clean up and remove blob from memory
      window.URL.revokeObjectURL(url);
    })
    .then(() => {
      if (onDownloadFinished) {
        onDownloadFinished();
      }
    })
    .catch((error) => {
      alert(
        "There was an error downloading your file. Please contact support@capturemymeeting.com."
      );
    });
};

export default DownloadIFrame;

import api from "../../services/api";
import * as actionTypes from "./actionTypes";
import moment from "moment";

// Courtesy of: https://stackoverflow.com/questions/29285056/get-video-duration-when-input-a-video-file/29285597
const loadVideo = (file) =>
  new Promise((resolve, reject) => {
    try {
      let video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        resolve(video);
      };

      video.onerror = function () {
        reject("Invalid video. Please select a video file.");
      };

      video.src = window.URL.createObjectURL(file);
    } catch (e) {
      reject(e);
    }
  });

async function getVideoDuration(inputVideo) {
  const loadedVideo = await loadVideo(inputVideo);
  return loadedVideo.duration;
}

const uploadProgressUpdate = (loadedPercent) => {
  return {
    type: actionTypes.UPLOAD_PROGRESS_UPDATE,
    loadedPercent: loadedPercent,
  };
};
const uploadMeetingStart = () => {
  return {
    type: actionTypes.UPLOAD_MEETING_VIDEO_START,
  };
};

const uploadMeetingSuccess = () => {
  return {
    type: actionTypes.UPLOAD_MEETING_VIDEO_SUCCESS,
  };
};

const uploadMeetingFail = (error) => {
  return {
    type: actionTypes.UPLOAD_MEETING_VIDEO_ERROR,
    error: error,
  };
};

export const uploadMeetingVideo = (accessToken, video) => {
  return (dispatch) => {
    dispatch(uploadMeetingStart());
    let meetingId;
    let duration;
    let startTimeMs = video.lastModified;
    const onUploadProgressUpdate = (progressEvent) => {
      const { loaded, total } = progressEvent;
      let loadedPercent = Math.floor((loaded * 100) / total);
      console.log(`Uploading Video! ${loadedPercent}%`);
      dispatch(uploadProgressUpdate(loadedPercent));
    };

    return getVideoDuration(video)
      .then((calculatedDuration) => {
        console.log("Video duration calculated.");
        duration = calculatedDuration;
        // naming the meeting "Video  uploading: failed" in case if the any of the following steps fail, the user will know that upload was not 100 successful.
        // this name is changed in a later stage
        return api.meetings.createMeeting(accessToken, "Video  Upload: Failed");
      })
      .then((response) => {
        meetingId = response.data.id;
        console.log("New meeting setup for uploading content.");
        return api.meetings.getVideoUploadUrl(accessToken, meetingId);
      })
      .then((response) => {
        console.log("Video upload URL obtained successfully");
        const body = response.data;
        const url = body.url;

        const formData = new FormData();
        formData.append(
          "Content-Disposition",
          body["fields"]["Content-Disposition"]
        );
        formData.append("Content-Type", body["fields"]["Content-Type"]);
        formData.append("key", body["fields"]["key"]);
        formData.append("AWSAccessKeyId", body["fields"]["AWSAccessKeyId"]);
        formData.append("policy", body["fields"]["policy"]);
        formData.append("signature", body["fields"]["signature"]);
        formData.append(
          "x-amz-security-token",
          body["fields"]["x-amz-security-token"]
        );
        formData.append("file", video, video.name);

        return api.meetings.uploadVideo(url, formData, onUploadProgressUpdate);
      })
      .then((response) => {
        console.log("Video successfully uploaded!");
        // Note: we are not enforcing the text recognition api call to succeed before we allow the user to view the meeting.
        //    this will also ensure that if our text recognition server is down, user can continue to use the app for the remaining pieces
        const startTimeUtc = moment(startTimeMs).utc();
        const endTime = moment(startTimeUtc).add(duration, "seconds").utc();
        return api.meetings.updateMeeting(accessToken, meetingId, {
          name: video.name.split(".mp4")[0],
          recording_available: true,
          start_time: startTimeUtc,
          end_time: endTime,
        });
      })
      .then((response) => {
        // note, we are not enforcing the text recognition api call to succeed before we allow the user to view the meeting.
        console.log("Meeting video is now available for user to view");
        return api.meetings.kickOffTextRecognition(accessToken, meetingId);
      })
      .then((response) => {
        console.log("Video indexing was kicked off successfully");
        dispatch(uploadMeetingSuccess());
      })
      .catch((error) => {
        console.log("Meeting upload api call failed:");
        console.log(error);
        dispatch(uploadMeetingFail(error));
        throw error;
      });
  };
};

import styled, { css } from "styled-components";

/**
 * Returns a base large button
 * @param props
 * {
 *      loading: indicates if the button is in loading state
 *      disabled: indicates if the button is in it's disabled state
 *  }
 * @type {StyledComponentClass<*, any, *>}
 */
const LargeButtonBase = styled.button`
  border-radius: 4px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  border: none;
  width: 100%;
  height: 48px;
  padding: 5px 24px;
  cursor: ${(props) => (props.loading ? "progress" : "pointer")};

  && {
    ${(props) =>
      props.disabled &&
      css`
        // if button is disabled, override the background color.
        background-color: #f0f0f0;
      `}
  }
`;

/**
 * Returns a purple large button
 * @param props
 * {
 *      loading: indicates if the button is in loading state
 *      disabled: indicates if the button is in it's disabled state
 *  }
 * @type {StyledComponentClass<*, any, *>}
 */
export const LargeButtonPurple = styled(LargeButtonBase)`
  background-color: #764ef2;
`;

/**
 * Returns a Green large button
 * @param props
 * {
 *      loading: indicates if the button is in loading state
 *      disabled: indicates if the button is in it's disabled state
 *  }
 * @type {StyledComponentClass<*, any, *>}
 */
export const LargeButtonGreen = styled(LargeButtonBase)`
  background-color: #39d0c5;
`;

/**
 * Returns a Dark Green large button
 * @param props
 * {
 *      loading: indicates if the button is in loading state
 *      disabled: indicates if the button is in it's disabled state
 *  }
 * @type {StyledComponentClass<*, any, *>}
 */
export const LargeButtonDarkGreen = styled(LargeButtonBase)`
  background-color: #069d92;
`;

export default LargeButtonPurple;

import React, { Component } from "react";
import "./SidePanel.css";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

import editIcon from "../../assets/functional_icons/edit_icon.svg";
import sidePanelBackgroundImageDesktop from "../../assets/images/background_images/sidepanel_background_image@2x.png";
import sidePanelBackgroundImageMobile from "../../assets/images/background_images/rotated_sidepanel_background_image.png";
import { CenterStyles } from "../UI/Styles";
import {
  LargeButtonGreen,
  LargeButtonDarkGreen,
} from "../UI/Buttons/LargeButton";
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import MeetingSurvey from "../Surveys/MeetingSurvey";
import ZoomModal from "../Zoom/ZoomModal";
import WebexModal from "../Webex/WebexModal";
import TodoistModal from "../Todoist/TodoistModal";
import BasicOpsModal from "../BasicOps/BasicOpsModal";
import ZoomLogo from "../UI/Logos/ZoomLogo.js";
import WebexLogo from "../UI/Logos/WebexLogo.js";
import TodoistLogo from "../UI/Logos/TodoistLogo.js";
import BasicOpsLogo from "../UI/Logos/BasicOpsLogo.js";
import { MONTLY_QUOTA_ALREADY_USED } from "../../constants";

const GreenButton = styled(LargeButtonGreen)`
  min-height: 100%;
`;

const DarkGreenButton = styled(LargeButtonDarkGreen)`
  min-height: 100%;
`;

const ButtonContainer = styled.div`
  min-height: 40px;
  max-height: 40px;
  width: 230px;
  margin-top: 15px;
`;

const SidePanelStyles = styled.div`
  background: url(${sidePanelBackgroundImageDesktop}) no-repeat center;
  background-size: cover;
  max-height: 990px;
  color: white;
  padding: 0 20px;
  width: 21.25%;

  @media screen and (max-width: 768px) {
    background: url(${sidePanelBackgroundImageMobile}) no-repeat center;
    background-size: cover;
    width: 100%;
  }
`;

const ButtonDescription = styled.p`
  font-size: 14px;
  opacity: 0.5;
  text-align: center;
  margin-top: 13px;
`;

const EditMyProfile = styled.a`
  margin-bottom: 20px;
  font-size: 12px;
  color: white;
  opacity: 0.5;
  cursor: pointer;
`;

const CompanyLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const CompanyLink = styled.a`
  margin-bottom: 10px;
  font-size: 12px;
  color: white;
  opacity: 0.5;
  cursor: pointer;
`;

const CompanyLinkSeparator = styled.div`
  border-left: 1px solid white;
  height: 15px;
  margin-left: 4px;
  margin-right: 4px;
  opacity: 0.5;
`;

class SidePanel extends Component {
  state = {
    // Initially, no file is selected
    selectedFile: null,
    uploadButtonColor: "#069D92",
    displaySurvey: false,
    displayZoomModal: false,
    displayWebexModal: false,
    displayTodoistModal: false,
    displayBasicOpsModal: false,
    surveyButtonLoading: false,
    surveyButtonDisabled: false,
    zoomButtonLoading: false,
    zoomButtonDisabled: false,
    webexButtonLoading: false,
    webexButtonDisabled: false,
    todoistButtonLoading: false,
    todoistButtonDisabled: false,
    basicOpsButtonLoading: false,
    basicOpsButtonDisabled: false,
  };

  onFileChange = (event) => {
    // Update the state
    this.setState({
      selectedFile: event.target.files[0],
      uploadButtonColor: "#39D0C5",
    });
  };

  uploadHandler = (event) => {
    // prevent default behavior
    event.preventDefault();
    console.log("upload called");
    if (this.state.selectedFile !== null) {
      if (
        (`${process.env.REACT_APP_STRIPE_UI_ENABLED}` === "true" ||
          `${process.env.REACT_APP_STRIPE_WHITELIST_ACCOUNTS}`.includes(
            this.props.userEmail
          )) &&
        this.props.userMinutes <= 0
      ) {
        toast.error(MONTLY_QUOTA_ALREADY_USED);
      } else {
        this.setState({ uploadButtonColor: "#069D92" });
        this.props
          .uploadMeetingVideo(this.props.accessToken, this.state.selectedFile)
          // refresh the page afterward
          .then((_) => window.location.reload(false));
      }
    }
  };

  onSurveyButtonClick = () => {
    this.setState({ displaySurvey: true });
  };

  onRequestCloseModal = () => {
    this.setState({ displaySurvey: false });
  };

  onZoomButtonClick = () => {
    this.setState({ displayZoomModal: true });
  };

  onRequestCloseZoomModal = () => {
    this.setState({ displayZoomModal: false });
  };

  onWebexButtonClick = () => {
    this.setState({ displayWebexModal: true });
  };

  onRequestCloseWebexModal = () => {
    this.setState({ displayWebexModal: false });
  };

  onTodoistButtonClick = () => {
    this.setState({ displayTodoistModal: true });
  };

  onRequestCloseTodoistModal = () => {
    this.setState({ displayTodoistModal: false });
  };

  onBasicOpsButtonClick = () => {
    this.setState({ displayBasicOpsModal: true });
  };

  onRequestCloseBasicOpsModal = () => {
    this.setState({ displayBasicOpsModal: false });
  };

  showIntegrateZoomButton = () => {
    return (
      (process.env.REACT_APP_ZOOM_INTEGRATION_FEATURE_ENABLED === "true" ||
        (process.env.REACT_APP_ZOOM_INTEGRATION_ADMIN &&
          process.env.REACT_APP_ZOOM_INTEGRATION_ADMIN.split(" ").includes(
            this.props.email
          ))) &&
      (!this.props.zoom_integrated || !this.props.zoom_refresh_token_exists)
    );
  };

  showIntegrateWebexButton = () => {
    return (
      (process.env.REACT_APP_WEBEX_INTEGRATION_FEATURE_ENABLED === "true" ||
        (process.env.REACT_APP_WEBEX_INTEGRATION_ADMIN &&
          process.env.REACT_APP_WEBEX_INTEGRATION_ADMIN.split(" ").includes(
            this.props.email
          ))) &&
      (!this.props.webex_integrated || !this.props.webex_refresh_token_exists)
    );
  };

  showIntegrateTodoistButton = () => {
    return (
      (process.env.REACT_APP_TODOIST_INTEGRATION_FEATURE_ENABLED === "true" ||
        (process.env.REACT_APP_TODOIST_INTEGRATION_ADMIN &&
          process.env.REACT_APP_TODOIST_INTEGRATION_ADMIN.split(" ").includes(
            this.props.email
          ))) &&
      (!this.props.todoistIntegrated || !this.props.todoistAccessTokenExists)
    );
  };

  showIntegrateBasicOpsButton = () => {
    return (
      (process.env.REACT_APP_BASICOPS_INTEGRATION_FEATURE_ENABLED === "true" ||
        (process.env.REACT_APP_BASICOPS_INTEGRATION_ADMIN &&
          process.env.REACT_APP_BASICOPS_INTEGRATION_ADMIN.split(" ").includes(
            this.props.email
          ))) &&
      (!this.props.basicOpsIntegrated || !this.props.basicOpsRefreshTokenExists)
    );
  };

  render() {
    return (
      <SidePanelStyles>
        <div>
          <div className="profile-picture"></div>
          <p className="profile-name">
            {this.props.firstName
              ? this.props.firstName + "'s Meeting"
              : "Loading..."}
          </p>
          <CenterStyles>
            <EditMyProfile onClick={this.props.showSettings}>
              Edit my profile
              <img
                style={{ marginLeft: "6px", height: "14px", width: "14px" }}
                src={editIcon}
                alt="Edit"
              />
            </EditMyProfile>
          </CenterStyles>
        </div>
        <div>
          <CenterStyles>
            <ButtonContainer>
              <form onSubmit={this.uploadHandler}>
                <input
                  style={{
                    width: "200px",
                    marginBottom: "5px",
                    opacity: "0.9",
                  }}
                  type="file"
                  accept="video/mp4,audio/x-m4a"
                  onChange={this.onFileChange}
                  required
                />
                <DarkGreenButton
                  style={{ backgroundColor: this.state.uploadButtonColor }}
                  loading={this.props.meetingUpload.loading}
                  disabled={this.props.meetingUpload.loading}
                  type="submit"
                >
                  Upload Meeting
                </DarkGreenButton>
              </form>
            </ButtonContainer>
          </CenterStyles>
          <div style={{ marginTop: "40px" }}>
            <ButtonDescription>
              Upload meeting recording file (mp4 or m4a).
            </ButtonDescription>
          </div>
        </div>
        {this.showIntegrateZoomButton() || this.showIntegrateWebexButton() ? (
          <CenterStyles>
            <div>------- or -------</div>
          </CenterStyles>
        ) : null}
        {this.showIntegrateZoomButton() ? (
          <div>
            <CenterStyles>
              <ButtonContainer>
                <GreenButton
                  loading={this.state.zoomButtonLoading}
                  disabled={this.state.zoomButtonDisabled}
                  onClick={this.onZoomButtonClick}
                >
                  <ZoomLogo logoText={"Integrate Zoom"}></ZoomLogo>
                </GreenButton>
              </ButtonContainer>
              <ZoomModal
                isOpen={this.state.displayZoomModal}
                shouldCloseOnOverlayClick={true}
                onRequestClose={this.onRequestCloseZoomModal}
              ></ZoomModal>
            </CenterStyles>
            <ButtonDescription>
              Sync your Zoom cloud recordings.
            </ButtonDescription>
          </div>
        ) : null}
        {this.showIntegrateWebexButton() ? (
          <div>
            <CenterStyles>
              <ButtonContainer>
                <GreenButton
                  loading={this.state.webexButtonLoading}
                  disabled={this.state.webexButtonDisabled}
                  onClick={this.onWebexButtonClick}
                >
                  <WebexLogo logoText={"Integrate Webex"}></WebexLogo>
                </GreenButton>
              </ButtonContainer>
              <WebexModal
                isOpen={this.state.displayWebexModal}
                shouldCloseOnOverlayClick={true}
                onRequestClose={this.onRequestCloseWebexModal}
              ></WebexModal>
            </CenterStyles>
            <ButtonDescription>
              Sync your Webex cloud recordings.
            </ButtonDescription>
          </div>
        ) : null}
        <CenterStyles>
          <div>-------------------------------------------</div>
        </CenterStyles>
        {this.showIntegrateBasicOpsButton() ? (
          <div>
            <CenterStyles>
              <ButtonContainer>
                <GreenButton
                  loading={this.state.basicOpsButtonLoading}
                  disabled={this.state.basicOpsButtonDisabled}
                  onClick={this.onBasicOpsButtonClick}
                >
                  <BasicOpsLogo logoText={"Integrate BasicOps"}></BasicOpsLogo>
                </GreenButton>
              </ButtonContainer>
              <BasicOpsModal
                isOpen={this.state.displayBasicOpsModal}
                shouldCloseOnOverlayClick={true}
                onRequestClose={this.onRequestCloseBasicOpsModal}
              ></BasicOpsModal>
            </CenterStyles>
            <ButtonDescription>
              Store meeting notes in BasicOps.
            </ButtonDescription>
          </div>
        ) : null}
        {this.showIntegrateTodoistButton() ? (
          <div>
            <CenterStyles>
              <ButtonContainer>
                <GreenButton
                  loading={this.state.todoistButtonLoading}
                  disabled={this.state.todoistButtonDisabled}
                  onClick={this.onTodoistButtonClick}
                >
                  <TodoistLogo logoText={"Integrate Todoist"}></TodoistLogo>
                </GreenButton>
              </ButtonContainer>
              <TodoistModal
                isOpen={this.state.displayTodoistModal}
                shouldCloseOnOverlayClick={true}
                onRequestClose={this.onRequestCloseTodoistModal}
              ></TodoistModal>
            </CenterStyles>
            <ButtonDescription>
              Store meeting action items in Todoist.
            </ButtonDescription>
          </div>
        ) : null}
        {this.showIntegrateTodoistButton() ||
        this.showIntegrateBasicOpsButton() ? (
          <CenterStyles>
            <div>-------------------------------------------</div>
          </CenterStyles>
        ) : null}
        <CenterStyles>
          <ButtonContainer>
            <GreenButton
              loading={this.state.surveyButtonLoading}
              disabled={this.state.surveyButtonDisabled}
              onClick={this.onSurveyButtonClick}
            >
              Submit Feedback
            </GreenButton>
          </ButtonContainer>
          <MeetingSurvey
            isOpen={this.state.displaySurvey}
            shouldCloseOnOverlayClick={true}
            onRequestClose={this.onRequestCloseModal}
          ></MeetingSurvey>
        </CenterStyles>
        <ButtonDescription>
          Let us know about your app experience.
        </ButtonDescription>
        <CenterStyles>
          <CompanyLinksContainer>
            <CompanyLink
              onClick={() => this.props.history.push("/privacy-policy")}
            >
              Privacy
            </CompanyLink>
            <CompanyLinkSeparator></CompanyLinkSeparator>
            <CompanyLink
              onClick={() => this.props.history.push("/terms-conditions")}
            >
              Terms&Conditions
            </CompanyLink>
            <CompanyLinkSeparator></CompanyLinkSeparator>
            <CompanyLink onClick={() => this.props.history.push("/support")}>
              Support
            </CompanyLink>
            <CompanyLinkSeparator></CompanyLinkSeparator>
            <CompanyLink
              onClick={() => this.props.history.push("/user-documentation")}
            >
              Documentation
            </CompanyLink>
          </CompanyLinksContainer>
        </CenterStyles>
      </SidePanelStyles>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    uploadMeetingVideo: (accessToken, file) =>
      dispatch(actions.uploadMeetingVideo(accessToken, file)),
  };
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    meetingUpload: state.meetingUpload,
    basicOpsRefreshTokenExists: state.user.basicOpsRefreshTokenExists,
    basicOpsIntegrated: state.user.basicOpsIntegrated,
    userMinutes: state.user?.minutes,
    userEmail: state.user.email,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidePanel)
);

import styled from "styled-components";

//TODO: Implement React/Tabs https://www.npmjs.com/package/@reach/tabs

/**
 * A container for Tab components.
 **/
export const Tabs = styled.div`
  display: inline-flex;
  justify-content: space-around;
  width: 100%;
`;

/**
 * Represents a single Tab. Each Tab has a corresponding Panel (body) which is displayed when the user selects the tab.
 */
export const Tab = styled.div`
  height: 25px;
  width: 75px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  border-bottom: ${(props) => (props.selected ? "3px solid #39D0C5" : "")};
  :hover {
    border-bottom: 3px solid #39d0c5;
  }
`;

/**
 * A container for Panel components.
 */
export const Panels = styled.div`
  background-color: #f1f7fb;
  height: 100%;
`;

/**
 * Panel contains the body for a tab. It's opened when the corresponding tab is selected by the user. Currently, the
 *  user of the component is responsible for hiding and displaying proper component based on the tab selected.
 */
export const Panel = styled.div``;

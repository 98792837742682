export default class User {
  _name;
  _email;

  constructor(name, email) {
    this._name = name;
    this._email = email;
  }

  get name() {
    return this._name;
  }

  get firstName() {
    const firstNameIndex = this.name.indexOf(" ");
    return firstNameIndex > -1
      ? this.name.slice(0, firstNameIndex).trim()
      : this.name;
  }

  get lastName() {
    const lastNameIndex = this.name.lastIndexOf(" ");
    return lastNameIndex > -1 ? this.name.slice(lastNameIndex + 1) : "";
  }

  get email() {
    return this._email;
  }
}

import React, { Component } from "react";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";

import GradientHybridModalNarrow from "../../../../../components/UI/Modals/Hybrid/GradientHybridModalNarrow";
import Icon from "../../../../../components/UI/Icon/Icon";
import CopyLinkImage from "../../../../../assets/custom_icons/copy_link_button.png";
import { wait } from "../../../../../utility";
import Input from "../../../../../components/UI/Forms/Input";

const ModalBody = styled.div`
  color: #170f30;
  text-align: center;
`;

const EmailInvitationIcon = styled(Icon)`
  display: block;
  margin: 42px auto 15px auto;
`;

const Title = styled.div`
  margin: 15px auto;
  font-size: 28px;
  line-height: 37px;
`;

const Directions = styled.div`
  margin: 15px auto 28px auto;
  max-width: 82%;
  opacity: 0.6;
  line-height: 24px;
`;

const ShareLink = styled.button`
  display: flex;
  cursor: pointer;
  margin: 3px auto 10px auto;
  height: 48px;
  width: 250px;
  border-radius: 24px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.63);
  background-color: #2d2349;
`;

const GreenText = styled.span`
  margin: 3px;
  display: block;
  visibility: ${(props) => (props.hidden ? "hidden" : "visible")};
  color: #21d361;
`;

const MeetingUrl = styled(Input)`
  background-color: #f1f3f4;
  border: none;
  margin: 5px 10px 10px 10px;
  max-width: 95%;
  text-align: center;
`;

class InviteOthers extends Component {
  state = {
    showCopiedText: false,
  };

  urlCopiedSuccessfully = () => {
    console.log("Text copied successfully!");

    // show copied text for 1 second
    this.setState({ showCopiedText: true }, () =>
      wait(1000).then((_) => this.setState({ showCopiedText: false }))
    );
  };

  render() {
    return (
      <GradientHybridModalNarrow
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        top={"20vh"}
        padding={"0px"}
      >
        <ModalBody>
          <EmailInvitationIcon
            name={"email_invite_storytelling_icon"}
            alt_name={"invite others"}
          />
          <Title>Invite people to the meeting</Title>
          <Directions>
            Invite up to ten people — copy the below link and share it with
            them.
          </Directions>

          <GreenText hidden={!this.state.showCopiedText}>Copied!</GreenText>

          <CopyToClipboard
            text={this.props.meetingLink}
            onCopy={this.urlCopiedSuccessfully}
          >
            <ShareLink>
              <img
                src={CopyLinkImage}
                alt="Copy"
                width={"26px"}
                height={"26px"}
                style={{ marginRight: "8px" }}
              />
              Copy link
            </ShareLink>
          </CopyToClipboard>

          <MeetingUrl type={"text"} value={this.props.meetingLink} />
        </ModalBody>
      </GradientHybridModalNarrow>
    );
  }
}

export default InviteOthers;

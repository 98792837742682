import * as actionTypes from "./actionTypes";
import api from "../../services/api";
import { UPDATED_REMOTE_ATTENDEES } from "./actionTypes";
import MeetingAttendee from "../../models/MeetingAttendee";

/*******************************************************
        UPDATE REMOTE ATTENDEES FOR ONGOING MEETING
 ********************************************************/

export const updateRemoteAttendees = (
  attendees,
  typeOfEdit = UPDATED_REMOTE_ATTENDEES
) => {
  return {
    type: typeOfEdit,
    attendees: attendees,
  };
};

/********************************************************
                SETUP MEETING SESSION
 ********************************************************/

const createMeetingStart = () => {
  return {
    type: actionTypes.CREATE_MEETING_START,
  };
};

const createMeetingSuccess = () => {
  return {
    type: actionTypes.CREATE_MEETING_SUCCESS,
  };
};

const createMeetingFail = (error) => {
  return {
    type: actionTypes.CREATE_MEETING_FAIL,
    error: error,
  };
};

const setupMeetingSessionHelper = (meetingId, user) => {
  return {
    type: actionTypes.SETUP_MEETING_SESSION,
    meetingId: meetingId,
    localUser: user,
  };
};

export const setupNewMeetingSession = (user, accessToken, meetingName) => {
  // todo: load other useful meeting data as well.
  const cleanResponse = (response) => {
    const body = response.data;
    return { id: body.id };
  };

  return (dispatch) => {
    console.log("createMeeting method called!");
    dispatch(createMeetingStart());

    api.meetings
      .createMeeting(accessToken, meetingName)
      .then((response) => {
        console.log("Meeting created successfully.");
        dispatch(createMeetingSuccess());
        const meetingId = cleanResponse(response).id;
        const localUser = new MeetingAttendee(
          `${user.firstName} ${user.lastName}`,
          user.email,
          false,
          true
        );

        console.log("MeetingId, ", meetingId, "localUser: ", localUser);
        dispatch(setupMeetingSessionHelper(meetingId, localUser));
      })
      .catch((error) => {
        console.log("There was an error creating the meeting!", error);
        createMeetingFail(error);
      });
  };
};

export const setupMeetingSession = (meetingId, user) => {
  return (dispatch) => {
    dispatch(addNewAttendeeStart());
    const attendee = {
      email: user.email,
      name: user.name,
    };
    // TODO: This API now takes access token credentials. Update this call to include credentials or use different API.
    api.meetings
      .addNewMeetingAttendees(meetingId, [attendee])
      .then((response) => {
        console.log("Attendee added successfully!");
        dispatch(addNewAttendeeSuccess());
        dispatch(setupMeetingSessionHelper(meetingId, user));
      })
      .catch((error) => {
        console.log(error);
        addNewAttendeeFail(error);
      });
  };
};

/********************************************************
            ADD NEW ATTENDEES FOR ONGOING MEETING
 ********************************************************/

const addNewAttendeeStart = () => {
  return {
    type: actionTypes.ADD_NEW_MEETING_ATTENDEE_START,
  };
};

const addNewAttendeeSuccess = () => {
  return {
    type: actionTypes.ADD_NEW_MEETING_ATTENDEE_SUCCESS,
  };
};

const addNewAttendeeFail = (error) => {
  return {
    type: actionTypes.ADD_NEW_MEETING_ATTENDEE_FAIL,
    error: error,
  };
};

export const addNewMeetingAttendee = (meetingId, attendee) => {
  return (dispatch) => {
    dispatch(addNewAttendeeStart());

    const attendee_obj = {
      email: attendee.email,
      name: attendee.name,
    };

    // TODO: This API now takes access token credentials. Update this call to include credentials or use different API.
    api.meetings
      .addNewMeetingAttendees(meetingId, [attendee_obj])
      .then((response) => {
        console.log("Attendee added successfully!");
        dispatch(addNewAttendeeSuccess());
      })
      .catch((error) => {
        console.log(error);
        addNewAttendeeFail(error);
      });
  };
};

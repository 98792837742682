import api from "../../services/api";
import * as actionTypes from "./actionTypes";
import axios from "axios";

export const downloadTranscriptStart = () => {
  return {
    type: actionTypes.DOWNLOAD_TRANSCRIPT_START,
  };
};

const downloadTranscriptSuccess = (transcriptData, meetingId) => {
  return {
    type: actionTypes.DOWNLOAD_TRANSCRIPT_SUCCESS,
    url: transcriptData.url,
    content: transcriptData.content,
    meetingId: meetingId,
  };
};

const downloadTranscriptFail = (error) => {
  return {
    type: actionTypes.DOWNLOAD_TRANSCRIPT_FAIL,
    error: error,
  };
};

export const downloadTranscript = (accessToken, meetingId) => {
  const cleanMeetingDetailsResponse = (response, url, expirationTime) => {
    const body = response.data;
    return {
      url: url,
      content: body,
      expirationTime: expirationTime,
    };
  };

  return (dispatch) => {
    let url = "";
    let expirationTime = "";
    dispatch(downloadTranscriptStart());
    return api.meetings
      .getMeetingTranscript(accessToken, meetingId)
      .then((response) => {
        console.log("Meeting transcript api call was successful");
        url = response.data.url;
        expirationTime = response.data.expiration_time;
        return axios.get(url);
      })
      .then((response) => {
        console.log("Meeting download api call was successful");
        dispatch(
          downloadTranscriptSuccess(
            cleanMeetingDetailsResponse(response, url, expirationTime),
            meetingId
          )
        );
      })
      .catch((error) => {
        console.log("Meeting download api call failed:");
        dispatch(downloadTranscriptFail(error));
      });
  };
};

export const getTranscriptUrlStart = () => {
  return {
    type: actionTypes.GET_TRANSCRIPT_URL_START,
  };
};

const getTranscriptUrlSuccess = (transcriptData, meetingId) => {
  return {
    type: actionTypes.GET_TRANSCRIPT_URL_SUCCESS,
    url: transcriptData.url,
    meetingId: meetingId,
  };
};

const getTranscriptUrlFail = (error) => {
  return {
    type: actionTypes.GET_TRANSCRIPT_URL_FAIL,
    error: error,
  };
};

export const getTranscriptUrl = (accessToken, meetingId) => {
  const cleanMeetingDetailsResponse = (response) => {
    const body = response.data;
    return {
      url: body.url,
      expirationTime: body.expiration_time,
    };
  };

  return (dispatch) => {
    dispatch(getTranscriptUrlStart());
    return api.meetings
      .getMeetingTranscript(accessToken, meetingId)
      .then((response) => {
        console.log("Meeting transcript url api call was successful");
        dispatch(
          getTranscriptUrlSuccess(
            cleanMeetingDetailsResponse(response),
            meetingId
          )
        );
      })
      .catch((error) => {
        console.log("Meeting transcript url api call failed:");
        dispatch(getTranscriptUrlFail(error));
      });
  };
};

export const getTranscriptSpeakerNamesStart = () => {
  return {
    type: actionTypes.GET_TRANSCRIPT_SPEAKER_NAMES_START,
  };
};

const getTranscriptSpeakerNamesSuccess = (speakerNamesObj, meetingId) => {
  return {
    type: actionTypes.GET_TRANSCRIPT_SPEAKER_NAMES_SUCCESS,
    speakerNamesObj: speakerNamesObj,
    meetingId: meetingId,
  };
};

const getTranscriptSpeakerNamesFail = (error) => {
  return {
    type: actionTypes.GET_TRANSCRIPT_SPEAKER_NAMES_FAIL,
    error: error,
  };
};

export const getTranscriptSpeakerNames = (accessToken, meetingId) => {
  return (dispatch) => {
    dispatch(getTranscriptSpeakerNamesStart());
    return api.meetings
      .getTranscriptSpeakerNames(accessToken, meetingId)
      .then((response) => {
        console.log(
          "Meeting transcript speaker names get api call was successful"
        );
        let speakerNamesObj = response.data;
        dispatch(getTranscriptSpeakerNamesSuccess(speakerNamesObj, meetingId));
      })
      .catch((error) => {
        console.log("Meeting get speaker names api call failed:");
        dispatch(getTranscriptSpeakerNamesFail(error));
      });
  };
};

export const updateSpeakerNamesStart = () => {
  return {
    type: actionTypes.UPDATE_SPEAKER_NAMES_START,
  };
};

const updateSpeakerNamesSuccess = (speakerNamesObj, meetingId) => {
  return {
    type: actionTypes.UPDATE_SPEAKER_NAMES_SUCCESS,
    speakerNamesObj: speakerNamesObj,
    meetingId: meetingId,
  };
};

const updateSpeakerNamesFail = (error) => {
  return {
    type: actionTypes.UPDATE_SPEAKER_NAMES_FAIL,
    error: error,
  };
};

export const updateSpeakerNames = (accessToken, meetingId, speakerNames) => {
  return (dispatch) => {
    dispatch(updateSpeakerNamesStart());
    return api.meetings
      .updateSpeakerNames(accessToken, meetingId, speakerNames)
      .then((response) => {
        console.log(
          "Meeting transcript speaker names update api call was successful"
        );
        let speakerNamesObj = response.data;
        dispatch(updateSpeakerNamesSuccess(speakerNamesObj, meetingId));
      })
      .catch((error) => {
        console.log("Meeting update speaker names api call failed:");
        dispatch(updateSpeakerNamesFail(error));
      });
  };
};

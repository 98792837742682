import React, { Component } from "react";
import styled from "styled-components";
import Icon from "../Icon/Icon";

const DarkRoundButtonStyled = styled.button`
  cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")};
  height: ${(props) => props.size || "52px"};
  width: ${(props) => props.size || "52px"};
  border-radius: 26px;
  border: none;
  background-color: #2d2349;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  animation: ripple 0.75s linear ${(props) => (props.ripple ? "infinite" : "0")};

  @keyframes ripple {
    0% {
      box-shadow:
        0 0 0 0 rgba(255, 255, 255, 0.1),
        0 0 0 7px rgba(255, 255, 255, 0.1),
        0 0 0 15px rgba(255, 255, 255, 0.1);
    }
    100% {
      box-shadow:
        0 0 0 7px rgba(255, 255, 255, 0.1),
        0 0 0 15px rgba(255, 255, 255, 0.1),
        0 0 0 20px rgba(255, 255, 255, 0);
    }
  }
`;

/**
 *
 * A dark circular button that places it's input elements in it's center.
 *  props
 *  {
 *      size: the size of the circle. Defaults to 52px which should be good for most use cases.
 *      mainIcon: the name of the icon used for normal view
 *      hoverIcon: the name of the icon used for hover view
 *      alt: alternative text to display if icons are not available
 * }
 * @type {StyledComponentClass<JSX.IntrinsicElements["div"], any, JSX.IntrinsicElements["div"]>}
 */
class DarkRoundButton extends Component {
  state = {
    hovered: false,
  };

  toggleHoveredState = () => this.setState({ hovered: !this.state.hovered });

  render() {
    return (
      <DarkRoundButtonStyled
        title={this.props.title}
        onClick={this.props.onClick}
        onMouseEnter={this.toggleHoveredState}
        onMouseLeave={this.toggleHoveredState}
        size={this.props.size}
        disabled={this.props.disabled}
        ripple={this.props.ripple}
      >
        {!this.state.hovered || this.props.disabled ? (
          <Icon name={this.props.mainIcon} alt={this.props.alt} />
        ) : (
          <Icon
            name={this.props.hoverIcon || this.props.mainIcon}
            alt={this.props.alt}
          />
        )}
      </DarkRoundButtonStyled>
    );
  }
}

export default DarkRoundButton;

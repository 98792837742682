export const CARD_INVALID_ERROR = "Card is Invalid";
export const PLAN_NOT_SELECTED_ERROR = "Kindly select a plan";
export const PAYMENT_FAILED_ERROR = "Payment Failed";
export const PAYMENT_SUCCESS = "Successfully Subscribed";
export const TRIAL_ACTIVATED = "Trial Successfully Activated";
export const PLAN_CHANGED_SUCCESSFULLY = "Plan changed successfully.";
export const PAYMENT_PROCESSING = "Payment Processing";
export const MONTLY_QUOTA_ALREADY_USED =
  "You have used up your minutes for the month.";
export const CANCEL_SUBSCRIPTION_ERROR = "Contact support, Error.";
export const CANCEL_SUBSCRIPTION_SUCCESS =
  "Subscription will be cancelled at end of period";

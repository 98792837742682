import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Home from "./Layouts/Home";
import SetupMeetingRoom from "./Layouts/Meeting/SetupMeetingRoom";
import LoginLogoutSignup from "./Pages/LoginSignup/LoginLogoutSignup";
import * as actions from "../store/actions/index";

import "./App.css";

class App extends Component {
  authCode = null;
  authFlowState = null;
  meetingPathSegment = null;

  componentDidMount() {
    console.log("App mounted");
    //TODO: This should ultimately be handled on a page that takes care of Zoom/Other integrations
    const pathSegments = this.props.location.pathname.split("/").slice(2);
    const search = this.props.location.search;
    let queryParams = new URLSearchParams(search);
    const code = queryParams.get("code");
    const state = queryParams.get("state");
    if (code != null) {
      this.authCode = code;
    }
    if (state != null) {
      this.authFlowState = state;
    }
    if (pathSegments[0] === "meeting") {
      this.meetingPathSegment = pathSegments;
    }
    this.props.onStartAutoLogin();
  }

  render() {
    return (
      //TODO: we need to create another component that handles callback from Zoom and other websites that we integrate with.
      // The component should consume the code, set it in Redux state (potentially even handle the success/failure scenario) and then
      // redirect the user to the home page once integration is complete.
      <Switch>
        <Route
          path="/home"
          exact
          render={(props) => (
            <Home
              authCode={this.authCode}
              authFlowState={this.authFlowState}
              meetingPathSegment={this.meetingPathSegment}
            />
          )}
        />

        <Route path="/startmeeting" component={SetupMeetingRoom} />
        <Route path="/joinmeeting/:meetingId" component={SetupMeetingRoom} />
        <Route path="/auth" component={LoginLogoutSignup} />
        <Route
          path="/privacy-policy"
          component={() => {
            window.location.href = "https://capturemymeeting.com/privacy/";
            return null;
          }}
        />
        <Route
          path="/terms-conditions"
          component={() => {
            window.location.href =
              "https://capturemymeeting.com/terms-conditions/";
            return null;
          }}
        />
        <Route
          path="/support"
          component={() => {
            window.location.href = "https://capturemymeeting.com/contact-us/";
            return null;
          }}
        />
        <Route
          path="/user-documentation"
          component={() => {
            window.location.href =
              "https://capturemymeeting.com/user-documentation/";
            return null;
          }}
        />
        <Redirect from="/" to="/home" />
      </Switch>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onStartAutoLogin: () => dispatch(actions.authCheckState()),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(App));

/* -----------------------------------------------------------
                Generic Utility Methods
  ----------------------------------------------------------- */

/**
 *  A promise that get's resolved after the provided number of ms.
 *      Example:
 *          wait(10*1000).then(() => doSomething("xyz")).catch(failureCallback);
 * @param ms - the time to wait before resolving the promise.
 * @returns {Promise<any>}
 */
export const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Returns true if a meeting is a sample meeting, otherwise returns false.
export const isSampleMeeting = (meetingId) => {
  return (
    meetingId === process.env.REACT_APP_SAMPLE_MEETING_1_ID ||
    meetingId === process.env.REACT_APP_SAMPLE_MEETING_2_ID
  );
};

// Given a list of host objects, return true if the given user email is included in that list, and false otherwise.
export const userIsHost = (hostObjs, userEmail) => {
  for (let i = 0; i < hostObjs.length; i++) {
    if (hostObjs[i].email === userEmail) {
      return true;
    }
  }
  return false;
};

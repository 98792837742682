import React from "react";
import styled from "styled-components";
const HamburgerIcon = styled.div`
  width: 30px;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  padding: 11px 0px;
  cursor: pointer;

  @media (min-width: 769px) {
    display: none;
  }
`;

const Bar = styled.div`
  width: 90%;
  height: 3px;
  background-color: rgb(117, 79, 242);
`;
const hamburger = (props) => {
  return (
    <HamburgerIcon onClick={props.toggle}>
      <Bar />
      <Bar />
      <Bar />
    </HamburgerIcon>
  );
};

export default hamburger;

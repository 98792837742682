import api from "../../services/api";
import * as actionTypes from "./actionTypes";

export const openStripeModal = () => {
  return { type: actionTypes.OPEN_STRIPE_MODAL };
};

export const closeStripeModal = () => {
  return { type: actionTypes.CLOSE_STRIPE_MODAL };
};
export const setSubscriptionStatus = (isSubscribed) => ({
  type: actionTypes.SET_SUBSCRIPTION_STATUS,
  payload: isSubscribed,
});

/************************************
      REQUEST GET CURRENT SUBSCRIPTION
 ************************************/

const getCurrentSubscriptionStart = () => {
  return {
    type: actionTypes.GET_CURRENT_SUBSCRIPTION_START,
  };
};

const getCurrentSubscriptionSuccess = (response) => {
  return {
    type: actionTypes.GET_CURRENT_SUBSCRIPTION_SUCCESS,
    currentSubscription: response,
  };
};

const getCurrentSubscriptionFail = (error) => {
  return {
    type: actionTypes.GET_CURRENT_SUBSCRIPTION_FAIL,
    error: error,
  };
};

export const getCurrentSubscription = (accessToken) => {
  return (dispatch) => {
    dispatch(getCurrentSubscriptionStart());

    return api.subscription
      .getCurrentSubscription(accessToken)
      .then((response) => {
        dispatch(getCurrentSubscriptionSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getCurrentSubscriptionFail(error));
        throw error;
      });
  };
};

/************************************
    GET ALL SUBSCRIPTIONS PRODUCTS
 ************************************/

const getAllSubscriptionProductsStart = () => {
  return {
    type: actionTypes.GET_ALL_SUBSCRIPTION_PRODUCTS_START,
  };
};

const getAllSubscriptionProductsSuccess = (subscriptionProducts) => {
  return {
    type: actionTypes.GET_ALL_SUBSCRIPTION_PRODUCTS_SUCCESS,

    subscriptionProducts: subscriptionProducts,
  };
};

const getAllSubscriptionProductsFail = (error) => {
  return {
    type: actionTypes.GET_ALL_SUBSCRIPTION_PRODUCTS_FAIL,
    error: error,
  };
};

export const getAllSubscriptionProducts = () => {
  return (dispatch) => {
    dispatch(getAllSubscriptionProductsStart());

    return api.subscription
      .getAllSubscriptionProducts()
      .then((response) => {
        dispatch(getAllSubscriptionProductsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getAllSubscriptionProductsFail(error));
        throw error;
      });
  };
};

/************************************
   INITIATE CHECKOUT
 ************************************/

const createPaymentIntentStart = () => {
  return {
    type: actionTypes.CREATE_PAYMENT_INTENT_START,
  };
};

const createPaymentIntentSuccess = () => {
  return {
    type: actionTypes.CREATE_PAYMENT_INTENT_SUCCESS,

    // subscriptionProducts:subscriptionProducts,
  };
};

const createPaymentIntentFail = (error) => {
  return {
    type: actionTypes.CREATE_PAYMENT_INTENT_FAIL,
    error: error,
  };
};

export const createPaymentIntent = (accessToken, paymentParams) => {
  return (dispatch) => {
    dispatch(createPaymentIntentStart());

    return api.subscription
      .createPaymentIntent(accessToken, paymentParams)
      .then((response) => {
        dispatch(createPaymentIntentSuccess());
        return response;
      })
      .catch((error) => {
        dispatch(createPaymentIntentFail(error));
        throw error;
      });
  };
};
/************************************
   GET CUSTOMER PORTAL
 ************************************/

const getCustomerPortalStart = () => {
  return {
    type: actionTypes.GET_CUSTOMER_PORTAL_START,
  };
};

const getCustomerPortalSuccess = () => {
  return {
    type: actionTypes.GET_CUSTOMER_PORTAL_SUCCESS,

    // subscriptionProducts:subscriptionProducts,
  };
};

const getCustomerPortalFail = (error) => {
  return {
    type: actionTypes.GET_CUSTOMER_PORTAL_FAIL,
    error: error,
  };
};

export const getCustomerPortal = (accessToken) => {
  return (dispatch) => {
    dispatch(getCustomerPortalStart());

    return api.subscription
      .getCustomerPortal(accessToken)
      .then((response) => {
        dispatch(getCustomerPortalSuccess());
        return response;
      })
      .catch((error) => {
        dispatch(getCustomerPortalFail(error));
        throw error;
      });
  };
};
/************************************
   CANCEL_SUBSCRIPTION
 ************************************/

const cancelSubscriptionStart = () => {
  return {
    type: actionTypes.CANCEL_SUBSCRIPTION_START,
  };
};

const cancelSubscriptionSuccess = () => {
  return {
    type: actionTypes.CANCEL_SUBSCRIPTION_SUCCESS,

    // subscriptionProducts:subscriptionProducts,
  };
};

const cancelSubscriptionFail = (error) => {
  return {
    type: actionTypes.CANCEL_SUBSCRIPTION_FAIL,
    error: error,
  };
};
export const clearSubscription = () => {
  return {
    type: actionTypes.CLEAR_SUBSCRIPTION,
  };
};

export const cancelSubscription = (accessToken) => {
  return (dispatch) => {
    dispatch(cancelSubscriptionStart());

    return api.subscription
      .cancelSubscription(accessToken)
      .then((response) => {
        dispatch(cancelSubscriptionSuccess());
      })
      .catch((error) => {
        dispatch(cancelSubscriptionFail(error));
        throw error;
      });
  };
};
/************************************
   FREE_SUBSCRIPTION_BOUGHT
 ************************************/

const freeTrialSubscriptionStart = () => {
  return {
    type: actionTypes.FREE_TRIAL_SUBSCRIPTION_START,
  };
};

const freeTrialSubscriptionSuccess = () => {
  return {
    type: actionTypes.FREE_TRIAL_SUBSCRIPTION_SUCCESS,

    // subscriptionProducts:subscriptionProducts,
  };
};

const freeTrialSubscriptionFail = (error) => {
  return {
    type: actionTypes.FREE_TRIAL_SUBSCRIPTION_FAIL,
    error: error,
  };
};

export const freeTrialSubscription = (accessToken) => {
  return (dispatch) => {
    dispatch(freeTrialSubscriptionStart());

    return api.subscription
      .freeTrialSubscription(accessToken)
      .then((response) => {
        dispatch(freeTrialSubscriptionSuccess());
      })
      .catch((error) => {
        dispatch(freeTrialSubscriptionFail(error));
        throw error;
      });
  };
};

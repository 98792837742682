import api from "../../services/api";
import * as actionTypes from "./actionTypes";
import { auth } from "./index";
import { cleanApiErrorResponse } from "../utility";

/****************************************
             USER SIGN UP
 *****************************************/

const signupUserStart = () => {
  return {
    type: actionTypes.SIGNUP_USER_START,
  };
};

const signupUserSuccess = () => {
  return {
    type: actionTypes.SIGNUP_USER_SUCCESS,
  };
};

const signupUserFail = (error) => {
  return {
    type: actionTypes.SIGNUP_USER_FAIL,
    error: error,
  };
};

export const setPrefferedPlan = (preferred_plan) => {
  return {
    type: actionTypes.SET_PREFFERED_PLAN,
    preffered_plan: preferred_plan,
  };
};

export const signupUser = (
  firstName,
  lastName,
  email,
  password,
  agreeToPrivacy,
  code,
  plan
) => {
  return (dispatch) => {
    dispatch(signupUserStart());

    return api.user
      .signupUser(
        firstName,
        lastName,
        email,
        password,
        agreeToPrivacy,
        code,
        plan
      )
      .then((response) => {
        console.log("Successfully created user account!");
        console.log(response);
        if (response?.data?.selected_plan) {
          dispatch({
            type: actionTypes.SET_PREFFERED_PLAN,
            preffered_plan: response.data.selected_plan,
          });
        }
        dispatch(signupUserSuccess());
        dispatch(auth(email, password));
      })
      .catch((error) => {
        console.log("User account couldn't be created!");
        console.log(error);
        dispatch(signupUserFail(cleanApiErrorResponse(error)));
      });
  };
};

/****************************************
             GET USER DATA
 *****************************************/

export const getUserStart = () => {
  return {
    type: actionTypes.GET_USER_START,
  };
};

export const getUserSuccess = (
  firstName,
  lastName,
  userId,
  company,
  email,
  settings,
  zoom_refresh_token_exists,
  zoom_integrated,
  webex_refresh_token_exists,
  webex_integrated,
  webex_auto_share_on,
  webex_auto_record_on,
  todoistIntegrated,
  todoistAccessTokenExists,
  todoistAutoStoreToInbox,
  basicOpsRefreshTokenExists,
  basicOpsIntegrated,
  minutes
) => {
  return {
    type: actionTypes.GET_USER_SUCCESS,
    firstName: firstName,
    lastName: lastName,
    userId: userId,
    company: company,
    email: email,
    settings: {
      emailNotes: settings.email_notes,
    },
    zoom_refresh_token_exists: zoom_refresh_token_exists,
    zoom_integrated: zoom_integrated,
    webex_refresh_token_exists: webex_refresh_token_exists,
    webex_integrated: webex_integrated,
    webex_auto_share_on: webex_auto_share_on,
    webex_auto_record_on: webex_auto_record_on,
    todoistIntegrated: todoistIntegrated,
    todoistAccessTokenExists: todoistAccessTokenExists,
    todoistAutoStoreToInbox: todoistAutoStoreToInbox,
    basicOpsRefreshTokenExists: basicOpsRefreshTokenExists,
    basicOpsIntegrated: basicOpsIntegrated,
    minutes: minutes,
  };
};

export const getUserFail = (error) => {
  return {
    type: actionTypes.GET_USER_FAIL,
    error: error,
  };
};

export const getUser = (accessToken) => {
  return (dispatch) => {
    dispatch(getUserStart());

    return api.user
      .getUser(accessToken)
      .then((response) => {
        const data = response.data.results[0];
        dispatch(
          getUserSuccess(
            data.first_name,
            data.last_name,
            data.id,
            data.company,
            data.email_object[0].email,
            data.user_settings,
            data.zoom_refresh_token_exists,
            data.zoom_integrated,
            data.webex_refresh_token_exists,
            data.webex_integrated,
            data.webex_auto_share_on,
            data.webex_auto_record_on,
            data.todoist_integrated,
            data.todoist_access_token_exists,
            data.todoist_auto_store_to_inbox,
            data.basicops_refresh_token_exists,
            data.basicops_integrated,
            data.minutes
          )
        );
      })
      .catch((error) => {
        console.log(error);
        getUserFail(error);
      });
  };
};

/****************************************
             USER UPDATE
 *****************************************/

const updateUserStart = () => {
  return {
    type: actionTypes.UPDATE_USER_START,
  };
};

const updateUserSuccess = () => {
  return {
    type: actionTypes.UPDATE_USER_SUCCESS,
  };
};

const updateUserFail = (error) => {
  console.log("User update failed");
  return {
    type: actionTypes.UPDATE_USER_FAIL,
    error: error,
  };
};

export const updateUser = (
  accessToken,
  firstName,
  lastName,
  company,
  userId
) => {
  return (dispatch) => {
    dispatch(updateUserStart());

    api.user
      .updateUser(accessToken, firstName, lastName, company, userId)
      .then((_) => {
        console.log("Successfully updated user account!");
        dispatch(updateUserSuccess());
      })
      .catch((error) => {
        console.log("User account couldn't be updated");
        console.log(error);
        dispatch(updateUserFail(cleanApiErrorResponse(error)));
      });
  };
};
/****************************************
            Forget User Password
 *****************************************/

export const forgetPasswordStart = () => {
  return {
    type: actionTypes.FORGET_PASSWORD_START,
  };
};
export const forgetPasswordSuccess = () => {
  return {
    type: actionTypes.FORGET_PASSWORD_SUCCESS,
  };
};
export const forgetPasswordFail = (error) => {
  return {
    type: actionTypes.FORGET_PASSWORD_FAIL,
    error: error,
  };
};
export const forgetPassword = (email) => {
  return (dispatch) => {
    dispatch(forgetPasswordStart());

    return api.user
      .forgetPassword(email)
      .then((_) => {
        dispatch(forgetPasswordSuccess);
      })
      .catch((error) => {
        dispatch(forgetPasswordFail(error));
        throw error;
      });
  };
};
/****************************************
            Setting New User Password
 *****************************************/
export const settingNewPasswordStart = () => {
  return {
    type: actionTypes.SETTING_NEWPASSWORD_START,
  };
};
export const settingNewPasswordSuccess = () => {
  return {
    type: actionTypes.SETTING_NEWPASSWORD_SUCCESS,
  };
};
export const settingNewPasswordFail = (error) => {
  return {
    type: actionTypes.SETTING_NEWPASSWORD_FAIL,
    error: error,
  };
};
export const settingNewPassword = (
  newPassword,
  confirmPassword,
  key,
  token
) => {
  return (dispatch) => {
    dispatch(settingNewPasswordStart());

    return api.user
      .settingNewPassword(newPassword, confirmPassword, key, token)
      .then((_) => {
        console.log("Get the password reset link successfully");
        dispatch(settingNewPasswordSuccess);
      })
      .catch((error) => {
        dispatch(settingNewPasswordFail(error));
        throw error;
      });
  };
};

/****************************************
            Update User Password
 *****************************************/

export const updatePasswordStart = () => {
  return {
    type: actionTypes.UPDATE_PASSWORD_START,
  };
};

export const updatePasswordSuccess = () => {
  return {
    type: actionTypes.UPDATE_PASSWORD_SUCCESS,
  };
};

export const updatePasswordFail = (error) => {
  console.log("Password update failed");
  return {
    type: actionTypes.UPDATE_PASSWORD_FAIL,
    error: error,
  };
};

export const updatePassword = (
  accessToken,
  userId,
  oldPassword,
  newPassword,
  confirmPassword
) => {
  return (dispatch) => {
    dispatch(updatePasswordStart());

    return api.user
      .updatePassword(
        accessToken,
        userId,
        oldPassword,
        newPassword,
        confirmPassword
      )
      .then((_) => {
        console.log("Password was updated successfully!");
        dispatch(updatePasswordSuccess());
      })
      .catch((error) => {
        dispatch(updatePasswordFail(error));
        throw error;
      });
  };
};
/************************************
      START EMAIL VERIFICATION
 ************************************/

export const startEmailVerificationStart = () => {
  return {
    type: actionTypes.START_EMAIL_VERIFICATION_START,
  };
};

export const startEmailVerificationSuccess = () => {
  return {
    type: actionTypes.START_EMAIL_VERIFICATION_SUCCESS,
  };
};

export const startEmailVerificationFail = (error) => {
  return {
    type: actionTypes.START_EMAIL_VERIFICATION_FAIL,
    error: error,
  };
};

export const startEmailVerification = (email) => {
  return (dispatch) => {
    dispatch(startEmailVerificationStart());

    return api.user
      .startEmailVerification(email)
      .then((_) => {
        console.log("Email verification started successfully");
        dispatch(startEmailVerificationSuccess());
      })
      .catch((error) => {
        console.log("Error starting email verification");
        dispatch(startEmailVerificationFail(error));
        throw error;
      });
  };
};

/************************************
      UPDATE USER SETTINGS
 ************************************/

const updateUserSettingsStart = () => {
  return {
    type: actionTypes.UPDATE_USER_SETTINGS_START,
  };
};

const updateUserSettingsSuccess = (settings) => {
  return {
    type: actionTypes.UPDATE_USER_SETTINGS_SUCCESS,
    emailNotes: settings.emailNotes,
  };
};

const updateUserSettingsFail = (error) => {
  return {
    type: actionTypes.UPDATE_USER_SETTINGS_FAIL,
    error: error,
  };
};

export const updateUserSettings = (accessToken, userId, settingsDict) => {
  return (dispatch) => {
    dispatch(updateUserSettingsStart());

    return api.user
      .updateUserSettings(accessToken, userId, settingsDict)
      .then((response) => {
        let updatedSettings = {
          emailNotes: response.data.email_notes,
        };
        dispatch(updateUserSettingsSuccess(updatedSettings));
      })
      .catch((error) => {
        const errorMsg = error.response.data.error;
        const statusCode = error.response.status;
        console.log(
          `There was a ${statusCode} error updating user settings: ${errorMsg}`
        );
        dispatch(updateUserSettingsFail(errorMsg));
        throw errorMsg;
      });
  };
};

/************************************
      GET ZOOM AUTHORIZATION URL
 ************************************/

const getZoomAuthorizationUrlStart = () => {
  return {
    type: actionTypes.GET_ZOOM_AUTHORIZATION_URL_START,
  };
};

const getZoomAuthorizationUrlSuccess = (authorization_url) => {
  return {
    type: actionTypes.GET_ZOOM_AUTHORIZATION_URL_SUCCESS,
    authorization_url: authorization_url,
  };
};

const getZoomAuthorizationUrlFail = (error) => {
  return {
    type: actionTypes.GET_ZOOM_AUTHORIZATION_URL_FAIL,
    error: error,
  };
};

export const getZoomAuthorizationUrl = (accessToken) => {
  return (dispatch) => {
    dispatch(getZoomAuthorizationUrlStart());

    return api.user
      .getZoomAuthorizationUrl(accessToken)
      .then((response) => {
        console.log("Zoom authorization url retrieved successfully.");
        dispatch(
          getZoomAuthorizationUrlSuccess(response.data.authorization_url)
        );
      })
      .catch((error) => {
        console.log(
          "There was an error retrieving the Zoom authorization url",
          error
        );
        dispatch(getZoomAuthorizationUrlFail(error));
        throw error;
      });
  };
};

/************************************
      STORE ZOOM USER INFO
 ************************************/

const storeZoomUserInfoStart = () => {
  return {
    type: actionTypes.STORE_ZOOM_USER_INFO_START,
  };
};

const storeZoomUserInfoSuccess = (
  zoom_refresh_token_exists,
  zoom_integrated
) => {
  return {
    type: actionTypes.STORE_ZOOM_USER_INFO_SUCCESS,
    zoom_refresh_token_exists: zoom_refresh_token_exists,
    zoom_integrated: zoom_integrated,
  };
};

const storeZoomUserInfoFail = (error) => {
  return {
    type: actionTypes.STORE_ZOOM_USER_INFO_FAIL,
    error: error,
  };
};

export const storeZoomUserInfo = (
  accessToken,
  userId,
  zoomAuthCode,
  authFlowState
) => {
  return (dispatch) => {
    if (
      !(
        zoomAuthCode &&
        authFlowState === process.env.REACT_APP_ZOOM_AUTH_STATE_VALUE
      )
    ) {
      return Promise.resolve();
    }

    dispatch(storeZoomUserInfoStart());

    return api.user
      .getZoomUserAccessToken(accessToken, userId, zoomAuthCode)
      .then((response) => {
        console.log("Zoom user access token retrieved successfully.");
        return api.user.setZoomUserId(accessToken, userId);
      })
      .then((response) => {
        console.log("Zoom user info stored successfully.");
        return api.user.setZoomAuthStatus(accessToken, userId);
      })
      .then((response) => {
        console.log("Zoom auth status set successfully.");
        dispatch(
          storeZoomUserInfoSuccess(
            response.data.zoom_refresh_token_exists,
            response.data.zoom_integrated
          )
        );
      })
      .catch((error) => {
        const errorMsg = error.response.data.error;
        const statusCode = error.response.status;
        console.log(
          `There was a ${statusCode} error storing the Zoom user info: ${errorMsg}`
        );

        // TODO: We are doing this to get around the issue where query params aren't getting cleared
        //  in the url, causing this API to be called multiple times. Fix that issue and remove this workaround.
        if (errorMsg === "invalid_grant") {
          return Promise.resolve();
        }

        dispatch(storeZoomUserInfoFail(errorMsg));
        throw errorMsg;
      });
  };
};

/************************************
      GET WEBEX AUTHORIZATION URL
 ************************************/

const getWebexAuthorizationUrlStart = () => {
  return {
    type: actionTypes.GET_WEBEX_AUTHORIZATION_URL_START,
  };
};

const getWebexAuthorizationUrlSuccess = (authorization_url) => {
  return {
    type: actionTypes.GET_WEBEX_AUTHORIZATION_URL_SUCCESS,
    authorization_url: authorization_url,
  };
};

const getWebexAuthorizationUrlFail = (error) => {
  return {
    type: actionTypes.GET_WEBEX_AUTHORIZATION_URL_FAIL,
    error: error,
  };
};

export const getWebexAuthorizationUrl = (accessToken) => {
  return (dispatch) => {
    dispatch(getWebexAuthorizationUrlStart());

    return api.user
      .getWebexAuthorizationUrl(accessToken)
      .then((response) => {
        console.log("Webex authorization url retrieved successfully.");
        dispatch(
          getWebexAuthorizationUrlSuccess(response.data.authorization_url)
        );
      })
      .catch((error) => {
        console.log(
          "There was an error retrieving the Webex authorization url",
          error
        );
        dispatch(getWebexAuthorizationUrlFail(error));
        throw error;
      });
  };
};

/************************************
      STORE WEBEX USER INFO
 ************************************/

const storeWebexUserInfoStart = () => {
  return {
    type: actionTypes.STORE_WEBEX_USER_INFO_START,
  };
};

const storeWebexUserInfoSuccess = (
  webex_refresh_token_exists,
  webex_integrated,
  webex_auto_share_on,
  webex_auto_record_on
) => {
  return {
    type: actionTypes.STORE_WEBEX_USER_INFO_SUCCESS,
    webex_refresh_token_exists: webex_refresh_token_exists,
    webex_integrated: webex_integrated,
    webex_auto_share_on: webex_auto_share_on,
    webex_auto_record_on: webex_auto_record_on,
  };
};

const storeWebexUserInfoFail = (error) => {
  return {
    type: actionTypes.STORE_WEBEX_USER_INFO_FAIL,
    error: error,
  };
};

export const storeWebexUserInfo = (
  accessToken,
  userId,
  webexAuthCode,
  authFlowState
) => {
  return (dispatch) => {
    if (
      !(
        webexAuthCode &&
        authFlowState === process.env.REACT_APP_WEBEX_AUTH_STATE_VALUE
      )
    ) {
      return Promise.resolve();
    }

    dispatch(storeWebexUserInfoStart());

    return api.user
      .getWebexUserAccessToken(accessToken, userId, webexAuthCode)
      .then((response) => {
        console.log("Webex user access token retrieved successfully.");
        return api.user.storeWebexUserInfo(accessToken, userId);
      })
      .then((response) => {
        console.log("Webex user info stored successfully.");
        dispatch(
          storeWebexUserInfoSuccess(
            response.data.webex_refresh_token_exists,
            response.data.webex_integrated,
            response.data.webex_auto_share_on,
            response.data.webex_auto_record_on
          )
        );
      })
      .catch((error) => {
        const errorMsg = error.response.data.error;
        const statusCode = error.response.status;
        console.log(
          `There was a ${statusCode} error storing the Webex user info: ${errorMsg}`
        );

        // TODO: We are doing this to get around the issue where query params aren't getting cleared
        //  in the url, causing this API to be called multiple times. Fix that issue and remove this workaround.
        if (errorMsg === "invalid_grant") {
          return Promise.resolve();
        }

        dispatch(storeWebexUserInfoFail(errorMsg));
        throw errorMsg;
      });
  };
};

/************************************
      REQUEST WEBEX REAUTHORIZATION
 ************************************/

const requestWebexReauthStart = () => {
  return {
    type: actionTypes.REQUEST_WEBEX_REAUTH_START,
  };
};

const requestWebexReauthSuccess = () => {
  return {
    type: actionTypes.REQUEST_WEBEX_REAUTH_SUCCESS,
  };
};

const requestWebexReauthFail = (error) => {
  return {
    type: actionTypes.REQUEST_WEBEX_REAUTH_FAIL,
    error: error,
  };
};

export const requestWebexReauth = (accessToken, userId) => {
  return (dispatch) => {
    dispatch(requestWebexReauthStart());

    return api.user
      .requestWebexReauth(accessToken, userId)
      .then((response) => {
        console.log("Webex reauthorization requested successfully.");
        dispatch(requestWebexReauthSuccess());
      })
      .catch((error) => {
        const errorMsg = error.response.data.error;
        const statusCode = error.response.status;
        console.log(
          `There was a ${statusCode} error storing the Webex user info: ${errorMsg}`
        );
        dispatch(requestWebexReauthFail(errorMsg));
        throw errorMsg;
      });
  };
};

/************************************
      UPDATE WEBEX USER SETTINGS
 ************************************/

const updateWebexUserSettingsStart = () => {
  return {
    type: actionTypes.UPDATE_WEBEX_USER_SETTINGS_START,
  };
};

const updateWebexUserSettingsSuccess = (settings) => {
  return {
    type: actionTypes.UPDATE_WEBEX_USER_SETTINGS_SUCCESS,
    webex_auto_share_on: settings.auto_share,
    webex_auto_record_on: settings.auto_record,
  };
};

const updateWebexUserSettingsFail = (error) => {
  return {
    type: actionTypes.UPDATE_WEBEX_USER_SETTINGS_FAIL,
    error: error,
  };
};

export const updateWebexUserSettings = (accessToken, userId, settingsDict) => {
  return (dispatch) => {
    dispatch(updateWebexUserSettingsStart());

    return api.user
      .updateWebexUserSettings(accessToken, userId, settingsDict)
      .then((response) => {
        dispatch(updateWebexUserSettingsSuccess(response.data));
      })
      .catch((error) => {
        const errorMsg = error.response.data.error;
        const statusCode = error.response.status;
        console.log(
          `There was a ${statusCode} error updating the Webex user settings: ${errorMsg}`
        );
        dispatch(updateWebexUserSettingsFail(errorMsg));
        throw errorMsg;
      });
  };
};

/************************************
      GET TODOIST AUTHORIZATION URL
 ************************************/

const getTodoistAuthorizationUrlStart = () => {
  return {
    type: actionTypes.GET_TODOIST_AUTHORIZATION_URL_START,
  };
};

const getTodoistAuthorizationUrlSuccess = (authorizationUrl) => {
  return {
    type: actionTypes.GET_TODOIST_AUTHORIZATION_URL_SUCCESS,
    authorizationUrl: authorizationUrl,
  };
};

const getTodoistAuthorizationUrlFail = (error) => {
  return {
    type: actionTypes.GET_TODOIST_AUTHORIZATION_URL_FAIL,
    error: error,
  };
};

export const getTodoistAuthorizationUrl = (accessToken) => {
  return (dispatch) => {
    dispatch(getTodoistAuthorizationUrlStart());

    return api.user
      .getTodoistAuthorizationUrl(accessToken)
      .then((response) => {
        console.log("Todoist authorization url retrieved successfully.");
        dispatch(
          getTodoistAuthorizationUrlSuccess(response.data.authorization_url)
        );
      })
      .catch((error) => {
        console.log(
          "There was an error retrieving the Todoist authorization url",
          error
        );
        dispatch(getTodoistAuthorizationUrlFail(error));
        throw error;
      });
  };
};

/************************************
      STORE TODOIST USER INFO
 ************************************/

const storeTodoistUserInfoStart = () => {
  return {
    type: actionTypes.STORE_TODOIST_USER_INFO_START,
  };
};

const storeTodoistUserInfoSuccess = (
  todoistAccessTokenExists,
  todoistIntegrated,
  todoistAutoStoreToInbox
) => {
  return {
    type: actionTypes.STORE_TODOIST_USER_INFO_SUCCESS,
    todoistAccessTokenExists: todoistAccessTokenExists,
    todoistIntegrated: todoistIntegrated,
    todoistAutoStoreToInbox: todoistAutoStoreToInbox,
  };
};

const storeTodoistUserInfoFail = (error) => {
  return {
    type: actionTypes.STORE_TODOIST_USER_INFO_FAIL,
    error: error,
  };
};

export const storeTodoistUserInfo = (
  accessToken,
  userId,
  todoistAuthCode,
  authFlowState
) => {
  return (dispatch) => {
    if (
      !(
        todoistAuthCode &&
        authFlowState === process.env.REACT_APP_TODOIST_AUTH_STATE_VALUE
      )
    ) {
      return Promise.resolve();
    }

    dispatch(storeTodoistUserInfoStart());

    return api.user
      .getTodoistUserAccessToken(accessToken, userId, todoistAuthCode)
      .then((response) => {
        console.log("Todoist user access token retrieved successfully.");
        return api.user.storeTodoistUserInfo(accessToken, userId);
      })
      .then((response) => {
        console.log("Todoist user info stored successfully.");
        dispatch(
          storeTodoistUserInfoSuccess(
            response.data.todoist_access_token_exists,
            response.data.todoist_integrated,
            response.data.todoist_auto_store_to_inbox
          )
        );
      })
      .catch((error) => {
        const errorMsg = error.response.data.error;
        const statusCode = error.response.status;
        console.log(
          `There was a ${statusCode} error storing the Todoist user info: ${errorMsg}`
        );

        // TODO: We are doing this to get around the issue where query params aren't getting cleared
        //  in the url, causing this API to be called multiple times. Fix that issue and remove this workaround.
        if (errorMsg === "invalid_grant") {
          return Promise.resolve();
        }

        dispatch(storeTodoistUserInfoFail(errorMsg));
        throw errorMsg;
      });
  };
};

/************************************
      REQUEST TODOIST REAUTHORIZATION
 ************************************/

const requestTodoistReauthStart = () => {
  return {
    type: actionTypes.REQUEST_TODOIST_REAUTH_START,
  };
};

const requestTodoistReauthSuccess = () => {
  return {
    type: actionTypes.REQUEST_TODOIST_REAUTH_SUCCESS,
  };
};

const requestTodoistReauthFail = (error) => {
  return {
    type: actionTypes.REQUEST_TODOIST_REAUTH_FAIL,
    error: error,
  };
};

export const requestTodoistReauth = (accessToken, userId) => {
  return (dispatch) => {
    dispatch(requestTodoistReauthStart());

    return api.user
      .requestTodoistReauth(accessToken, userId)
      .then((response) => {
        console.log("Todoist reauthorization requested successfully.");
        dispatch(requestTodoistReauthSuccess());
      })
      .catch((error) => {
        const errorMsg = error.response.data.error;
        const statusCode = error.response.status;
        console.log(
          `There was a ${statusCode} error storing the Todoist user info: ${errorMsg}`
        );
        dispatch(requestTodoistReauthFail(errorMsg));
        throw errorMsg;
      });
  };
};

/************************************
      UPDATE TODOIST USER SETTINGS
 ************************************/

const updateTodoistUserSettingsStart = () => {
  return {
    type: actionTypes.UPDATE_TODOIST_USER_SETTINGS_START,
  };
};

const updateTodoistUserSettingsSuccess = (settings) => {
  return {
    type: actionTypes.UPDATE_TODOIST_USER_SETTINGS_SUCCESS,
    todoistAutoStoreToInbox: settings.autoStoreToInbox,
  };
};

const updateTodoistUserSettingsFail = (error) => {
  return {
    type: actionTypes.UPDATE_TODOIST_USER_SETTINGS_FAIL,
    error: error,
  };
};

export const updateTodoistUserSettings = (
  accessToken,
  userId,
  settingsDict
) => {
  return (dispatch) => {
    dispatch(updateTodoistUserSettingsStart());

    return api.user
      .updateTodoistUserSettings(accessToken, userId, settingsDict)
      .then((response) => {
        let updatedSettings = {
          autoStoreToInbox: response.data.auto_store_to_inbox,
        };
        dispatch(updateTodoistUserSettingsSuccess(updatedSettings));
      })
      .catch((error) => {
        const errorMsg = error.response.data.error;
        const statusCode = error.response.status;
        console.log(
          `There was a ${statusCode} error updating the Todoist user settings: ${errorMsg}`
        );
        dispatch(updateTodoistUserSettingsFail(errorMsg));
        throw errorMsg;
      });
  };
};

/************************************
      GET BasicOps AUTHORIZATION URL
 ************************************/

const getBasicOpsAuthorizationUrlStart = () => {
  return {
    type: actionTypes.GET_BASICOPS_AUTHORIZATION_URL_START,
  };
};

const getBasicOpsAuthorizationUrlSuccess = (authorizationUrl) => {
  return {
    type: actionTypes.GET_BASICOPS_AUTHORIZATION_URL_SUCCESS,
    authorizationUrl: authorizationUrl,
  };
};

const getBasicOpsAuthorizationUrlFail = (error) => {
  return {
    type: actionTypes.GET_BASICOPS_AUTHORIZATION_URL_FAIL,
    error: error,
  };
};

export const getBasicOpsAuthorizationUrl = (accessToken) => {
  return (dispatch) => {
    dispatch(getBasicOpsAuthorizationUrlStart());

    return api.user
      .getBasicOpsAuthorizationUrl(accessToken)
      .then((response) => {
        console.log("BasicOps authorization url retrieved successfully.");
        dispatch(
          getBasicOpsAuthorizationUrlSuccess(response.data.authorization_url)
        );
      })
      .catch((error) => {
        console.log(
          "There was an error retrieving the BasicOps authorization url",
          error
        );
        dispatch(getBasicOpsAuthorizationUrlFail(error));
        throw error;
      });
  };
};

/************************************
      AUTHORIZE BASICOPS USER
 ************************************/

const authorizeBasicOpsUserStart = () => {
  return {
    type: actionTypes.AUTHORIZE_BASICOPS_USER_START,
  };
};

const authorizeBasicOpsUserSuccess = (
  basicOpsRefreshTokenExists,
  basicOpsIntegrated
) => {
  return {
    type: actionTypes.AUTHORIZE_BASICOPS_USER_SUCCESS,
    basicOpsRefreshTokenExists: basicOpsRefreshTokenExists,
    basicOpsIntegrated: basicOpsIntegrated,
  };
};

const authorizeBasicOpsUserFail = (error) => {
  return {
    type: actionTypes.AUTHORIZE_BASICOPS_USER_FAIL,
    error: error,
  };
};

export const authorizeBasicOpsUser = (
  accessToken,
  userId,
  basicOpsAuthCode,
  authFlowState
) => {
  return (dispatch) => {
    if (
      !(
        basicOpsAuthCode &&
        authFlowState === process.env.REACT_APP_BASICOPS_AUTH_STATE_VALUE
      )
    ) {
      return Promise.resolve();
    }

    dispatch(authorizeBasicOpsUserStart());

    return api.user
      .authorizeBasicOpsUser(accessToken, userId, basicOpsAuthCode)
      .then((response) => {
        console.log("BasicOps user authorized successfully.");
        dispatch(
          authorizeBasicOpsUserSuccess(
            response.data.basicops_refresh_token_exists,
            response.data.basicops_integrated
          )
        );
      })
      .catch((error) => {
        const errorMsg = error.response.data.error;
        const statusCode = error.response.status;
        console.log(
          `There was a ${statusCode} error storing the BasicOps user info`
        );

        // TODO: We are doing this to get around the issue where query params aren't getting cleared
        //  in the url, causing this API to be called multiple times. Fix that issue and remove this workaround.
        if (errorMsg === "invalid_grant") {
          return Promise.resolve();
        }

        dispatch(authorizeBasicOpsUserFail(errorMsg));
        throw errorMsg;
      });
  };
};

/************************************
      REMOVE BASICOPS INTEGRATION
 ************************************/

const removeBasicOpsStart = () => {
  return {
    type: actionTypes.REMOVE_BASICOPS_START,
  };
};

const removeBasicOpsSuccess = () => {
  return {
    type: actionTypes.REMOVE_BASICOPS_SUCCESS,
  };
};

const removeBasicOpsFail = (error) => {
  return {
    type: actionTypes.REMOVE_BASICOPS_FAIL,
    error: error,
  };
};

export const removeBasicOps = (accessToken, userId) => {
  return (dispatch) => {
    dispatch(removeBasicOpsStart());

    return api.user
      .removeBasicOps(accessToken, userId)
      .then((response) => {
        console.log("BasicOps integration removed successfully.");
        dispatch(removeBasicOpsSuccess());
      })
      .catch((error) => {
        const errorMsg = error.response.data.error;
        const statusCode = error.response.status;
        console.log(
          `There was a ${statusCode} error storing the BasicOps user info`
        );
        dispatch(removeBasicOpsFail(errorMsg));
        throw errorMsg;
      });
  };
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class SentimentLineChartDot extends Component {
  state = {};

  render() {
    // Storing props passed in by default Recharts Dot for the record
    const { cx, cy, r, getColor, payload, stroke } = this.props;
    const color = getColor(payload);

    return (
      <circle
        cx={cx}
        cy={cy}
        r={r}
        fill={color}
        stroke={stroke}
        cursor={"pointer"}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SentimentLineChartDot)
);

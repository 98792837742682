import React, { Component } from "react";
import styled from "styled-components";
import webexIcon from "../../../assets/images/webex/webex_icon.png";

const WebexLogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

/**
 *
 * A component that places the Webex icon next to the desired text. The desired text is optional.
 *  props
 *  {
 *      height: the height of the Webex icon
 *      width: the width of the Webex icon
 *      logoText: the logo text, which will appear to the right of the icon if given
 * }
 */
class WebexLogo extends Component {
  state = {};

  height = () => (this.props.height ? this.props.height : "25px");
  width = () => (this.props.width ? this.props.width : "30px");

  render() {
    return (
      <WebexLogoContainer>
        <img
          src={webexIcon}
          alt="Webex Logo"
          style={{ height: this.height(), width: this.width() }}
        />
        {this.props.logoText ? (
          <div style={{ marginLeft: "5px" }}>{this.props.logoText}</div>
        ) : null}
      </WebexLogoContainer>
    );
  }
}

export default WebexLogo;

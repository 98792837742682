import api from "../../services/api";
import * as actionTypes from "./actionTypes";

export const getNotesStart = () => {
  return {
    type: actionTypes.GET_MEETING_NOTES_START,
  };
};

const getNotesSuccess = (notesData, meetingId) => {
  return {
    type: actionTypes.GET_MEETING_NOTES_SUCCESS,
    meetingId: meetingId,
    notesId: notesData.notesId,
    overview: notesData.overview,
    summary: notesData.summary,
    actionItems: notesData.actionItems,
    timestampRanges: notesData.timestampRanges,
  };
};

const getNotesFail = (error) => {
  return {
    type: actionTypes.GET_MEETING_NOTES_FAIL,
    error: error,
  };
};

export const getMeetingNotes = (accessToken, meetingId) => {
  const cleanMeetingNotesResponse = (response) => {
    const body = response.data;
    return {
      notesId: body.notes_id,
      overview: body.overview,
      summary: body.summary,
      actionItems: body.action_items,
      timestampRanges: body.timestamp_ranges,
    };
  };

  return (dispatch) => {
    dispatch(getNotesStart());
    return api.meetings
      .getMeetingNotes(accessToken, meetingId)
      .then((response) => {
        console.log("Meeting notes api call was successful");
        dispatch(
          getNotesSuccess(cleanMeetingNotesResponse(response), meetingId)
        );
      })
      .catch((error) => {
        console.log("Meeting notes api call failed:");
        dispatch(getNotesFail(error));
        throw error;
      });
  };
};

export const storeActionItemsInTodoistStart = () => {
  return {
    type: actionTypes.STORE_ACTION_ITEMS_IN_TODOIST_START,
  };
};

const storeActionItemsInTodoistSuccess = (actionItems) => {
  return {
    type: actionTypes.STORE_ACTION_ITEMS_IN_TODOIST_SUCCESS,
    actionItems: actionItems,
  };
};

const storeActionItemsInTodoistFail = (error) => {
  return {
    type: actionTypes.STORE_ACTION_ITEMS_IN_TODOIST_FAIL,
    error: error,
  };
};

export const storeActionItemsInTodoist = (
  accessToken,
  notesId,
  actionItemIds
) => {
  return (dispatch) => {
    dispatch(storeActionItemsInTodoistStart());
    return api.meetings
      .storeActionItemsInTodoist(accessToken, notesId, actionItemIds)
      .then((response) => {
        console.log("Store action items in Todoist api call was successful");
        let actionItems = response.data.action_items;
        dispatch(storeActionItemsInTodoistSuccess(actionItems));
      })
      .catch((error) => {
        console.log("Store action items in Todoist api call failed:");
        dispatch(storeActionItemsInTodoistFail(error));
      });
  };
};

export const getSentimentsDataStart = () => {
  return {
    type: actionTypes.GET_SENTIMENTS_DATA_START,
  };
};

const getSentimentsDataSuccess = (sentimentsData, meetingId) => {
  return {
    type: actionTypes.GET_SENTIMENTS_DATA_SUCCESS,
    sentimentsData: sentimentsData,
    meetingId: meetingId,
  };
};

const getSentimentsDataFail = (error) => {
  return {
    type: actionTypes.GET_SENTIMENTS_DATA_FAIL,
    error: error,
  };
};

export const getSentimentsData = (accessToken, meetingId) => {
  return (dispatch) => {
    dispatch(getSentimentsDataStart());
    return api.meetings
      .getSentimentsData(accessToken, meetingId)
      .then((response) => {
        console.log("Meeting sentiments data get api call was successful");
        let sentimentsData = response.data.sentiments_data;
        dispatch(getSentimentsDataSuccess(sentimentsData, meetingId));
      })
      .catch((error) => {
        console.log("Meeting get sentiments data api call failed:");
        dispatch(getSentimentsDataFail(error));
      });
  };
};

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const GET_USER_START = "GET_USER_START";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const SIGNUP_USER_START = "SIGNUP_USER_START";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_FAIL = "SIGNUP_USER_FAIL";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const FORGET_PASSWORD_START = "FORGET_PASSWORD_START";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAIL = "FORGET_PASSWORD_FAIL";

export const SETTING_NEWPASSWORD_START = "SETTING_NEWPASSWORD_START";
export const SETTING_NEWPASSWORD_SUCCESS = "SETTING_NEWPASSWORD_SUCCESS";
export const SETTING_NEWPASSWORD_FAIL = "SETTING_NEWPASSWORD_FAIL";

export const UPDATE_PASSWORD_START = "UPDATE_PASSWORD_START";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";

export const SET_PREFFERED_PLAN = "SET_PREFFERED_PLAN";
export const UNSET_PREFFERED_PLAN = "UNSET_PREFFERED_PLAN";

export const START_EMAIL_VERIFICATION_START = "START_EMAIL_VERIFICATION_START";
export const START_EMAIL_VERIFICATION_SUCCESS =
  "START_EMAIL_VERIFICATION_SUCCESS";
export const START_EMAIL_VERIFICATION_FAIL = "START_EMAIL_VERIFICATION_FAIL";

export const UPDATE_USER_SETTINGS_START = "UPDATE_USER_SETTINGS_START";
export const UPDATE_USER_SETTINGS_SUCCESS = "UPDATE_USER_SETTINGS_SUCCESS";
export const UPDATE_USER_SETTINGS_FAIL = "UPDATE_USER_SETTINGS_FAIL";

//TODO: re-purpose these for searching
export const GET_MEETINGS_START = "GET_MEETINGS_START";
export const GET_MEETINGS_SUCCESS = "GET_MEETINGS_SUCCESS";
export const GET_MEETINGS_FAIL = "GET_MEETINGS_FAIL";

export const SEARCH_MEETINGS_TEXT_CONTENT_START =
  "SEARCH_MEETINGS_TEXT_CONTENT_START";
export const SEARCH_MEETINGS_TEXT_CONTENT_SUCCESS =
  "SEARCH_MEETINGS_TEXT_CONTENT_SUCCESS";
export const SEARCH_MEETINGS_TEXT_CONTENT_FAIL =
  "SEARCH_MEETINGS_TEXT_CONTENT_FAIL";
export const SEARCH_MEETINGS_VOICE_CONTENT_START =
  "SEARCH_MEETINGS_VOICE_CONTENT_START";
export const SEARCH_MEETINGS_VOICE_CONTENT_SUCCESS =
  "SEARCH_MEETINGS_VOICE_CONTENT_SUCCESS";
export const SEARCH_MEETINGS_VOICE_CONTENT_FAIL =
  "SEARCH_MEETINGS_VOICE_CONTENT_FAIL";
export const UPDATE_SEARCH_QUERY_TYPE = "UPDATE_SEARCH_QUERY_TYPE";

export const UPDATE_MEETING_NAME_START = "UPDATE_MEETING_NAME_START";
export const UPDATE_MEETING_NAME_SUCCESS = "UPDATE_MEETING_NAME_SUCCESS";
export const UPDATE_MEETING_NAME_FAIL = "UPDATE_MEETING_NAME_FAIL";

export const GET_ZOOM_AUTHORIZATION_URL_START =
  "GET_ZOOM_AUTHORIZATION_URL_START";
export const GET_ZOOM_AUTHORIZATION_URL_SUCCESS =
  "GET_ZOOM_AUTHORIZATION_URL_SUCCESS";
export const GET_ZOOM_AUTHORIZATION_URL_FAIL =
  "GET_ZOOM_AUTHORIZATION_URL_FAIL";

export const STORE_ZOOM_USER_INFO_START = "STORE_ZOOM_USER_INFO_START";
export const STORE_ZOOM_USER_INFO_SUCCESS = "STORE_ZOOM_USER_INFO_SUCCESS";
export const STORE_ZOOM_USER_INFO_FAIL = "STORE_ZOOM_USER_INFO_FAIL";

export const GET_WEBEX_AUTHORIZATION_URL_START =
  "GET_WEBEX_AUTHORIZATION_URL_START";
export const GET_WEBEX_AUTHORIZATION_URL_SUCCESS =
  "GET_WEBEX_AUTHORIZATION_URL_SUCCESS";
export const GET_WEBEX_AUTHORIZATION_URL_FAIL =
  "GET_WEBEX_AUTHORIZATION_URL_FAIL";

export const STORE_WEBEX_USER_INFO_START = "STORE_WEBEX_USER_INFO_START";
export const STORE_WEBEX_USER_INFO_SUCCESS = "STORE_WEBEX_USER_INFO_SUCCESS";
export const STORE_WEBEX_USER_INFO_FAIL = "STORE_WEBEX_USER_INFO_FAIL";

export const REQUEST_WEBEX_REAUTH_START = "REQUEST_WEBEX_REAUTH_START";
export const REQUEST_WEBEX_REAUTH_SUCCESS = "REQUEST_WEBEX_REAUTH_SUCCESS";
export const REQUEST_WEBEX_REAUTH_FAIL = "REQUEST_WEBEX_REAUTH_FAIL";

export const UPDATE_WEBEX_USER_SETTINGS_START =
  "UPDATE_WEBEX_USER_SETTINGS_START";
export const UPDATE_WEBEX_USER_SETTINGS_SUCCESS =
  "UPDATE_WEBEX_USER_SETTINGS_SUCCESS";
export const UPDATE_WEBEX_USER_SETTINGS_FAIL =
  "UPDATE_WEBEX_USER_SETTINGS_FAIL";

export const GET_TODOIST_AUTHORIZATION_URL_START =
  "GET_TODOIST_AUTHORIZATION_URL_START";
export const GET_TODOIST_AUTHORIZATION_URL_SUCCESS =
  "GET_TODOIST_AUTHORIZATION_URL_SUCCESS";
export const GET_TODOIST_AUTHORIZATION_URL_FAIL =
  "GET_TODOIST_AUTHORIZATION_URL_FAIL";

export const STORE_TODOIST_USER_INFO_START = "STORE_TODOIST_USER_INFO_START";
export const STORE_TODOIST_USER_INFO_SUCCESS =
  "STORE_TODOIST_USER_INFO_SUCCESS";
export const STORE_TODOIST_USER_INFO_FAIL = "STORE_TODOIST_USER_INFO_FAIL";

export const REQUEST_TODOIST_REAUTH_START = "REQUEST_TODOIST_REAUTH_START";
export const REQUEST_TODOIST_REAUTH_SUCCESS = "REQUEST_TODOIST_REAUTH_SUCCESS";
export const REQUEST_TODOIST_REAUTH_FAIL = "REQUEST_TODOIST_REAUTH_FAIL";

export const UPDATE_TODOIST_USER_SETTINGS_START =
  "UPDATE_TODOIST_USER_SETTINGS_START";
export const UPDATE_TODOIST_USER_SETTINGS_SUCCESS =
  "UPDATE_TODOIST_USER_SETTINGS_SUCCESS";
export const UPDATE_TODOIST_USER_SETTINGS_FAIL =
  "UPDATE_TODOIST_USER_SETTINGS_FAIL";

export const GET_BASICOPS_AUTHORIZATION_URL_START =
  "GET_BASICOPS_AUTHORIZATION_URL_START";
export const GET_BASICOPS_AUTHORIZATION_URL_SUCCESS =
  "GET_BASICOPS_AUTHORIZATION_URL_SUCCESS";
export const GET_BASICOPS_AUTHORIZATION_URL_FAIL =
  "GET_BASICOPS_AUTHORIZATION_URL_FAIL";

export const AUTHORIZE_BASICOPS_USER_START = "AUTHORIZE_BASICOPS_USER_START";
export const AUTHORIZE_BASICOPS_USER_SUCCESS =
  "AUTHORIZE_BASICOPS_USER_SUCCESS";
export const AUTHORIZE_BASICOPS_USER_FAIL = "AUTHORIZE_BASICOPS_USER_FAIL";

export const REMOVE_BASICOPS_START = "REMOVE_BASICOPS_START";
export const REMOVE_BASICOPS_SUCCESS = "REMOVE_BASICOPS_SUCCESS";
export const REMOVE_BASICOPS_FAIL = "REMOVE_BASICOPS_FAIL";

export const DELETE_MEETING_START = "DELETE_MEETING_START";
export const DELETE_MEETING_SUCCESS = "DELETE_MEETING_SUCCESS";
export const DELETE_MEETING_FAIL = "DELETE_MEETING_FAIL";

export const GET_MEETING_RECORDING_START = "GET_MEETING_RECORDING_START";
export const GET_MEETING_RECORDING_SUCCESS = "GET_MEETING_RECORDING_SUCCESS";
export const GET_MEETING_RECORDING_FAIL = "GET_MEETING_RECORDING_FAIL";
export const OPEN_MEETING_MODAL = "OPEN_MEETING_MODAL";
export const CLOSE_MEETING_MODAL = "CLOSE_MEETING_MODAL";

export const CREATE_MEETING_START = "CREATE_MEETING_START";
export const CREATE_MEETING_SUCCESS = "CREATE_MEETING_SUCCESS";
export const CREATE_MEETING_FAIL = "CREATE_MEETING_FAIL";

export const DOWNLOAD_MEETING_START = "DOWNLOAD_MEETING_START";
export const DOWNLOAD_MEETING_SUCCESS = "DOWNLOAD_MEETING_SUCCESS";
export const DOWNLOAD_MEETING_ERROR = "DOWNLOAD_MEETING_ERROR";

export const DOWNLOAD_TRANSCRIPT_START = "DOWNLOAD_TRANSCRIPT_START";
export const DOWNLOAD_TRANSCRIPT_SUCCESS = "DOWNLOAD_TRANSCRIPT_SUCCESS";
export const DOWNLOAD_TRANSCRIPT_FAIL = "DOWNLOAD_TRANSCRIPT_FAIL";

export const GET_TRANSCRIPT_URL_START = "GET_TRANSCRIPT_URL_START";
export const GET_TRANSCRIPT_URL_SUCCESS = "GET_TRANSCRIPT_URL_SUCCESS";
export const GET_TRANSCRIPT_URL_FAIL = "GET_TRANSCRIPT_URL_FAIL";

export const GET_TRANSCRIPT_SPEAKER_NAMES_START =
  "GET_TRANSCRIPT_SPEAKER_NAMES_START";
export const GET_TRANSCRIPT_SPEAKER_NAMES_SUCCESS =
  "GET_TRANSCRIPT_SPEAKER_NAMES_SUCCESS";
export const GET_TRANSCRIPT_SPEAKER_NAMES_FAIL =
  "GET_TRANSCRIPT_SPEAKER_NAMES_FAIL";

export const UPDATE_SPEAKER_NAMES_START = "UPDATE_SPEAKER_NAMES_START";
export const UPDATE_SPEAKER_NAMES_SUCCESS = "UPDATE_SPEAKER_NAMES_SUCCESS";
export const UPDATE_SPEAKER_NAMES_FAIL = "UPDATE_SPEAKER_NAMES_FAIL";

export const GET_MEETING_NOTES_START = "GET_MEETING_NOTES_START";
export const GET_MEETING_NOTES_SUCCESS = "GET_MEETING_NOTES_SUCCESS";
export const GET_MEETING_NOTES_FAIL = "GET_MEETING_NOTES_FAIL";

export const GET_SENTIMENTS_DATA_START = "GET_SENTIMENTS_DATA_START";
export const GET_SENTIMENTS_DATA_SUCCESS = "GET_SENTIMENTS_DATA_SUCCESS";
export const GET_SENTIMENTS_DATA_FAIL = "GET_SENTIMENTS_DATA_FAIL";

export const STORE_ACTION_ITEMS_IN_TODOIST_START =
  "STORE_ACTION_ITEMS_IN_TODOIST_START";
export const STORE_ACTION_ITEMS_IN_TODOIST_SUCCESS =
  "STORE_ACTION_ITEMS_IN_TODOIST_SUCCESS";
export const STORE_ACTION_ITEMS_IN_TODOIST_FAIL =
  "STORE_ACTION_ITEMS_IN_TODOIST_FAIL";

export const UPLOAD_MEETING_VIDEO_START = "UPLOAD_MEETING_VIDEO_START";
export const UPLOAD_MEETING_VIDEO_SUCCESS = "UPLOAD_MEETING_VIDEO_SUCCESS";
export const UPLOAD_MEETING_VIDEO_ERROR = "UPLOAD_MEETING_VIDEO_ERROR";

export const SETUP_MEETING_SESSION = "SETUP_MEETING_SESSION";

export const ADD_NEW_MEETING_ATTENDEE_START = "ADD_NEW_MEETING_ATTENDEE_START";
export const ADD_NEW_MEETING_ATTENDEE_SUCCESS =
  "ADD_NEW_MEETING_ATTENDEE_SUCCESS";
export const ADD_NEW_MEETING_ATTENDEE_FAIL = "ADD_NEW_MEETING_ATTENDEE_FAIL";

export const ADDED_REMOTE_ATTENDEES = "ADDED_REMOTE_ATTENDEES";
export const REMOVED_REMOTE_ATTENDEES = "REMOVED_REMOTE_ATTENDEES";
export const UPDATED_REMOTE_ATTENDEES = "UPDATED_REMOTE_ATTENDEES";

export const SUBMIT_MEETING_SURVEY_START = "SUBMIT_MEETING_SURVEY_START";
export const SUBMIT_MEETING_SURVEY_SUCCESS = "SUBMIT_MEETING_SURVEY_SUCCESS";
export const SUBMIT_MEETING_SURVEY_FAIL = "SUBMIT_MEETING_SURVEY_FAIL";

export const SHARE_MEETING_START = "SHARE_MEETING_START";
export const SHARE_MEETING_SUCCESS = "SHARE_MEETING_SUCCESS";
export const SHARE_MEETING_FAIL = "SHARE_MEETING_FAIL";

export const DELETE_ATTENDEE_START = "DELETE_ATTENDEE_START";
export const DELETE_ATTENDEE_SUCCESS = "DELETE_ATTENDEE_SUCCESS";
export const DELETE_ATTENDEE_FAIL = "DELETE_ATTENDEE_FAIL";

export const UPLOAD_PROGRESS_UPDATE = "UPLOAD_PROGRESS_UPDATE";

export const OPEN_STRIPE_MODAL = "OPEN_STRIPE_MODAL";
export const CLOSE_STRIPE_MODAL = "CLOSE_STRIPE_MODAL";
export const SET_SUBSCRIPTION_STATUS = "SET_SUBSCRIPTION_STATUS";

export const GET_CURRENT_SUBSCRIPTION_START = "GET_CURRENT_SUBSCRIPTION_START";
export const GET_CURRENT_SUBSCRIPTION_SUCCESS =
  "GET_CURRENT_SUBSCRIPTION_SUCCESS";
export const GET_CURRENT_SUBSCRIPTION_FAIL = "GET_CURRENT_SUBSCRIPTION_FAIL";

export const GET_ALL_SUBSCRIPTION_PRODUCTS_START =
  "GET_ALL_SUBSCRIPTION_PRODUCTS_START";
export const GET_ALL_SUBSCRIPTION_PRODUCTS_SUCCESS =
  "GET_ALL_SUBSCRIPTION_PRODUCTS_SUCCESS";
export const GET_ALL_SUBSCRIPTION_PRODUCTS_FAIL =
  "GET_ALL_SUBSCRIPTION_PRODUCTS_FAIL";

export const CREATE_PAYMENT_INTENT_START = "CREATE_PAYMENT_INTENT_START";
export const CREATE_PAYMENT_INTENT_SUCCESS = "CREATE_PAYMENT_INTENT_SUCCESS";
export const CREATE_PAYMENT_INTENT_FAIL = "CREATE_PAYMENT_INTENT_FAIL";

export const GET_CUSTOMER_PORTAL_START = "GET_CUSTOMER_PORTAL_START";
export const GET_CUSTOMER_PORTAL_SUCCESS = "GET_CUSTOMER_PORTAL_SUCCESS";
export const GET_CUSTOMER_PORTAL_FAIL = "GET_CUSTOMER_PORTAL_FAIL";

export const CANCEL_SUBSCRIPTION_START = "CANCEL_SUBSCRIPTION_START";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAIL = "CANCEL_SUBSCRIPTION_FAIL";

export const FREE_TRIAL_SUBSCRIPTION_START = "FREE_TRIAL__SUBSCRIPTION_START";
export const FREE_TRIAL_SUBSCRIPTION_SUCCESS =
  "FREE_TRIAL__SUBSCRIPTION_SUCCESS";
export const FREE_TRIAL_SUBSCRIPTION_FAIL = "FREE_TRIAL__SUBSCRIPTION_FAIL";

export const CLEAR_SUBSCRIPTION = "CLEAR_SUBSCRIPTION";

import kurentoUtils from "kurento-utils";
import { v4 as uuidv4 } from "uuid";

import MeetingAttendee from "../models/MeetingAttendee";
import * as actionTypes from "../store/actions/actionTypes";
import { AttendeeTypes } from "../containers/Layouts/Meeting/MeetingRoom/MeetingRoom";

// TODO: add logger
// TODO: make use of exceptions
// var logger = window.Logger || console

class Capture {
  UNKNOWN = "UNKNOWN";
  _ws;
  _webRtcPeer;
  _updateStateWithAttendees;
  _getAttendees;
  _meetingId;
  _serviceUser; // the main user who started/joined the service
  _sessionTerminated;
  _setPersonRemovalState; // update the state of the person removal toggle

  mediaConstraints = {
    audio: true,
    // it will try to obtain 1920x1080 image and if it's not available
    //    then it will find the next available setting such that the aspect
    //    ration is maintained.
    video: true,
    //  video: {
    //     aspectRatio: { ideal: 1.7777777778 },
    //     height: { min: 400, ideal: 1080 },
    //     width: { min: 640, ideal: 1920 },
    //     resizeMode: 'crop-and-scale',
    // }
  };

  /***
   * @param updateAttendees - the function to call when we need to update state with current list of attendees
   * @param getAttendees - a method that can be invoked to get the current list of attendees
   */
  constructor(
    updateAttendees,
    getAttendees,
    sessionTerminated,
    setPersonRemovalState
  ) {
    this._updateStateWithAttendees = updateAttendees;
    this._getAttendees = getAttendees;
    this._sessionTerminated = sessionTerminated;
    this._setPersonRemovalState = setPersonRemovalState;

    // todo: maybe initiate the service as sendAsPresenter fxn (but in that case, will need to wait on websocket connection to be established before proceeding)
    this.initiate();
  }

  initiate = () => {
    this._ws = new WebSocket(`${process.env.REACT_APP_MEDIA_SERVER}/one2many`);
    this._ws.onmessage = this.processMessage;
  };

  getMeetingId = () => this._meetingId;

  addAttendee = (user) => {
    this._updateStateWithAttendees(
      [...this._getAttendees(), user],
      actionTypes.ADDED_REMOTE_ATTENDEES
    );
  };

  removeAttendee = (email, participantSessionId, addtDataToggleEnabled) => {
    const currentAttendees = this._getAttendees();
    const indexOfAttendee = currentAttendees.findIndex((meetingAttendee) => {
      if (addtDataToggleEnabled)
        return meetingAttendee.participantSessionId === participantSessionId;
      else return meetingAttendee.email === email;
    });
    if (indexOfAttendee >= 0) {
      currentAttendees.splice(indexOfAttendee, 1);
      this._updateStateWithAttendees(
        currentAttendees,
        actionTypes.REMOVED_REMOTE_ATTENDEES
      );
    }
  };

  //TODO: long term - pass back User objects to Kurento service
  processMessage = (message) => {
    const jsonMsg = JSON.parse(message.data);
    const parseUser = (userJson) => {
      console.log("participant type is: ", userJson.participantType);
      console.log(
        "userJson.participantType === AttendeeTypes.HOST => ",
        userJson.participantType === AttendeeTypes.HOST
      );
      const isHost = userJson.participantType === AttendeeTypes.HOST;
      return new MeetingAttendee(
        userJson.name,
        userJson.email,
        true,
        isHost,
        userJson.participantSessionId
      );
    };

    switch (jsonMsg.id) {
      // signals only used by presenter
      case "presenterResponse":
        this.processPresenterOfferResponse(jsonMsg);
        break;

      // common signals
      case "newAttendee":
        const attendee =
          `${process.env.REACT_APP_CALL_SERVER_ADDITIONAL_DATA_EXCHANGE_ENABLED}` ===
          "true"
            ? jsonMsg
            : {
                name: jsonMsg.email.split("@")[0],
                email: jsonMsg.email,
                participantSessionId: uuidv4(),
                participantType: AttendeeTypes.PARTICIPANT,
              };
        const user = parseUser(attendee);
        this.addAttendee(user);
        break;
      case "attendeeLeft":
        this.removeAttendee(
          jsonMsg.email,
          jsonMsg.participantSessionId,
          `${process.env.REACT_APP_CALL_SERVER_ADDITIONAL_DATA_EXCHANGE_ENABLED}` ===
            "true"
        );
        break;
      case "iceCandidate":
        this._webRtcPeer.addIceCandidate(jsonMsg.candidate);
        break;

      case "toggleResponse":
        // provides response for the togglePersonRemovalFilter signal
        this.processPersonRemovalToggleFlipResponse(jsonMsg);
        break;

      // signals only used by viewers
      case "viewerResponse":
        this.processViewerOfferResponse(jsonMsg);
        break;
      case "currentAttendees":
        jsonMsg.attendees.forEach((att) => {
          const attendee =
            `${process.env.REACT_APP_CALL_SERVER_ADDITIONAL_DATA_EXCHANGE_ENABLED}` ===
            "true"
              ? att
              : {
                  name: att.split("@")[0],
                  email: att,
                  participantSessionId: uuidv4(),
                  participantType: AttendeeTypes.PARTICIPANT,
                };
          const user = parseUser(attendee);
          this.addAttendee(user);
        });
        break;
      case "stopCommunication":
        this.cleanup();
        this._sessionTerminated(AttendeeTypes.HOST);
        break;

      default:
        console.error("Unrecognized message received:", jsonMsg);
    }
  };

  sendMessage = (message) => {
    console.log("Sending message: ", message);
    this._ws.send(JSON.stringify(message));
  };

  sendPresenterOffer = (meetingId, user) => (error, offerSdp) => {
    if (error) console.log("Error occurred while generating SDP offer", error);
    else
      this.sendMessage({
        id: "presenter",
        sdpOffer: offerSdp,
        roomId: meetingId,
        email: user.email,
        name: user.name,
        participantSessionId: user.participantSessionId,
        participantType: AttendeeTypes.HOST,
      });
  };

  sendViewerOffer = (meetingId, user) => (error, offerSdp) => {
    if (error) console.log("Error occurred while generating SDP offer", error);
    else
      this.sendMessage({
        id: "viewer",
        sdpOffer: offerSdp,
        roomId: meetingId,
        email: user.email,
        name: user.name,
        participantSessionId: user.participantSessionId,
        participantType: AttendeeTypes.PARTICIPANT,
      });
  };

  sendIceCandidate = (meetingId, user) => (candidate) => {
    this.sendMessage({
      id: "onIceCandidate",
      candidate: candidate,
      roomId: meetingId,
      email: user.email,
      name: user.name,
      participantSessionId: user.participantSessionId,
      participantType: user.isHost()
        ? AttendeeTypes.HOST
        : AttendeeTypes.PARTICIPANT,
    });
  };

  /**
   *  Indicates if there is an ongoing videoElement session at the moment
   * @returns {boolean}
   */
  sessionIsOnGoing = () => !!(this._webRtcPeer && this._ws);

  /**
   *  This function starts the videoElement chat for the presenter user.
   *  @param meetingId - meeting id for the ongoing meeting
   *  @param user - host user
   *  @param videoElement - reference to a videoElement element where the videoElement will be captured.
   *  @param permissionsScreenToggle - a function that will stop displaying text asking for user permissions.
   */

  startAsPresenter = (
    meetingId,
    user,
    videoElement,
    permissionsScreenToggle
  ) => {
    if (!this.sessionIsOnGoing()) {
      console.log(
        "Toggle -> CALL_SERVER_ADDITIONAL_DATA_EXCHANGE_ENABLED: ",
        `${process.env.REACT_APP_CALL_SERVER_ADDITIONAL_DATA_EXCHANGE_ENABLED}` ===
          "true"
      );
      this._meetingId = meetingId;
      this._serviceUser = user;
      this.addAttendee(this._serviceUser);
      const sendPresenterOffer = this.sendPresenterOffer(meetingId, user);
      const opts = {
        remoteVideo: videoElement,
        onicecandidate: this.sendIceCandidate(meetingId, user),
        mediaConstraints: this.mediaConstraints,
      };
      this._webRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerSendrecv(
        opts,
        function (error) {
          if (error)
            console.log(
              "Error occurred while creating a WebRTCConnection",
              error
            );
          else {
            permissionsScreenToggle();
            this.generateOffer(sendPresenterOffer);
          }
        }
      );
    }
  };

  /**
   *  This function starts the videoElement chat for the viewer user.
   *  @param meetingId - meeting id for the ongoing meeting
   *  @param user - host user
   *  @param videoElement - reference to a videoElement element where the videoElement will be captured.
   */
  startAsViewer = (meetingId, user, videoElement, permissionsScreenToggle) => {
    if (!this.sessionIsOnGoing()) {
      console.log(
        "Toggle -> CALL_SERVER_ADDITIONAL_DATA_EXCHANGE_ENABLED: ",
        `${process.env.REACT_APP_CALL_SERVER_ADDITIONAL_DATA_EXCHANGE_ENABLED}` ===
          "true"
      );
      const onOfferViewerFuncPtr = this.sendViewerOffer(meetingId, user);
      const options = {
        remoteVideo: videoElement,
        onicecandidate: this.sendIceCandidate(meetingId, user),
      };
      this._webRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerSendrecv(
        options,
        function (error) {
          if (error) console.log(error);
          else {
            permissionsScreenToggle();
            this.generateOffer(onOfferViewerFuncPtr);
          }
        }
      );

      this._meetingId = meetingId;
      this._serviceUser = user;
      this.addAttendee(this._serviceUser);
    }
  };

  /**
   * Allows clients to switch on/off their microphone.
   * @returns returns True/False based on whether the mic is enabled/disabled after the toggle change.
   */
  toggleMic = () => {
    if (this.sessionIsOnGoing()) {
      this._webRtcPeer.audioEnabled = !this._webRtcPeer.audioEnabled;
      return this._webRtcPeer.audioEnabled;
    }
  };

  /**
   * Starts the process of switching person removal toggle to on/off
   */
  initiatePersonRemovalToggleFlip = () => {
    if (this.sessionIsOnGoing()) {
      this.sendMessage({
        id: "togglePersonRemovalFilter",
        roomId: this._meetingId,
      });
    }
  };

  processPersonRemovalToggleFlipResponse = (message) => {
    if (message.response === "accepted") {
      const newToggleState =
        message.personRemovalFilterState.toLowerCase() === "on";
      this._setPersonRemovalState(newToggleState);
    } else {
      console.warn("Person removal could not be toggled");
    }
  };

  processPresenterOfferResponse = (message) => {
    if (message.response === "accepted")
      this._webRtcPeer.processAnswer(message.sdpAnswer);
    else {
      const errorMsg = message.message
        ? message.message
        : "A unknown error occurred! Please try again. If the problem persists, please contact the support team.";
      console.warn("Call not accepted:", errorMsg);

      // dispose the connection
      this.disposeWRTCConnection();
    }
  };

  processViewerOfferResponse = (message) => {
    if (message.response === "accepted") {
      const sendMsg = this.sendMessage;
      const meetingId = this._meetingId;
      const user = this._serviceUser;

      this._webRtcPeer.processAnswer(message.sdpAnswer, function (error) {
        if (error) console.log(error);
        else
          sendMsg({
            id: "viewerProcessed",
            roomId: meetingId,
            email: user.email,
            name: `${user.name}`,
            participantSessionId: user.participantSessionId,
          });
      });
    } else {
      const errorMsg = message.message
        ? message.message
        : "Unknown error occurred, please try again! If the problem persists, please contact the support team.";
      console.warn("Call not accepted: " + errorMsg);

      // dispose the WebRTC connection
      this.disposeWRTCConnection();
    }
  };

  stop = () => {
    if (this.sessionIsOnGoing()) {
      this.sendMessage({
        id: "stop",
        roomId: this._meetingId,
        email: this._serviceUser.email,
        participantSessionId: this._serviceUser.participantSessionId,
      });
      this.cleanup();
    }
    this._sessionTerminated(AttendeeTypes.PARTICIPANT);
  };

  cleanup = () => {
    this.disposeWRTCConnection();
    this.closeWebSocket();
    this._updateStateWithAttendees([]);
  };

  closeWebSocket = () => {
    if (this._ws) {
      this._ws.close();
      this._ws = undefined; // set it to null to ensure this Websocket connection is not accessible
    }
  };

  disposeWRTCConnection = () => {
    if (this._webRtcPeer) {
      this._webRtcPeer.dispose();
      this._webRtcPeer = undefined; // set it to null to ensure this WebRTC connection is not accessible
    }
  };
}

export default Capture;

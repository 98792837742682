import { css } from "styled-components";

export const media = {
  ltXSm: (...args) => css`
    @media (max-width: 479px) {
      ${css(...args)}
    }
  `,
  gtXSm: (...args) => css`
    @media (min-width: 480px) {
      ${css(...args)}
    }
  `,
  ltSm: (...args) => css`
    @media (max-width: 768px) {
      ${css(...args)}
    }
  `,

  gtSm: (...args) => css`
    @media (min-width: 769px) {
      ${css(...args)}
    }
  `,
};

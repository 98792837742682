import React, { Component } from "react";
import styled from "styled-components";

import GradientHybridModalNarrow from "../UI/Modals/Hybrid/GradientHybridModalNarrow";
import LargeButtonPurple from "../UI/Buttons/LargeButton";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../store/actions";
import { wait } from "../../utility";
import zoomIntegrationLogo from "../../assets/images/zoom/zoom_integration_logo.png";

const ModalBody = styled.div`
  background-color: #f1f7fb;
`;

const Instructions = styled.div`
  background-color: white;
  padding: 35px 41px 20px 41px; //per UI mock-up, padding: 41px 41px 37px 41px;
  line-height: 24px;
  font-size: 16px;
  opacity: 0.8;
  text-align: center;
`;

const Title = styled.div`
  margin: 0 20px 8px 20px;
  letter-spacing: 0.5px;
  font-size: 28px;
  line-height: 37px;
`;

const Explanation = styled.div`
  font-size: 14px; //per UI mockup-up 16px.
  opacity: 0.7;
  line-height: 20px; //per UI mock-up, 24px.
  text-align: left;
`;

const AuthorizePanel = styled.form`
  padding: 33px 26px 26px 26px;
  color: #170f30;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
`;

class ZoomModal extends Component {
  state = {
    disabled: false,
  };

  onSubmitHandler = (event) => {
    event.preventDefault();
    this.setState({ disabled: true });
    this.props
      .getZoomAuthorizationUrl(this.props.accessToken)
      // induce a two second delay before doing the redirect to make it feel more natural
      .then(() => wait(2000))
      .then(() => {
        window.location.assign(this.props.zoom_authorization_url);
      })
      .catch((error) => {
        this.setState({ disabled: false });
      });
  };

  render() {
    return (
      <GradientHybridModalNarrow
        isOpen={this.props.isOpen}
        shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
        onRequestClose={this.props.onRequestClose}
        padding={"0px"}
        maxHeight={"90vh"}
        top={"100px"}
      >
        <ModalBody>
          <Instructions>
            <Title>Integrate Zoom</Title>
            <img
              alt="Zoom Logo"
              src={zoomIntegrationLogo}
              style={{ height: "150px", width: "400px" }}
            />
            <Explanation>
              Integrate your Zoom account with CaptureMyMeeting. Then,
              CaptureMyMeeting will automatically import and process any meeting
              that you record to the cloud going forward and provide you with
              notes and analysis shortly afterward.
            </Explanation>
          </Instructions>
          <AuthorizePanel onSubmit={this.onSubmitHandler}>
            <LargeButtonPurple
              type="submit"
              loading={this.state.disabled}
              disabled={this.state.disabled}
            >
              Integrate Zoom
            </LargeButtonPurple>
          </AuthorizePanel>
        </ModalBody>
      </GradientHybridModalNarrow>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getZoomAuthorizationUrl: (accessToken) =>
      dispatch(actions.getZoomAuthorizationUrl(accessToken)),
  };
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    zoom_authorization_url: state.user.zoom_authorization_url,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ZoomModal)
);

import React, { Component } from "react";
import styled from "styled-components";

import GradientHybridModalNarrow from "../UI/Modals/Hybrid/GradientHybridModalNarrow";
import RatingBar from "./RatingBar/RatingBar";
import LargeButtonPurple from "../UI/Buttons/LargeButton";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../store/actions";
import { wait } from "../../utility";

const ModalBody = styled.div`
  background-color: #f1f7fb;
`;

const Instructions = styled.div`
  background-color: white;
  padding: 35px 41px 20px 41px; //per UI mock-up, padding: 41px 41px 37px 41px;
  line-height: 24px;
  font-size: 16px;
  opacity: 0.8;
  text-align: center;
`;

const Title = styled.div`
  margin: 0 20px 8px 20px;
  letter-spacing: 0.5px;
  font-size: 28px;
  line-height: 37px;
`;

const MotivationalClause = styled.div`
  font-size: 14px; //per UI mockup-up 16px.
  opacity: 0.7;
  line-height: 20px; //per UI mock-up, 24px.
  text-align: left;
`;

const Survey = styled.form`
  padding: 33px 26px 26px 26px;
  color: #170f30;
  width: 100%;
  //height: 348px;
  font-size: 14px;
  line-height: 20px;
`;

const TextArea = styled.textarea`
  margin: 20px 0 24px 0;
  width: 100%;
  height: 100px;
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.21);
  border-radius: 3px;
  color: #000000ad;
  font-size: 16px;

  ::placeholder {
    color: rgba(0, 0, 0, 0.21);
  }
`;

const MeetingRatingBar = styled(RatingBar)`
  margin-top: 15px;
`;

class MeetingSurvey extends Component {
  state = {
    rating: null,
    writtenFeedback: "", // by default, the comment is an empty string.
    disabled: false,
  };

  ratingHandler = (rating) => this.setState({ rating: rating });
  feedbackHandler = (event) =>
    this.setState({ writtenFeedback: event.target.value });
  onSubmitHandler = (event) => {
    event.preventDefault();
    if (this.state.rating == null) return; // have the user provide a rating before submitting the form.
    this.setState({ disabled: true });
    const meetingId = this.props.meetingSession.meetingId
      ? this.props.meetingSession.meetingId
      : this.props.meetingId;
    const email = this.props.meetingSession.localUser
      ? this.props.meetingSession.localUser.email
      : this.props.email;
    this.props
      .postSurveyResults(
        email,
        meetingId,
        this.state.rating,
        this.state.writtenFeedback
      )
      // induce a two second delay before doing the redirect to make it feel more natural
      .then(() => wait(2000))
      .then(() => this.props.history.push("/"))
      .catch((error) => {
        this.setState({ disabled: false });
      });
  };

  render() {
    const placeholder =
      "Please explain the primary reason for your rating. If you experienced any technical " +
      "difficulties please describe them to help us troubleshoot your issues. If we can follow up for more " +
      "details let us know.";
    const commentLimit = 400;

    return (
      <GradientHybridModalNarrow
        isOpen={this.props.isOpen}
        shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
        onRequestClose={this.props.onRequestClose}
        padding={"0px"}
        maxHeight={"90vh"}
      >
        <ModalBody>
          <Instructions>
            <Title>Your feedback is very important to us</Title>
            <MotivationalClause>
              We appreciate you taking a few minutes to tell us what you like
              and what we should improve. Thank you for your time and feedback.{" "}
              <br />
              <br />- CaptureMyMeeting Team
            </MotivationalClause>
          </Instructions>
          <Survey onSubmit={this.onSubmitHandler}>
            <div style={{ opacity: "0.8" }}>
              How would you rate your recent CaptureMyMeeting experience?
            </div>
            <MeetingRatingBar
              maxValue={10}
              selectedRating={this.state.rating}
              onSelect={this.ratingHandler}
            />
            <TextArea
              placeholder={placeholder}
              maxlength={commentLimit}
              onChange={this.feedbackHandler}
            />
            <LargeButtonPurple
              type="submit"
              loading={this.state.disabled}
              disabled={this.state.disabled}
            >
              Submit My Feedback
            </LargeButtonPurple>
          </Survey>
        </ModalBody>
      </GradientHybridModalNarrow>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postSurveyResults: (email, meetingId, givenRating, comment) =>
      dispatch(
        actions.submitMeetingSurvey(email, meetingId, givenRating, comment)
      ),
  };
};

const mapStateToProps = (state) => {
  return {
    survey: state.survey,
    meetingSession: state.meetingSession,
    email: state.user && state.user.email ? state.user.email : null,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MeetingSurvey)
);

import api from "../../services/api";
import * as actionTypes from "./actionTypes";
import { cleanApiErrorResponse } from "../utility";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (accessToken, refreshToken) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    accessToken: accessToken,
    refreshToken: refreshToken,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("expirationDate");

  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000); // Set timeout takes time in milliseconds
  };
};

export const auth = (email, password) => {
  return (dispatch) => {
    dispatch(authStart());

    api.auth
      .signIn(email, password)
      .then((response) => {
        const expiresIn = 24 * 60 * 60; // token currently expires after 24 hours
        const expirationDate = new Date(
          new Date().getTime() + expiresIn * 1000
        );
        localStorage.setItem("accessToken", response.data.access);
        localStorage.setItem("refreshToken", response.data.refresh);
        localStorage.setItem("expirationDate", expirationDate);

        dispatch(authSuccess(response.data.access, response.data.refresh));
        dispatch(checkAuthTimeout(expiresIn));
      })
      .catch((error) => {
        console.log("Authentication error occurred", error);
        dispatch(authFail(cleanApiErrorResponse(error)));
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (!accessToken) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));

      if (expirationDate < new Date()) {
        dispatch(logout());
      } else {
        dispatch(authSuccess(accessToken, refreshToken));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};

import React, { Component } from "react";
import styled from "styled-components";

import { media } from "../../../../UI/StyleUtils";
import SimpleMenu from "../../../../UI/Menu/Menu";
import MediaQuery from "react-responsive";
import Aux from "../../../../../hoc/ReactAux";
import { connect } from "react-redux";
import * as actions from "../../../../../store/actions";
import MeetingModal from "./Modals/MeetingModal";
import OverFlowButton from "../../../../UI/Buttons/OverflowButton";
import confirmationAlert from "../../../../UI/ConfirmationAlert/ConfirmationAlert";
import DownloadIFrame from "./DownloadFile";
import MeetingSurvey from "../../../../Surveys/MeetingSurvey";
import ShareModal from "../../../../Share/ShareModal";
import { paintRed } from "../../../../UI/Styles";
import { userIsHost } from "../../../../../utility";
import NotesModal from "../../../../Notes/NotesModal";
import SentimentsModal from "../../../../SentimentAnalysis/SentimentsModal";
import TranscriptModal from "../../../../Transcript/TranscriptModal";

const OverflowContainer = styled.div`
  display: inline-block;
  flex-basis: 5%;
  ${media.gtSm`
        flex-basis: 4.5%;
    `};
`;

class Overflow extends Component {
  state = {
    displaySurvey: false,
    displayShareModal: false,
    displayNotesModal: false,
    displayTranscriptModal: false,
    displaySentimentsModal: false,
    downloadClicked: false,
    secondsToScrollToInTranscript: null,
  };

  allMenuItemsDict = {
    watchRecording: "Watch recording",
    download: "Download",
    notes: "Notes",
    sentiments: "Sentiment Analysis",
    transcript: "Transcript",
    delete: "Delete",
    submitFeedback: "Submit feedback",
    share: "Share",
    filterByMeeting: "Search Meeting",
  };

  // the following operations can be directly invoked based on URL mapping
  directOperationsAllowed = ["watchRecording", "notes", "transcript"];

  userIsHost = () => {
    return userIsHost(this.props.hosts, this.props.email);
  };

  menuOptionsDesktop = () => {
    return this.props.bulkOperation
      ? this.userIsHost()
        ? [this.allMenuItemsDict.share]
        : []
      : this.props.recordingAvailable
        ? this.userIsHost()
          ? [
              this.allMenuItemsDict.watchRecording,
              this.allMenuItemsDict.download,
              this.allMenuItemsDict.notes,
              this.allMenuItemsDict.sentiments,
              this.allMenuItemsDict.transcript,
              this.allMenuItemsDict.share,
              paintRed(this.allMenuItemsDict.delete),
              this.allMenuItemsDict.filterByMeeting,
              this.allMenuItemsDict.submitFeedback,
            ]
          : [
              this.allMenuItemsDict.watchRecording,
              this.allMenuItemsDict.download,
              this.allMenuItemsDict.notes,
              this.allMenuItemsDict.sentiments,
              this.allMenuItemsDict.transcript,
              this.allMenuItemsDict.filterByMeeting,
              this.allMenuItemsDict.submitFeedback,
            ]
        : this.userIsHost()
          ? [
              this.allMenuItemsDict.share,
              paintRed(this.allMenuItemsDict.delete),
              this.allMenuItemsDict.filterByMeeting,
              this.allMenuItemsDict.submitFeedback,
            ]
          : [
              this.allMenuItemsDict.filterByMeeting,
              this.allMenuItemsDict.submitFeedback,
            ];
  };

  menuOptionsMobile = () => {
    return this.props.bulkOperation
      ? this.userIsHost()
        ? [this.allMenuItemsDict.share]
        : []
      : this.props.recordingAvailable
        ? this.userIsHost()
          ? [
              this.allMenuItemsDict.download,
              this.allMenuItemsDict.notes,
              this.allMenuItemsDict.sentiments,
              this.allMenuItemsDict.transcript,
              this.allMenuItemsDict.share,
              paintRed(this.allMenuItemsDict.delete),
              this.allMenuItemsDict.filterByMeeting,
              this.allMenuItemsDict.submitFeedback,
            ]
          : [
              this.allMenuItemsDict.download,
              this.allMenuItemsDict.notes,
              this.allMenuItemsDict.sentiments,
              this.allMenuItemsDict.transcript,
              this.allMenuItemsDict.filterByMeeting,
              this.allMenuItemsDict.submitFeedback,
            ]
        : this.userIsHost()
          ? [
              this.allMenuItemsDict.share,
              paintRed(this.allMenuItemsDict.delete),
              this.allMenuItemsDict.filterByMeeting,
              this.allMenuItemsDict.submitFeedback,
            ]
          : [
              this.allMenuItemsDict.filterByMeeting,
              this.allMenuItemsDict.submitFeedback,
            ];
  };

  componentDidMount() {
    const predefinedOperation = this.props.meetings.meeting.operation;
    if (
      predefinedOperation &&
      this.directOperationsAllowed.some((allowedOperation) =>
        predefinedOperation.includes(allowedOperation)
      )
    ) {
      this.menuItemSelectionHandle(
        this.allMenuItemsDict[predefinedOperation],
        null
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const predefinedOperation = this.props.meetings.meeting.operation;
    if (
      predefinedOperation &&
      prevProps.meetings.meeting.operation !== predefinedOperation &&
      this.directOperationsAllowed.some((allowedOperation) =>
        predefinedOperation.includes(allowedOperation)
      )
    ) {
      this.menuItemSelectionHandle(
        this.allMenuItemsDict[predefinedOperation],
        null
      );
    }
  }

  deleteMeeting = () => {
    confirmationAlert("Are you sure?", "You want to delete this meeting?", [
      {
        label: "No",
        onClick: () => {},
      },
      {
        label: "Yes, Delete it!",
        onClick: () =>
          this.props.deleteMeeting(
            this.props.accessToken,
            this.props.id,
            this.props.meetings
          ),
      },
    ]);
  };

  onCloseNotesModal = () => {
    this.setState({ displayNotesModal: false });
  };

  onCloseSentimentsModal = () => {
    this.setState({ displaySentimentsModal: false });
  };

  onCloseTranscriptModal = () => {
    this.setState({
      displayTranscriptModal: false,
      secondsToScrollToInTranscript: null,
    });
  };

  onSurveyButtonClick = () => {
    this.setState({ displaySurvey: true });
  };

  onRequestCloseModal = () => {
    this.setState({ displaySurvey: false });
  };

  onShareOptionClick = () => {
    this.setState({ displayShareModal: true });
  };

  onRequestCloseShareModal = () => {
    this.setState({ displayShareModal: false });
  };

  openTranscriptModal = () => {
    this.setState({ displayTranscriptModal: true });
  };

  // called when a user clicks on a menu ITEM
  menuItemSelectionHandle = (value, event) => {
    switch (value) {
      case this.allMenuItemsDict.watchRecording:
        console.log("Action taken - watch recording");
        this.props.viewMeetingRecording(
          this.props.accessToken,
          this.props.id,
          "view"
        );
        break;
      case this.allMenuItemsDict.download:
        console.log("Action taken - download meeting");
        this.setState({ downloadClicked: true });
        this.props.downloadMeeting(this.props.accessToken, this.props.id);
        break;
      case this.allMenuItemsDict.notes:
        console.log("Action taken - view notes");
        this.props
          .getMeetingNotes(this.props.accessToken, this.props.id)
          .then(() => {
            if (
              !(
                (this.props.meetingNotes.overview ||
                  this.props.meetingNotes.summary ||
                  this.props.meetingNotes.actionItems) &&
                this.props.meetingNotes.timestampRanges
              )
            ) {
              this.setState({ displayNotesModal: false });
              alert(
                "There was an error fetching your meeting notes. " +
                  "Please contact support@capturemymeeting.com."
              );
            } else {
              this.setState({ displayNotesModal: true });
            }
          })
          .catch((error) => {
            if (error && error.response) {
              if (error.response.status === 404) {
                alert(
                  "Your meeting notes are currently being generated and will be ready shortly. " +
                    "We will notify you by email when they are ready. For reference, a one-hour " +
                    "meeting recording may take about 10 minutes to finish processing." +
                    "\n\nNote: If you are importing a test recording, please ensure that someone is " +
                    "speaking for at least 30 seconds within the recording to use our transcript, " +
                    "notes, and analysis features."
                );
              } else {
                alert(
                  "There was an error fetching your meeting notes. " +
                    "Please contact support@capturemymeeting.com."
                );
              }
            } else {
              alert(
                "There was an error fetching your meeting notes. " +
                  "Please contact support@capturemymeeting.com."
              );
            }
          });
        break;
      case this.allMenuItemsDict.sentiments:
        console.log("Action taken - view sentiments");
        this.props
          .getTranscriptSpeakerNames(this.props.accessToken, this.props.id)
          .catch((error) => {
            // If we can't get the speaker names for some reason, just fail silently to allow the user to still view the sentiments.
          })
          .then(() => {
            return this.props.downloadTranscript(
              this.props.accessToken,
              this.props.id
            );
          })
          .catch((error) => {
            // If we can't get the transcript for some reason, just fail silently to allow the user to still view the sentiments.
          })
          .then(() => {
            return this.props.getSentimentsData(
              this.props.accessToken,
              this.props.id
            );
          })
          .then(() => {
            if (!this.props.meetingNotes.sentimentsData) {
              this.setState({ displaySentimentsModal: false });
              alert(
                "The sentiment analysis is currently being generated and will be ready shortly. For reference, a one-hour meeting recording may take about 15 minutes to finish processing.\n\nNote: If you are importing a test recording, please ensure that someone is speaking for at least 30 seconds within the recording."
              );
            } else {
              this.setState({ displaySentimentsModal: true });
            }
          })
          .catch((error) => {
            alert(
              "There was an error fetching your sentiment analysis. Please contact support@capturemymeeting.com."
            );
          });
        break;
      case this.allMenuItemsDict.transcript:
        console.log("Action taken - view transcript");
        this.props
          .getTranscriptSpeakerNames(this.props.accessToken, this.props.id)
          .catch((error) => {
            // If we can't get the speaker names for some reason, just fail silently to allow the user to still view the transcript.
          })
          .then(() => {
            return this.props.downloadTranscript(
              this.props.accessToken,
              this.props.id
            );
          })
          .then(() => {
            if (!this.props.meetingTranscript.content) {
              this.setState({ displayTranscriptModal: false });
              alert(
                "Your meeting transcript is currently being processed and will be ready shortly. We will notify you by email when it is ready. For reference, a one-hour meeting recording may take about 10 minutes to finish processing.\n\nNote: If you are importing a test recording, please ensure that someone is speaking for at least 30 seconds within the recording to use our transcript, notes, and analysis features."
              );
            } else {
              this.setState({ displayTranscriptModal: true });
            }
          })
          .catch((error) => {
            alert(
              "There was an error processing your meeting transcript. Please contact support@capturemymeeting.com."
            );
          });
        break;
      case this.allMenuItemsDict.submitFeedback:
        console.log("Action taken - submit feedback");
        this.onSurveyButtonClick();
        break;
      case this.allMenuItemsDict.share:
        console.log("Action taken - share meeting");
        this.onShareOptionClick();
        break;
      case this.allMenuItemsDict.filterByMeeting:
        console.log("Action taken - search meeting");
        this.props.filterByMeetingHandler();
        break;
      default:
        // Delete option needs to be checked here as it's inside a span tag
        if (
          value.props &&
          value.props.children === this.allMenuItemsDict.delete
        ) {
          this.deleteMeeting();
        } else {
          console.log("An unrecognized menu option was selected by the user");
          console.log("value: ", value, "Event: ", event);
        }
    }
  };

  // called when a user clicks on overflow BUTTON
  overflowHandleMobile = () => {
    this.props.recordingAvailable
      ? this.props.viewMeetingRecording(
          this.props.accessToken,
          this.props.id,
          "view"
        ) // fetch video resources and open the modal
      : this.props.openMeetingModal(this.props.id, "view"); // simply open the modal
  };

  openTranscriptAndScrollToTimestamp = (seconds) => {
    this.setState({
      displayTranscriptModal: true,
      secondsToScrollToInTranscript: seconds,
    });
  };

  render() {
    return (
      <Aux>
        {
          // inject modal, if needed.
          this.props.meetingModal.meetingId === this.props.id ? (
            <MeetingModal
              isOpen={this.props.meetingModal.viewModal.display}
              onRequestClose={() => {
                this.props.closeMeetingModal("view");
              }}
              recordingAvailable={this.props.recordingAvailable}
              video={this.props.meetingModal.data}
              dateTime={this.props.dateTime}
              nameDuration={this.props.nameDuration}
              menuItems={this.menuOptionsMobile()}
              menuItemSelected={this.menuItemSelectionHandle}
            />
          ) : null
        }

        {
          // inject download element, if needed.
          this.state.downloadClicked &&
          this.props.meetingDownload.meetingId === this.props.id ? (
            <DownloadIFrame url={this.props.meetingDownload.data.url} />
          ) : null
        }

        {this.state.displaySurvey ? (
          <MeetingSurvey
            isOpen={this.state.displaySurvey}
            shouldCloseOnOverlayClick={true}
            onRequestClose={this.onRequestCloseModal}
            meetingId={this.props.id}
          ></MeetingSurvey>
        ) : null}

        {this.state.displayShareModal ? (
          <ShareModal
            isOpen={this.state.displayShareModal}
            shouldCloseOnOverlayClick={true}
            onRequestClose={this.onRequestCloseShareModal}
            meetingData={this.props.meetingData}
          ></ShareModal>
        ) : null}

        {this.state.displayNotesModal ? (
          <NotesModal
            isOpen={this.state.displayNotesModal}
            shouldCloseOnOverlayClick={true}
            onRequestClose={this.onCloseNotesModal}
            meetingName={this.props.meetingName}
          ></NotesModal>
        ) : null}

        {this.state.displaySentimentsModal ? (
          <SentimentsModal
            isOpen={this.state.displaySentimentsModal}
            shouldCloseOnOverlayClick={true}
            onRequestClose={this.onCloseSentimentsModal}
            meetingName={this.props.meetingName}
            openTranscriptModal={this.openTranscriptAndScrollToTimestamp}
          ></SentimentsModal>
        ) : null}

        {this.state.displayTranscriptModal ? (
          <TranscriptModal
            isOpen={this.state.displayTranscriptModal}
            secondsToScrollTo={this.state.secondsToScrollToInTranscript}
            shouldCloseOnOverlayClick={true}
            onRequestClose={this.onCloseTranscriptModal}
            meetingId={this.props.id}
            meetingName={this.props.meetingName}
            userIsHost={this.userIsHost()}
          ></TranscriptModal>
        ) : null}

        <OverflowContainer>
          <MediaQuery query="(min-width: 769px)">
            {(matches) => {
              if (matches) {
                return (
                  <SimpleMenu
                    menuButton={<OverFlowButton />}
                    menuItems={this.menuOptionsDesktop()}
                    handleSelection={this.menuItemSelectionHandle}
                  />
                );
              } else {
                return <OverFlowButton onClick={this.overflowHandleMobile} />;
              }
            }}
          </MediaQuery>
        </OverflowContainer>
      </Aux>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    viewMeetingRecording: (accessToken, meetingId, modalType, seconds) =>
      dispatch(
        actions.viewMeetingRecording(accessToken, meetingId, modalType, seconds)
      ),
    openMeetingModal: (meetingId, modalType) =>
      dispatch(actions.openMeetingModal(meetingId, modalType)),
    closeMeetingModal: (modalType) =>
      dispatch(actions.closeMeetingModal(modalType)),
    deleteMeeting: (accessToken, meetingId, meetings) =>
      dispatch(actions.deleteMeeting(accessToken, meetingId, meetings)),
    downloadMeeting: (accessToken, meetingId) =>
      dispatch(actions.downloadMeetingRecording(accessToken, meetingId)),
    downloadTranscript: (accessToken, meetingId) =>
      dispatch(actions.downloadTranscript(accessToken, meetingId)),
    getTranscriptSpeakerNames: (accessToken, meetingId) =>
      dispatch(actions.getTranscriptSpeakerNames(accessToken, meetingId)),
    getMeetingNotes: (accessToken, meetingId) =>
      dispatch(actions.getMeetingNotes(accessToken, meetingId)),
    getSentimentsData: (accessToken, meetingId) =>
      dispatch(actions.getSentimentsData(accessToken, meetingId)),
  };
};

const mapStateToProps = (state) => {
  return {
    meetingModal: state.meetingModal,
    meetingDownload: state.meetingDownload,
    meetingTranscript: state.meetingTranscript,
    meetingNotes: state.meetingNotes,
    meetings: state.meetings,
    accessToken: state.auth.accessToken,
    email: state.user.email,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overflow);

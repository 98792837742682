import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";

const DateTimeContainer = styled.div`
  font-size: 17px;
  letter-spacing: -0.16px;
  line-height: 20px;
  font-weight: lighter;
`;

class CurrentDateTime extends Component {
  _clockIntervalID;

  state = {
    currentTime: moment(),
  };

  componentDidMount() {
    this._clockIntervalID = setInterval(() => {
      this.setState({
        currentTime: moment(),
      });
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this._clockIntervalID);
  }

  render() {
    const forcedWhiteSpace = "\u00A0";
    return (
      <DateTimeContainer>
        {this.state.currentTime.format("dddd, MMMM D").toString() +
          `${forcedWhiteSpace} | ${forcedWhiteSpace}` +
          this.state.currentTime.format("h:mm A").toString()}
      </DateTimeContainer>
    );
  }
}

export default CurrentDateTime;

import styled from "styled-components";
import React from "react";

export const VFlexCenterJustified = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const GradientDiv = styled.div`
  background: linear-gradient(316.6deg, #39d0c5 0%, #764ef2 100%);
`;

export const CenterStyles = styled.div`
  display: flex;
  justify-content: center;
`;

export const Error = styled.div`
  color: tomato;
  font-size: 14px;
`;

export const paintRed = (text) => <span style={{ color: "red" }}>{text}</span>;

export const Link = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

export const InfoCircle = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;
  border: 1px solid;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
`;

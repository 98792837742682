import React from "react";
import styled from "styled-components";

import SmallButtonWhite from "../../../../UI/Buttons/SmallButton";
import { media } from "../../../../UI/StyleUtils";

const ShareButtonContainer = styled.div`
  flex-basis: 7.5%;
  display: none;

  ${media.gtSm`
        display: block;
        visibility: hidden;
    `}
`;

const CustomSmallButton = styled(SmallButtonWhite)`
  height: 39px;
  width: 78px;
  border: 1px solid #c5c3cc;
`;

const ShareButton = (props) => {
  return (
    <ShareButtonContainer Class={props.className}>
      <CustomSmallButton>Share</CustomSmallButton>
    </ShareButtonContainer>
  );
};

export default ShareButton;

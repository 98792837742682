import React, { Component } from "react";
import styled from "styled-components";

import GradientHybridModalNarrow from "../UI/Modals/Hybrid/GradientHybridModalNarrow";
import LargeButtonPurple from "../UI/Buttons/LargeButton";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../store/actions";
import { wait } from "../../utility";
import todoistIntegrationLogo from "../../assets/images/todoist/todoist_integration_logo.png";

const ModalBody = styled.div`
  background-color: #f1f7fb;
`;

const Instructions = styled.div`
  background-color: white;
  padding: 35px 41px 20px 41px; //per UI mock-up, padding: 41px 41px 37px 41px;
  line-height: 24px;
  font-size: 16px;
  opacity: 0.8;
  text-align: center;
`;

const Title = styled.div`
  margin: 0 20px 8px 20px;
  letter-spacing: 0.5px;
  font-size: 28px;
  line-height: 37px;
  margin-bottom: 20px;
`;

const Explanation = styled.div`
  font-size: 14px; //per UI mockup-up 16px.
  opacity: 0.7;
  line-height: 20px; //per UI mock-up, 24px.
  text-align: left;
  margin-top: 20px;
`;

const AuthorizePanel = styled.form`
  padding: 33px 26px 26px 26px;
  color: #170f30;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
`;

class TodoistModal extends Component {
  state = {
    disabled: false,
  };

  onSubmitHandler = (event) => {
    event.preventDefault();
    this.setState({ disabled: true });
    this.props
      .getTodoistAuthorizationUrl(this.props.accessToken)
      // induce a two second delay before doing the redirect to make it feel more natural
      .then(() => wait(2000))
      .then(() => {
        window.location.assign(this.props.todoistAuthorizationUrl);
      })
      .catch((error) => {
        this.setState({ disabled: false });
      });
  };

  render() {
    return (
      <GradientHybridModalNarrow
        isOpen={this.props.isOpen}
        shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
        onRequestClose={this.props.onRequestClose}
        padding={"0px"}
        maxHeight={"90vh"}
        top={"100px"}
      >
        <ModalBody>
          <Instructions>
            <Title>Integrate Todoist</Title>
            <img
              alt="Todoist Integration Logo"
              src={todoistIntegrationLogo}
              style={{ height: "150px", width: "370px" }}
            />
            <Explanation>
              Integrate your Todoist account with your CaptureMyMeeting account.
              You can then store any action items (tasks) that CaptureMyMeeting
              detects from each of your meeting recordings directly into your
              personal Todoist inbox going forward. You will be able to select
              which action items you want stored from within the
              CaptureMyMeeting app, or alternatively you can allow
              CaptureMyMeeting to automatically store all action items.
            </Explanation>
          </Instructions>
          <AuthorizePanel onSubmit={this.onSubmitHandler}>
            <LargeButtonPurple
              type="submit"
              loading={this.state.disabled}
              disabled={this.state.disabled}
            >
              Integrate Todoist
            </LargeButtonPurple>
          </AuthorizePanel>
        </ModalBody>
      </GradientHybridModalNarrow>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTodoistAuthorizationUrl: (accessToken) =>
      dispatch(actions.getTodoistAuthorizationUrl(accessToken)),
  };
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    todoistAuthorizationUrl: state.user.todoistAuthorizationUrl,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TodoistModal)
);

import React from "react";
import styled from "styled-components";

import NavigationItem, {
  NonLinkNavigationItem,
} from "./NavigationItem/NavigationItem";
import sideDrawerBackgroundImage from "../../../assets/images/background_images/sidepanel_background_image.png";

const SideDrawerContainer = styled.div`
  position: fixed;
  width: 280px;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 500;
  background: url(${sideDrawerBackgroundImage}) no-repeat bottom left;
  background-size: cover;
  transition: transform 0.3s ease-in-out;
  transform: translateX(
    ${(props) => {
      return !!props.show ? "0" : "100%";
    }}
  );

  @media (min-width: 769px) {
    display: none;
  }
`;

const NavigationItems = styled.nav`
  color: white;
  list-style: none;
`;

const sideDrawer = (props) => {
  return (
    <SideDrawerContainer show={props.show}>
      <NavigationItems>
        <NavigationItem link="/dashboard">My Dashboard</NavigationItem>
        <NonLinkNavigationItem onClick={props.showSettings}>
          {" "}
          Edit Profile
        </NonLinkNavigationItem>
        {/*<NonLinkNavigationItem onClick ={props.showSettings}> Settings </NonLinkNavigationItem>*/}
        {/*<NavigationItem link="/feedback">Feedback</NavigationItem>*/}
        <NavigationItem link="/auth/logout">Sign Out</NavigationItem>
      </NavigationItems>
    </SideDrawerContainer>
  );
};

export default sideDrawer;

import styled from "styled-components";

export const HorizontalLine = styled.div`
  overflow: hidden;
  width: 100%;
  height: 1px;
  background-color: rgba(222, 229, 234, 1);
`;

export default HorizontalLine;

import React from "react";

import BaseModal from "../BaseModal";
import Icon from "../../Icon/Icon";
import GradientBorder from "../../GradientBorder";
import { IconPosition } from "../CommonDesignElements";

/**
 *  A react accessible modal with gradient top border that is easy to use. For a detailed set of accessibility features and how to utilize
 *  them visit http://reactcommunity.org/react-modal/accessibility/#keyboard

 * @param props
 *  {
 *      isOpen: whether the modal is open. Ex: true.
 *      onRequestClose: the function to call to close the modal. If null is specified, user will not be allowed to close the modal manually.
 *      width: modal width. Defaulted to cover the screen from left to right with 40px margins
 *      top: modal position from top of the screen. Defaulted to 40px
 *      bottom: modal end position in relations to the bottom of the screen. Defaulted to only cover the content of the modal.
 *      maxHeight: maximum height for the modal, ex. 90px, or 90vh. Defaults to none.
 *      backgroundColor: the color used for the background shadow. By Default, it's transparent purple, rgba(41,42,68, 0.75).
 *      topGradientBorder: whether or not to include the gradient border on the top. Defaults to true.
 *  }
 * @returns {*}
 */

// TODO: don't expose minWidth and maxWidth as part of DesktopModal.
const DesktopModal = (props) => {
  return (
    <BaseModal
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
      onRequestClose={props.onRequestClose}
      width={props.width}
      top={props.top}
      bottom={props.bottom}
      minWidth={props.minWidth}
      maxWidth={props.maxWidth}
      maxHeight={props.maxHeight}
      backgroundColor={props.backgroundColor}
      zIndex={props.zIndex}
    >
      {props.topGradientBorder !== false ? <GradientBorder /> : null}
      <div style={{ padding: props.padding || "8px" }}>
        {props.onRequestClose != null ? (
          <IconPosition onClick={props.onRequestClose}>
            <Icon name="close_icon" alt="Close" />
          </IconPosition>
        ) : null}
        {props.children}
      </div>
    </BaseModal>
  );
};

export default DesktopModal;

import React, { Component } from "react";

import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Card from "../../../../components/UI/Card";
import { GradientDiv } from "../../../../components/UI/Styles";

import Input from "../../../../components/UI/Forms/Input";
import Button from "../../../../components/UI/Buttons/LargeButton";
import hidePassword from "../../../../assets/functional_icons/eye-hide-svgrepo-com.svg";
import showPassword from "../../../../assets/functional_icons/eye-show-svgrepo-com.svg";

import { BottomPart, CMMLogo, TopMainText } from "./CommonStyles";
import * as actions from "../../../../store/actions/index";

const TopPart = styled.div`
  min-height: 175px; // 407px when social auth is re-added.
  min-width: 320px;
  padding-top: 25px;
  padding-bottom: 25px;
  color: white;
  justify-content: center;
  text-align: center;
`;

const BottomContainer = styled.div`
  min-height: 295px;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
`;

const FormInputContainer = styled.div`
  margin-bottom: 22px;
  margin-top: 22px;
  position: relative;
`;

const LoginButton = styled(Button)`
  margin-top: 10px;
`;

const ContactSupport = styled.p`
  margin: 5px 4.5px 15px 4.5px;
  font-size: 14px;
  text-align: center;
  color: rgba(23, 15, 48, 0.51);
`;

//-------------Forget pass link-----------------

const Error = styled.p`
  color: tomato;
`;
class ResetPassword extends Component {
  state = {
    newPassword: "",
    confirmPassword: "",
    hideNewPassword: true,
    hideConfirmPassword: true,
    isPasswordMatch: false,
    key: "",
    errorMsg: "",
    isPasswordChange: false,
  };
  componentDidMount() {
    // Use this.props.location.search to access the query string of the URL
    const queryParams = new URLSearchParams(this.props.location.search);
    const key = queryParams.get("key");

    this.setState({ key });
  }

  changeHandle = (event, inputIdentifier) => {
    var value = null;
    if (
      inputIdentifier === "isPasswordChange" ||
      inputIdentifier === "errorMsg"
    ) {
      value = event;
    } else {
      value = event.target.value;
    }
    this.setState((prevState) => {
      const updatedFormValues = {
        ...prevState,
        [inputIdentifier]: value,
      };

      // Calculate isPasswordMatch based on newPassword and confirmPassword
      updatedFormValues["isPasswordMatch"] =
        updatedFormValues["newPassword"] &&
        updatedFormValues["confirmPassword"] &&
        updatedFormValues["newPassword"] ===
          updatedFormValues["confirmPassword"];

      return updatedFormValues;
    });
  };
  changeVisuality = (value, inputIdentifier) => {
    const updatedFormValues = {
      ...this.state,
    };
    updatedFormValues[inputIdentifier] = value;

    this.setState(updatedFormValues);
  };
  submitHandler = (event) => {
    // we want to avoid the default behavior of form submission (reloading the page)
    event.preventDefault();
    const newpassword = this.state.newPassword;
    const confirmPassword = this.state.confirmPassword;
    const key = this.state.key;
    this.changeHandle("", "errorMsg");
    if (this.state.isPasswordMatch) {
      if (newpassword.length >= 8) {
        this.props
          .settingNewPassword(newpassword, confirmPassword, key)
          .then((response) => {
            this.changeHandle(true, "isPasswordChange");
          })
          .catch((error) => {
            // Handle network errors or other issues
            if (error.response.data?.non_field_errors[0]) {
              this.changeHandle(
                error.response.data?.non_field_errors,
                "errorMsg"
              );

              console.log(
                "An error occurred:",
                error.response.data?.non_field_errors
              );
            }
          });
      } else {
        this.changeHandle(
          "Password must be at-least 8 characters long.",
          "errorMsg"
        );
      }
    } else {
      this.changeHandle("Passwords do not match.", "errorMsg");
    }
  };

  render() {
    return (
      <div>
        <Card>
          <GradientDiv>
            <TopPart>
              <CMMLogo />
              <TopMainText>New Password</TopMainText>
            </TopPart>
          </GradientDiv>
          {/*<CircleDivider/>*/}
          <BottomPart>
            <BottomContainer>
              <form onSubmit={this.submitHandler}>
                <FormInputContainer>
                  <Input
                    placeholder="New Password"
                    type={this.state.hideNewPassword ? "password" : "text"}
                    value={this.state.newPassword}
                    onChange={(event) =>
                      this.changeHandle(event, "newPassword")
                    }
                    required
                  />
                  <img
                    alt="Hide Password"
                    onClick={() => {
                      this.changeVisuality(
                        !this.state.hideNewPassword,
                        "hideNewPassword"
                      );
                    }}
                    style={{
                      position: "absolute",
                      width: "30px",
                      top: "5px",
                      bottom: "0px",
                      right: "5px",
                    }}
                    src={
                      this.state.hideNewPassword ? hidePassword : showPassword
                    }
                  />
                </FormInputContainer>
                <FormInputContainer>
                  <Input
                    placeholder="Confirm Password"
                    type={this.state.hideConfirmPassword ? "password" : "text"}
                    value={this.state.confirmPassword}
                    onChange={(event) =>
                      this.changeHandle(event, "confirmPassword")
                    }
                    required
                  />
                  <img
                    alt="Hide Confirm Password"
                    onClick={() => {
                      this.changeVisuality(
                        !this.state.hideConfirmPassword,
                        "hideConfirmPassword"
                      );
                    }}
                    style={{
                      position: "absolute",
                      width: "30px",
                      top: "5px",
                      bottom: "0px",
                      right: "5px",
                    }}
                    src={
                      this.state.hideConfirmPassword
                        ? hidePassword
                        : showPassword
                    }
                  />
                  <div style={{ marginTop: "10px" }}>
                    {this.state.isPasswordMatch ? (
                      <div
                        style={{
                          display: this.state.newPassword ? "inline" : "none",
                          color: "green",
                        }}
                      >
                        Passwords match!
                      </div>
                    ) : (
                      <div
                        style={{
                          display: this.state.newPassword ? "inline" : "none",
                          color: "red",
                        }}
                      >
                        Passwords do not match.
                      </div>
                    )}
                  </div>
                </FormInputContainer>
                {this.state.isPasswordChange ? (
                  <p style={{ color: "green" }}>
                    Your password has been reset. Please navigate back to the{" "}
                    <Link to="/auth/login">login page</Link> and login to your
                    account with your new password.
                  </p>
                ) : (
                  <LoginButton type="submit">Confirm</LoginButton>
                )}
                <Error>{this.state.errorMsg}</Error>
              </form>
              <ContactSupport>
                For questions or concerns, please contact{" "}
                <a href="mailto:support@capturemymeeting.com">
                  support@capturemymeeting.com
                </a>
              </ContactSupport>
            </BottomContainer>
          </BottomPart>
        </Card>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    settingNewPassword: (newpassword, confirmPassword, key) =>
      dispatch(actions.settingNewPassword(newpassword, confirmPassword, key)),
  };
};
const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

export const updateObject = (initialObject, updatedFields) => {
  return {
    ...initialObject,
    ...updatedFields,
  };
};

export const cleanApiErrorResponse = (error) => {
  if (error.response == null)
    error.response = { status: 503, data: "Service Unavailable" };
  return error;
};

/***
 *  Given an API response (such as search), filter the needed data for a specific page.
 * @param response - the response received from REST API call. The response must have a data field which contains a list
 *      of all the search match results.
 * @param pageNumber - the page number to filter data for
 * @param pageSize - number of results per page
 * @returns {{totalMatches: *, pagedData: [*]}}
 */

export const paginateResults = (response, pageNumber, pageSize) => {
  const body = response.data;
  const totalMatches = body.length;
  const pageIndex = pageNumber - 1;
  const fistMeetingIndex = pageIndex * pageSize;
  const lastMeetingIndex =
    Math.min((pageIndex + 1) * pageSize, totalMatches) - 1;

  return {
    totalMatches: totalMatches,
    pagedData: body.slice(fistMeetingIndex, lastMeetingIndex + 1),
  };
};

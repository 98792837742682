import api from "../../services/api";
import * as actionTypes from "./actionTypes";

export const downloadMeetingStart = () => {
  return {
    type: actionTypes.DOWNLOAD_MEETING_START,
  };
};

const downloadMeetingSuccess = (videoData, meetingId) => {
  return {
    type: actionTypes.DOWNLOAD_MEETING_SUCCESS,
    data: videoData,
    meetingId: meetingId,
  };
};

const downloadMeetingFail = (error) => {
  return {
    type: actionTypes.GET_MEETING_RECORDING_FAIL,
    error: error,
  };
};

export const downloadMeetingRecording = (accessToken, meetingId) => {
  const cleanMeetingDetailsResponse = (response) => {
    const body = response.data;
    return {
      name: body.name,
      url: body.video_url,
      expirationTime: body.expiration_time,
    };
  };

  return (dispatch) => {
    dispatch(downloadMeetingStart());
    api.meetings
      .getMeetingRecording(accessToken, meetingId)
      .then((response) => {
        console.log("Meeting download api call was successful");
        dispatch(
          downloadMeetingSuccess(
            cleanMeetingDetailsResponse(response),
            meetingId
          )
        );
      })
      .catch((error) => {
        console.log("Meeting download api call failed:");
        console.log(error);
        dispatch(downloadMeetingFail(error));
      });
  };
};

import React from "react";
import styled from "styled-components";

import Attendee from "./Attendee/Attendee";

const AttendeesContainer = styled.div`
  margin: 16px 8vw 18px 8vw;
  display: flex;
  overflow: auto;
`;

/**
 *  A JSX component that understands how to render attendees
 * @param props
 *  {
 *      users: a list of attendees that need to be rendered
 *  }
 * @returns {*}
 * @constructor
 */
const Attendees = (props) => {
  return (
    <AttendeesContainer>
      {props.list.map((attendee, index) => (
        <Attendee key={index} attendee={attendee} />
      ))}
    </AttendeesContainer>
  );
};

export default Attendees;

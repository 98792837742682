import React from "react";
import styled from "styled-components";
import LightLogoBug from "../../../../components/UI/Logos/LightLogoBug";

export const TopMainText = styled.h2`
  font-weight: 300;
  line-height: 37px;
  font-size: 28px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: lighter;
`;

export const TopSubText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
`;

export const BottomPart = styled.div`
  padding-right: 20px;
  padding-left: 20px;
`;

export const CMMLogo = (props) => <LightLogoBug height={55}></LightLogoBug>;

import * as actionTypes from "../actions/actionTypes";

const initialState = {
  error: null,
  loading: false,
  loadedPercent: 0,
};

const meetingUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_MEETING_VIDEO_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPLOAD_MEETING_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPLOAD_PROGRESS_UPDATE:
      return {
        ...state,
        loadedPercent: action.loadedPercent,
        loading: true,
      };
    case actionTypes.UPLOAD_MEETING_VIDEO_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default meetingUploadReducer;

import api from "../../services/api";
import * as actionTypes from "./actionTypes";

/****************************************
         Post Meeting Survey Results
 *****************************************/

const submitMeetingSurveyStart = () => {
  return {
    type: actionTypes.SUBMIT_MEETING_SURVEY_START,
  };
};

const submitMeetingSurveySuccess = () => {
  return {
    type: actionTypes.SUBMIT_MEETING_SURVEY_SUCCESS,
  };
};

const submitMeetingSurveyFail = (error) => {
  console.log("meeting feedback failure occurred");
  return {
    type: actionTypes.SUBMIT_MEETING_SURVEY_FAIL,
    error: error,
  };
};

export const submitMeetingSurvey = (email, meetingId, givenRating, comment) => {
  return (dispatch) => {
    dispatch(submitMeetingSurveyStart());

    return api.survey
      .submitMeetingResults(email, meetingId, givenRating, comment)
      .then((_) => {
        console.log("Successfully posted meeting feedback!");
        dispatch(submitMeetingSurveySuccess());
      })
      .catch((error) => {
        console.log("Could not post meeting feedback!");
        console.log(error);
        dispatch(submitMeetingSurveyFail(error));
        throw error;
      });
  };
};

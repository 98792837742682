import React from "react";
import ReactPlayer from "react-player";

/**
 *  A videoElement player component that can play many types of videoElement content, including youtube, facebook, twitch, file, and many more.
 * @param props
 *  {
 *      height: the height of the videoElement player. default value of 360px used.
 *      width: the width of the videoElement player. default value of 640px used.
 *  }
 * @returns {*}
 * @constructor
 */
const Video = (props) => {
  return (
    <ReactPlayer
      url={props.url}
      controls
      height={props.width || "360px"}
      width={props.height || "640px"}
      config={{
        file: {
          attributes: {
            controlsList: "nodownload",
          },
        },
      }}
    />
  );
};

export default Video;

import React from "react";
import ReactPaginate from "react-paginate";

import "./Paginator.css";

/**
 * A React UI component for pagination.
 * @param props
 * {
 *      pageCount: number of pages to render
 *      onPageChange: function to call when a page is changed
 *      showEllipses: whether or not to show ellipses (...) when the next page number shown is not currentPage + 1. Default value of `True` is used.
 *      marginPagesDisplayed: The number of pages to display for margins. Default value of 1 is used.
 *      pageRangeDisplayed: The range of pages displayed. This should be an odd number; default value of 5 is used.
 * }
 * @returns JSX component
 * @constructor
 */

const Paginator = (props) => {
  return props.pageCount > 1 ? (
    <ReactPaginate
      previousLabel={<span>&lt;</span>}
      nextLabel={<span>&gt;</span>}
      breakLabel={props.showEllipses === false ? "" : <span>...</span>}
      breakClassName={"break"}
      pageCount={props.pageCount}
      marginPagesDisplayed={
        props.marginPagesDisplayed != null ? props.marginPagesDisplayed : 1
      }
      pageRangeDisplayed={
        props.pageRangeDisplayed != null ? props.pageRangeDisplayed : 5
      }
      onPageChange={props.onPageChange}
      containerClassName={"pagination"}
      subContainerClassName={"page"}
      activeClassName={"active"}
      forcePage={null}
    />
  ) : null;
};

export default Paginator;

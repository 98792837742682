import React from "react";
import HybridModal from "./HybridModal";

/**
 * A React Modal component designed for desktop and mobile use. Both modals have gradient top borders.
 *  When in mobile mode: The modal always occupies the full screen.
 *  when in desktop mode: The modal is 460px wide and starts at 10% from top of the screen.
 *
 * @param props
 * {
 *  isOpen - indicates if the modal should be currently open or not.
 *  onRequestClose: the function to call to close the modal. If null is specified, user will not be allowed to close the modal manually.
 *  backgroundColor: the color used for the background shadow. By Default, it's transparent purple, rgba(41,42,68, 0.75), which should work for most use cases.
 *  top: modal position from top of the screen. Defaults to 40px.
 *  maxHeight: maximum height for the modal, ex. 90px, or 90vh. Defaults to none.
 *  padding: padding to be applied inside the body; Defaults to 8px;
 * }
 * @returns JSX component
 * @constructor
 */
const GradientHybridModalNarrow = (props) => {
  return (
    <HybridModal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
      backgroundColor={props.backgroundColor}
      top={props.top}
      maxHeight={props.maxHeight}
      padding={props.padding}
      width={props.width || "460px"}
    >
      {props.children}
    </HybridModal>
  );
};

export default GradientHybridModalNarrow;

import React, { Component } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Card from "../../../../components/UI/Card";
import { GradientDiv } from "../../../../components/UI/Styles";
import Input from "../../../../components/UI/Forms/Input";
import Button from "../../../../components/UI/Buttons/LargeButton";

import hidePassword from "../../../../assets/functional_icons/eye-hide-svgrepo-com.svg";
import showPassword from "../../../../assets/functional_icons/eye-show-svgrepo-com.svg";

import { BottomPart, CMMLogo, TopMainText } from "./CommonStyles";
import * as actions from "../../../../store/actions/index";

const TopPart = styled.div`
  min-height: 175px; // 407px when social auth is re-added.
  min-width: 320px;
  padding-top: 25px;
  padding-bottom: 25px;
  color: white;
  justify-content: center;
  text-align: center;
`;

const BottomContainer = styled.div`
  min-height: 295px;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
`;

const FormInputContainer = styled.div`
  margin-bottom: 22px;
  position: relative;
`;

const LoginButton = styled(Button)`
  margin-top: 10px;
`;

const RememberMeForgotPass = styled.div`
  margin-top: 10px; // set margin to 25px when the div is actually being used.
  font-size: 14px;
  font-weight: bold;
  color: rgba(23, 15, 48, 0.5);
  display: flex;
  justify-content: space-between;
`;

const ContactSupport = styled.p`
  margin: 5px 4.5px 15px 4.5px;
  font-size: 14px;
  text-align: center;
  color: rgba(23, 15, 48, 0.51);
`;

const DontHaveAnAccount = styled.p`
  height: 19px;
  color: #a0a0a0;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 15px;
`;

const DisplayError = styled.div`
  color: tomato;
  font-size: 14px;
  padding: ${(props) => (props.hasError ? "30px 0 7px 10px" : "53px 0 0 0")};
`;
//-------------Forget pass link-----------------

const ForgetPass = styled.p`
  width: fit-content;
  margin-top: -15px;
  margin-left: auto;
  font-size: 14px;
  color: tomato;
  text-decoration: none;
`;
class Login extends Component {
  state = {
    hidePassword: true,
  };

  submitHandler = (event) => {
    // we want to avoid the default behavior of form submission (reloading the page)
    event.preventDefault();
    this.props.onAuth(
      ReactDOM.findDOMNode(this.refs.emailRef).value,
      ReactDOM.findDOMNode(this.refs.passwordRef).value
    );
  };

  inferErrorMessage = (error) => {
    console.log(error);

    if (error) {
      const response = error.response;
      if (response.status === 400) return "Invalid username or password.";
      else if (response.status === 503)
        return (
          "Sorry, the service is currently unavailable for a short duration." +
          " If you need to use it, please contact support at the email address mentioned below."
        );
      else return "Hmmm...something went wrong, please try again.";
    } else {
      return "";
    }
  };
  changeVisuality = (value, inputIdentifier) => {
    const updatedFormValues = {
      ...this.state,
    };
    updatedFormValues[inputIdentifier] = value;

    this.setState(updatedFormValues);
  };

  render() {
    const error = this.props.auth.error;
    const loading = this.props.auth.loading;

    return (
      <Card>
        <GradientDiv>
          <TopPart>
            <CMMLogo />
            <TopMainText>Welcome Back!</TopMainText>
            {/*<TopSubText>Use your preferred business account.</TopSubText>*/}
            {/*<ExtLogoContainer><GoogleLogoHorizontal width="282px"/></ExtLogoContainer>*/}
            {/*<ExtLogoContainer><MicrosoftLogoHorizontal width="282px"/></ExtLogoContainer>*/}
            {/*<ExtLogoContainer><LinkedInLogoHorizontal width="282px"/></ExtLogoContainer>*/}
          </TopPart>
        </GradientDiv>
        {/*<CircleDivider/>*/}
        <BottomPart>
          <BottomContainer>
            <DisplayError hasError={error != null}>
              {this.inferErrorMessage(error)}
            </DisplayError>
            <form onSubmit={this.submitHandler}>
              <FormInputContainer>
                <Input
                  placeholder="My Email"
                  type="email"
                  ref="emailRef"
                  required
                />
              </FormInputContainer>
              <FormInputContainer>
                <Input
                  placeholder="My Password"
                  type={this.state.hidePassword ? "password" : "text"}
                  ref="passwordRef"
                  required
                />
                <img
                  alt="Hide Password"
                  onClick={() => {
                    this.changeVisuality(
                      !this.state.hidePassword,
                      "hidePassword"
                    );
                  }}
                  style={{
                    position: "absolute",
                    width: "30px",
                    top: "0px",
                    bottom: "0px",
                    right: "5px",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                  src={this.state.hidePassword ? hidePassword : showPassword}
                />
              </FormInputContainer>
              <ForgetPass>
                <Link to="/auth/requestResetPassword">
                  Forget your password ?
                </Link>
              </ForgetPass>
              <LoginButton type="submit" disabled={loading} loading={loading}>
                Sign In
              </LoginButton>
              <RememberMeForgotPass>
                {/*<CheckBox fontSize="14px">Remember Me</CheckBox>*/}
                {/*<a>Forgot Password</a>*/}
              </RememberMeForgotPass>
            </form>
            <DontHaveAnAccount>
              Don't have an account?{" "}
              <Link to="/auth/signup">
                <u>Sign up here</u>
              </Link>
            </DontHaveAnAccount>
            <ContactSupport>
              For questions or concerns, please contact{" "}
              <a href="mailto:support@capturemymeeting.com">
                support@capturemymeeting.com
              </a>
            </ContactSupport>
          </BottomContainer>
        </BottomPart>
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password) => dispatch(actions.auth(email, password)),
  };
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React, { Component } from "react";
import styled from "styled-components";

import Tile from "../Tile/Tile";
import { Panel } from "../Tabs/Tabs";
import * as actions from "../../../../store/actions/index";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import SmallButton from "../../../UI/Buttons/SmallButton.js";

import { media } from "../../../UI/StyleUtils.js";

const Body = styled(Panel)`
  padding-top: 15px;
  padding-bottom: 15px;
  overflow: hidden;
`;

const ButtonContainer = styled.div`
  height: 56px;
`;
const SmallButtonPurple = styled(SmallButton)`
  background-color: #764ef2;
  width: auto;
  float: right;
  color: white;
  height: 30px;
  padding: 5px;

  ${media.ltSm`
        width: 100%;
        float:none;
    `}
`;

const StyledLabel = styled.div`
  display: inline-block;
  color: #170f30;
  font-weight: bold;
  opacity: 0.25;
`;

const RowBlockContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-left: auto;
  height: 40px;
`;

const CancelContainer = styled.div`
  display: flex;
  justify-content: center;
  font-size: 12px;
  margin-top: 10px;
`;

const ValueLabel = styled.div`
  display: inline-block;
  margin-left: auto;
`;

const ProgressBar = styled.div`
  width: 120%;
  height: 10px;
  background-color: #0accd9;
  border-radius: 10px;
`;

const FilledBar = styled.div`
  width: ${(props) => props.progress}%;
  height: 120%;
  background-color: #0074d9; /* Change this color to your desired fill color */
  border-radius: 10px;
`;

class SubscriptionPanel extends Component {
  state = {
    progress: 100,

    subscription: null,
    openModal: false,
  };

  updateState = (stateName, stateValue) => {
    this.setState((prevState) => ({
      ...prevState,
      [stateName]: stateValue,
    }));
  };
  getPortal = async () => {
    try {
      await this.props
        .getCustomerPortal(this.props.accessToken)
        .then((result) => {
          const url = result.data.url;
          window.open(url, "_blank");
        })
        .catch((err) => {
          console.error("Error getting customer portal: ", err);
        });
    } catch (error) {
      console.error("Error Sub Data: ", error);
    }
  };

  componentDidMount() {
    console.log("SubscriptionPanel component did mount");
    this.props
      .getUserData(this.props.accessToken)
      .then((resp) => {
        return this.props.getCurrentSubscription(this.props.accessToken);
      })
      .then((result) => {
        this.updateState(
          "subscription",
          this.props.currentSubscription?.subscription
        );
      })
      .catch((err) => {
        console.error("Error getting data in subscription panel: ", err);
      });
  }

  render() {
    const { subscription } = this.state;
    const plan = this.props.currentSubscription?.product;
    const purchased_at = subscription?.start_date;
    const purchased_date = new Date(purchased_at * 1000).toLocaleDateString(
      "en-US"
    );

    const sub_start = subscription?.current_period_start;
    const sub_end = subscription?.current_period_end;
    var date = new Date(sub_start * 1000);
    const start_date = date.toLocaleDateString("en-US");
    var date2 = new Date(sub_end * 1000);
    const end_date = date2.toLocaleDateString("en-US");

    const userMinutes = this.props.user?.minutes;
    const planMinutes =
      this.props.currentSubscription?.subscription?.items?.data[0]?.price
        ?.metadata?.minutes;

    const progressPercentage = (userMinutes / planMinutes) * 100;
    if (this.props?.loadingFlag === "loading") {
      return <>Loading...</>;
    }
    return (
      <Body>
        {subscription && plan ? (
          <Tile
            title={plan?.name}
            titleStyle={{ color: "black", "font-size": "20px" }}
          >
            <RowBlockContainer>
              <StyledLabel> Purchased at </StyledLabel>
              <ValueLabel>{purchased_date}</ValueLabel>
            </RowBlockContainer>
            <RowBlockContainer>
              <StyledLabel> Current Period </StyledLabel>
              <ValueLabel>{start_date + " --- " + end_date}</ValueLabel>
            </RowBlockContainer>
            <RowBlockContainer>
              <StyledLabel> Status </StyledLabel>
              <ValueLabel>
                {subscription?.cancel_at_period_end
                  ? " TO BE CANCELED"
                  : subscription?.status}
              </ValueLabel>
            </RowBlockContainer>
            <RowBlockContainer style={{ justifyContent: "space-between" }}>
              <StyledLabel> Available Minutes </StyledLabel>
              <StyledLabel> {userMinutes + "/" + planMinutes} </StyledLabel>
            </RowBlockContainer>
            <RowBlockContainer style={{ justifyContent: "space-between" }}>
              <ProgressBar>
                <FilledBar progress={progressPercentage} />
              </ProgressBar>
            </RowBlockContainer>
            <RowBlockContainer>
              <ButtonContainer
                onClick={() => {
                  this.getPortal();
                }}
              >
                <SmallButtonPurple type="submit">
                  {" "}
                  Manage Subscription{" "}
                </SmallButtonPurple>
              </ButtonContainer>
              <ButtonContainer onClick={this.props.openStripeModal}>
                <SmallButtonPurple type="submit">
                  {" "}
                  Update Package{" "}
                </SmallButtonPurple>
              </ButtonContainer>
            </RowBlockContainer>
            <CancelContainer>
              To update your current plan, click Update Package. To add a new
              card, click Manage Subscription. To cancel your subscription,
              contact support@capturemymeeting.com.
            </CancelContainer>
          </Tile>
        ) : (
          <div style={{ padding: "5px" }}>
            <p>You dont have any subscription yet.</p>
            <RowBlockContainer>
              <ButtonContainer
                onClick={() => {
                  this.getPortal();
                }}
              >
                <SmallButtonPurple type="submit">
                  {" "}
                  Manage Subscription{" "}
                </SmallButtonPurple>
              </ButtonContainer>
              <ButtonContainer onClick={this.props.openStripeModal}>
                <SmallButtonPurple type="submit">
                  {" "}
                  Update Package{" "}
                </SmallButtonPurple>
              </ButtonContainer>
            </RowBlockContainer>
          </div>
        )}
      </Body>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserData: (accessToken) => dispatch(actions.getUser(accessToken)),
    openStripeModal: () => dispatch(actions.openStripeModal()),
    getCurrentSubscription: (accessToken) =>
      dispatch(actions.getCurrentSubscription(accessToken)),
    getCustomerPortal: (accessToken) =>
      dispatch(actions.getCustomerPortal(accessToken)),
  };
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    user: state.user,
    currentSubscription: state.subscription.currentSubscription,
    loadingFlag: state.subscription.loadingFlag,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubscriptionPanel)
);

import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-grid-system";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { media } from "../../UI/StyleUtils";
import Meeting from "./Meeting/Meeting";
import * as actions from "../../../store/actions";
import { PurpleDottedSpinner } from "../../UI/Spinners/DottedSpinner";
import MeetingsPaginator from "./MeetingsPaginator/MeetingsPaginator";
import GradientBorder from "../../UI/GradientBorder";
import SearchBar, { QueryTypes } from "./SearchBar";
import { HorizontalLine } from "../../UI/Lines/Lines";
import VoiceContentSearchResult from "./VoiceContentSearchResult/VoiceContentSearchResult";
import MeetingModal from "./Meeting/OverflowMenu/Modals/MeetingModal";
import DateTime from "./Meeting/DateTime/DateTime";
import NameDuration from "./Meeting/NameDuration/NameDuration";
import OverFlowMenu from "./Meeting/OverflowMenu/Overflow";
import CheckBox from "../../UI/Forms/Checkbox";
import { isSampleMeeting, userIsHost } from "../../../utility";
import CircularProgressBar from "../../UI/ProgressBar/CircularProgressbar";
import { SmallButtonPurple } from "../../UI/Buttons/SmallButton";

const BulkOperationsContainer = styled.div``;

const BulkOperationsSelector = styled.div`
  height: 100%;
  margin: 0 0.5% 0 2.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(234, 234, 234, 0.3);
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const OverlayText = styled.div`
  text-decoration: underline;
  text-shadow: 0px 0px black;
  padding: 10px 60px;
  font-size: 18px;
`;

const MeetingsCard = styled.div`
  width: 100%;
  background-color: #ffffff;
  overflow: visible;

  ${media.gtSm`
        // margin: 24px auto;  when to once stats card is enabled
        margin: 10px auto 24px auto;
        width: 96%;
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 rgba(12,0,51,0.1);
    `}
`;

const Heading = styled.div`
  height: 106px;

  ${media.gtSm`
        height: 79px;       // potentially revert back to 69 when stats card added.
        `}
`;

const InnerHeading = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const GradientBorderCust = styled(GradientBorder)`
  display: none;
  ${media.gtSm`
        display: block;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    `};
`;

const MeetingsContainer = styled.div`
  height: 744px;
  // height: 601px;   revert to once stats card is enabled. Will need to verify
  position: relative;
  ${media.gtSm`
        // height: 566px;    revert to once stats card is enabled
        height: 825px;
    `}
`;

const MyMeetingsText = styled.p`
  margin: 13px 16px 0 16px;
  font-size: 18px;
  line-height: 24px;
  color: #170f30;
  display: inline-block;

  ${media.gtSm`
	    margin: 16px 16px 16px 34px;
	    font-size: 20px;
	    line-height: 32px;
    `};
`;

const MeetingContainer = styled.div`
  height: 72px;
  border-top: #dee5ea solid 1px;
  border-bottom: #dee5ea solid 1px;

  ${media.gtSm`
        height: 84px;
    `};
`;

const CustomSpinner = styled(PurpleDottedSpinner)`
  /* padding: 72px auto; */
  left: calc(50% - 25px);
  margin-top: 72px;
  position: absolute;
`;

const PaginatorContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: 38px;

  ${media.gtSm`
        display: block;
        position: absolute;
        padding-left: 18px;
        bottom: 20px
    `};
`;

const BoundingBoxes = styled.div`
  position: relative;
  z-index: 2;
`;

const BoundingBox = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 0, 0.5);
  left: ${(props) => props.x * props.scale * 0.67 + "px"};
  top: ${(props) => props.y * props.scale * 0.67 + "px"};
  width: ${(props) => props.width * props.scale * 0.67 + "px"};
  height: ${(props) => props.height * props.scale * 0.67 + "px"};
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

const InformationalTextForSearch = styled.div`
  margin: 20px;
`;

const FilterRemover = styled.div`
  width: 25px;
  height: 21px;
  border: 1.5px solid #764ef2;
  color: #764ef2;
  font-weight: bolder;
  cursor: pointer;
  margin-top: 3px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MeetingResult = styled.p`
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
`;
const CircularProgressBarContainer = styled.div`
  margin-inline: auto;
  margin-top: 100px;
  display: flex;
  justify-content: center;
`;
class Meetings extends Component {
  state = {
    pageTitle: "My Meetings",
    meetingFilterTitle: "",
    filterRemoverDisplay: "none",
    selectAllChecked: false,
    currentPageNumber: 1,
    meetingSelectedStates: {},
    currentSearchQuery: "",
  };

  componentDidMount() {
    const query = "";
    const searchForSpecificMeeting =
      this.props.meetingPathSegment && this.props.meetingPathSegment[1];
    if (searchForSpecificMeeting) {
      const meetingId = this.props.meetingPathSegment[1];
      const meetingOperation = this.props.meetingPathSegment[2];
      this.filterByMeeting(query, meetingId, meetingOperation);
    } else {
      this.props
        .getMeetings(
          this.props.accessToken,
          query,
          null,
          null,
          1,
          this.props.meetings.pageSize
        )
        .then(() => {
          this.setState({
            meetingSelectedStates: this.getInitialMeetingSelectedStates(1),
          });
        });
    }
  }

  getInitialMeetingSelectedStates = (pageNumber) => {
    let newMeetingSelectedStates = this.state.meetingSelectedStates;
    newMeetingSelectedStates[pageNumber] =
      this.getInitialMeetingSelectedStatesForPage();
    return newMeetingSelectedStates;
  };

  getInitialMeetingSelectedStatesForPage = () => {
    return this.props.meetings.data
      .filter(
        // TODO: Remove userIsHost check after adding other non-admin bulk operations.
        (meeting) =>
          !isSampleMeeting(meeting.id) &&
          userIsHost(meeting.hosts, this.props.userEmail)
      )
      .reduce(
        (accumulatedIdsObj, currentMeeting) =>
          Object.assign(accumulatedIdsObj, {
            [currentMeeting.id]: {
              checked: false,
              id: currentMeeting.id,
              name: currentMeeting.name,
              hosts: currentMeeting.hosts,
            },
          }),
        {}
      );
  };

  // Return a copy of meetingSelectedStates where all meeting checkbox states for the current page are set to boolValue
  getMeetingSelectedStates = (boolValue) => {
    let newMeetingSelectedStatesForPage = this.state.meetingSelectedStates;
    newMeetingSelectedStatesForPage[this.state.currentPageNumber] =
      this.getMeetingSelectedStatesForPage(boolValue);
    return newMeetingSelectedStatesForPage;
  };

  getMeetingSelectedStatesForPage = (boolValue) => {
    return Object.keys(
      this.state.meetingSelectedStates[this.state.currentPageNumber]
    ).reduce(
      (accumulatedIdsObj, currentId) =>
        Object.assign(accumulatedIdsObj, {
          [currentId]: {
            ...this.state.meetingSelectedStates[this.state.currentPageNumber][
              currentId
            ],
            checked: boolValue,
          },
        }),
      {}
    );
  };

  handlePageChange = (data) => {
    // page indices start at 0
    const selectedPage = data.selected + 1;
    const queryType = this.props.meetings.queryType;
    if (queryType === QueryTypes.NAME) {
      this.props
        .getMeetings(
          this.props.accessToken,
          this.props.meetings.query,
          this.props.meetings.meeting.id,
          null,
          selectedPage,
          this.props.meetings.pageSize
        )
        .then(() => {
          if (!(selectedPage in this.state.meetingSelectedStates)) {
            this.setState({
              currentPageNumber: selectedPage,
              meetingSelectedStates:
                this.getInitialMeetingSelectedStates(selectedPage),
              selectAllChecked: false,
            });
          } else {
            this.setState({
              currentPageNumber: selectedPage,
              selectAllChecked: false,
            });
          }
        });
    } else if (queryType === QueryTypes.VOICE)
      this.props.searchMeetingsForVoiceContent(
        this.props.accessToken,
        this.props.meetings.query,
        this.props.meetings.meeting.id,
        selectedPage
      );
    else if (queryType === QueryTypes.TEXT)
      this.props.searchMeetingsForTextContent(
        this.props.accessToken,
        this.props.meetings.query,
        this.props.meetings.meeting.id,
        selectedPage
      );
    else
      console.warn(
        "handlePageChange() called for a queryType that is not supported yet:",
        queryType
      );
  };

  handleFilterTypeChange = (newQueryType, event) => {
    //TODO: perhaps make a new query for this filter type or remove the query completely for now.
    console.log("new filter type is: " + newQueryType);
    if (this.props.meetings.queryType !== newQueryType) {
      if (newQueryType === QueryTypes.NAME)
        this.props.getMeetings(
          this.props.accessToken,
          this.props.meetings.query,
          this.props.meetings.meeting.id,
          null,
          1,
          this.props.meetings.pageSize
        );
      else if (newQueryType === QueryTypes.TEXT)
        this.props.searchMeetingsForTextContent(
          this.props.accessToken,
          this.props.meetings.query,
          this.props.meetings.meeting.id,
          1
        );
      else
        this.props.searchMeetingsForVoiceContent(
          this.props.accessToken,
          this.props.meetings.query,
          this.props.meetings.meeting.id,
          1
        );

      // go ahead and update the query type
      this.props.updateQueryType(newQueryType);
    }
  };

  getQueryResults = (newQuery, queryType, meetingId) => {
    if (queryType === QueryTypes.NAME)
      return this.props.getMeetings(
        this.props.accessToken,
        newQuery,
        meetingId,
        null,
        1,
        this.props.meetings.pageSize
      );
    // when a new filter is applied, we always look for first page
    else if (queryType === QueryTypes.TEXT)
      return this.props.searchMeetingsForTextContent(
        this.props.accessToken,
        newQuery,
        meetingId,
        1
      );
    else
      return this.props.searchMeetingsForVoiceContent(
        this.props.accessToken,
        newQuery,
        meetingId,
        1
      );
  };

  handleFilterChange = (event) => {
    const newQuery = event.target.value;
    this.setState({ currentSearchQuery: newQuery });
    if (event.key === "Enter") {
      this.getQueryResults(
        newQuery,
        this.props.meetings.queryType,
        this.props.meetings.meeting.id
      );
    }
  };

  getSearchResults = () => {
    this.getQueryResults(
      this.state.currentSearchQuery,
      this.props.meetings.queryType,
      this.props.meetings.meeting.id
    );
  };

  openMeetingModal = (meetingId, seconds) => {
    this.props.viewMeetingRecording(
      this.props.accessToken,
      meetingId,
      "search",
      seconds
    );
  };

  filterByMeeting = (query, meetingId, invokeOperation) => {
    this.props
      .getMeetings(
        this.props.accessToken,
        query,
        meetingId,
        invokeOperation,
        1,
        this.props.meetings.pageSize
      )
      .then(() =>
        this.setState({
          meetingFilterTitle: ` | ${this.props.meetings.meeting.name}`,
          filterRemoverDisplay: "inline",
        })
      )
      .catch((error) => {
        console.log("There was an error processing your request.");
      });
  };

  removeFilter = () => {
    window.location.reload(false);
    // this.getQueryResults(this.props.meetings.query, this.props.meetings.queryType, null)
    //     .then(() => this.setState({
    //         meetingFilterTitle: "",
    //         filterRemoverDisplay: "none",
    //     }))
    //     .catch(error => {
    //         console.log('There was an error processing your request.');
    //     });
  };

  onSelectAll = () => {
    if (this.state.selectAllChecked) {
      this.setState({
        selectAllChecked: !this.state.selectAllChecked,
        meetingSelectedStates: this.getMeetingSelectedStates(false),
      });
    } else {
      this.setState({
        selectAllChecked: !this.state.selectAllChecked,
        meetingSelectedStates: this.getMeetingSelectedStates(true),
      });
    }
  };

  onSelectMeeting = (id) => {
    let newMeetingSelectedStates = this.state.meetingSelectedStates;
    newMeetingSelectedStates[this.state.currentPageNumber][id]["checked"] =
      !newMeetingSelectedStates[this.state.currentPageNumber][id]["checked"];
    this.setState({
      selectAllChecked: false,
      meetingSelectedStates: newMeetingSelectedStates,
    });
  };

  getSelectedMeetings = () => {
    let meetingsData = [];
    for (const pageNumber in this.state.meetingSelectedStates) {
      for (const meetingId in this.state.meetingSelectedStates[pageNumber]) {
        if (
          this.state.meetingSelectedStates[pageNumber][meetingId]["checked"]
        ) {
          meetingsData.push(
            this.state.meetingSelectedStates[pageNumber][meetingId]
          );
        }
      }
    }
    return meetingsData;
  };

  getHostsForMySelectedMeetings = () => {
    let selectedMeetings = this.getSelectedMeetings();
    let hosts = [];
    for (let i = 0; i < selectedMeetings.length; i++) {
      for (let j = 0; j < selectedMeetings[i].hosts.length; j++) {
        if (selectedMeetings[i].hosts[j].email === this.props.userEmail) {
          hosts.push(selectedMeetings[i].hosts[j]);
        }
      }
    }
    if (hosts.length === selectedMeetings.length) {
      return hosts;
    } else {
      return [];
    }
  };

  someMeetingsAreSelected = () => {
    for (const pageNumber in this.state.meetingSelectedStates) {
      if (
        Object.keys(this.state.meetingSelectedStates[pageNumber]).some(
          (meetingId) =>
            this.state.meetingSelectedStates[pageNumber][meetingId][
              "checked"
            ] === true
        )
      )
        return true;
    }
    return false;
  };

  selectableMeetingsExistOnPage = () => {
    return (
      this.state.currentPageNumber in this.state.meetingSelectedStates &&
      this.state.meetingSelectedStates[this.state.currentPageNumber] &&
      Object.keys(
        this.state.meetingSelectedStates[this.state.currentPageNumber]
      ).length > 0
    );
  };

  renderMeetings = (query, matchedMeetings) => {
    return matchedMeetings.length === 0 ? (
      <InformationalTextForSearch>
        Nothing matched for "{query}".
      </InformationalTextForSearch>
    ) : (
      <div>
        {matchedMeetings.map((meeting, index) => (
          <MeetingContainer key={index}>
            <Meeting
              id={meeting.id}
              meetingName={meeting.name}
              meetingOperation={meeting.operation}
              meetingData={[{ id: meeting.id, name: meeting.name }]}
              startTime={meeting.startTime}
              endTime={meeting.endTime}
              numAttendees={meeting.numAttendees}
              numBoards={meeting.numBoards}
              recordingAvailable={meeting.recordingAvailable}
              hosts={meeting.hosts}
              userEmail={this.props.userEmail}
              meetingNameUpdateHandler={(meetingName) =>
                this.props.meetingNameUpdateHandler(
                  this.props.accessToken,
                  meeting.id,
                  meetingName
                )
              }
              filterByMeetingHandler={() =>
                this.filterByMeeting(query, meeting.id, null)
              }
              onSelectMeeting={this.onSelectMeeting}
              meetingSelectedStates={this.state.meetingSelectedStates}
              currentPageNumber={this.state.currentPageNumber}
              viewMeetingRecordingHandler={() =>
                this.props.viewMeetingRecording(
                  this.props.accessToken,
                  meeting.id,
                  "view"
                )
              }
            />
          </MeetingContainer>
        ))}
      </div>
    );
  };

  renderTextContentSearchResults = (query, matchedResults) => {
    let queryResults;
    const singleResult = (match, index) => {
      const boundingBoxes = (words, scale) => {
        return (
          <BoundingBoxes>
            {words
              .map((word) => word.box)
              .map((box) => (
                <BoundingBox
                  x={box.x}
                  y={box.y}
                  height={box.height}
                  width={box.width}
                  scale={scale}
                />
              ))}
          </BoundingBoxes>
        );
      };
      const timestamp = moment("2020-01-01 00:00:00").add(
        moment.duration(match.timestamp)
      );
      const timestampFormatted =
        timestamp.hour() !== 0
          ? timestamp.format("H:mm:ss")
          : timestamp.format("m:ss");
      const seconds = moment.duration(match.timestamp) / 1000;

      return (
        <Col
          key={`ts_${index}`}
          xs={6}
          md={4}
          lg={3}
          style={{ height: "260px", marginTop: "5px" }}
        >
          <div>
            {
              // place bounding boxes before adding the image below.
              boundingBoxes(match.words, match.scale)
            }
            {/*<img src={match.imageUrl} alt="broken result" style={{maxHeight: "180px", maxWidth: "260px"}}/>*/}
            <img
              src={match.imageUrl}
              alt="Broken Result"
              style={{ maxHeight: "200px", maxWidth: "200px" }}
            />
          </div>
          <MeetingResult
            onClick={() => this.openMeetingModal(match.meetingId, seconds)}
          >
            {match.meetingName} &nbsp;&nbsp;|&nbsp;&nbsp; {timestampFormatted}
          </MeetingResult>
        </Col>
      );
    };

    if (query.length === 0)
      queryResults = (
        <InformationalTextForSearch>
          Search for written content in your meetings (e.g. text written on a
          whiteboard or slides). For reference, a one-hour meeting recording may
          take about 10 minutes before becoming searchable. We will notify you
          by email when your meeting is ready to be searched.
        </InformationalTextForSearch>
      );
    else if (matchedResults.length === 0) {
      queryResults = (
        <InformationalTextForSearch>
          Nothing matched for "{query}".
        </InformationalTextForSearch>
      );
    } else {
      queryResults = matchedResults.map((match, index) =>
        singleResult(match, index)
      );
    }

    return (
      <Container>
        <Row>{queryResults}</Row>
      </Container>
    );
  };

  renderVoiceContentResults = (query, matchedResults) => {
    let queryResults;

    if (query.length === 0)
      queryResults = (
        <InformationalTextForSearch>
          Search for things discussed during your meetings. For reference, a
          one-hour meeting recording may take about 10 minutes before becoming
          searchable. We will notify you by email when your meeting is ready to
          be searched.
        </InformationalTextForSearch>
      );
    else if (matchedResults.length === 0) {
      queryResults = (
        <InformationalTextForSearch>
          Nothing matched for "{query}".
        </InformationalTextForSearch>
      );
    } else {
      // eslint-disable-next-line no-useless-escape
      const wordsInQuery = query.toLowerCase().split(/[ \.,]+/);

      queryResults = matchedResults.map((match, index) => {
        const seconds = moment.duration(match.timestamp);
        return (
          <VoiceContentSearchResult
            key={`vs_${index}`}
            meetingName={match.meetingName}
            meetingId={match.meetingId}
            matchedContent={match.matchedContent}
            time={match.timestamp}
            wordsInQuery={wordsInQuery}
            onOpenMeetingModal={() =>
              this.openMeetingModal(match.meetingId, seconds)
            }
          />
        );
      });
    }

    return <div>{queryResults}</div>;
  };

  render() {
    window.addEventListener("beforeunload", (event) => {
      // Assuming this logic is inside a React component method
      if (
        this.props.meetingUpload &&
        this.props.meetingUpload.loadedPercent &&
        this.props.meetingUpload.loadedPercent !== 100
      ) {
        const confirmationMessage =
          "Your upload progress is incomplete. Are you sure you want to leave?";
        event.returnValue = confirmationMessage;
      }
    });
    let meetingsData, paginator;

    if (this.props.meetings.loading) {
      meetingsData = <CustomSpinner size={50} />;
    } else if (this.props.meetingUpload.loading) {
      meetingsData = (
        <CircularProgressBarContainer>
          <CircularProgressBar
            progress={this.props.meetingUpload.loadedPercent}
            heading={"Uploading..."}
          />
        </CircularProgressBarContainer>
      );
    } else if (this.props.meetings.error)
      // TODO: improve the error message screen. Also, when a meeting delete call fails, we are throwing the same error.
      // perhaps we should change this behavior in the future.
      meetingsData = (
        <InformationalTextForSearch>
          An error occurred while loading your meetings. Please try again later.
          If this problem continues to occur, please contact customer support.{" "}
        </InformationalTextForSearch>
      );
    else if (this.props.meetings.queryType === QueryTypes.NAME)
      meetingsData = this.renderMeetings(
        this.props.meetings.query,
        this.props.meetings.data
      );
    else if (this.props.meetings.queryType === QueryTypes.TEXT)
      meetingsData = this.renderTextContentSearchResults(
        this.props.meetings.query,
        this.props.meetings.byContent.matchedData
      );
    else
      meetingsData = this.renderVoiceContentResults(
        this.props.meetings.query,
        this.props.meetings.byContent.matchedData
      );

    let pageCount;
    if (this.props.meetings.queryType === QueryTypes.NAME)
      pageCount =
        this.props.meetings.totalMeetings / this.props.meetings.pageSize;
    else if (this.props.meetings.queryType === QueryTypes.TEXT)
      pageCount =
        this.props.meetings.byContent.totalMatches /
        this.props.meetings.byContent.textSearchPageSize;
    else
      pageCount =
        this.props.meetings.byContent.totalMatches /
        this.props.meetings.byContent.voiceSearchPageSize;

    paginator = (
      <PaginatorContainer>
        <MeetingsPaginator
          pageCount={pageCount}
          onPageChange={this.handlePageChange}
        />
      </PaginatorContainer>
    );

    return (
      <div>
        <MeetingsCard className="Meetings">
          <Heading>
            <GradientBorderCust />
            <InnerHeading>
              <TitleContainer>
                <MyMeetingsText>
                  {this.state.pageTitle}
                  {this.props.meetings.meeting.id
                    ? this.state.meetingFilterTitle
                    : null}
                </MyMeetingsText>
                {this.props.meetings.meeting.id ? (
                  <FilterRemover
                    style={{ display: this.state.filterRemoverDisplay }}
                    title={"Remove Filter"}
                    onClick={this.removeFilter}
                  >
                    &nbsp;&nbsp;X
                  </FilterRemover>
                ) : null}
              </TitleContainer>
              <SearchBar
                currentFilter={this.props.meetings.query}
                currentFilterType={this.props.meetings.queryType}
                updateFilterType={this.handleFilterTypeChange}
                onFilterChange={this.handleFilterChange}
                getSearchResults={this.getSearchResults}
              />
            </InnerHeading>
          </Heading>
          <div style={{ position: "relative" }}>
            {(`${process.env.REACT_APP_STRIPE_UI_ENABLED}` === "true" ||
              `${process.env.REACT_APP_STRIPE_WHITELIST_ACCOUNTS}`.includes(
                this.props.userEmail
              )) &&
            this.props.userMinutes <= 0 ? (
              <Overlay>
                <OverlayText>
                  You have reached the maximum number of minutes allowed for the
                  month under your current plan. Please upgrade to continue
                  using the app this month, or wait until your minutes reset at
                  the end of the current monthly period.
                </OverlayText>
                <SmallButtonPurple
                  type="submit"
                  onClick={this.props.openStripeModal}
                  style={{ maxHeight: "40px", maxWidth: "150px" }}
                >
                  {" "}
                  Upgrade{" "}
                </SmallButtonPurple>
              </Overlay>
            ) : null}
            {this.props.meetings.queryType === QueryTypes.NAME &&
            this.selectableMeetingsExistOnPage() ? (
              <BulkOperationsContainer>
                <HorizontalLine />
                <HorizontalLine style={{ marginBottom: "3px" }} />
                <BulkOperationsSelector>
                  <CheckBox
                    fontSize="12px"
                    required={true}
                    isChecked={this.state.selectAllChecked}
                    onChange={(event) => this.onSelectAll()}
                    title="Select All Meetings"
                  ></CheckBox>
                  {this.someMeetingsAreSelected() ? (
                    <OverFlowMenu
                      hosts={this.getHostsForMySelectedMeetings()}
                      bulkOperation={true}
                      meetingData={this.getSelectedMeetings()}
                    />
                  ) : null}
                </BulkOperationsSelector>
              </BulkOperationsContainer>
            ) : null}
            <MeetingsContainer
              style={{ marginTop: "3px", position: "relative" }}
            >
              <HorizontalLine />
              {meetingsData}
              {paginator}
            </MeetingsContainer>
            <MeetingModal
              isOpen={this.props.meetingModal.searchModal.display}
              onRequestClose={() => {
                this.props.closeMeetingModal("search");
              }}
              recordingAvailable={
                this.props.meetingModal.data.recordingAvailable
              }
              video={this.props.meetingModal.data}
              dateTime={
                <DateTime time={this.props.meetingModal.data.startTime} />
              }
              nameDuration={
                <NameDuration
                  name={this.props.meetingModal.data.name}
                  startTime={this.props.meetingModal.data.startTime}
                  endTime={this.props.meetingModal.data.endTime}
                  meetingNameUpdateHandler={this.props.meetingNameUpdateHandler}
                />
              }
              zIndex={5}
            />
          </div>
        </MeetingsCard>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openStripeModal: () => dispatch(actions.openStripeModal()),
    getMeetings: (
      accessToken,
      query,
      meetingId,
      operation = null,
      pageNumber = 1,
      pageSize = 9
    ) =>
      dispatch(
        actions.getMeetings(
          accessToken,
          query,
          meetingId,
          operation,
          pageNumber,
          pageSize
        )
      ),
    searchMeetingsForTextContent: (
      accessToken,
      query,
      meetingId,
      pageNumber = 1,
      pageSize = 12
    ) =>
      dispatch(
        actions.searchMeetingsForTextContent(
          accessToken,
          query,
          meetingId,
          pageNumber,
          pageSize
        )
      ),
    searchMeetingsForVoiceContent: (
      accessToken,
      query,
      meetingId,
      pageNumber = 1,
      pageSize = 10
    ) =>
      dispatch(
        actions.searchMeetingsForVoiceContent(
          accessToken,
          query,
          meetingId,
          pageNumber,
          pageSize
        )
      ),
    updateQueryType: (newQueryType) =>
      dispatch(actions.updateQueryType(newQueryType)),
    meetingNameUpdateHandler: (accessToken, meetingId, meetingName) =>
      dispatch(actions.updateMeetingName(accessToken, meetingId, meetingName)),
    viewMeetingRecording: (accessToken, meetingId, modalType, seconds) =>
      dispatch(
        actions.viewMeetingRecording(accessToken, meetingId, modalType, seconds)
      ),
    closeMeetingModal: (modalType) =>
      dispatch(actions.closeMeetingModal(modalType)),
  };
};

const mapStateToProps = (state) => {
  return {
    meetingModal: state.meetingModal,
    meetings: state.meetings,
    accessToken: state.auth.accessToken,
    meetingUpload: state.meetingUpload,
    userEmail: state.user.email,
    userMinutes: state.user?.minutes,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Meetings)
);

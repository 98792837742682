import React from "react";
import Modal from "react-modal";

/**
 *  A react accessible modal with gradient top border that is easy to use. For a detailed set of accessibility features and how to utilize
 *  them visit http://reactcommunity.org/react-modal/accessibility/#keyboard

 * @param props
 *  {
 *      isOpen: whether the modal is open. Ex: true.
 *      shouldCloseOnOverlayClick: when true (default value for this property), requires onRequestClose to be defined in order to close the modal.
 *      onRequestClose: the function to call when the modal is closed.
 *      width: modal width. Defaulted to cover the screen from left to right with 40px margins
 *      top: modal position from top of the screen. Defaulted to 40px
 *      bottom: modal end position in relations to the bottom of the screen. Defaulted to only cover the content of the modal.
 *      backgroundColor: modal backdrop color. Defaulted to transparent purple, rgba(41,42,68, 0.75).
 *      maxHeight: maximum height for the modal, ex. 90px, or 90vh. Defaults to none.
 *      zIndex: The zIndex of the modal. Defaults to 5.
 *  }
 * @returns {*}
 */

const BaseModal = (props) => {
  Modal.setAppElement(document.getElementById("root"));

  return (
    <Modal
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
      onRequestClose={props.onRequestClose}
      ariaHideApp={true} // for accessibility
      contentLabel={props.contentLabel || "A Modal"}
      style={{
        overlay: {
          backgroundColor: props.backgroundColor || "rgba(41,42,68, 0.75)",
          zIndex: props.zIndex || 1,
        },
        content: {
          padding: "0",
          left: "0px", // overrides default value of 40px
          right: "0px", // overrides default value of 40px
          margin: "auto",
          width: props.width,
          top: props.top || "40px",
          bottom: props.bottom || null,

          minWidth: props.minWidth,
          maxWidth: props.maxWidth,
          maxHeight: props.maxHeight || null,
        },
      }}
    >
      {props.children}
    </Modal>
  );
};

export default BaseModal;

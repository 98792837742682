import React from "react";
import { MainInput } from "../MeetingStyles";
import { isSampleMeeting } from "../../../../../utility";

class MeetingNameForm extends React.Component {
  state = {
    name: this.props.meetingName,
  };

  submitHandler = (event) => {
    if (this.state.name) {
      this.props.meetingNameUpdateHandler(this.state.name);
    }
  };

  changeHandle = (event) => {
    let updatedFormValues = {
      name: this.state.name,
    };
    updatedFormValues["name"] = event.target.value;
    this.setState(updatedFormValues);
  };

  inputIsReadOnly = () => {
    return isSampleMeeting(this.props.meetingId) || !this.props.userIsHost;
  };

  render() {
    return (
      <form onSubmit={this.submitHandler} onBlur={this.submitHandler}>
        <MainInput
          style={{ cursor: "pointer" }}
          title={this.state.name}
          placeholder={this.props.meetingName}
          type="text"
          value={this.state.name}
          onChange={(event) => {
            this.changeHandle(event);
          }}
          readOnly={this.inputIsReadOnly()}
          required
        />
      </form>
    );
  }
}

export default MeetingNameForm;

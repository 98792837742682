import React, { Component } from "react";
import styled from "styled-components";
import GradientHybridModalNarrow from "../UI/Modals/Hybrid/GradientHybridModalNarrow";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../store/actions";
import { loadStripe } from "@stripe/stripe-js";
import Input from "../UI/Forms/Input";
import Icon from "../UI/Icon/Icon";
import SwitchButton from "../UI/Buttons/SwitchButton";
import {
  CARD_INVALID_ERROR,
  PLAN_NOT_SELECTED_ERROR,
  PAYMENT_FAILED_ERROR,
  PAYMENT_SUCCESS,
  PAYMENT_PROCESSING,
  CANCEL_SUBSCRIPTION_ERROR,
  CANCEL_SUBSCRIPTION_SUCCESS,
  TRIAL_ACTIVATED,
  PLAN_CHANGED_SUCCESSFULLY,
} from "../../constants";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ModalBody = styled.div``;

// Styled components in React

// Styled heading
const SubscribeHeading = styled.h1`
  color: #333;
  text-align: center;
  margin-top: 50px;
`;

// Styled description
const SubscribeDescription = styled.p`
  color: #555;
  text-align: center;
`;

// Styled pricing table container
const PricingTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
`;

// Styled pricing card

const Card = styled.div`
  background-color: ${(props) =>
    props.status === "PURCHASED"
      ? "#1111"
      : props.status === "SELECTED"
        ? "lightblue"
        : "white"};
  border: ${(props) =>
    props.status === "PURCHASED"
      ? "1px solid lightblue"
      : props.status === "SELECTED"
        ? ""
        : "white"};
  width: 300px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  align-items: center;
  text-align: left;
  border-radius: 5px;
  height: fit-content;
  min-height: 78vh;

  position: relative;

  h3 {
    color: #333;
  }

  p {
    color: #555;
  }

  span {
    display: block;
    margin-bottom: 5px;
  }

  button {
    background-color: #764ef2;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
    margin-top: auto;
    width: 100%;

    &:hover {
      background-color: #a38bed;
    }
  }
`;

const StyledCardForm = styled.div`
  width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #a4a4a4;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;

  font-weight: bold;
`;
const LargeLabel = styled.label`
  display: block;
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 18px;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 4px;
  align-items: center;
  padding-left: 3px;
`;

const CardInputExpiry = styled.div`
  width: 100%;

  font-size: 16px;
  padding: 8px;
`;
const CardInputCVC = styled.div`
  width: 100%;
  font-size: 16px;
  padding: 8px;
`;
const CardInput = styled.div`
  width: 100%;
  font-size: 16px;
  padding: 8px;
`;
const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #a4a4a4; /* You can customize the color */
  margin: 10px 0; /* Adjust margin as needed */
`;
const StyledButton = styled.button`
  background-color: #764ef2;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  margin-top: 10px;

  &:hover {
    background-color: #a38bed;
  }
`;

const InfoSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Value = styled.p`
  margin-left: 10px;
`;
const InlineWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const IconWrapper = styled.div`
  width: 10px;
  margin: 0px 5px;
`;

const Feature = styled.div`
  display: flex;
  width: 100%;
  text-align: left;
  gap: 10px;
  font-size: 0.9rem;
`;

class StripeModal extends Component {
  state = {
    billingName: "JOHN DOE",
    selectedPlan:
      this.props.preffered_plan ===
      `${process.env.REACT_APP_FREE_PLAN_PRICE_ID}`
        ? null
        : this.props.preffered_plan,
    // selectedPlan: "price_1OLVInLwOcfWtrog2Y0FX4kT",
    // this stores the price id of the plan
    // selectedProduct: "", // this stores the product id of the product, product may have to plans yearly or monthly
    stripe: "",
    PricingCards: [],
    error: null,

    // // preffered_plan:
    // //   this.props.preffered_plan == `${process.env.REACT_APP_FREE_PLAN_PRICE_ID}`
    // //     ? null
    // //     : this.props.preffered_plan,
    // preffered_plan: "",
    currentProductIndex: 0,
    loadingPage: true,
    cardNumberElement: null,
    selectedPlanAmount: null,
    loading: false,
    options: {
      // passing the client secret obtained from the server
      clientSecret: "",
    },
    currentSubscription: null,
    cardName: "",
    coupon: "",
    annually: false,
    expandedCards: {},
  };

  // Calls when page is loaded
  // Inside this getCurrentSubscription and populatePricingTable will be called
  // Inside this loadingStripe is called after feteching the details of user's previous data

  getPlanById = (products, priceId) => {
    for (const product of products) {
      const price = product.prices.find((p) => p.id === priceId);
      if (price) {
        return price;
      }
    }
    return null; // Return null if no product found with the given price ID
  };

  planIsSelected = () => {
    const planSelected =
      this.state.selectedPlan &&
      this.state.selectedPlan !== `${process.env.REACT_APP_FREE_PLAN_PRICE_ID}`;
    return planSelected ? "block" : "none";
  };
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/";
    script.async = true;

    document.body.appendChild(script);

    this.props
      .getCurrentSubscription(this.props.accessToken)
      .then(() => {
        this.loadingStripe();
      })
      .catch((error) => {
        console.error("Error Sub Data:", error);
      });
    this.props
      .getAllSubscriptionProducts()
      .then((result) => {
        this.setState({
          PricingCards: this.props.subscriptionProducts?.products,
        });

        // This below we are checking to switch button state based on if preferred plan is set or if current plan is yearly
        const preffered_plan = this.getPlanById(
          this.props.subscriptionProducts?.products,
          this.state.selectedPlan
        );

        const CurrentPurchasedPlan = this.getPlanById(
          this.props.subscriptionProducts?.products,
          this.props.currentSubscription?.subscription?.plan?.id
        );

        if (preffered_plan?.recurring_interval === "year") {
          this.setState({
            annually: true,
            selectedPlanAmount: preffered_plan.unit_amount,
          });
        } else if (CurrentPurchasedPlan?.recurring_interval === "year") {
          this.setState({
            annually: true,
          });
        } else {
          this.setState({ selectedPlanAmount: preffered_plan.unit_amount });
        }
      })
      .catch((err) => {});
  }
  async componentDidUpdate(prevProps, prevState) {
    // This will run whenever props or state change
    const { selectedPlan } = this.state;
    // Check if the dependencies have changed
    if (selectedPlan !== prevState.selectedPlan) {
      await this.loadingStripe();
    }
  }
  //this methode loads the stripeObject from env
  //displayCardsElement is called from this
  loadingStripe = async () => {
    const stripeobject = await loadStripe(
      process.env.REACT_APP_STRIPE_PUBLIC_KEY
    );
    this.setState({ stripe: stripeobject, loadingPage: false });
    this.displayCardElement(stripeobject);
  };

  //--------------------------------- For mounting Private stripe element(cardNumber,cardExpiry,cardCVC on DOM elements) ------------------------------------------
  displayCardElement = async (stripeobject) => {
    // Create an instance of Elements and ElementsProvider

    const elements = stripeobject.elements();

    // Create a cardNumberElement and mount it to a DOM element

    const cardNumber = elements.create("cardNumber");
    const cardExpiry = elements.create("cardExpiry");
    const cardCvc = elements.create("cardCvc");

    this.setState({ cardNumberElement: cardNumber });

    if (
      document.getElementById("cardNumber") &&
      document.getElementById("cardExpiry") &&
      document.getElementById("cardCvc")
    ) {
      // Mounting stripe CardNumber Element to the div with id cardNumber, similarly for cardCvc, cardExpiry
      cardNumber.mount("#cardNumber");
      cardExpiry.mount("#cardExpiry");
      cardCvc.mount("#cardCvc");
      console.log("COMPONENT MOUNT SUCCESSFULLY");
    } else {
      console.log(
        "Stripe Elements not Mounted SUCCESFULLY",
        document.getElementById("cardNumber")
      );
    }
  };

  //--------------------------------- If user is doing first time with card ------------------------------------------
  payWithCard = async (stripe, card, clientSecret) => {
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: card,
      },
    });

    if (result.error) {
      this.setState({ loading: false });

      toast.error(PAYMENT_FAILED_ERROR);
    } else {
      this.setState({ loading: false });
      toast.success(PAYMENT_SUCCESS);
      this.props
        .getCurrentSubscription(this.props.accessToken)
        .then(() => {})
        .catch((error) => {
          console.error("Error Sub Data:", error);
        });
      this.props.closeStripeModal();
      window.location.reload(false);
    }
  };

  //--------------------------------- If User Card is already integrated ------------------------------------------
  stripeConfirmPaymentWithoutCard = async (stripe, clientSecret) => {
    const result = await stripe.confirmPayment({
      clientSecret: clientSecret,
      redirect: "if_required",
    });
    if (result.error) {
      this.setState({ loading: false });
      toast.error(PAYMENT_FAILED_ERROR);
    } else {
      this.setState({ loading: false });
      toast.success(PAYMENT_SUCCESS);
      this.props
        .getCurrentSubscription(this.props.accessToken)
        .then(() => {})
        .catch((error) => {
          console.error("Error Sub Data:", error);
        });
      this.props.closeStripeModal();
      window.location.reload(false);
    }
  };

  //--------------------------------- Cancelling Subscription ------------------------------------------
  cancelSubscription = async () => {
    try {
      await this.props.cancelSubscription(this.props.accessToken);

      this.setState({ loading: false });
      toast.success(CANCEL_SUBSCRIPTION_SUCCESS);
      this.props
        .getCurrentSubscription(this.props.accessToken)
        .then(() => {})
        .catch((error) => {
          console.error("Error Sub Data:", error);
        });
      this.props.closeStripeModal();
      window.location.reload(false);
    } catch (error) {
      toast.error(CANCEL_SUBSCRIPTION_ERROR);
    }
  };

  //--------------------------------- Initiating checkout by creating payment intent ------------------------------------------
  initiateCheckout = async () => {
    toast.info(PAYMENT_PROCESSING);

    let paymentParams = {
      price_id: this.state.selectedPlan,
      coupon_code: this.state.coupon ? this.state.coupon : undefined,
    };
    if (
      !this.props.currentSubscription?.customer?.invoice_settings
        ?.default_payment_method
        ? true
        : false
    ) {
      try {
        const paymentMethodResult = await this.state.stripe.createPaymentMethod(
          {
            type: "card",
            card: this.state.cardNumberElement,
          }
        );

        paymentParams["payment_method"] = paymentMethodResult.paymentMethod.id;
      } catch (error) {
        this.setState({ loading: false });
        toast.error(CARD_INVALID_ERROR);
        return;
      }
    }
    try {
      await this.props
        .createPaymentIntent(this.props.accessToken, paymentParams)
        .then((result) => {
          if (result.data.error) {
            this.setState({ loading: false });
            toast.error(result.data.error);
            return;
          }
          var clientSecret = result.data.clientSecret;
          if (!clientSecret && this.state.selectedPlanAmount === 0) {
            this.setState({ loading: false });
            toast.success(TRIAL_ACTIVATED);
            this.props
              .getCurrentSubscription(this.props.accessToken)
              .then(() => {
                this.props.closeStripeModal();
                window.location.reload(false);
              })
              .catch((error) => {
                console.error("Error Sub Data:", error);
              });
          } else if (!clientSecret && result.data.is_subscription_success) {
            this.setState({ loading: false });
            toast.success(PLAN_CHANGED_SUCCESSFULLY);
            this.props
              .getCurrentSubscription(this.props.accessToken)
              .then(() => {
                this.props.closeStripeModal();
                window.location.reload(false);
              })
              .catch((error) => {
                console.error("Error Sub Data:", error);
              });
          } else if (
            this.props.currentSubscription?.customer?.invoice_settings
              ?.default_payment_method
              ? true
              : false
          ) {
            this.stripeConfirmPaymentWithoutCard(
              this.state.stripe,
              clientSecret
            );
          } else {
            this.payWithCard(
              this.state.stripe,
              this.state.cardNumberElement,
              clientSecret
            );
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          toast.error(PAYMENT_FAILED_ERROR);
        });
    } catch {
      this.setState({ loading: false });
      toast.error(PAYMENT_FAILED_ERROR);
    }
  };

  //--------------------------------- Getting Stripe Subscription Portal ------------------------------------------
  getPortal = async () => {
    try {
      await this.props
        .getCustomerPortal(this.props.accessToken)
        .then((result) => {
          const url = result.data.url;
          window.open(url, "_blank");
        })
        .catch((err) => {});
    } catch (error) {
      console.error("Error Sub Data:", error);
    }
  };
  //--------------------------------- Free Trial Subscription ------------------------------------------
  freeTrialSubscription = async () => {
    try {
      await this.props.freeTrialSubscription(this.props.accessToken);

      this.setState({ loading: false });
      toast.success(PAYMENT_SUCCESS);
      this.props
        .getCurrentSubscription(this.props.accessToken)
        .then(() => {
          this.props.closeStripeModal();
          window.location.reload(false);
        })
        .catch((error) => {
          console.error("Error Sub Data:", error);
        });
    } catch (error) {
      toast.error(CANCEL_SUBSCRIPTION_ERROR);
    }
  };
  toggleExpand = (index) => {
    this.setState((prevState) => ({
      expandedCards: {
        ...prevState.expandedCards,
        [index]: !prevState.expandedCards[index], // Toggle expansion state for the clicked card
      },
    }));
  };
  render() {
    const PricingCards = this.state.PricingCards;

    const currentProductPriceId =
      this.props.currentSubscription?.subscription?.plan?.id;
    const isPaymentMethod = this.props.currentSubscription?.customer
      ?.invoice_settings?.default_payment_method
      ? true
      : false;

    const monthlyProducts = PricingCards.filter((product) =>
      product.prices.some((price) => price.recurring_interval === "month")
    ).map((product) => ({
      ...product,
      prices: product.prices.filter(
        (price) => price.recurring_interval === "month"
      ),
    }));

    const annualProducts = PricingCards.filter((product) =>
      // Filter to get only the products that have prices with recurring_interval as "year"
      product.prices.some((price) => price.recurring_interval === "year")
    ).map((product) => ({
      ...product,
      // For each filtered product, update its prices to only include those with recurring_interval as "year"
      prices: product.prices.filter(
        (price) => price.recurring_interval === "year"
      ),
    }));

    const cardPricesDetails = (card) =>
      card.prices.map((price) => {
        if (this.state.annually) {
          if (price.recurring_interval === "year") {
            return price.id;
          } else {
            return null;
          }
        } else {
          if (price.recurring_interval === "month") {
            return price.id;
          } else {
            return null;
          }
        }
      });

    const CardFeatures = (card, index) => {
      const displayedFeatures =
        this.state.expandedCards[index] || false
          ? card.features
          : card.features.slice(0, 6); // Show only first 3 features if not expanded

      return (
        <div>
          {displayedFeatures.map((feature, index) => (
            <React.Fragment key={index}>
              <br />
              <Feature>
                {" "}
                <IconWrapper>
                  <Icon name="tick" alt="Tick" />
                </IconWrapper>{" "}
                {feature.name}
              </Feature>
            </React.Fragment>
          ))}
          {card.features.length > 6 && ( // Show 'See All' button only if there are more than 3 features
            <div
              onClick={() =>
                this.setState((prevState) => ({
                  expandedCards: {
                    ...prevState.expandedCards,
                    [index]: !prevState.expandedCards[index], // Toggle expansion state for the clicked card
                  },
                }))
              }
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
                width: "100%",
                textAlign: "center",
                position: "absolute",
                bottom: "0px",
                margin: "0px -20px",
                marginBottom: "10px",
              }}
            >
              <br></br>
              {this.state.expandedCards[index] || false
                ? "See Less"
                : "See All"}
            </div>
          )}
        </div>
      );
    };

    const CardComponent = (status, index, card) => (
      <Card status={status}>
        <h3>{card.name}</h3>
        <p>{card.description}</p>
        {card.prices.map((price) => {
          let isPricePurchase = false;

          if (price.id === currentProductPriceId) {
            isPricePurchase = true;
          }

          return (
            <>
              <div style={{ width: "100%" }}>
                <p style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ fontSize: "1.5rem", fontWeight: "700" }}>
                    $ {(price.unit_amount / 100).toFixed(2).split(".")[0]}.
                  </span>{" "}
                  <span
                    style={{
                      fontSize: "0.8rem",
                      verticalAlign: "top",
                      fontWeight: "600",
                      marginBottom: "auto",
                      marginTop: "4px",
                    }}
                  >
                    {(price.unit_amount / 100).toFixed(2).split(".")[1]}
                  </span>{" "}
                  / {price.recurring_interval}
                </p>
                {isPricePurchase ? (
                  <div
                    style={{
                      marginTop: "10px",
                      textAlign: "center",
                      backgroundColor: "rgb(57, 208, 197)",
                      color: "white",
                      padding: "10px",
                      borderRadius: "5px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Current Plan
                  </div>
                ) : price.unit_amount === 0 ? (
                  <button
                    data-product-id={price.id}
                    onClick={() => {
                      this.setState({
                        selectedPlan: price.id,

                        selectedPlanAmount: price.unit_amount,
                        cardName: card.name,
                      });
                    }}
                  >
                    {this.state.selectedPlan === price.id
                      ? "Selected"
                      : "Select"}
                  </button>
                ) : this.state.currentProductIndex > index &&
                  !this.state.annually ? (
                  <div
                    style={{
                      marginTop: "10px",
                      textAlign: "center",
                      backgroundColor: "#6f6f6f",
                      color: "white",
                      padding: "5px",
                      borderRadius: "5px",
                      fontSize: "12px",
                    }}
                  >
                    Contact Support for Downgrade
                  </div>
                ) : (
                  <button
                    data-product-id={price.id}
                    onClick={() => {
                      this.setState({
                        selectedPlan: price.id,

                        selectedPlanAmount: price.unit_amount,
                        cardName: card.name,
                      });
                    }}
                  >
                    {this.state.selectedPlan === price.id
                      ? "Selected"
                      : "Select"}
                  </button>
                )}
              </div>
            </>
          );
        })}

        {
          <div>
            <br />
            This includes:
            <br />
            {CardFeatures(card, index)}
          </div>
        }
        <br />
      </Card>
    );

    const pricingTable = (
      <PricingTable>
        {/* ? Below this ternary operator is checking if switch Button state to show only annual or monthly products */}
        {(this.state.annually ? annualProducts : monthlyProducts).map(
          (card, index) => {
            // card is each product whose Card will be shown
            let status = "PENDING";
            let cardPriceDetails = cardPricesDetails(card);

            if (cardPriceDetails[0].id === currentProductPriceId) {
              status = "PURCHASED";
              if (this.state.currentProductIndex !== index) {
                this.setState({ currentProductIndex: index });
              }
            } else if (cardPriceDetails[0].id === this.state.selectedPlan) {
              status = "SELECTED";
            }
            //Below commented code is basically filtering based on preffered_plan
            // if (
            //   this.state.preffered_plan &&
            //   !cardPricesDetails(card).includes(this.state.preffered_plan)
            // ) {
            //   return null;
            // }

            return CardComponent(status, index, card);
          }
        )}
      </PricingTable>
    );

    return (
      <GradientHybridModalNarrow
        isOpen={this.props.isOpen}
        onRequestClose={() => {
          this.props.onRequestClose();
        }}
        shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
        padding={"20px"}
        maxHeight={"90vh"}
        width={"90vw"}
      >
        <ModalBody>
          {/* ? Below this ternary operator is checking whether page is loaded or loading */}
          {this.state.loadingPage ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              Loading ...
            </div>
          ) : (
            <>
              <SubscribeHeading>Subscribe to Our Service</SubscribeHeading>
              <SubscribeDescription>
                Please select a plan and scroll down to complete your
                subscription.
              </SubscribeDescription>

              <SwitchButton
                option1="Monthly"
                option2="Annually"
                highlightText="Save 15%!"
                onClick={() =>
                  this.setState({ annually: !this.state.annually })
                }
                buttonState={this.state.annually}
              />

              {PricingCards && pricingTable}
              {/* ? Below this ternary operator is checking If user select free plan we will not ask to enter card details we just show him/her the button to do it. */}
              {this.state.selectedPlan ===
                `${process.env.REACT_APP_FREE_PLAN_PRICE_ID}` ||
              this.state.currentSubscription?.subscription?.plan?.id ===
                `${process.env.REACT_APP_FREE_PLAN_PRICE_ID}` ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <StyledButton
                    onClick={() => {
                      // pay logic

                      if (!this.state.selectedPlan) {
                        toast.error(PLAN_NOT_SELECTED_ERROR);
                      } else {
                        this.setState({ loading: true });
                        this.freeTrialSubscription();
                      }
                    }}
                    disabled={this.state.loading}
                    style={
                      this.state.loading
                        ? {
                            backgroundColor: "#ccc",
                            opacity: 0.5,
                            cursor: "progress",
                          }
                        : {}
                    }
                    id="checkout-button"
                  >
                    Start Free Plan
                  </StyledButton>
                </div>
              ) : (
                <StyledCardForm style={{ display: this.planIsSelected() }}>
                  <LargeLabel htmlFor="cardNumber">
                    Payment Card Details
                  </LargeLabel>

                  {!isPaymentMethod ? (
                    <>
                      <FormGroup>
                        <InputContainer>
                          <Icon name="credit_card"></Icon>
                          <CardInput id="cardNumber"></CardInput>
                        </InputContainer>
                      </FormGroup>

                      <InlineWrapper>
                        <FormGroup style={{ width: "50%" }}>
                          <InputContainer>
                            <Icon name="card_expiry"></Icon>
                            <CardInputExpiry id="cardExpiry" />
                          </InputContainer>
                        </FormGroup>
                        <FormGroup style={{ width: "30%" }}>
                          <InputContainer>
                            <Icon name="card_cvv"></Icon>
                            <CardInputCVC id="cardCvc" />
                          </InputContainer>
                        </FormGroup>
                      </InlineWrapper>
                    </>
                  ) : (
                    <>
                      <p>
                        Your existing card is **** **** ****{" "}
                        {this.props.currentSubscription.card_details.last4}{" "}
                        {this.props.currentSubscription.card_details.brand}
                      </p>
                    </>
                  )}

                  <HorizontalLine />
                  <InfoSection>
                    <Label>Selected plan:</Label>
                    <Value>{this.state.cardName}</Value>
                  </InfoSection>
                  <InfoSection>
                    <Label>Plan price:</Label>
                    <Value>
                      <b>$</b>{" "}
                      {(this.state.selectedPlanAmount / 100).toFixed(2)}{" "}
                    </Value>
                  </InfoSection>
                  <InfoSection>
                    <Label>Coupon code:</Label>
                    <Input
                      placeholder="Enter discount code"
                      type="text"
                      onChange={(e) => {
                        this.setState({ coupon: e.target.value });
                      }}
                      style={{ width: "195px" }}
                    />
                  </InfoSection>
                  <InfoSection>
                    <StyledButton
                      style={{ backgroundColor: "rgb(57, 208, 197)" }}
                      onClick={() => {
                        this.getPortal();
                      }}
                    >
                      Manage Subscription
                    </StyledButton>
                    <StyledButton
                      onClick={() => {
                        // pay logic

                        if (!this.state.selectedPlan) {
                          toast.error(PLAN_NOT_SELECTED_ERROR);
                        } else {
                          this.setState({ loading: true });
                          this.initiateCheckout();
                        }
                      }}
                      disabled={this.state.loading}
                      style={
                        this.state.loading
                          ? {
                              backgroundColor: "#ccc",
                              opacity: 0.5,
                              cursor: "progress",
                            }
                          : {}
                      }
                      id="checkout-button"
                    >
                      Submit Payment
                    </StyledButton>
                  </InfoSection>
                  {isPaymentMethod && (
                    <h6>
                      To add a new card, click Manage Subscription. To cancel
                      your subscription, contact support@capturemymeeting.com.
                    </h6>
                  )}
                </StyledCardForm>
              )}
              <div
                style={{
                  width: "400px",
                  margin: "auto",
                  fontSize: "0.7rem",
                  fontWeight: "400",
                  marginTop: "8px",
                }}
              >
                *We will cap minutes to allow for fair usage and to prevent
                abuse. Contact{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:support@capturemymeeting.com"
                >
                  support@capturemymeeting.com
                </a>{" "}
                if you need more minutes.
              </div>
            </>
          )}
        </ModalBody>
      </GradientHybridModalNarrow>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCurrentSubscription: (accessToken) =>
      dispatch(actions.getCurrentSubscription(accessToken)),
    getAllSubscriptionProducts: () =>
      dispatch(actions.getAllSubscriptionProducts()),
    createPaymentIntent: (accessToken, paymentParams) =>
      dispatch(actions.createPaymentIntent(accessToken, paymentParams)),
    getCustomerPortal: (accessToken) =>
      dispatch(actions.getCustomerPortal(accessToken)),
    closeStripeModal: () => dispatch(actions.closeStripeModal()),
    cancelSubscription: (accessToken) =>
      dispatch(actions.cancelSubscription(accessToken)),
    freeTrialSubscription: (accessToken) =>
      dispatch(actions.freeTrialSubscription(accessToken)),
  };
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    preffered_plan: state.user.preffered_plan,
    currentSubscription: state.subscription.currentSubscription,
    subscriptionProducts: state.subscription.subscriptionProducts,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StripeModal)
);

import React from "react";

import CMMLogo from "../../../assets/images/company_logos/CMM_Logo_BugOnly_Light.svg";

const DarkLogoBug = (props) => {
  return (
    <picture>
      <img
        src={CMMLogo}
        alt="CaptureMyMeeting"
        width={props.width}
        height={props.height}
      />
    </picture>
  );
};

export default DarkLogoBug;

import React from "react";
import styled from "styled-components";

const GetMediaAccessTemp = styled.div`
  padding: 13% 0px 0px 8%;
  // 801px keeps the text wide enough for it to fit on two lines when possible.
  // 8% is added to the width to accommodate for 8% padding on the left.
  max-width: calc(801px + 8%);
`;

const MainText = styled.div`
  margin-bottom: 17px;
  font-size: 48px;
  line-height: 62px;
`;

const SubText = styled.div`
  margin-bottom: 17px;
  font-size: 30px;
  line-height: 42px;
`;

const RecoverText = styled.div`
  width: 699px;
  font-size: 27px;
  font-weight: 100;
  margin-top: 35px;
`;

// Based on how things go, we might remove GetUserMedia and stick with this approach.
const GetUserMediaTemp = (props) => {
  return (
    <GetMediaAccessTemp>
      <SubText>No one can {props.userIsHost ? "see or" : ""} hear you.</SubText>
      <MainText>
        Click <strong>allow</strong> for the ability to speak{" "}
        {props.userIsHost ? "and share your whiteboard" : ""} with everyone.
      </MainText>
      <RecoverText>
        Don’t see it? Visit your browser <strong>settings</strong> and{" "}
        <strong>refresh</strong> the page .
      </RecoverText>
    </GetMediaAccessTemp>
  );
};

export default GetUserMediaTemp;

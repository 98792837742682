import React, { Component } from "react";
import styled from "styled-components";

import * as actions from "../../../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ToggleSetting from "../ToggleSetting/ToggleSetting.js";

const Settings = styled.div`
  display: flex;
  flex-direction: column;
  color: #a0a0a0;
`;

const SettingsTitle = styled.div`
  display: flex;
  color: #a0a0a0;
  font-weight: bolder;
  text-decoration: underline;
  margin: 1% 1% 1% 1%;
`;

const AutoRecordSetting = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const AutoRecordSettingSubtext = styled.div`
  margin-bottom: 5px;
  margin-left: 10px;
  text-align: left;
  font-size: 12px;
`;

class WebexSettings extends Component {
  state = {
    autoShareChecked: false,
    autoRecordChecked: false,
  };

  componentDidMount() {
    console.log("IntegrationsPanel component did mount");
    if (this.props.user && this.props.user.webex_auto_share_on !== null) {
      this.setState({ autoShareChecked: this.props.user.webex_auto_share_on });
    }
    if (this.props.user && this.props.user.webex_auto_record_on !== null) {
      this.setState({
        autoRecordChecked: this.props.user.webex_auto_record_on,
      });
    }
  }

  onAutoShareToggleChange = () => {
    this.props
      .updateWebexUserSettings(this.props.accessToken, this.props.user.userId, {
        auto_share: !this.state.autoShareChecked,
      })
      .then(() => {
        this.setState({ autoShareChecked: !this.state.autoShareChecked });
      })
      .catch((error) => {
        alert(
          "There was problem while updating your Webex integration auto_share settings. Please contact support@capturemymeeting.com."
        );
      });
  };

  onAutoRecordToggleChange = () => {
    this.props
      .updateWebexUserSettings(this.props.accessToken, this.props.user.userId, {
        auto_record: !this.state.autoRecordChecked,
      })
      .then(() => {
        this.setState({ autoRecordChecked: !this.state.autoRecordChecked });
      })
      .catch((error) => {
        alert(
          "There was problem while updating your Webex integration auto_record settings. Please contact support@capturemymeeting.com."
        );
      });
  };

  render() {
    return (
      <Settings>
        <SettingsTitle>Settings:</SettingsTitle>
        <AutoRecordSetting>
          <ToggleSetting
            settingText={"Automatically record my meetings"}
            settingTooltipText={
              "If on, each meeting that you host will automatically start recording as soon as you join the meeting. If off, you must remember to record your meeting to the cloud if you want CaptureMyMeeting to process it."
            }
            settingChecked={this.state.autoRecordChecked}
            onToggleChange={this.onAutoRecordToggleChange}
          ></ToggleSetting>
          <AutoRecordSettingSubtext>
            Requires Webex Assistant ON for
            <a
              style={{ margin: "0px 3px 0px 3px" }}
              href="https://help.webex.com/en-us/article/nwl9xii/Enable-Webex-Assistant-for-Meetings-in-Control-Hub"
              target="_blank"
              rel="noopener noreferrer"
            >
              org
            </a>
            &
            <a
              style={{ margin: "0px 0px 0px 3px" }}
              href="https://help.webex.com/en-us/article/4pj83q/Set-your-meeting-scheduling-preferences"
              target="_blank"
              rel="noopener noreferrer"
            >
              user
            </a>
            , or
            <a
              style={{ margin: "0px 3px 0px 3px" }}
              href="https://help.webex.com/en-us/article/lzi8h2/Show-or-hide-automated-closed-captions-during-a-Webex-meeting-or-webinar"
              target="_blank"
              rel="noopener noreferrer"
            >
              Closed Captions
            </a>
            ON.
          </AutoRecordSettingSubtext>
          <AutoRecordSettingSubtext>
            Not available on new Webex Suite. Remember to
            <a
              style={{ margin: "0px 3px 0px 3px" }}
              href="https://help.webex.com/en-us/article/n62735y/Webex-%7C-Record-a-meeting-or-webinar"
              target="_blank"
              rel="noopener noreferrer"
            >
              record to the cloud
            </a>
            .
          </AutoRecordSettingSubtext>
        </AutoRecordSetting>
        <ToggleSetting
          settingText={"Automatically share with participants"}
          settingTooltipText={
            "If on, then for each meeting you host, all processed meeting content (notes, transcripts, recordings, searchable content) will be automatically shared in a read-only state with all participants who joined your meeting, as soon as your meeting recording has finished processing."
          }
          settingChecked={this.state.autoShareChecked}
          onToggleChange={this.onAutoShareToggleChange}
        ></ToggleSetting>
      </Settings>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateWebexUserSettings: (accessToken, userId, settingsDict) =>
      dispatch(
        actions.updateWebexUserSettings(accessToken, userId, settingsDict)
      ),
  };
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    user: state.user,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WebexSettings)
);

import React from "react";
import styled from "styled-components";
import moment from "moment/moment";

const ResultContainer = styled.div`
  display: flex;
  height: 75px;
  align-items: center;
  border-bottom: #dee5ea solid 1px;
  padding: 0 0.5% 0 2.5%;
`;

const MeetingName = styled.div`
  flex-basis: 25%;
`;

const MatchedContent = styled.div`
  font-size: 14px;
  flex-basis: 67%;
`;

const Timestamp = styled.div`
  text-decoration: underline;
  flex-basis: 8%;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
`;

/**
 *
 * @param meetingName - name of the meeting that had the match.
 * @param meetingId - meeting id of the meeting that had a match.
 * @param matchedContent - the actual matched content itself.
 * @param time - time of the match
 * @param wordsInQuery - words that were part of the query. Split on dot, comma, and space. Lower-cased.
 * @returns {*}
 * @constructor
 */
const VoiceContentSearchResult = ({
  meetingName,
  meetingId,
  matchedContent,
  time,
  wordsInQuery,
  onOpenMeetingModal,
}) => {
  const seconds = time.split(".")[0];
  const timestamp = moment("2020-01-01 00:00:00").add(
    moment.duration(seconds, "seconds")
  );
  const timestampFormatted =
    timestamp.hour() !== 0
      ? timestamp.format("H:mm:ss")
      : timestamp.format("m:ss");
  // TODO: potentially optimize text bolding in the future by utilizing a dictionary of wordsInQuery
  //        and checking against that dictionary perhaps?
  const matchedContentBolded = matchedContent.split(" ").map((word, index) => {
    if (wordsInQuery.some((x) => word.toLowerCase().startsWith(x))) {
      // don't add space for the first word in the result.
      return index === 0 ? <strong>{word}</strong> : <strong> {word}</strong>;
    } else {
      return " " + word;
    }
  });

  return (
    <ResultContainer>
      <MeetingName>{meetingName}</MeetingName>
      <MatchedContent>"{matchedContentBolded}"</MatchedContent>
      <Timestamp onClick={onOpenMeetingModal}>{timestampFormatted}</Timestamp>
    </ResultContainer>
  );
};

export default VoiceContentSearchResult;

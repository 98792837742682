import React from "react";
import moment from "moment";
import styled from "styled-components";

import { SubText } from "../MeetingStyles";
import { media } from "../../../../UI/StyleUtils";
import MeetingNameForm from "../EditMeetingNameForm/MeetingNameForm";

const NameDurationContainer = styled.div`
  flex-basis: 60%;

  ${media.gtXSm`
      flex-basis: 40%;
    `}

  ${media.gtSm`
        flex-basis: 24%;
    `}
`;

const NameDuration = (props) => {
  // Note function assumes start time and end time are valid and follow ISO standard
  const extractDuration = (startTime, endTime) => {
    if (null == endTime) return "In session";
    else {
      const stMoment = moment(startTime);
      const etMoment = moment(endTime);
      const diff = etMoment.diff(stMoment, "seconds");
      const duration = moment.duration(diff, "seconds").humanize();
      return capitalizeFirstLetter(duration);
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <NameDurationContainer>
      <MeetingNameForm
        meetingId={props.id}
        meetingName={props.name}
        userIsHost={props.userIsHost}
        meetingNameUpdateHandler={props.meetingNameUpdateHandler}
      />
      <SubText>{extractDuration(props.startTime, props.endTime)}</SubText>
    </NameDurationContainer>
  );
};

export default NameDuration;

import * as actionTypes from "../actions/actionTypes";

const initialState = {
  viewModal: {
    display: false,
  },
  searchModal: {
    display: false,
  },
  meetingId: null,
  data: {},
  error: null,
  loading: false,
};

const meetingModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_MEETING_MODAL:
      return {
        ...state,
        viewModal: {
          ...state.viewModal,
          // open meeting modal for this particular meeting. Setting this is important for mobile design when
          // no videoElement data is there to be fetched using the api.
          display: action.modalType === "view" ? true : state.viewModal.display,
        },
        searchModal: {
          ...state.searchModal,
          display:
            action.modalType === "search" ? true : state.searchModal.display,
        },
        meetingId: action.meetingId,
      };
    case actionTypes.CLOSE_MEETING_MODAL:
      return {
        ...state,
        viewModal: {
          ...state.viewModal,
          display:
            action.modalType === "view" ? false : state.viewModal.display,
        },
        searchModal: {
          ...state.searchModal,
          display:
            action.modalType === "search" ? false : state.searchModal.display,
        },
        meetingId: null,
        data: {},
      };
    case actionTypes.GET_MEETING_RECORDING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_MEETING_RECORDING_SUCCESS:
      return {
        ...state,
        data: action.data,
        meetingId: action.meetingId,
        loading: false,
      };
    case actionTypes.GET_MEETING_RECORDING_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default meetingModalReducer;

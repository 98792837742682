import React from "react";
import styled from "styled-components";

import GradientBorder from "../../UI/GradientBorder";
import { media } from "../../UI/StyleUtils";

const StatsContainer = styled.div`
  display: None;
  ${media.gtSm`
        display: None;
        height: 235px;
        width: 96%;
        margin: 10px auto;
        border-radius: 5px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px 0 rgba(12,0,51,0.1);
        overflow: hidden;
    `}
`;

const Content = styled.div`
  padding: 0px 10px 10px 10px;
  overflow: visible;
`;

const MeetingStats = (props) => {
  return (
    <StatsContainer>
      <GradientBorder />
      <Content>
        <h3>Meeting Stats</h3>
      </Content>
    </StatsContainer>
  );
};

export default MeetingStats;

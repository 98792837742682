import React from "react";
import styled from "styled-components";

const Choice = styled.button`
  background-color: ${(props) => (props.filled ? "#764EF2" : "transparent")};
  color: ${(props) => (props.filled ? "white" : "#A0A0A0")};
  width: ${(props) => props.size || "32px"};
  height: ${(props) => props.size || "32px"};
  border-radius: ${(props) => props.size / 2 || "16px"};
  border: 1px solid;
  cursor: pointer;

  :hover {
    background-color: #764ef2;
    color: white;
  }
`;

const Choices = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Hints = styled.div`
  display: flex;
  margin: 5px 0;
  width: 100%;
  justify-content: space-between;
  font-size: 12px;
  opacity: 0.4;
`;

const RatingBar = (props) => {
  return (
    <div className={props.className}>
      <Choices>
        {[...Array(10).keys()].map((value, index) => {
          const rating = value + 1;
          // specify the button type explicitly to ensure it doesn't submit any form
          return (
            <Choice
              type="button"
              key={rating}
              filled={rating === props.selectedRating}
              onClick={(_) => props.onSelect(rating)}
            >
              {rating}
            </Choice>
          );
        })}
      </Choices>
      <Hints>
        <p>Very Poor</p>
        <p>Excellent</p>
      </Hints>
    </div>
  );
};

export default RatingBar;

import React, { Component } from "react";
import styled from "styled-components";

import DateTime from "./DateTime/DateTime";
import NameDuration from "./NameDuration/NameDuration";
import AttendeesBoards from "./AttendeesBoards/AttendeesBoards";
import RecordingAvailability from "./RecordingAvailability/RecordingAvailability";
import ShareButton from "./ShareButton/ShareButton";
import OverFlowMenu from "./OverflowMenu/Overflow";
import { media } from "../../../UI/StyleUtils";
import CheckBox from "../../../UI/Forms/Checkbox";
import { isSampleMeeting, userIsHost } from "../../../../utility";

const MeetingContainer = styled.div`
  height: 100%;
  margin: 0 0.5% 0 2.5%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  ${media.gtSm`
        margin: 0 1.25% 0 2.5%;
    `}
`;

class Meeting extends Component {
  dateTime = () => {
    return <DateTime time={this.props.startTime} />;
  };

  // TODO: Within render function, remove userIsHost check in checkbox logic after adding other non-admin bulk operations.
  userIsHost = () => {
    return userIsHost(this.props.hosts, this.props.userEmail);
  };

  nameDuration = () => {
    return (
      <NameDuration
        id={this.props.id}
        name={this.props.meetingName}
        startTime={this.props.startTime}
        endTime={this.props.endTime}
        userIsHost={this.userIsHost()}
        meetingNameUpdateHandler={this.props.meetingNameUpdateHandler}
      />
    );
  };

  render() {
    return (
      <MeetingContainer>
        {!isSampleMeeting(this.props.id) && this.userIsHost() ? (
          <CheckBox
            // NOTE: Due to mix of redux and react component states, when the following properties are
            //      about to be set for direct meeting links from the home page, certain values from redux state are not available yet and hence local state doesn't have meeting selection data.
            //      It's not needed anyway in such use case, so we don't need to display it. Clicking on it may lead to errors.
            hidden={
              !(
                this.props.meetingSelectedStates &&
                this.props.meetingSelectedStates[
                  this.props.currentPageNumber
                ] &&
                this.props.meetingSelectedStates[this.props.currentPageNumber][
                  this.props.id
                ]
              )
            }
            fontSize="12px"
            required={true}
            isChecked={
              this.props.meetingSelectedStates[this.props.currentPageNumber] &&
              this.props.meetingSelectedStates[this.props.currentPageNumber][
                this.props.id
              ]
                ? this.props.meetingSelectedStates[
                    this.props.currentPageNumber
                  ][this.props.id]["checked"]
                : false
            }
            onChange={(event) => this.props.onSelectMeeting(this.props.id)}
            title="Select Meeting"
          ></CheckBox>
        ) : null}
        {this.dateTime()}
        {this.nameDuration()}
        <AttendeesBoards
          numAttendees={this.props.numAttendees}
          numBoards={this.props.numBoards}
        />
        <RecordingAvailability
          recordingAvailable={this.props.recordingAvailable}
        />
        <ShareButton />
        <OverFlowMenu
          {...this.props}
          dateTime={this.dateTime()}
          nameDuration={this.nameDuration()}
          filterByMeetingHandler={this.props.filterByMeetingHandler}
        />
      </MeetingContainer>
    );
  }
}

export default Meeting;

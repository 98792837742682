import React from "react";

import BaseModal from "../BaseModal";
import GradientBorder from "../../GradientBorder";

/**
 * A simple React Modal component designed for mobile/tablet.
   Utilizes full-screen and a gradient top border.
 *
 * @param props
 * {
 *  isOpen - indicates if the modal should be currently open or not.
 *  onRequestClose - a function that should be called when the modal is closed by the user
 * }
 * @returns JSX component
 * @constructor
 */

// TODO: migrate to using [[GradientHybridModalNarrow]] and deprecate this in future.
const GradientMobileModal = (props) => {
  return (
    <BaseModal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      width={"100%"}
      top={"0%"}
      bottom={"0%"}
      backgroundColor={"rgba(41,42,68, 0.92)"}
      maxWidth={"560px"}
      minWidth={"320px"}
    >
      <GradientBorder />
      {props.children}
    </BaseModal>
  );
};

export default GradientMobileModal;

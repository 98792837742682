import React from "react";
import styled from "styled-components";

import { MainText, SubText } from "../MeetingStyles";
import { media } from "../../../../UI/StyleUtils";

const AttendeesBoardsContainer = styled.div`
  flex-basis: 26%;
  display: none;

  ${media.gtXSm`
        display: block;
    `}
`;

const AttendeesBoards = (props) => {
  const numAttendees = props.numAttendees;
  const numBoards = props.numBoards;

  return (
    <AttendeesBoardsContainer>
      <MainText>
        {"Shared With: " + (numAttendees <= 0 ? 0 : numAttendees - 1)}
      </MainText>
      <SubText style={{ visibility: "hidden" }}>
        {numBoards + " Board" + (numBoards > 1 ? "s" : "") + " shared"}
      </SubText>
    </AttendeesBoardsContainer>
  );
};

export default AttendeesBoards;

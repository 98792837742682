import React from "react";

import CMMLogo from "../../../assets/images/company_logos/CaptureMyMeeting_BugOnly_Lockup_Dark@2x.png";

const LightLogoBug = (props) => {
  return (
    <picture>
      <img
        src={CMMLogo}
        alt="CaptureMyMeeting"
        width={props.width}
        height={props.height}
      />
    </picture>
  );
};

export default LightLogoBug;

import React from "react";
import styled from "styled-components";

const BackDropStyled = styled.div`
  width: 100%;
  height: 100%;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const backdrop = (props) => {
  return props.show ? <BackDropStyled onClick={props.closed} /> : <div></div>;
};

export default backdrop;

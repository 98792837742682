import React from "react";
import MediaQuery from "react-responsive";
import DesktopModal from "../Desktop/DesktopModal";
import MobileModal from "../Mobile/MobileModal";

/**
 * A React Modal component designed for desktop and mobile use.
 *  When in mobile mode: The modal always occupies the full screen.
 *  when in desktop mode: The modal takes the specified position & size.
 *
 * @param props
 * {
 *  isOpen - indicates if the modal should be currently open or not.
 *  onRequestClose: the function to call to close the modal. If null is specified, user will not be allowed to close the modal manually.
 *  mobileTopGradientBorder: whether to include the gradient border on the top for mobile view. Defaults to true.
 *  desktopTopGradientBorder: whether to include the gradient border on the top for desktop view. Defaults to true.
 *  padding: padding to be applied inside the body; Defaults to 8px;
 *  width: modal width. Defaulted to cover the screen from left to right with 40px margins
 *  top: modal position from top of the screen. Defaulted to 40px
 *  bottom: modal end position in relations to the bottom of the screen. Defaulted to only cover the content of the modal.
 *  maxHeight: maximum height for the modal, ex. 90px, or 90vh. Defaults to none.
 *  backgroundColor: the color used for the background shadow. By Default, it's transparent purple, rgba(41,42,68, 0.75).
 * }
 * @returns JSX component
 * @constructor
 */
const HybridModal = (props) => {
  return (
    <MediaQuery query="(min-width: 769px)">
      {(matches) => {
        return matches ? (
          <DesktopModal
            isOpen={props.isOpen}
            onAfterOpen={props.onAfterOpen}
            onRequestClose={props.onRequestClose}
            shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
            maxHeight={props.maxHeight}
            width={props.width}
            top={props.top}
            bottom={props.bottom}
            backgroundColor={props.backgroundColor}
            topGradientBorder={props.desktopTopGradientBorder}
            padding={props.padding}
          >
            {props.children}
          </DesktopModal>
        ) : (
          <MobileModal
            isOpen={props.isOpen}
            onAfterOpen={props.onAfterOpen}
            onRequestClose={props.onRequestClose}
            shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
            topGradientBorder={props.mobileTopGradientBorder}
            padding={props.padding}
          >
            {props.children}
          </MobileModal>
        );
      }}
    </MediaQuery>
  );
};

export default HybridModal;

import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Card from "../../../../components/UI/Card";
import { GradientDiv } from "../../../../components/UI/Styles";

import Input from "../../../../components/UI/Forms/Input";
import Button from "../../../../components/UI/Buttons/LargeButton";

import { BottomPart, CMMLogo, TopMainText } from "./CommonStyles";
import * as actions from "../../../../store/actions/index";

const TopPart = styled.div`
  min-height: 175px; // 407px when social auth is re-added.
  min-width: 320px;
  padding-top: 25px;
  padding-bottom: 25px;
  color: white;
  justify-content: center;
  text-align: center;
`;

const BottomContainer = styled.div`
  min-height: 295px;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
`;

const FormInputContainer = styled.div`
  margin-bottom: 22px;
  margin-top: 22px;
`;

const LoginButton = styled(Button)`
  margin-top: 10px;
`;

const ContactSupport = styled.p`
  margin: 5px 4.5px 15px 4.5px;
  font-size: 14px;
  text-align: center;
  color: rgba(23, 15, 48, 0.51);
`;
const Instructions = styled.p`
  font-size: 14px;
`;

//-------------Forget pass link-----------------

const Error = styled.p`
  color: tomato;
`;

class RequestResetPassword extends Component {
  state = { email: "", emailSent: false, errorMsg: "" };
  changeHandle = (event, inputIdentifier) => {
    var value = null;
    if (inputIdentifier === "email") {
      value = event.target.value;
    }
    if (inputIdentifier === "emailSent" || inputIdentifier === "errorMsg") {
      value = event;
    }
    const updatedFormValues = {
      ...this.state,
    };
    updatedFormValues[inputIdentifier] = value;

    this.setState(updatedFormValues);
  };

  submitHandler = (event) => {
    // we want to avoid the default behavior of form submission (reloading the page)
    event.preventDefault();

    this.changeHandle("", "errorMsg");
    const email = this.state.email;
    if (!this.state.email) {
      alert(
        "Please fill out the My Email field before sending a verification code."
      );
    } else {
      this.props
        .forgetPassword(email)
        .then((response) => {
          this.changeHandle(true, "emailSent");
        })
        .catch((error) => {
          // Handle network errors or other issues
          this.changeHandle(error.response.data[0], "errorMsg");
          console.log("An error occurred:", error.response);
        });
    }
  };

  render() {
    return (
      <div>
        <Card>
          <GradientDiv>
            <TopPart>
              <CMMLogo />
              <TopMainText>Reset Your Password</TopMainText>
            </TopPart>
          </GradientDiv>

          <BottomPart>
            <BottomContainer>
              <form onSubmit={this.submitHandler}>
                <Instructions>
                  Please enter your email address below and click “Send Link”.
                  We will then send you a password reset link.
                </Instructions>
                <FormInputContainer>
                  <Input
                    placeholder="Email"
                    type="email"
                    value={this.state.email}
                    onChange={(event) => this.changeHandle(event, "email")}
                    required
                  />
                </FormInputContainer>
                <Error>{this.state.errorMsg}</Error>

                {this.state.emailSent ? (
                  <p style={{ color: "green" }}>
                    {" "}
                    Password reset link sent. Please check your email (including
                    your spam folder). If you did not receive an email, check
                    that the email address you provided is correct, or go back
                    to the <Link to="/auth/login">login page</Link> and try
                    again.
                  </p>
                ) : (
                  <LoginButton type="submit">Send Link</LoginButton>
                )}
              </form>
              <ContactSupport>
                For questions or concerns, please contact{" "}
                <a href="mailto:support@capturemymeeting.com">
                  support@capturemymeeting.com
                </a>
              </ContactSupport>
            </BottomContainer>
          </BottomPart>
        </Card>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    forgetPassword: (email) => dispatch(actions.forgetPassword(email)),
  };
};
const mapStateToProps = (state) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestResetPassword);

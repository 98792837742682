import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import uniqid from "uniqid";

import backgroundImage from "../../../assets/images/background_images/background_image@2x.png";
import * as actions from "../../../store/actions/index";
import MeetingRoom from "./MeetingRoom/MeetingRoom";
import GetUserDetails from "./GetUserDetails/GetUserDetails";
import { PurpleDottedSpinner } from "../../../components/UI/Spinners/DottedSpinner";

const StartMeetingContainer = styled.div`
  background: url(${backgroundImage}) no-repeat center;
  background-size: cover;
  color: white;
  height: 100%;
  width: 100%;
  overflow: auto;
`;

const CustomSpinner = styled(PurpleDottedSpinner)`
  margin: 250px auto;
`;

// TODO: rename to CreateMeeting -> MeetingRoom
class SetupMeetingRoom extends Component {
  startingAsHost = !this.props.match.params.meetingId;

  componentDidMount() {
    console.log("component did mount called for SetupMeetingRoom");
    const defaultMeetingName = "Meeting " + uniqid();

    if (this.startingAsHost) {
      this.props.setupNewMeetingSession(
        this.props.user,
        this.props.accessToken,
        defaultMeetingName
      );
    } else {
      // ask for the username for the unlogged in user
    }
  }

  render() {
    console.log("render method called for SetupMeetingRoom");

    const localUser = this.props.session.localUser;

    let body = null;
    if (localUser != null) {
      body = (
        <MeetingRoom
          meetingSession={this.props.session}
          userIsHost={this.startingAsHost}
        />
      );
    } else if (this.props.session.loading) {
      body = <CustomSpinner size={50} />;
    } else {
      body = (
        <GetUserDetails
          meetingId={this.props.match.params.meetingId}
          isOpen={localUser == null}
        />
      );
    }

    return <StartMeetingContainer>{body}</StartMeetingContainer>;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setupNewMeetingSession: (user, accessToken, meetingName) =>
      dispatch(actions.setupNewMeetingSession(user, accessToken, meetingName)),
  };
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    user: state.user,
    session: state.meetingSession,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetupMeetingRoom);

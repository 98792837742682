import React from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";

import DesktopModal from "../../../../../UI/Modals/Desktop/DesktopModal";
import Video from "../../../../../UI/Video/Video";
import { SmallButtonLightGrey } from "../../../../../UI/Buttons/SmallButton";
import { HorizontalLine } from "../../../../../UI/Lines/Lines";
import MobileModalTransparentSides from "../../../../../UI/Modals/Mobile/MobileModalTransparentSides";

const MeetingName = styled.div`
  font-size: 18px;
  font-weight: bolder;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const NavigationBar = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const MenuItems = styled(NavigationBar)`
  padding: 12px 17px 0px 17px;
`;

const NavigationItem = styled.li`
  height: 40px;
  line-height: 40px;
  width: 100%;
  color: rgba(118, 78, 242, 1);

  &:hover {
    background-color: #f1f7fb;
  }
`;

const CancelButtonContainer = styled.div`
  height: 36px;
  margin: 2px 16px 15px 16px;
`;

const MeetingInfoContainer = styled.div`
  display: flex;
  height: 76px;
  padding: 0 15px 0 15px;
  align-items: center;
`;

const VideoContainer = styled.div`
  margin: 5px;
`;

const MeetingModal = (props) => {
  const MeetingOverflowDesktopModal = (
    <DesktopModal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      width={"750px"}
      zIndex={props.zIndex}
    >
      <MeetingName>{props.video.name}</MeetingName>
      <Video height={"100%"} width={"100%"} url={props.video.url} />
    </DesktopModal>
  );

  const MeetingOverflowMobileModal = (
    <MobileModalTransparentSides
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      zIndex={props.zIndex}
    >
      {props.recordingAvailable ? (
        <VideoContainer>
          <Video height={"100%"} width={"100%"} url={props.video.url} />
        </VideoContainer>
      ) : null}
      <MeetingInfoContainer>
        {props.dateTime}
        {props.nameDuration}
      </MeetingInfoContainer>
      <HorizontalLine />

      {props.menuItems ? (
        <MenuItems>
          {props.menuItems.map((menuItem, index) => (
            <NavigationItem
              key={index}
              onClick={(event) => props.menuItemSelected(menuItem, event)}
            >
              {menuItem}
            </NavigationItem>
          ))}
        </MenuItems>
      ) : null}
      <CancelButtonContainer>
        <SmallButtonLightGrey onClick={props.onRequestClose}>
          Cancel
        </SmallButtonLightGrey>
      </CancelButtonContainer>
    </MobileModalTransparentSides>
  );

  return (
    <MediaQuery query="(min-width: 769px)">
      {(matches) => {
        return matches
          ? MeetingOverflowDesktopModal
          : MeetingOverflowMobileModal;
      }}
    </MediaQuery>
  );
};

export default MeetingModal;

import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import MediaQuery from "react-responsive";

import Toolbar from "../../components/Navigation/Toolbar/Toolbar";
import Sidebar from "../../components/SidePanel/SidePanel";
import Dashboard from "../../components/Dashboard/Dashboard";
import SideDrawer from "../../components/Navigation/SideDrawer/SideDrawer";
import Settings from "../../components/Dashboard/Settings/Settings";
import Backdrop from "../../components/UI/Backdrop/Backdrop";
import Aux from "../../hoc/ReactAux";
import * as actions from "../../store/actions/index";
import DesktopModal from "../../components/UI/Modals/Desktop/DesktopModal";
import SimpleMobileModal from "../../components/UI/Modals/Mobile/SimpleMobileModal";
import MessageModal from "../../components/UI/Modals/Hybrid/MessageModal";
import WebexSettings from "../../components/Dashboard/Settings/WebexSettings/WebexSettings.js";
import TodoistSettings from "../../components/Dashboard/Settings/TodoistSettings/TodoistSettings.js";

const DashboardContainerStyles = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  max-width: 1800px;
`;

const Explanation = styled.div`
  font-size: 14px; //per UI mockup-up 16px.
  opacity: 0.7;
  line-height: 20px; //per UI mock-up, 24px.
  text-align: left;
  white-space: pre-wrap;
  margin-bottom: 20px;
`;

class Home extends Component {
  state = {
    showSideDrawer: false,
    isOpen: false,
    zoomModalIsOpen: false,
    zoomModalTitle: "",
    zoomModalMessage: "",
    webexModalIsOpen: false,
    webexModalTitle: "",
    webexModalMessage: "",
    todoistModalIsOpen: false,
    todoistModalTitle: "",
    todoistModalMessage: "",
    basicOpsModalIsOpen: false,
    basicOpsModalTitle: "",
    basicOpsModalMessage: "",
  };

  componentDidMount() {
    console.log("Home component did mount");
    if (this.props.accessToken != null) {
      this.props.getUserData(this.props.accessToken).then((_) => {
        this.processZoomEvent();
        this.processWebexEvent();
        this.processTodoistEvent();
        this.processBasicOpsEvent();

        if (
          `${process.env.REACT_APP_STRIPE_UI_ENABLED}` === "true" ||
          `${process.env.REACT_APP_STRIPE_WHITELIST_ACCOUNTS}`.includes(
            this.props.email
          )
        ) {
          this.props.getCurrentSubscription(this.props.accessToken);
        }
      });
    }
  }

  processZoomEvent = () => {
    this.props
      .storeZoomUserInfo(
        this.props.accessToken,
        this.props.userId,
        this.props.authCode,
        this.props.authFlowState
      )
      .then(() => {
        let zoomModalIsOpen = this.state.zoomModalIsOpen;
        let zoomModalTitle = this.state.zoomModalTitle;
        let zoomModalMessage = this.state.zoomModalMessage;
        if (
          this.props.authCode &&
          this.props.authFlowState ===
            process.env.REACT_APP_ZOOM_AUTH_STATE_VALUE
        ) {
          if (
            this.props.zoom_integrated &&
            this.props.zoom_refresh_token_exists
          ) {
            zoomModalIsOpen = true;
            zoomModalTitle = "Zoom Integration Complete!";
            zoomModalMessage = "";
          } else if (this.props.error) {
            zoomModalIsOpen = true;
            zoomModalTitle = "Zoom Authorization Error";
            zoomModalMessage =
              "There was an error while attempting to sync with your Zoom account. " +
              "Please contact support@capturemymeeting.com.";
          }
        }

        if (
          this.props.zoom_integrated &&
          !this.props.zoom_refresh_token_exists
        ) {
          zoomModalIsOpen = true;
          zoomModalTitle = "Zoom Reauthorization Required";
          zoomModalMessage =
            "We have gone through some updates and now require that you reauthorize us to access your cloud recordings. " +
            'Please do so by clicking the "Integrate Zoom" button again on the left side panel and completing the steps. ' +
            "Until you do so, CaptureMyMeeting will not be able to process your cloud recordings. " +
            "If this message persists after reauthorization, or your cloud recordings are still not being processed, " +
            "please contact support@capturemymeeting.com. Thank you!";
        }

        if (zoomModalIsOpen) {
          this.setState({
            zoomModalIsOpen: zoomModalIsOpen,
            zoomModalTitle: zoomModalTitle,
            zoomModalMessage: zoomModalMessage,
          });
        }
      })
      .catch((error) => {
        let zoomModalMessage =
          "There was an error while attempting to sync with your Zoom account. " +
          "Please contact support@capturemymeeting.com.";

        this.setState({
          zoomModalIsOpen: true,
          zoomModalTitle: "Zoom Authorization Processing Error",
          zoomModalMessage: zoomModalMessage,
        });
      });
  };

  processWebexEvent = () => {
    this.props
      .storeWebexUserInfo(
        this.props.accessToken,
        this.props.userId,
        this.props.authCode,
        this.props.authFlowState
      )
      .then(() => {
        let webexModalIsOpen = null;
        let webexModalTitle = null;
        let webexModalMessage = null;
        if (
          this.props.authCode &&
          this.props.authFlowState ===
            process.env.REACT_APP_WEBEX_AUTH_STATE_VALUE
        ) {
          if (
            this.props.webex_integrated &&
            this.props.webex_refresh_token_exists
          ) {
            webexModalIsOpen = true;
            webexModalTitle = "Webex Integration Complete!";
            webexModalMessage = "";
          } else if (this.props.error) {
            webexModalIsOpen = true;
            webexModalTitle = "Webex Authorization Error";
            webexModalMessage =
              "There was an error while attempting to sync with your Webex account. " +
              "Please contact support@capturemymeeting.com.";
          }
        }

        if (
          this.props.webex_integrated &&
          !this.props.webex_refresh_token_exists
        ) {
          webexModalIsOpen = true;
          webexModalTitle = "Webex Reauthorization Required";
          webexModalMessage =
            "We have gone through some updates and now require that you reauthorize us to access your cloud recordings. " +
            'Please do so by clicking the "Integrate Webex" button again on the left side panel and completing the steps. ' +
            "Until you do so, CaptureMyMeeting will not be able to process your cloud recordings. " +
            "If this message persists after reauthorization, or your cloud recordings are still not being processed, " +
            "please contact support@capturemymeeting.com. Thank you!";
        }

        if (webexModalIsOpen) {
          this.setState({
            webexModalIsOpen: webexModalIsOpen,
            webexModalTitle: webexModalTitle,
            webexModalMessage: webexModalMessage,
          });
        }
      })
      .catch((error) => {
        let webexModalMessage =
          "There was an error while attempting to sync with your Webex account. " +
          "Please contact support@capturemymeeting.com.";

        this.setState({
          webexModalIsOpen: true,
          webexModalTitle: "Webex Authorization Processing Error",
          webexModalMessage: webexModalMessage,
        });
      });
  };

  processTodoistEvent = () => {
    this.props
      .storeTodoistUserInfo(
        this.props.accessToken,
        this.props.userId,
        this.props.authCode,
        this.props.authFlowState
      )
      .then(() => {
        let todoistModalIsOpen = null;
        let todoistModalTitle = null;
        let todoistModalMessage = null;
        if (
          this.props.authCode &&
          this.props.authFlowState ===
            process.env.REACT_APP_TODOIST_AUTH_STATE_VALUE
        ) {
          if (
            this.props.todoistIntegrated &&
            this.props.todoistAccessTokenExists
          ) {
            todoistModalIsOpen = true;
            todoistModalTitle = "Todoist Integration Complete!";
            todoistModalMessage =
              "Your Todoist account has been successfully integrated with CaptureMyMeeting. " +
              "\n\nNext, import your meeting recordings, either by manually uploading them to the app, or by integrating Zoom or Webex. " +
              "Once your recordings are imported and processed, you can store any action items detected from them into your personal Todoist inbox using the Notes section of each meeting." +
              "\n\nIMPORTANT: For an automated experience, please update your Todoist Integration Settings below. " +
              "Note that automatic storing into Todoist is by default OFF.";
          } else if (this.props.error) {
            todoistModalIsOpen = true;
            todoistModalTitle = "Todoist Authorization Error";
            todoistModalMessage =
              "There was an error while attempting to integrate your Todoist account. " +
              "Please contact support@capturemymeeting.com.";
          }
        }

        if (
          this.props.todoistIntegrated &&
          !this.props.todoistAccessTokenExists
        ) {
          todoistModalIsOpen = true;
          todoistModalTitle = "Todoist Reauthorization Required";
          todoistModalMessage =
            "We have gone through some updates and now require that you reauthorize us to access your Todoist account. " +
            'Please do so by clicking the "Integrate Todoist" button again on the left side panel and completing the steps. ' +
            "Until you do so, CaptureMyMeeting will not be able to store action items into your Todoist inbox. " +
            "If this message persists after reauthorization, or your action items are still not being stored in Todoist, " +
            "please contact support@capturemymeeting.com. Thank you!";
        }

        if (todoistModalIsOpen) {
          this.setState({
            todoistModalIsOpen: todoistModalIsOpen,
            todoistModalTitle: todoistModalTitle,
            todoistModalMessage: todoistModalMessage,
          });
        }
      })
      .catch((error) => {
        let todoistModalMessage =
          "There was an error while attempting to integrate your Todoist account. " +
          "Please contact support@capturemymeeting.com.";

        this.setState({
          todoistModalIsOpen: true,
          todoistModalTitle: "Todoist Authorization Processing Error",
          todoistModalMessage: todoistModalMessage,
        });
      });
  };

  processBasicOpsEvent = () => {
    this.props
      .authorizeBasicOpsUser(
        this.props.accessToken,
        this.props.userId,
        this.props.authCode,
        this.props.authFlowState
      )
      .then(() => {
        let basicOpsModalIsOpen = null;
        let basicOpsModalTitle = null;
        let basicOpsModalMessage = null;
        if (
          this.props.authCode &&
          this.props.authFlowState ===
            process.env.REACT_APP_BASICOPS_AUTH_STATE_VALUE
        ) {
          if (
            this.props.basicOpsIntegrated &&
            this.props.basicOpsRefreshTokenExists
          ) {
            basicOpsModalIsOpen = true;
            basicOpsModalTitle = "BasicOps Integration Complete!";
            basicOpsModalMessage =
              "Your BasicOps account has been successfully integrated with CaptureMyMeeting. " +
              "\n\nNext, import a meeting recording, either by manually uploading it to the app, or by integrating Zoom or Webex. " +
              "Once your recording is imported and processed, CaptureMyMeeting will automatically store your meetings notes into BasicOps as a personal Note. ";
          } else if (this.props.error) {
            basicOpsModalIsOpen = true;
            basicOpsModalTitle = "BasicOps Authorization Error";
            basicOpsModalMessage =
              "There was an error while attempting to sync with your BasicOps account. " +
              "Please contact support@capturemymeeting.com.";
          }
        }

        if (
          this.props.basicOpsIntegrated &&
          !this.props.basicOpsRefreshTokenExists
        ) {
          basicOpsModalIsOpen = true;
          basicOpsModalTitle = "BasicOps Reauthorization Required";
          basicOpsModalMessage =
            "We have gone through some updates and now require that you reauthorize us to access your BasicOps account. " +
            'Please do so by clicking the "Integrate BasicOps" button again on the left side panel and completing the steps. ' +
            "Until you do so, CaptureMyMeeting will not be able to store notes into your BasicOps account. " +
            "If this message persists after reauthorization, or your notes are still not being stored in BasicOps, " +
            "please contact support@capturemymeeting.com. Thank you!";
        }

        if (basicOpsModalIsOpen) {
          this.setState({
            basicOpsModalIsOpen: basicOpsModalIsOpen,
            basicOpsModalTitle: basicOpsModalTitle,
            basicOpsModalMessage: basicOpsModalMessage,
          });
        }
      })
      .catch((error) => {
        let basicOpsModalMessage =
          "There was an error while attempting to integrate with your BasicOps account. " +
          "Please contact support@capturemymeeting.com.";

        this.setState({
          basicOpsModalIsOpen: true,
          basicOpsModalTitle: "BasicOps Authorization Processing Error",
          basicOpsModalMessage: basicOpsModalMessage,
        });
      });
  };

  sideDrawerClosedHandler = () => {
    this.setState({ showSideDrawer: !this.state.showSideDrawer });
  };

  sideDrawerToggleHandler = () => {
    this.setState((prevState) => {
      console.log(this.props);
      return { showSideDrawer: !prevState.showSideDrawer };
    });
  };

  settingsOpen = () => {
    this.setState({ isOpen: true });
  };

  settingsClose = () => {
    this.setState({ isOpen: false });
  };

  settingsMobile = () => {
    this.setState({ isOpen: true });
    this.setState({ showSideDrawer: false });
  };

  onRequestCloseZoomModal = () => {
    this.setState({
      zoomModalIsOpen: false,
      zoomModalTitle: "",
      zoomModalMessage: "",
    });
  };

  onRequestCloseWebexModal = () => {
    this.setState({
      webexModalIsOpen: false,
      webexModalTitle: "",
      webexModalMessage: "",
    });
  };

  onRequestCloseTodoistModal = () => {
    this.setState({
      todoistModalIsOpen: false,
      todoistModalTitle: "",
      todoistModalMessage: "",
    });
  };

  onRequestCloseBasicOpsModal = () => {
    this.setState({
      basicOpsModalIsOpen: false,
      basicOpsModalTitle: "",
      basicOpsModalMessage: "",
    });
  };

  render() {
    const name =
      this.props.firstName && this.props.lastName
        ? `${this.props.firstName} ${this.props.lastName}`
        : "Loading...";
    const unAuthenticated = <Redirect to="/auth/login" />;
    const mainUI = (
      <Aux>
        <SideDrawer
          show={this.state.showSideDrawer}
          closed={this.sideDrawerClosedHandler}
          showSettings={this.settingsMobile}
        />
        <Backdrop
          show={this.state.showSideDrawer}
          closed={this.sideDrawerClosedHandler}
        />
        <Toolbar
          name={name}
          toggle={this.sideDrawerToggleHandler}
          showSettings={this.settingsOpen}
        />
        <MediaQuery query="(min-width: 769px)">
          {(matches) => {
            if (matches) {
              return (
                <DesktopModal
                  isOpen={this.state.isOpen}
                  onRequestClose={this.settingsClose}
                  width="30%"
                  top="10%"
                  padding="0px"
                  maxWidth="560px"
                  minWidth="320px"
                >
                  <Settings
                    zoom_integrated={this.props.zoom_integrated}
                    zoom_refresh_token_exists={
                      this.props.zoom_refresh_token_exists
                    }
                    webex_integrated={this.props.webex_integrated}
                    webex_refresh_token_exists={
                      this.props.webex_refresh_token_exists
                    }
                  />
                </DesktopModal>
              );
            } else {
              return (
                <SimpleMobileModal
                  isOpen={this.state.isOpen}
                  onRequestClose={this.settingsClose}
                >
                  <Settings
                    zoom_integrated={this.props.zoom_integrated}
                    zoom_refresh_token_exists={
                      this.props.zoom_refresh_token_exists
                    }
                    webex_integrated={this.props.webex_integrated}
                    webex_refresh_token_exists={
                      this.props.webex_refresh_token_exists
                    }
                  />
                </SimpleMobileModal>
              );
            }
          }}
        </MediaQuery>

        <DashboardContainerStyles>
          <Sidebar
            firstName={this.props.firstName}
            email={this.props.email}
            showSettings={this.settingsOpen}
            zoom_integrated={this.props.zoom_integrated}
            zoom_refresh_token_exists={this.props.zoom_refresh_token_exists}
            webex_integrated={this.props.webex_integrated}
            webex_refresh_token_exists={this.props.webex_refresh_token_exists}
            todoistIntegrated={this.props.todoistIntegrated}
            todoistAccessTokenExists={this.props.todoistAccessTokenExists}
          />
          <Dashboard meetingPathSegment={this.props.meetingPathSegment} />
          <MessageModal
            isOpen={this.state.zoomModalIsOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={this.onRequestCloseZoomModal}
            title={this.state.zoomModalTitle}
            message={this.state.zoomModalMessage}
          >
            {this.state.zoomModalTitle === "Zoom Integration Complete!" ? (
              <div>
                <Explanation>
                  Your Zoom integration was successful. Going forward, for each
                  Zoom meeting that you host and
                  <a
                    style={{ margin: "0px 3px 0px 3px" }}
                    href="https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0062627"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    record to the cloud
                  </a>
                  , CaptureMyMeeting will automatically process the meeting
                  recording as soon as the recording is available. Once
                  processing is finished, you can view, share, and search
                  through your processed meeting content (notes, transcripts,
                  sentiment analysis) from within this application.
                </Explanation>
                <Explanation>
                  IMPORTANT: To get speaker names automatically in your notes
                  and transcripts, please
                  <a
                    style={{ margin: "0px 3px 0px 3px" }}
                    href="https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0065911"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    enable transcription
                  </a>
                  for your Zoom cloud recordings.
                </Explanation>
              </div>
            ) : null}
          </MessageModal>
          <MessageModal
            isOpen={this.state.webexModalIsOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={this.onRequestCloseWebexModal}
            title={this.state.webexModalTitle}
            message={this.state.webexModalMessage}
          >
            {this.state.webexModalTitle === "Webex Integration Complete!" ? (
              <div>
                <Explanation>
                  Your Webex integration was successful. Going forward, for each
                  Webex meeting that you host and
                  <a
                    style={{ margin: "0px 3px 0px 3px" }}
                    href="https://help.webex.com/en-us/article/n62735y/Webex-%7C-Record-a-meeting-or-webinar"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    record to the cloud
                  </a>
                  , CaptureMyMeeting will automatically process the meeting
                  recording as soon as the recording is available. Once
                  processing is finished, you can view, share, and search
                  through your processed meeting content (notes, transcripts,
                  sentiment analysis) from within this application.
                </Explanation>
                <Explanation>
                  IMPORTANT: For an automated experience, please update your
                  Webex Integration Settings below. To get automatic recording
                  and speaker names in your notes and transcripts, Webex
                  Assistant must be enabled for your
                  <a
                    style={{ margin: "0px 3px 0px 3px" }}
                    href="https://help.webex.com/en-us/article/nwl9xii/Enable-Webex-Assistant-for-Meetings-in-Control-Hub"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    org
                  </a>
                  and for your
                  <a
                    style={{ margin: "0px 0px 0px 3px" }}
                    href="https://help.webex.com/en-us/article/4pj83q/Set-your-meeting-scheduling-preferences"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    user
                  </a>
                  , or
                  <a
                    style={{ margin: "0px 3px 0px 3px" }}
                    href="https://help.webex.com/en-us/article/lzi8h2/Show-or-hide-automated-closed-captions-during-a-Webex-meeting-or-webinar"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Closed Captions
                  </a>
                  must be on. Cisco AI Assistant (if available) must be
                  <a
                    style={{ margin: "0px 3px 0px 3px" }}
                    href="https://help.webex.com/en-us/article/9dofjcb/Administer-Cisco-AI-Assistant-in-Control-Hub"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    OFF
                  </a>
                  to use Webex Assistant.
                </Explanation>
                <WebexSettings></WebexSettings>
              </div>
            ) : null}
          </MessageModal>
          <MessageModal
            isOpen={this.state.todoistModalIsOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={this.onRequestCloseTodoistModal}
            title={this.state.todoistModalTitle}
            message={this.state.todoistModalMessage}
          >
            {this.state.todoistModalTitle ===
            "Todoist Integration Complete!" ? (
              <TodoistSettings></TodoistSettings>
            ) : null}
          </MessageModal>
          <MessageModal
            isOpen={this.state.basicOpsModalIsOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={this.onRequestCloseBasicOpsModal}
            title={this.state.basicOpsModalTitle}
            message={this.state.basicOpsModalMessage}
          >
            {this.state.basicOpsModalTitle ===
            "BasicOps Integration Complete!" ? (
              <div>
                <Explanation>
                  Note: For Webex integrations, in order to include speaker
                  names in your notes, you must have either Webex Assistant
                  activated (for your
                  <a
                    style={{ margin: "0px 3px 0px 3px" }}
                    href="https://help.webex.com/en-us/article/nwl9xii/Enable-Webex-Assistant-for-Meetings-in-Control-Hub"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    org
                  </a>
                  and
                  <a
                    style={{ margin: "0px 0px 0px 3px" }}
                    href="https://help.webex.com/en-us/article/4pj83q/Set-your-meeting-scheduling-preferences"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    user
                  </a>
                  ) OR
                  <a
                    style={{ margin: "0px 3px 0px 3px" }}
                    href="https://help.webex.com/en-us/article/lzi8h2/Show-or-hide-automated-closed-captions-during-a-Webex-meeting-or-webinar"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Closed Captions
                  </a>
                  activated during your meeting.
                </Explanation>
              </div>
            ) : null}
          </MessageModal>
        </DashboardContainerStyles>
      </Aux>
    );

    return this.props.isAuthenticated ? mainUI : unAuthenticated;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserData: (accessToken) => dispatch(actions.getUser(accessToken)),
    storeZoomUserInfo: (accessToken, userId, zoomAuthCode, authFlowState) =>
      dispatch(
        actions.storeZoomUserInfo(
          accessToken,
          userId,
          zoomAuthCode,
          authFlowState
        )
      ),
    storeWebexUserInfo: (accessToken, userId, webexAuthCode, authFlowState) =>
      dispatch(
        actions.storeWebexUserInfo(
          accessToken,
          userId,
          webexAuthCode,
          authFlowState
        )
      ),
    storeTodoistUserInfo: (
      accessToken,
      userId,
      todoistAuthCode,
      authFlowState
    ) =>
      dispatch(
        actions.storeTodoistUserInfo(
          accessToken,
          userId,
          todoistAuthCode,
          authFlowState
        )
      ),
    authorizeBasicOpsUser: (
      accessToken,
      userId,
      basicOpsAuthCode,
      authFlowState
    ) =>
      dispatch(
        actions.authorizeBasicOpsUser(
          accessToken,
          userId,
          basicOpsAuthCode,
          authFlowState
        )
      ),
    getCurrentSubscription: (accessToken) =>
      dispatch(actions.getCurrentSubscription(accessToken)),
  };
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.accessToken !== null,
    userId: state.user.userId,
    accessToken: state.auth.accessToken,
    firstName: state.user.firstName,
    lastName: state.user.lastName,
    company: state.user.company,
    email: state.user.email,
    zoom_refresh_token_exists: state.user.zoom_refresh_token_exists,
    zoom_integrated: state.user.zoom_integrated,
    webex_refresh_token_exists: state.user.webex_refresh_token_exists,
    webex_integrated: state.user.webex_integrated,
    todoistAccessTokenExists: state.user.todoistAccessTokenExists,
    todoistIntegrated: state.user.todoistIntegrated,
    basicOpsRefreshTokenExists: state.user.basicOpsRefreshTokenExists,
    basicOpsIntegrated: state.user.basicOpsIntegrated,
    error: state.user.error,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));

import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import Input from "../../../../components/UI/Forms/Input";
import Button from "../../../../components/UI/Buttons/LargeButton";
import * as actions from "../../../../store/actions/index";
import DarkLogoBug from "../../../../components/UI/Logos/DarkLogoBug";
import GradientHybridModalNarrow from "../../../../components/UI/Modals/Hybrid/GradientHybridModalNarrow";

import MeetingAttendee from "../../../../models/MeetingAttendee";

const LogoContainer = styled.div`
  width: fit-content;
  margin: 22px auto 0 auto;
`;

const MainText = styled.div`
  margin-top: 18px;
  height: 37px;
  opacity: 0.7;
  font-size: 28px;
  line-height: 37px;
  text-align: center;
`;

const SubText = styled.div`
  margin: 8px 37px 0 37px;
  opacity: 0.35;
  line-height: 24px;
  text-align: center;
  font-weight: normal;
`;

const Form = styled.form`
  padding: 22px 6.75% 32px 6.75%;
  height: 233px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ModalBody = styled.div`
  max-width: 550px;
  margin: auto;
`;

class GetUserDetails extends Component {
  state = {
    joinMeetingForm: {
      name: "",
      emailAddress: "",
    },
  };

  changeHandle = (event, inputIdentifier) => {
    const updatedFormValues = {
      ...this.state.joinMeetingForm,
    };
    updatedFormValues[inputIdentifier] = event.target.value;
    this.setState({ joinMeetingForm: updatedFormValues });
  };

  submitHandler = (event) => {
    // we want to avoid the default behavior of form submission (reloading the page)
    event.preventDefault();
    const name = this.state.joinMeetingForm.name.trim();
    const localUser = new MeetingAttendee(
      name,
      this.state.joinMeetingForm.emailAddress,
      false,
      false
    );
    this.props.setupMeetingSession(this.props.meetingId, localUser);
  };

  render() {
    // TODO: make emails private and mention that as part of subtext below.
    // "Please enter the name your meeting mates will see and your email (private) to join the meeting."
    return (
      <GradientHybridModalNarrow
        isOpen={this.props.isOpen}
        top={"20%"}
        backgroundColor={"rgba(41,42,68, 0.25)"}
      >
        {/* modal content */}
        <ModalBody>
          <LogoContainer>
            <DarkLogoBug height={40} />
          </LogoContainer>
          <MainText>Welcome to the meeting!</MainText>
          {/*<SubText>Please enter your name and email address in order to join the meeting.</SubText>*/}
          <SubText>Please enter your name and email address.</SubText>
          <Form onSubmit={this.submitHandler}>
            <Input
              placeholder="John Smith or Meeting Room 2"
              type="text"
              value={this.state.joinMeetingForm.name}
              onChange={(event) => this.changeHandle(event, "name")}
              required
            />
            <Input
              placeholder="Email"
              type="email"
              value={this.state.joinMeetingForm.emailAddress}
              onChange={(event) => this.changeHandle(event, "emailAddress")}
              required
            />
            {/*<Button type='submit'>Join the Meeting</Button>*/}
            <Button type="submit">Next </Button>
          </Form>
        </ModalBody>
      </GradientHybridModalNarrow>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setupMeetingSession: (meetingId, user) =>
      dispatch(actions.setupMeetingSession(meetingId, user)),
  };
};

export default connect(null, mapDispatchToProps)(GetUserDetails);

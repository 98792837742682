import React from "react";
import styled from "styled-components";

// Define the styled label component outside of the checkbox function
const LabelStyled = styled.label`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  font-size: ${(props) => props.fontSize};
  align-items: center;
`;
const checkbox = (props) => {
  return (
    <LabelStyled hidden={props.hidden} fontSize={props.fontSize}>
      <input
        type="checkbox"
        id="checkbox"
        title={props.title}
        onChange={props.onChange}
        checked={props.isChecked}
        required={props.required}
      />
      &nbsp; {props.children}
    </LabelStyled>
  );
};

export default checkbox;

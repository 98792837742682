import api from "../../services/api";
import * as actionTypes from "./actionTypes";

export const openMeetingModal = (meetingId, modalType) => {
  return {
    type: actionTypes.OPEN_MEETING_MODAL,
    meetingId: meetingId,
    modalType: modalType,
  };
};

export const closeMeetingModal = (modalType) => {
  return {
    type: actionTypes.CLOSE_MEETING_MODAL,
    modalType: modalType,
  };
};

const getMeetingRecordingStart = () => {
  return {
    type: actionTypes.GET_MEETING_RECORDING_START,
  };
};

const getMeetingRecordingSuccess = (recordingData, meetingId) => {
  return {
    type: actionTypes.GET_MEETING_RECORDING_SUCCESS,
    data: recordingData,
    meetingId: meetingId,
  };
};

const getMeetingRecordingFail = (error) => {
  return {
    type: actionTypes.GET_MEETING_RECORDING_FAIL,
    error: error,
  };
};

export const viewMeetingRecording = (
  accessToken,
  meetingId,
  modalType,
  seconds
) => {
  const cleanMeetingDetailsResponse = (response, seconds) => {
    const body = response.data;
    return {
      name: body.name,
      url: seconds ? body.video_url + "#t=" + seconds : body.video_url,
      expirationTime: body.expiration_time,
      recordingAvailable: body.recording_available,
      startTime: body.start_time,
      endTime: body.end_time,
    };
  };

  return (dispatch) => {
    dispatch(getMeetingRecordingStart());
    return api.meetings
      .getMeetingRecording(accessToken, meetingId)
      .then((response) => {
        console.log("Meeting recording api call was successful");
        dispatch(openMeetingModal(meetingId, modalType)); // open the modal right before we have already obtained necessary data to make the ui experience better
        dispatch(
          getMeetingRecordingSuccess(
            cleanMeetingDetailsResponse(response, seconds),
            meetingId
          )
        );
      })
      .catch((error) => {
        console.log("Meeting recording api call failed:");
        console.log(error);
        dispatch(getMeetingRecordingFail(error));
      });
  };
};

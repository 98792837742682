import React, { Component, createRef } from "react";
import styled from "styled-components";

import HybridModal from "../UI/Modals/Hybrid/HybridModal";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../store/actions";
import { downloadFile } from "../Dashboard/Meetings/Meeting/OverflowMenu/DownloadFile";
import { MainInput } from "../Dashboard/Meetings/Meeting/MeetingStyles";
import {
  SmallButtonPurple,
  SmallButtonLightGrey,
} from "../UI/Buttons/SmallButton";
import moment from "moment";

const ModalBody = styled.div`
  background-color: #f1f7fb;
`;

const Transcript = styled.div`
  background-color: white;
  padding: 35px 41px 20px 41px; //per UI mock-up, padding: 41px 41px 37px 41px;
  line-height: 24px;
  font-size: 16px;
  opacity: 0.8;
  text-align: center;
`;

const TranscriptTitle = styled.div`
  margin: 0 20px 8px 20px;
  letter-spacing: 0.5px;
  font-size: 28px;
  line-height: 37px;
`;

const TranscriptContent = styled.div`
  font-size: 14px; //per UI mockup-up 16px.
  opacity: 0.7;
  line-height: 20px; //per UI mock-up, 24px.
  text-align: left;
  overflow-y: auto;
  height: 550px;
  min-width: 400px;
  white-space: pre-line;
`;

const TranscriptOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const SpeakerNamesContainer = styled.div``;

const SpeakerNamesFormContainer = styled.div``;

const SpeakerNamesFormInstructions = styled.div`
  font-size: 12px;
  max-width: 250px;
  line-height: 15px;
  text-align: left;
`;

const SpeakerNamesForm = styled.div`
  margin-top: 5px;
`;

const SpeakerNameInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SpeakerLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SpeakerNamesButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Link = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  height: 25px;
`;

class TranscriptModal extends Component {
  state = {
    transcriptParts: [],
    showSpeakerNamesForm: false,
    speakerNames: {},
  };

  componentDidMount() {
    if (this.props.meetingTranscript.content) {
      let transcriptParts = this.getTranscriptContentWithClickableTimestamps(
        this.props.meetingTranscript.content
      );
      this.setState({ transcriptParts: transcriptParts });
    }
  }

  onDownloadTranscriptClicked = () => {
    this.props
      .getTranscriptUrl(this.props.accessToken, this.props.meetingId)
      .then(() => {
        if (!this.props.meetingTranscript.url) {
          alert(
            "There was a problem downloading your meeting transcript. Please contact support@capturemymeeting.com."
          );
        } else {
          downloadFile(
            this.props.meetingTranscript.url,
            { method: "GET" },
            "speaker_labeled_transcript.txt"
          );
        }
      })
      .catch((error) => {
        alert(
          "There was an error downloading your meeting transcript. Please contact support@capturemymeeting.com."
        );
      });
  };

  onLabelSpeakerNamesClicked = () => {
    this.setState({ showSpeakerNamesForm: true });
  };

  onCloseSpeakerNamesForm = () => {
    this.setState({ showSpeakerNamesForm: false });
  };

  updateSpeakerNames = (event) => {
    if (this.state.speakerNames) {
      this.props
        .updateSpeakerNames(
          this.props.accessToken,
          this.props.meetingId,
          this.state.speakerNames
        )
        .then(() => {
          if (this.props.meetingTranscript.error) {
            alert(
              "There was an error updating your speaker names. Please contact support@capturemymeeting.com."
            );
          } else {
            this.props
              .downloadTranscript(this.props.accessToken, this.props.meetingId)
              .then(() => {
                if (!this.props.meetingTranscript.content) {
                  alert(
                    "There was an error retrieving your updated transcript. Please contact support@capturemymeeting.com."
                  );
                } else {
                  let transcriptParts =
                    this.getTranscriptContentWithClickableTimestamps(
                      this.props.meetingTranscript.content
                    );
                  this.setState({ transcriptParts: transcriptParts });
                }
              })
              .catch((error) => {
                alert(
                  "There was an issue retrieving your updated transcript. Please contact support@capturemymeeting.com."
                );
              });
          }
        })
        .catch((error) => {
          alert(
            "There was an issue updating your speaker names. Please contact support@capturemymeeting.com."
          );
        });
    } else {
      alert("Please edit the speaker names before clicking Update.");
    }
  };

  onChangeSpeakerName = (event, speakerLabel) => {
    let newSpeakerNames = this.props.meetingTranscript.speakerNames;

    if (this.state.speakerNames) {
      for (const [speakerLab, speakerName] of Object.entries(
        this.state.speakerNames
      )) {
        if (speakerLab in newSpeakerNames) {
          newSpeakerNames[speakerLab] = speakerName;
        }
      }
    }

    newSpeakerNames[speakerLabel] = event.target.value;
    this.setState({ speakerNames: newSpeakerNames });
  };

  onCloseTranscriptModal = () => {
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
    this.setState({ showSpeakerNamesForm: false, speakerNames: {} });
  };

  getSpeakerName = (speakerLabel, defaultSpeakerName) => {
    if (this.state.speakerNames && speakerLabel in this.state.speakerNames) {
      return this.state.speakerNames[speakerLabel];
    } else {
      return defaultSpeakerName;
    }
  };

  openMeetingModal = (meetingId, seconds) => {
    this.props.viewMeetingRecording(
      this.props.accessToken,
      this.props.meetingId,
      "search",
      seconds
    );
  };

  getTranscriptContentWithClickableTimestamps = (transcriptContent) => {
    const transcriptParts = [];
    const timestampRegex = /\d+:\d+:\d+/g;

    if (transcriptContent) {
      let timestampMatches = transcriptContent.matchAll(timestampRegex);
      let i = 0;
      let currentIndex = 0;
      for (const timestampMatch of timestampMatches) {
        const seconds = moment
          .duration(timestampMatch[0], "HH:mm:ss")
          .asSeconds();
        transcriptParts.push(
          transcriptContent.slice(currentIndex, timestampMatch.index)
        );
        transcriptParts.push(
          <Link
            ref={createRef()}
            key={"ts_" + seconds}
            style={{ float: "left" }}
            onClick={() => this.openMeetingModal(this.props.meetingId, seconds)}
          >
            {timestampMatch[0]}
          </Link>
        );
        currentIndex = timestampMatch.index + timestampMatch[0].length;
        i = i + 1;
      }
      transcriptParts.push(transcriptContent.slice(currentIndex));
    }

    return transcriptParts;
  };

  speakerNamesExist = (speakerNamesObj) => {
    return speakerNamesObj && Object.keys(speakerNamesObj).length > 0;
  };

  onAfterOpen = () => {
    if (this.props.secondsToScrollTo !== null && this.state.transcriptParts) {
      let validTimestampComponents = this.state.transcriptParts.filter(
        (comp) => {
          let seconds = 0;
          if (comp.type === Link && comp.key.includes("ts_")) {
            seconds = parseFloat(comp.key.split("_")[1]);
          }
          return (
            comp.type === Link &&
            comp.key.includes("ts_") &&
            seconds <= this.props.secondsToScrollTo
          );
        }
      );

      let timestampRefToScrollTo =
        validTimestampComponents[validTimestampComponents.length - 1].ref;

      timestampRefToScrollTo.current.scrollIntoView({ behavior: "instant" });
    }
  };

  render() {
    return (
      <div>
        <HybridModal
          isOpen={this.props.isOpen}
          onAfterOpen={this.onAfterOpen}
          shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
          onRequestClose={this.onCloseTranscriptModal}
          padding={"0px"}
          maxHeight={"90vh"}
          width={"760px"}
        >
          <ModalBody>
            <Transcript>
              <TranscriptTitle>{this.props.meetingName}</TranscriptTitle>
              <TranscriptOptions>
                <Link onClick={this.onDownloadTranscriptClicked}>
                  Download Transcript
                </Link>
                {this.props.userIsHost &&
                this.speakerNamesExist(
                  this.props.meetingTranscript.speakerNames
                ) ? (
                  <SpeakerNamesContainer>
                    <Link onClick={this.onLabelSpeakerNamesClicked}>
                      (New!) Label Speaker Names
                    </Link>
                    {this.state.showSpeakerNamesForm &&
                    this.speakerNamesExist(
                      this.props.meetingTranscript.speakerNames
                    ) ? (
                      <SpeakerNamesFormContainer>
                        <SpeakerNamesFormInstructions>
                          Edit the speaker names, and then click Update. Speaker
                          names will be updated wherever CaptureMyMeeting did
                          not already label them.
                        </SpeakerNamesFormInstructions>
                        <SpeakerNamesForm>
                          {Object.entries(
                            this.props.meetingTranscript.speakerNames
                          ).map(([speakerLabel, speakerName], idx) => {
                            return (
                              <SpeakerNameInput key={"sn" + idx}>
                                <SpeakerLabel>{speakerLabel}: </SpeakerLabel>
                                <MainInput
                                  style={{
                                    cursor: "pointer",
                                    border: "1px solid",
                                    marginLeft: "10px",
                                  }}
                                  placeholder={this.getSpeakerName(
                                    speakerLabel,
                                    speakerName
                                  )}
                                  type="text"
                                  value={this.getSpeakerName(
                                    speakerLabel,
                                    speakerName
                                  )}
                                  onChange={(event) => {
                                    this.onChangeSpeakerName(
                                      event,
                                      speakerLabel
                                    );
                                  }}
                                  required
                                />
                              </SpeakerNameInput>
                            );
                          })}
                        </SpeakerNamesForm>
                        <SpeakerNamesButtons>
                          <ButtonContainer>
                            <SmallButtonLightGrey
                              type="button"
                              onClick={this.onCloseSpeakerNamesForm}
                            >
                              Close
                            </SmallButtonLightGrey>
                          </ButtonContainer>
                          <ButtonContainer style={{ marginLeft: "5px" }}>
                            <SmallButtonPurple
                              type="button"
                              loading={this.props.meetingTranscript.loading}
                              disabled={this.props.meetingTranscript.loading}
                              onClick={this.updateSpeakerNames}
                            >
                              Update
                            </SmallButtonPurple>
                          </ButtonContainer>
                        </SpeakerNamesButtons>
                      </SpeakerNamesFormContainer>
                    ) : null}
                  </SpeakerNamesContainer>
                ) : null}
              </TranscriptOptions>
              <TranscriptContent>
                {this.state.transcriptParts}
              </TranscriptContent>
            </Transcript>
          </ModalBody>
        </HybridModal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTranscriptUrl: (accessToken, meetingId) =>
      dispatch(actions.getTranscriptUrl(accessToken, meetingId)),
    downloadTranscript: (accessToken, meetingId) =>
      dispatch(actions.downloadTranscript(accessToken, meetingId)),
    updateSpeakerNames: (accessToken, meetingId, speakerNames) =>
      dispatch(
        actions.updateSpeakerNames(accessToken, meetingId, speakerNames)
      ),
    viewMeetingRecording: (accessToken, meetingId, modalType, seconds) =>
      dispatch(
        actions.viewMeetingRecording(accessToken, meetingId, modalType, seconds)
      ),
  };
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    meetingTranscript: state.meetingTranscript,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TranscriptModal)
);

import React from "react";
import { Link } from "react-router-dom";
import CMMLogo from "../../../../assets/images/company_logos/horizontal_logo_lockup_dark.svg";
import CMMLogoMobile from "../../../../assets/images/company_logos/CMM_Logo_BugOnly_Light.svg";

const toolbarLogo = (props) => (
  <Link to="/">
    <picture>
      <source srcSet={CMMLogoMobile} media="(max-width: 768px)" />
      <img src={CMMLogo} alt="CaptureMyMeeting" />
    </picture>
  </Link>
);

export default toolbarLogo;

import React, { Component } from "react";
import styled, { keyframes } from "styled-components";

import GradientHybridModalNarrow from "../UI/Modals/Hybrid/GradientHybridModalNarrow";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../store/actions";
import { wait } from "../../utility";
import { paintRed } from "../UI/Styles";
import SwitchButton from "../UI/Buttons/SwitchButton";
import { TagsInput } from "react-tag-input-component";
import { InfoCircle } from "../UI/Styles";

const ModalBody = styled.div`
  input {
    width: 100%;
  }
`;

const InputMessage = styled.textarea`
  width: 100%;
  padding: 10px;
  position: relative;
  border: 2px solid #0074d9;
  border-radius: 5px;
  margin: 10px 0px 0px 0px;
  resize: none;
  padding-bottom: 20px;
  &.focus {
    border: 3px solid red;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const AccessButton = styled.button`
  color: white;
  padding: 10px;
  border: 1px solid #0074d9;
  border-radius: 5px;
  background: #0074d9;
  cursor: pointer;
  height: 40px;
  padding: 10px;
  font-weight: 500;
  &:hover {
    background: unset;
    color: #0074d9;
    border: 2px solid #0074d9;
    animation: ${fadeIn} 0.5s ease;
  }
  &:disabled {
    background: lightgrey;
    color: white;
    border: none;
    cursor: wait;
  }
`;

const Tooltip = styled.span`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
  animation: ${fadeIn} 0.3s ease;

  left: 0;
  top: -50px;
  width: fit-content;
  white-space: pre-wrap;
  &.show {
    visibility: visible;
    opacity: 1;
  }
`;

const CopyButton = styled.button`
  border-radius: 20px;
  background-color: unset;
  border: 1px solid #0074d9;
  position: relative;
  color: #0074d9;
  height: 40px;
  padding: 10px;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    background-color: #0074d9;
    color: white;

    animation: ${fadeIn} 0.5s ease;
  }
`;

const Profile = styled.div`
  margin-top: 0;
  height: 40px;
  width: 40px;
  position: relative;
`;

const MeetingsList = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
`;

const MeetingsNamesContainer = styled.div`
  min-height: 30px;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 20px 0px 20px 15px;
`;

const MeetingsNames = styled.p`
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
  color: black;
`;

const PersonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  justify-content: space-between;
  text-align: left;
`;

const PersonInnerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const PersonDeleteButton = styled.button`
  color: red;
  border: 0px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  cursor: pointer;
  &:hover {
    background-color: red;
    color: white;
    animation: ${fadeIn} 0.5s ease;
  }
  &:disabled {
    background: lightgrey;
    color: white;
    cursor: wait;
  }
`;

const BottomButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 20px;
`;

const GeneralAccessCotnainer = styled.div`
  display: none;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const SwitchButtonContainer = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
`;

const PeopleAccessContainer = styled.div``;

const PeopleContainer = styled.div`
  max-height: 150px;
  min-height: 150px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: inset 0 -20px 20px -20px rgba(0, 0, 0, 0.05);
`;

const MessageContainer = styled.div`
  position: relative;
`;

const MessageLimit = styled.p`
  color: rgba(0, 0, 0, 0.6);
  margin: 0px;
  margin-inline-start: auto;
`;

const MessageInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TagInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

class ShareModal extends Component {
  state = {
    emailString: "",
    disabled: false,
    messageString: "",
    messageError: "",
    emailError: "",
    attendees: [],
    selectedEmails: [],
    tagInputText: "",
    multiMeetingShare: false,
  };

  maxCharacterLimit = 2000;

  componentDidMount() {
    // meetingData Singular is coming from  parent component in props
    const meetingIds = this.props.meetingData.map((meeting) => meeting.id);

    if (this.props.meetingData.length === 1) {
      // meetingsData Plural is directly coming from props from redux store
      const meetingDetails = this.props.meetingsData.filter(
        (meeting) => meeting.id === meetingIds[0]
      );

      this.setState({ attendees: meetingDetails[0].attendees });
    } else {
      this.setState({ multiMeetingShare: true });
    }
  }

  messageHandler = (event) => {
    const msgStr = event.target.value;
    if (msgStr.length >= this.maxCharacterLimit) {
      this.setState({
        messageString: msgStr,
        messageError: "You have reached the maximum character limit.",
      });
    } else {
      this.setState({ messageString: msgStr, messageError: "" });
    }
  };

  handleTagChange = (selectedEmails) => {
    this.setState({ selectedEmails: selectedEmails });
  };

  handleInputChange = (event) => {
    this.setState({ tagInputText: event.target.value });
  };

  handleBeforeAddValidate = (tag, existingTags) => {
    // Custom validation for email address format
    const emailPattern =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    if (!emailPattern.test(tag.trim())) {
      this.setState({
        emailError:
          "The email address you entered is invalid. Please double-check it. Remember to remove any extra spaces.",
      });
      return false; // Don't add the tag if it's not a valid email address
    }
    this.setState({ emailError: "" });
    return true; // Add the tag if it's a valid email address
  };

  handleCopyLink = () => {
    const meetingIds = this.props.meetingData.map((meeting) => meeting.id);
    if (meetingIds.length === 1) {
      const link = `${process.env.REACT_APP_HOST}/home/meeting/${meetingIds[0]}`;

      navigator.clipboard.writeText(link);
      this.showTooltip();
    }
  };

  handleSubmit = () => {
    this.setState({ disabled: true });
    if (this.state.selectedEmails.length === 0) {
      if (this.state.tagInputText.length === 0) {
        this.setState({
          emailError: "Please enter at least one email address.",
          disabled: false,
        });
      } else {
        this.setState({
          emailError: "Hit the return key after entering an email address.",
          disabled: false,
        });
      }
    } else if (this.state.messageError) {
      this.setState({ disabled: false });
    } else {
      const meetingIds = this.props.meetingData.map((meeting) => meeting.id);
      const newAttendees = this.state.selectedEmails.map((email) => ({
        email: email.trim(),
      }));
      this.props
        .shareMeeting(
          this.props.accessToken,
          meetingIds,
          newAttendees,
          this.state.messageString
        )
        // induce a two second delay before doing the redirect to make it feel more natural
        .then(() => wait(2000))
        .then(() => this.props.history.push("/"))
        .catch((error) => {
          this.setState({
            disabled: false,
            error: "There was an error processing your request.",
          });
        });
    }
  };

  // Method to show the tooltip
  showTooltip = () => {
    const tooltip = document.getElementById("copy-share-link-tooltip");
    tooltip.classList.add("show");
    setTimeout(() => {
      tooltip.classList.remove("show");
    }, 2000); // Hide the tooltip after 2 seconds
  };

  handleDeleteAttendee = (attendeeEmail) => {
    const meetingId = this.props.meetingData.map((meeting) => meeting.id)[0];

    this.setState({ disabled: true });

    this.props
      .deleteAttendee(this.props.accessToken, meetingId, attendeeEmail)
      .then(() => {
        const updatedAttendees = this.props.meetingsData.filter(
          (meeting) => meeting.id === meetingId
        )[0].attendees;
        this.setState({ attendees: updatedAttendees, disabled: false });
      })
      .catch((error) => {
        this.setState({ disabled: false });
      });
  };

  render() {
    const placeholderMessage = "(Optional) Add a message for your guests.";

    const separators = ["Enter", " ", ","];
    const meetingData = this.props.meetingData;
    return (
      <GradientHybridModalNarrow
        isOpen={this.props.isOpen}
        shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
        onRequestClose={this.props.onRequestClose}
        padding={"12px"}
        maxHeight={"92vh"}
        zIndex={"12"}
        title=""
      >
        <ModalBody>
          <MeetingsList>
            {meetingData.length === 1 ? (
              <p
                style={{
                  width: "100%",
                  height: "50px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                Share{" "}
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "1.2rem",
                    lineHeight: "25px",
                    cursor: "pointer",
                  }}
                  title={meetingData[0].name}
                >
                  {meetingData[0].name}
                </span>
              </p>
            ) : (
              <div>
                <div>
                  Share{" "}
                  {this.props.meetingData ? this.props.meetingData.length : 0}{" "}
                  meetings
                </div>
                <MeetingsNamesContainer>
                  {this.props.meetingData
                    ? this.props.meetingData.map((meeting, index) => (
                        <MeetingsNames key={index}>
                          - {meeting.name}
                        </MeetingsNames>
                      ))
                    : null}
                </MeetingsNamesContainer>
              </div>
            )}
          </MeetingsList>
          <TagInputContainer>
            <TagsInput
              value={this.state.selectedEmails}
              onChange={this.handleTagChange}
              onKeyUp={(event) => this.handleInputChange(event)}
              name="Emails"
              placeHolder="Add an email address, then hit the return key."
              beforeAddValidate={this.handleBeforeAddValidate}
              separators={separators}
            />
            <div className="text-danger" style={{ marginTop: "5px" }}>
              {paintRed(this.state.emailError)}
            </div>
          </TagInputContainer>
          <MessageContainer>
            <InputMessage
              placeholder={placeholderMessage}
              name="Text1"
              cols="40"
              rows="5"
              onChange={this.messageHandler}
              maxLength={this.maxCharacterLimit}
              value={this.state.messageString}
            ></InputMessage>
            <MessageInfoContainer>
              <div className="text-danger">
                {paintRed(this.state.messageError)}
              </div>
              <MessageLimit>
                {this.state.messageString.length}/2000
              </MessageLimit>
            </MessageInfoContainer>
          </MessageContainer>
          {this.state.multiMeetingShare ? (
            <></>
          ) : (
            <PeopleAccessContainer>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <h3>People with read access</h3>
                <InfoCircle
                  title={
                    "Shared members will be able to view, download, and search through the meeting content."
                  }
                  style={{ marginLeft: "7px" }}
                >
                  i
                </InfoCircle>
              </div>
              <PeopleContainer>
                {this.state.attendees.map((person, index) => (
                  <PersonContainer key={index}>
                    {/* In Row of person there is left container with profilepic and email and right side with role and delete  */}

                    <PersonInnerContainer //INSIDE ROW LEFT CONTAINER
                    >
                      <Profile
                        style={{ margin: "0px" }}
                        className="profile-picture"
                      />
                      <p> {person.email} </p>
                    </PersonInnerContainer>
                    <PersonInnerContainer //INSIDE ROW RIGHT CONTAINER
                    >
                      {person.role === "host" ? (
                        <p>(Host)</p>
                      ) : (
                        <PersonDeleteButton
                          onClick={() =>
                            this.handleDeleteAttendee(person.email)
                          }
                          disabled={this.state.disabled}
                        >
                          {" "}
                          &#10006;{" "}
                        </PersonDeleteButton>
                      )}
                    </PersonInnerContainer>
                  </PersonContainer>
                ))}
              </PeopleContainer>
            </PeopleAccessContainer>
          )}

          <GeneralAccessCotnainer>
            <h3>General Access</h3>
            <SwitchButtonContainer>
              <SwitchButton option1="Public" option2="Private" margin="0px" />
            </SwitchButtonContainer>
          </GeneralAccessCotnainer>
          <BottomButtonContainer>
            {this.state.multiMeetingShare ? (
              <></>
            ) : (
              <CopyButton onClick={this.handleCopyLink}>
                Copy Share Link
                <Tooltip id="copy-share-link-tooltip">Link Copied!</Tooltip>
              </CopyButton>
            )}
            <AccessButton
              onClick={this.handleSubmit}
              disabled={this.state.disabled}
            >
              Share
            </AccessButton>
          </BottomButtonContainer>
        </ModalBody>
      </GradientHybridModalNarrow>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    shareMeeting: (accessToken, meetingId, attendees, messageString) =>
      dispatch(
        actions.shareMeeting(accessToken, meetingId, attendees, messageString)
      ),
    deleteAttendee: (accessToken, meetingId, attendeeEmail) =>
      dispatch(actions.deleteAttendee(accessToken, meetingId, attendeeEmail)),
  };
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    meetingsData: state.meetings.data,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShareModal)
);

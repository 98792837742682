import React, { Component } from "react";
import Switch from "react-switch";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const Setting = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a0a0a0;
  font-weight: bolder;
  margin: 1% 1% 1% 1%;
`;

const InfoCircle = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;
  border: 1px solid;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
`;

class ToggleSetting extends Component {
  state = {};

  render() {
    return (
      <Setting>
        <span>
          {this.props.settingText}{" "}
          <InfoCircle title={this.props.settingTooltipText}>i</InfoCircle>
        </span>
        <Switch
          onChange={this.props.onToggleChange}
          checked={
            this.props.settingChecked ? this.props.settingChecked : false
          }
          checkedIcon={false}
          uncheckedIcon={false}
          onColor="#754ff2"
          height={18}
          width={36}
        />
      </Setting>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ToggleSetting)
);

import React, { Component } from "react";
import styled from "styled-components";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import backgroundImage3x from "../../../assets/images/background_images/signin_signup_background@3x.png";
import backgroundImage2x from "../../../assets/images/background_images/signin_signup_background@2x.png";

import Signup from "./Cards/Signup";
import Signin from "./Cards/Login";
import Logout from "./Cards/Logout";
import ResetPassword from "./Cards/ResetPassword";
import RequestResetPassword from "./Cards/RequestResetPassword";
import { media } from "../../../components/UI/StyleUtils";

const PageStyles = styled.div`
  background-color: white;
  min-height: 100%;
  min-width: 100%;
  display: flex;
  justify-content: center;

  ${media.gtXSm`
        align-items: center;
        background: url(${backgroundImage2x}) no-repeat center top;
        background-color: #F6F8FB; 
        background-size: contain;
    `};

  ${media.gtSm`
        background: url(${backgroundImage3x}) no-repeat center top;
        background-color: #F6F8FB;
        background-size: contain;  
        align-items: center;
        
    `};
`;

class LoginLogoutSignup extends Component {
  render() {
    const authRedirect = this.props.isAuthenticated ? (
      <Redirect to="/" />
    ) : null;

    return (
      <PageStyles>
        <Switch>
          <Route path={this.props.match.url + "/logout"} component={Logout} />{" "}
          {authRedirect}
          <Route path={this.props.match.url + "/signup"} component={Signup} />
          <Route path={this.props.match.url + "/login"} component={Signin} />
          <Route
            path={this.props.match.url + "/requestResetPassword"}
            component={RequestResetPassword}
          />
          <Route
            path={this.props.match.url + "/resetPassword"}
            component={ResetPassword}
          />
          <Redirect to="/" />
        </Switch>
      </PageStyles>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.accessToken !== null,
  };
};

export default connect(mapStateToProps)(LoginLogoutSignup);

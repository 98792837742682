import React, { Component } from "react";
import * as actions from "../../../store/actions/index";
import { connect } from "react-redux";
import ReactDOM from "react-dom";

import styled from "styled-components";
import profileImage1 from "../../../assets/images/background_images/profile_banner_image@1x.png";
import profileImage2 from "../../../assets/images/background_images/profile_banner_image@2x.png";
import SmallButton from "../../UI/Buttons/SmallButton";
import { media } from "../../UI/StyleUtils";
import { Tabs, Tab, Panels, Panel } from "./Tabs/Tabs";
import AccountPanel from "./Account/AccountPanel";
import GeneralPanel from "./General/GeneralPanel";
import IntegrationsPanel from "./Integrations/IntegrationsPanel";
import SubscriptionPanel from "./Subscription/SubscriptionPanel";

const SmallButtonPurple = styled(SmallButton)`
  background-color: #764ef2;
  width: 50px;
  float: right;
  color: white;
  height: 30px;

  ${media.ltSm`
        width: 80%;
        float:none;
    `}
`;

const ButtonContainer = styled.div`
  height: 56px;
`;
const StyledForm = styled.form`
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: 5%;
  margin-right: 5%;
`;

const Title = styled.div`
  margin: 15px 0 11px 22px;
  font-size: 22px;
  color: #170f30;
`;

const MiddleContainer = styled.div`
  min-height: 150px;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  height: 50%;
  background: url(${profileImage2}) no-repeat;
  background-position: center top;
  background-size: 100% 75%;

  ${media.ltSm`
        background: url(${profileImage1}) no-repeat top;
        width: 100%;
        color: white
        background-position: center top;
        background-size: 100% 75%;
    `}

  padding-right:0px;
  padding-left: 0px;
`;

const BottomContainer = styled.div`
  padding-left: 8px;
  padding-top: 20px;
  text-align: center;
`;

const FormInputContainer = styled.div`
  margin-bottom: 26px;
  display: inline-block;
  margin-left: auto;
  width: 70%;
`;

const FormBlockContainer = styled.div`
  display: block;
  margin-left: auto;
`;

const StyledLabel = styled.div`
  width: 30%;
  display: inline-block;
  color: #170f30;
  font-weight: bold;
  opacity: 0.25;
`;

const Input = styled.input`
  &::placeholder {
    opacity: 1;
    color: #170f30;
  }

  &:focus {
    border-radius: 10px;
    background-color: #f2f7fa;
    border-style: hidden;
  }
  color: #764ef2;
  height: 40px;
  width: 100%;
  padding-left: 5px;
  border: none;
`;

const SettingsContainer = styled.div`
  max-width: 680px;
  height: 100%;
`;

const Profile = styled.div`
  top: 45px;
  margin-top: 0;
  height: 143px;
  width: 143px;
  position: relative;
`;

class Settings extends Component {
  state = {
    tab: "profile",
  };

  updateProfile = (event) => {
    console.log(this.props.userId);

    this.props.onUpdate(
      this.props.accessToken,
      ReactDOM.findDOMNode(this.refs.firstNameRef).value,
      ReactDOM.findDOMNode(this.refs.lastNameRef).value,
      ReactDOM.findDOMNode(this.refs.companyRef).value,
      this.props.userId
    );
  };

  changeTab = (tab) => {
    this.setState({
      tab: tab,
    });
  };

  render() {
    const profilePanel = (
      <Panel style={{ backgroundColor: "white" }}>
        <MiddleContainer>
          <Profile className="profile-picture" />
        </MiddleContainer>
        <BottomContainer>
          <StyledForm onSubmit={this.updateProfile}>
            <FormBlockContainer>
              <StyledLabel> First Name </StyledLabel>
              <FormInputContainer>
                <Input
                  placeholder={this.props.firstName}
                  type="text"
                  ref="firstNameRef"
                  onFocus="this.placeholder = ''"
                />
              </FormInputContainer>
            </FormBlockContainer>
            <FormBlockContainer>
              <StyledLabel> Last Name </StyledLabel>
              <FormInputContainer>
                <Input
                  placeholder={this.props.lastName}
                  type="text"
                  ref="lastNameRef"
                />
              </FormInputContainer>
            </FormBlockContainer>
            <FormBlockContainer>
              <StyledLabel> Company </StyledLabel>
              <FormInputContainer>
                <Input
                  placeholder={this.props.company}
                  type="text"
                  ref="companyRef"
                />
              </FormInputContainer>
            </FormBlockContainer>
            <FormBlockContainer>
              <StyledLabel> Email </StyledLabel>
              <FormInputContainer>
                <Input
                  placeholder={this.props.email}
                  type="text"
                  ref="emailRef"
                  readOnly
                />
              </FormInputContainer>
            </FormBlockContainer>
            <ButtonContainer>
              <SmallButtonPurple type="submit"> Save </SmallButtonPurple>
            </ButtonContainer>
          </StyledForm>
        </BottomContainer>
      </Panel>
    );

    return (
      <SettingsContainer>
        <Title>Settings</Title>
        <Tabs>
          <Tab
            onClick={() => this.changeTab("profile")}
            selected={this.state.tab === "profile"}
          >
            Profile
          </Tab>
          <Tab
            onClick={() => this.changeTab("account")}
            selected={this.state.tab === "account"}
          >
            Account
          </Tab>
          <Tab
            onClick={() => this.changeTab("general")}
            selected={this.state.tab === "general"}
          >
            General
          </Tab>
          <Tab
            onClick={() => this.changeTab("integrations")}
            selected={this.state.tab === "integrations"}
          >
            Integrations
          </Tab>
          {`${process.env.REACT_APP_STRIPE_UI_ENABLED}` === "true" ||
          `${process.env.REACT_APP_STRIPE_WHITELIST_ACCOUNTS}`.includes(
            this.props.email
          ) ? (
            <Tab
              onClick={() => this.changeTab("subscription")}
              selected={this.state.tab === "subscription"}
            >
              Subscription
            </Tab>
          ) : (
            <></>
          )}
        </Tabs>
        <Panels>
          {this.state.tab === "profile" && profilePanel}
          {this.state.tab === "account" && <AccountPanel></AccountPanel>}
          {this.state.tab === "general" && <GeneralPanel />}
          {this.state.tab === "integrations" && (
            <IntegrationsPanel
              zoom_integrated={this.props.zoom_integrated}
              zoom_refresh_token_exists={this.props.zoom_refresh_token_exists}
              webex_integrated={this.props.webex_integrated}
              webex_refresh_token_exists={this.props.webex_refresh_token_exists}
            />
          )}
          {this.state.tab === "subscription" && (
            <SubscriptionPanel></SubscriptionPanel>
          )}
        </Panels>
      </SettingsContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserData: (accessToken) => dispatch(actions.getUser(accessToken)),
    onUpdate: (accessToken, firstName, lastName, company, userId) =>
      dispatch(
        actions.updateUser(accessToken, firstName, lastName, company, userId)
      ),
  };
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    firstName: state.user.firstName,
    lastName: state.user.lastName,
    company: state.user.company,
    email: state.user.email,
    userId: state.user.userId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);

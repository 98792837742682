import React from "react";

import BaseModal from "../BaseModal";

/**
 * A simple React Modal component designed for mobile/Tablet screens.
 *
 * @param props
 * {
 *  isOpen - indicates if the modal should be currently open or not.
 *  onRequestClose - a function that should be called when the modal is closed by the user
 * }
 * @returns JSX component
 * @constructor
 */
const MobileModalTransparentSides = (props) => {
  return (
    <BaseModal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      width={"93%"}
      top={"3%"}
      backgroundColor={"rgba(41,42,68, 0.92)"}
      zIndex={props.zIndex}
    >
      {props.children}
    </BaseModal>
  );
};

export default MobileModalTransparentSides;

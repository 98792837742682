import * as actionTypes from "../actions/actionTypes";

const initialState = {
  meetingId: null,
  localUser: null,
  remoteAttendees: [],
  loading: false,
  error: null,
};

const meetingSessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_MEETING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CREATE_MEETING_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    // we need to perform the exact same work for the next three actions.
    case actionTypes.ADDED_REMOTE_ATTENDEES:
    case actionTypes.UPDATED_REMOTE_ATTENDEES:
    case actionTypes.REMOVED_REMOTE_ATTENDEES:
      return {
        ...state,
        remoteAttendees: action.attendees,
      };
    case actionTypes.ADD_NEW_MEETING_ATTENDEE_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_NEW_MEETING_ATTENDEE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ADD_NEW_MEETING_ATTENDEE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.SETUP_MEETING_SESSION:
      return {
        ...state,
        meetingId: action.meetingId,
        localUser: action.localUser,
      };
    default:
      return state;
  }
};

export default meetingSessionReducer;

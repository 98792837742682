import React, { Component } from "react";
import styled from "styled-components";

import GradientHybridModalNarrow from "./GradientHybridModalNarrow";

const ModalBody = styled.div`
  background-color: #f1f7fb;
`;

const Instructions = styled.div`
  background-color: white;
  padding: 35px 41px 20px 41px; //per UI mock-up, padding: 41px 41px 37px 41px;
  line-height: 24px;
  font-size: 16px;
  opacity: 0.8;
  text-align: center;
`;

const Title = styled.div`
  margin: 0 20px 8px 20px;
  letter-spacing: 0.5px;
  font-size: 28px;
  line-height: 37px;
`;

const Explanation = styled.div`
  font-size: 14px; //per UI mockup-up 16px.
  opacity: 0.7;
  line-height: 20px; //per UI mock-up, 24px.
  text-align: left;
  white-space: pre-wrap;
  margin-bottom: 20px;
`;

class MessageModal extends Component {
  state = {
    disabled: false,
  };

  render() {
    return (
      <GradientHybridModalNarrow
        isOpen={this.props.isOpen}
        shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
        onRequestClose={this.props.onRequestClose}
        padding={"0px"}
        maxHeight={"90vh"}
        top={"100px"}
      >
        <ModalBody>
          <Instructions>
            <Title>{this.props.title}</Title>
            <Explanation>{this.props.message}</Explanation>
            {this.props.children}
          </Instructions>
        </ModalBody>
      </GradientHybridModalNarrow>
    );
  }
}

export default MessageModal;

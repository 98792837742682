import React, { Component } from "react";
import styled from "styled-components";
import todoistIcon from "../../../assets/images/todoist/todoist_icon.png";

const TodoistLogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

/**
 *
 * A component that places the Todoist icon next to the desired text. The desired text is optional.
 *  props
 *  {
 *      height: the height of the Todoist icon
 *      width: the width of the Todoist icon
 *      logoText: the logo text, which will appear to the right of the icon if given
 * }
 */
class TodoistLogo extends Component {
  state = {};

  height = () => (this.props.height ? this.props.height : "25px");
  width = () => (this.props.width ? this.props.width : "25px");

  render() {
    return (
      <TodoistLogoContainer>
        <img
          src={todoistIcon}
          alt="Todoist Logo"
          style={{ height: this.height(), width: this.width() }}
        />
        {this.props.logoText ? (
          <div style={{ marginLeft: "5px" }}>{this.props.logoText}</div>
        ) : null}
      </TodoistLogoContainer>
    );
  }
}

export default TodoistLogo;

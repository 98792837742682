import * as actionTypes from "../actions/actionTypes";
import { QueryTypes } from "../../components/Dashboard/Meetings/SearchBar";

const initialState = {
  //TODO: make this structure better, restructure existing content under byName and byContent!
  queryType: QueryTypes.NAME,
  query: "", // meeting name/content to search for
  meeting: {
    // used only when one meeting is selected
    id: null,
    name: null,
    operation: null,
  },
  currentPageNumber: 1, // this page correspond to page number on the front-end and not api page
  totalMeetings: 0, // currently the data for actual meeting rows is stored by this and next two fields. Eventually, it should be restructured.
  pageSize: 9,
  data: [],
  byContent: {
    textSearchPageSize: 12,
    voiceSearchPageSize: 10,
    totalMatches: 0,
    matchedData: [],
  },
  error: null,
  loading: false,
};

const meetingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SEARCH_QUERY_TYPE:
      return {
        ...state,
        queryType: action.queryType,
      };
    case actionTypes.GET_MEETINGS_START:
    case actionTypes.SEARCH_MEETINGS_TEXT_CONTENT_START:
    case actionTypes.SEARCH_MEETINGS_VOICE_CONTENT_START:
      return {
        ...state,
        loading: true,
        query: action.query,
        totalMeetings: state.query === action.query ? state.totalMeetings : 0, // if this a new query, set totalMeetings to 0 because we don't know how many will be there
      };
    case actionTypes.GET_MEETINGS_FAIL:
    case actionTypes.SEARCH_MEETINGS_TEXT_CONTENT_FAIL:
    case actionTypes.SEARCH_MEETINGS_VOICE_CONTENT_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case actionTypes.GET_MEETINGS_SUCCESS:
      return {
        ...state,
        totalMeetings: action.totalMeetings,
        currentPageNumber: action.currentPageNumber,
        data: action.data,
        meeting: {
          id: action.meetingId,
          name: action.meetingName,
          operation: action.operation,
        },
        loading: false,
      };
    case actionTypes.SEARCH_MEETINGS_TEXT_CONTENT_SUCCESS:
    case actionTypes.SEARCH_MEETINGS_VOICE_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentPageNumber: action.currentPageNumber,
        byContent: {
          ...state.byContent,
          totalMatches: action.totalMatches,
          matchedData: action.matchedData,
        },
        meeting: {
          id: action.meetingId,
          name: action.meetingName,
        },
        error: null,
      };
    case actionTypes.DELETE_MEETING_START:
      return {
        ...state,
      };
    case actionTypes.DELETE_MEETING_SUCCESS:
      return {
        ...state,
        error: false,
      };
    case actionTypes.DELETE_MEETING_FAIL:
      return {
        ...state,
        error: action.error,
      };

    case actionTypes.SHARE_MEETING_START:
      return {
        ...state,
      };
    case actionTypes.SHARE_MEETING_SUCCESS:
      return {
        ...state,
        error: false,
      };
    case actionTypes.SHARE_MEETING_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.DELETE_ATTENDEE_START:
      return {
        ...state,
      };
    case actionTypes.DELETE_ATTENDEE_SUCCESS:
      return {
        ...state,
        data: state.data.map((meeting) =>
          meeting.id === action.meetingData.id
            ? {
                ...meeting,
                attendees: action.meetingData.attendees.map((attendeeInfo) => ({
                  id: attendeeInfo.emails ? attendeeInfo.emails.id : null,
                  email: attendeeInfo.emails ? attendeeInfo.emails.email : null,
                  name: attendeeInfo.name,
                  role: attendeeInfo.role,
                })),
                numAttendees: action.meetingData.attendee_count,
              }
            : meeting
        ),
        error: null,
      };
    case actionTypes.DELETE_ATTENDEE_FAIL:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default meetingsReducer;

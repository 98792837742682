import React from "react";
import styled from "styled-components";

import Icon from "../../UI/Icon/Icon";
import { media } from "../../UI/StyleUtils";
import SimpleMenu from "../../UI/Menu/Menu";

const SearchInputBar = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 14px;
  position: relative;
  width: 100%;

  ${media.gtSm`
        width: 270px;
    `};
`;

const InputVioletBorder = styled.input`
  &::placeholder {
    color: #170f30;
    opacity: 0.25;
    font-weight: 400;
  }

  &:focus {
    border: 1px solid #9471ff;
  }

  outline: none;
  color: #354052;
  font-weight: bold;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  border: 1px solid rgba(23, 15, 48, 0.25);
  border-radius: 3px;
  padding: 0 34px 0 18px;
  width: 100%;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 14px;
  top: calc(50% - 7.5px);
  cursor: pointer;
`;

const MenuButton = styled.div`
  width: 105px;
  height: 36px;
  margin: 0 3px 3px 0;
  padding-left: 5px;
  border: 1px solid #c5c3cc;
  cursor: pointer;
  border-radius: 3px;
  line-height: 36px;
`;

export class QueryTypes {
  static NAME = "Name";
  static TEXT = "Text";
  static VOICE = "Voice";
}

const SearchBar = ({
  currentFilter,
  currentFilterType,
  updateFilterType,
  onFilterChange,
  getSearchResults,
}) => {
  // search for meetings content
  let placeholderText;
  if (currentFilterType === QueryTypes.NAME)
    placeholderText = "Filter meetings by name";
  else if (currentFilterType === QueryTypes.TEXT)
    placeholderText = "Search for text content";
  else placeholderText = "Search for voice content";

  const menuButton = (
    <MenuButton>
      By {currentFilterType} &nbsp;
      <span style={{ fontSize: "10px", height: "36px" }}> &#x25bc;</span>
    </MenuButton>
  );
  const searchTypeDropdown = (
    <SimpleMenu
      menuButton={menuButton}
      menuItems={[QueryTypes.NAME, QueryTypes.TEXT, QueryTypes.VOICE]}
      handleSelection={updateFilterType}
    />
  );

  const searchInputBar = (
    <SearchInputBar>
      <InputVioletBorder
        placeholder={placeholderText}
        onKeyUp={onFilterChange}
      />
      <IconWrapper
        onClick={(e) => {
          getSearchResults();
        }}
      >
        <Icon name="search_purple_icon" alt="Search" />
      </IconWrapper>
    </SearchInputBar>
  );

  return (
    <div style={{ display: "inline-flex", alignItems: "center" }}>
      {searchInputBar}
      {searchTypeDropdown}
    </div>
  );
};

export default SearchBar;

import React from "react";
import styled from "styled-components";
import moment from "moment";

import { media } from "../../../../UI/StyleUtils";

const DateTimeContainer = styled.div`
  flex-basis: 35%;

  ${media.gtXSm`
      flex-basis: 30%;
  `}
  ${media.gtSm`
      flex-basis: 18%;
    `}
`;

const Date = styled.p`
  margin: 0 0 0px 4px;
  height: 16px;
  color: #170f30;
  font-size: 11px;
  font-weight: bold;
  line-height: 16px;

  ${media.gtSm`
	    margin: 0 0 4px 9px;
	`}
`;

const Time = styled.p`
  display: inline-block;
  margin: 0;
  color: #c5c3cb;
  font-size: 24px;
  line-height: 28px;

  ${media.gtSm`
	    font-size: 36px;
        line-height: 42px;
    `}
`;

const AmPm = styled.p`
  margin: 0 0 0 4px;
  display: inline-block;
  opacity: 0.25;
  color: #170f30;
  font-weight: bolder;
  font-size: 12px;
  line-height: 16px;
`;

const DateTime = (props) => {
  const meetingTime = moment(props.time);

  return (
    <DateTimeContainer>
      <Date>
        {moment().year() === meetingTime.year()
          ? meetingTime.format("ddd, MMM D")
          : meetingTime.format("ddd, MMM D Y")}
      </Date>
      <div>
        <Time>{meetingTime.format("h:mm")}</Time>
        <AmPm>{meetingTime.format("A")}</AmPm>
      </div>
    </DateTimeContainer>
  );
};

export default DateTime;

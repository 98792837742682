import React, { Component } from "react";
import styled from "styled-components";

import GradientHybridModalNarrow from "../UI/Modals/Hybrid/GradientHybridModalNarrow";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../store/actions";

import CheckBox from "../UI/Forms/Checkbox";

import { SmallButtonPurple } from "../UI/Buttons/SmallButton";

const ModalBody = styled.div`
  background-color: #f1f7fb;
`;

const MeetingNotes = styled.div`
  background-color: white;
  padding: 35px 41px 20px 41px; //per UI mock-up, padding: 41px 41px 37px 41px;
  line-height: 24px;
  font-size: 16px;
  opacity: 0.8;
  text-align: center;
`;

const NotesTitle = styled.div`
  margin: 0px 20px 8px 20px;
  letter-spacing: 0.5px;
  font-size: 28px;
  line-height: 37px;
`;

const NotesContent = styled.div`
  font-size: 14px; //per UI mockup-up 16px.
  opacity: 0.7;
  line-height: 20px; //per UI mock-up, 24px.
  text-align: left;
  overflow-y: auto;
  height: 550px;
  min-width: 400px;
  white-space: pre-line;
  margin: 0px 0px 0px 0px;
`;

const OverviewContainer = styled.div`
  margin-bottom: 40px;
`;

const SummaryContainer = styled.div`
  margin-bottom: 40px;
`;

const ActionItemsContainer = styled.div`
  margin-bottom: 20px;
`;

const ActionItemsHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ActionItemsTitle = styled.div`
  margin-bottom: 20px;
`;

const AddToTodoistButtonContainer = styled.div`
  height: 25px;
`;

const ActionItemsContentContainer = styled.div`
  margin-bottom: 20px;
`;

const MeetingPart = styled.div``;

const ActionItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ActionItem = styled.div`
  display: flex;
  flex-direction: row;
`;

const ActionItemNumber = styled.div`
  margin-right: 5px;
`;

const ActionItemContent = styled.div``;

class NotesModal extends Component {
  state = {
    displayAddToTodoistButton: "none",
    actionItemsCheckedObj: {},
  };

  componentDidMount() {
    let initialActionItemsCheckedObj = {};
    initialActionItemsCheckedObj = this.initializeActionItemsCheckedObj(
      initialActionItemsCheckedObj
    );

    this.setState({
      actionItemsCheckedObj: initialActionItemsCheckedObj,
    });
  }

  initializeActionItemsCheckedObj = (actionItemsCheckedObj) => {
    this.props.meetingNotes.actionItems.forEach((actionItem, index) => {
      actionItemsCheckedObj[actionItem.number] = false;
    });
    return actionItemsCheckedObj;
  };

  showTodoistOptions = () => {
    let showTodoistOptions =
      this.props.todoistIntegrated && this.props.todoistAccessTokenExists;
    return showTodoistOptions;
  };

  allActionItemsUnchecked = (actionItemsCheckedObj) => {
    let allActionItemsAreUnchecked = true;
    Object.values(actionItemsCheckedObj).forEach((actionItemChecked) => {
      if (actionItemChecked) {
        allActionItemsAreUnchecked = false;
      }
    });
    return allActionItemsAreUnchecked;
  };

  onActionItemChecked = (actionIdNumber) => {
    let newActionItemsCheckedObj = {};
    let displayAddToTodoistButton = this.state.displayAddToTodoistButton;
    Object.keys(this.state.actionItemsCheckedObj).forEach(
      (actionItemNumberKey, idx) => {
        if (parseInt(actionItemNumberKey, 10) === actionIdNumber) {
          newActionItemsCheckedObj[actionItemNumberKey] =
            !this.state.actionItemsCheckedObj[actionItemNumberKey];
        } else {
          newActionItemsCheckedObj[actionItemNumberKey] =
            this.state.actionItemsCheckedObj[actionItemNumberKey];
        }

        if (this.allActionItemsUnchecked(newActionItemsCheckedObj)) {
          displayAddToTodoistButton = "none";
        } else {
          displayAddToTodoistButton = "inline";
        }
      }
    );

    this.setState({
      displayAddToTodoistButton: displayAddToTodoistButton,
      actionItemsCheckedObj: newActionItemsCheckedObj,
    });
  };

  onAddToTodoistButtonClick = () => {
    let actionItemNumbersToStore = [];
    Object.entries(this.state.actionItemsCheckedObj).forEach(
      ([actionItemNumber, checked], index) => {
        if (checked) {
          actionItemNumbersToStore.push(actionItemNumber);
        }
      }
    );
    this.props
      .storeActionItemsInTodoist(
        this.props.accessToken,
        this.props.meetingNotes.notesId,
        actionItemNumbersToStore
      )
      .then(() => {
        let newActionItemsCheckedObj = this.state.actionItemsCheckedObj;
        newActionItemsCheckedObj = this.initializeActionItemsCheckedObj(
          newActionItemsCheckedObj
        );

        this.setState({
          actionItemsCheckedObj: newActionItemsCheckedObj,
          displayAddToTodoistButton: "none",
        });
      })
      .catch((error) => {
        alert(
          "There was problem while storing your action items in Todoist. Please contact support@capturemymeeting.com."
        );
      });
  };

  render() {
    return (
      <GradientHybridModalNarrow
        isOpen={this.props.isOpen}
        shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
        onRequestClose={this.props.onRequestClose}
        padding={"0px"}
        maxHeight={"90vh"}
      >
        <ModalBody>
          <MeetingNotes>
            <NotesTitle>{this.props.meetingName}</NotesTitle>
            <NotesContent>
              {this.props.meetingNotes.overview ? (
                <OverviewContainer>
                  {this.props.meetingNotes.overview}
                </OverviewContainer>
              ) : null}
              <SummaryContainer>
                {this.props.meetingNotes.summary}
              </SummaryContainer>
              <ActionItemsContainer>
                <ActionItemsHeadingContainer>
                  <ActionItemsTitle>Action Items</ActionItemsTitle>
                  {this.showTodoistOptions() ? (
                    <AddToTodoistButtonContainer
                      style={{ display: this.state.displayAddToTodoistButton }}
                    >
                      <SmallButtonPurple
                        type="button"
                        loading={this.props.meetingNotes.loading}
                        disabled={this.props.meetingNotes.loading}
                        onClick={this.onAddToTodoistButtonClick}
                      >
                        Add to Todoist
                      </SmallButtonPurple>
                    </AddToTodoistButtonContainer>
                  ) : null}
                </ActionItemsHeadingContainer>
                {this.props.meetingNotes.timestampRanges.map(
                  (timestampRange, index) => (
                    <ActionItemsContentContainer>
                      {timestampRange !== "0:00:00 - End of Meeting" ? (
                        <MeetingPart key={index}>
                          ----Part {index + 1} of the Meeting ({timestampRange}
                          )----
                        </MeetingPart>
                      ) : null}
                      {this.props.meetingNotes.actionItems.map(
                        (actionItem, index) => {
                          if (actionItem.timestamp_range === timestampRange) {
                            return (
                              <ActionItemContainer>
                                {this.showTodoistOptions() &&
                                actionItem.id &&
                                !actionItem.stored_in_todoist ? (
                                  <CheckBox
                                    fontSize="12px"
                                    required={true}
                                    isChecked={
                                      actionItem.number in
                                      this.state.actionItemsCheckedObj
                                        ? this.state.actionItemsCheckedObj[
                                            actionItem.number
                                          ]
                                        : false
                                    }
                                    onChange={(event) =>
                                      this.onActionItemChecked(
                                        actionItem.number
                                      )
                                    }
                                    title="Select Action Item"
                                  />
                                ) : null}
                                <ActionItem>
                                  <ActionItemNumber>
                                    {actionItem.number}.
                                  </ActionItemNumber>
                                  <ActionItemContent>
                                    {actionItem.content}
                                  </ActionItemContent>
                                </ActionItem>
                              </ActionItemContainer>
                            );
                          }
                          return null;
                        }
                      )}
                    </ActionItemsContentContainer>
                  )
                )}
              </ActionItemsContainer>
            </NotesContent>
          </MeetingNotes>
        </ModalBody>
      </GradientHybridModalNarrow>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    storeActionItemsInTodoist: (accessToken, notesId, actionItemNumbers) =>
      dispatch(
        actions.storeActionItemsInTodoist(
          accessToken,
          notesId,
          actionItemNumbers
        )
      ),
  };
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    meetingNotes: state.meetingNotes,
    todoistAccessTokenExists: state.user.todoistAccessTokenExists,
    todoistIntegrated: state.user.todoistIntegrated,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotesModal)
);

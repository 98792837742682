import React, { Component } from "react";
import styled from "styled-components";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Link } from "../UI/Styles";

const SentimentTooltipContainer = styled.div`
  border: 1px solid black;
  width: auto;
  height: auto;
  max-width: 170px;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  word-wrap: break-word;
`;

const TimestampContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DataAttribute = styled.div`
  margin-bottom: 3px;
  font-weight: 400;
  font-style: italic;
`;

const DataAttributeContent = styled.div`
  display: inline;
  margin-left: 5px;
  font-weight: 300;
  font-style: normal;
`;

class SentimentLineChartTooltip extends Component {
  state = {};

  render() {
    // Storing props passed in by default Recharts tooltip for the record
    let dataPoint = {
      start_time: null,
      sentiment_score: null,
      speaker_label: null,
      emotions: null,
      content: null,
    };
    let speakerNames = {};
    let speakerNumber = null;

    if (
      this.props.payload &&
      this.props.payload.length > 0 &&
      this.props.payload[0].payload
    ) {
      dataPoint = this.props.payload[0].payload;
      dataPoint["start_time"] = parseFloat(dataPoint["start_time"]);
      dataPoint["sentiment_score"] = parseFloat(dataPoint["sentiment_score"]);
      speakerNumber =
        parseInt(dataPoint["speaker_label"].split("_")[1], 10) + 1;
    }

    if (this.props.speakerNames) {
      speakerNames = this.props.speakerNames;
    }

    return (
      <SentimentTooltipContainer>
        <TimestampContainer>
          <DataAttribute style={{ marginRight: "5px" }}>
            Timestamp:
          </DataAttribute>
          <Link
            style={{ pointerEvents: "auto" }}
            onClick={(event) => {
              this.props.onTimestampClick(event, dataPoint["start_time"]);
            }}
          >
            {this.props.getFormattedTimestamp(dataPoint["start_time"])}
          </Link>
        </TimestampContainer>
        <DataAttribute>
          Sentiment Score:{" "}
          <DataAttributeContent>{`${dataPoint["sentiment_score"]}`}</DataAttributeContent>
        </DataAttribute>
        {dataPoint["emotions"] ? (
          <DataAttribute>
            Emotions:{" "}
            <DataAttributeContent>
              {" "}
              {`${dataPoint["emotions"]}`}
            </DataAttributeContent>
          </DataAttribute>
        ) : null}
        <DataAttribute>
          Speaker:
          <DataAttributeContent>
            {`${speakerNumber} (${speakerNames[`Speaker ${speakerNumber}`]})`}
          </DataAttributeContent>
        </DataAttribute>
        <DataAttribute>
          Text:{" "}
          <DataAttributeContent>{`"${dataPoint["content"]}"`}</DataAttributeContent>
        </DataAttribute>
      </SentimentTooltipContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SentimentLineChartTooltip)
);

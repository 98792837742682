import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import "normalize.css";
import "./index.css";
import App from "./containers/App";
import authReducer from "./store/reducers/auth";
import userReducer from "./store/reducers/user";
import meetingReducer from "./store/reducers/meetings";
import meetingModalReducer from "./store/reducers/meetingModal";
import meetingDownloadReducer from "./store/reducers/meetingDownload";
import meetingTranscriptReducer from "./store/reducers/meetingTranscript";
import meetingSessionReducer from "./store/reducers/meetingSession";
import surveyReducer from "./store/reducers/survey";
import { unregister } from "./registerServiceWorker";
import meetingUploadReducer from "./store/reducers/meetingUpload";
import meetingNotesReducer from "./store/reducers/meetingNotes";
import subscription from "./store/reducers/subscription";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//custom middlewar
// eslint-disable-next-line no-unused-vars
const logger = (store) => {
  return (next) => {
    return (action) => {
      console.log("[Middleware] Will dispatch: ", action);
      const result = next(action);
      console.log(
        "[Middleware] state after performing action:",
        store.getState()
      );
      return result;
    };
  };
};

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  meetings: meetingReducer,
  meetingModal: meetingModalReducer,
  meetingDownload: meetingDownloadReducer,
  meetingTranscript: meetingTranscriptReducer,
  meetingNotes: meetingNotesReducer,
  meetingUpload: meetingUploadReducer,
  meetingSession: meetingSessionReducer,
  survey: surveyReducer,
  subscription: subscription,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

/*
    Service workers enable us to serve the app in offline mode. However, when those are enabled, the worker running in the
    browser may choose to cache older version of index.html (especially noticed in Firefox) and serve that even when a
    new build (with a new index.html) has been created. This causes the older version of app to be displayed unless user explicitly does
    a hard refresh in the browser or clears out local caches.  https://github.com/facebook/create-react-app/issues/1910

    We don't need them at the moment so unregistering them for now. The browser should still be able to cache static content for
    our app based on the cache control setting set in nginx.conf file.

    Before service workers are enabled in the future, we should add some mechanism to explicitly bust react caches.
    https://dev.to/flexdinesh/cache-busting-a-react-app-22lk

    import registerServiceWorker from './registerServiceWorker';
    registerServiceWorker();

 */
unregister();

import React from "react";
import styled from "styled-components";
import { media } from "../../components/UI/StyleUtils";

const CardStyles = styled.div`
  background-color: white;
  width: 100%;
  overflow-y: hidden;

  ${media.gtXSm`
        margin: 30px 0;
        
        width: 460px;
        min-height: 200px;
        border-radius: 5px;
        box-shadow: 0 2px 4px 0 rgba(12,0,51,0.1);
    `};
`;

const card = (props) => {
  return <CardStyles>{props.children}</CardStyles>;
};

export default card;

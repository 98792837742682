import styled from "styled-components";

export const IconPosition = styled.div`
  position: absolute;
  right: 8px;
  top: 14px;
  z-index: 5;

  @media (min-width: 768px) {
    right: 14px;
  }
`;

import React from "react";
import styled from "styled-components";

import overflowIcon from "../../../assets/functional_icons/overflow_purple.svg";

const OverFlowButtonContainer = styled.div`
  height: 39px;
  width: 36px;
  padding: 8px 15px;
  background-color: white;
  cursor: pointer;
  border: 1px solid transparent;

  :hover {
    border: 1px solid #c5c3cc;
    border-radius: 4px;
  }
`;

const OverFlowButton = (props) => (
  <OverFlowButtonContainer onClick={props.onClick}>
    <img src={overflowIcon} alt="Options Icon" height="20px" />
  </OverFlowButtonContainer>
);

export default OverFlowButton;

import React, { Component } from "react";
import styled from "styled-components";

/**
 * NOTE: This is a temporary component, to be used till we get an actual ICON built for the person removal toggle button.
 */
const DarkRoundButtonStyled = styled.button`
  cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")};
  height: ${(props) => props.size || "52px"};
  width: ${(props) => props.size || "52px"};
  border-radius: 26px;
  border: none;
  background-color: #2d2349;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: rgba(206, 208, 218, 0.78);

  :hover:enabled {
    color: rgba(243, 245, 255, 1);
  }
`;

/**
 *
 * A dark circular button that places it's input elements in it's center.
 *  props
 *  {
 *      title: the tooltip for the button
 *      onClick: the function to call when the button is clicked
 *      size: the size of the circle. Defaults to 52px which should be good for most use cases.
 *      disabled: whether or not the button is disabled.
 * }
 * @type {StyledComponentClass<JSX.IntrinsicElements["div"], any, JSX.IntrinsicElements["div"]>}
 */
class DarkRoundButton extends Component {
  render() {
    return (
      <DarkRoundButtonStyled
        title={this.props.title}
        onClick={this.props.onClick}
        size={this.props.size}
        disabled={this.props.disabled}
      >
        {!this.props.personRemovalEnabled
          ? "Remove Presenter"
          : "Leave Presenter"}
      </DarkRoundButtonStyled>
    );
  }
}

export default DarkRoundButton;

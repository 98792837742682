import * as actionTypes from "../actions/actionTypes";

const initialState = {
  meetingId: null,
  data: {},
  error: null,
  loading: false,
};

const meetingDownloadReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DOWNLOAD_MEETING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DOWNLOAD_MEETING_SUCCESS:
      return {
        ...state,
        data: action.data,
        meetingId: action.meetingId,
        loading: false,
      };
    case actionTypes.DOWNLOAD_MEETING_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default meetingDownloadReducer;

import React from "react";
import styled from "styled-components";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // import needed css properties for confirmAlert library

import GradientBorder from "../GradientBorder";
import { SmallButtonLightGrey } from "../Buttons/SmallButton";

const ConfirmAlertWindow = styled.div`
  background-color: white;
  width: 400px;
  max-width: calc(100vw - 9vw); // -9vw is to align this popup on mobile screens
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  overflow: hidden;
`;

const ConfirmAlertContentContainer = styled.div`
  padding: 0 10px 10px 10px;
`;

const Button = styled(SmallButtonLightGrey)`
  margin: 5px;
  width: 130px;
  height: 40px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;

/**
 * A custom React component that can be used to display confirmation alerts when user is about to take a sensitive action.
 * This component is desktop and mobile compatible.
 * @param title: The main heading that you want to display. Defaults to "Are you sure?".
 * @param message: The message that you would like to display in the body of the alert window. Defaults to "You want to perform this action?"
 * @param buttons: An Array of Json objects containing a label and a custom action to perform when the respective button is clicked. Please note that
 *          once any given button is clicked, and the respective action performed, the confirmation window is closed automatically.
 *          Example:
 *          [
 *              {label: 'no',
 *               action: () => {};
 *               },
 *               {label: 'Yes, Delete it!
 *                action: () => {this.props.performSomeAction(); console.log("Yes button was clicked, action has been performed");}
 *                }
 *           ]
 *
 * }
 * @returns a React Component
 * @constructor
 */

const confirmationAlert = (title, message, buttons) => {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <ConfirmAlertWindow>
          <GradientBorder />
          <ConfirmAlertContentContainer>
            <h2>{title || "Are you sure?"}</h2>
            <p>{message || "You want to perform this action?"}</p>
            <Buttons>
              {buttons.map((button) => (
                <Button
                  key={button.label}
                  onClick={() => {
                    button.onClick && button.onClick();
                    onClose();
                  }}
                >
                  {button.label}
                </Button>
              ))}
            </Buttons>
          </ConfirmAlertContentContainer>
        </ConfirmAlertWindow>
      );
    },
  });
};

export default confirmationAlert;

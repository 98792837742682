import * as actionTypes from "../actions/actionTypes";

const initialState = {
  meetingId: null,
  url: null,
  content: null,
  error: null,
  loading: false,
  speakerNames: null,
};

const meetingTranscriptReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DOWNLOAD_TRANSCRIPT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DOWNLOAD_TRANSCRIPT_SUCCESS:
      return {
        ...state,
        url: action.url,
        content: action.content,
        meetingId: action.meetingId,
        error: null,
        loading: false,
      };
    case actionTypes.DOWNLOAD_TRANSCRIPT_FAIL:
      return {
        ...state,
        url: null,
        content: null,
        meetingId: null,
        error: action.error,
        loading: false,
      };
    case actionTypes.GET_TRANSCRIPT_URL_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_TRANSCRIPT_URL_SUCCESS:
      return {
        ...state,
        url: action.url,
        meetingId: action.meetingId,
        error: null,
        loading: false,
      };
    case actionTypes.GET_TRANSCRIPT_URL_FAIL:
      return {
        ...state,
        url: null,
        meetingId: null,
        error: action.error,
        loading: false,
      };
    case actionTypes.GET_TRANSCRIPT_SPEAKER_NAMES_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_TRANSCRIPT_SPEAKER_NAMES_SUCCESS:
      return {
        ...state,
        speakerNames: action.speakerNamesObj,
        error: null,
        loading: false,
      };
    case actionTypes.GET_TRANSCRIPT_SPEAKER_NAMES_FAIL:
      return {
        ...state,
        speakerNames: null,
        error: action.error,
        loading: false,
      };
    case actionTypes.UPDATE_SPEAKER_NAMES_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_SPEAKER_NAMES_SUCCESS:
      return {
        ...state,
        speakerNames: action.speakerNamesObj,
        error: null,
        loading: false,
      };
    case actionTypes.UPDATE_SPEAKER_NAMES_FAIL:
      return {
        ...state,
        speakerNames: null,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default meetingTranscriptReducer;

import React from "react";

import BaseModal from "../BaseModal";
import GradientBorder from "../../GradientBorder";
import { IconPosition } from "../CommonDesignElements";
import Icon from "../../Icon/Icon";

/**
 * A simple React Modal component designed for mobile/tablet.
 full-screen with gradient border
 *
 * @param props
 * {
 *  isOpen - indicates if the modal should be currently open or not.
 *  onRequestClose - a function to call in order to close the modal. If null is specified, user will not be allowed to manually close the modal.
 *  topGradientBorder: whether or not to include the gradient border on the top. Defaults to true.
 * }
 * @returns JSX component
 * @constructor
 */
const MobileModal = (props) => {
  return (
    <BaseModal
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
      onRequestClose={props.onRequestClose}
      width={"100%"}
      top={"0%"}
      bottom={"0%"}
    >
      {props.topGradientBorder !== false ? <GradientBorder /> : null}
      <div style={{ padding: props.padding || "8px" }}>
        {props.onRequestClose != null ? (
          <IconPosition onClick={props.onRequestClose}>
            <Icon name="close_icon" alt="Close" />
          </IconPosition>
        ) : null}
        {props.children}
      </div>
    </BaseModal>
  );
};

export default MobileModal;

import React from "react";
import CurrentDateTime from "./DateTime/DateTime";
import { Body, MainText, SubText } from "./CommonStyles";

const ReadyToCollaborate = (props) => {
  return (
    <Body>
      <CurrentDateTime />
      <MainText>Ready to collaborate?</MainText>
      {props.userIsHost ? (
        <SubText>
          Use the pulsating button above to{" "}
          <strong>start sharing your whiteboard.</strong>
        </SubText>
      ) : (
        <SubText>
          Use the pulsating button above to <strong>join the crew.</strong>
        </SubText>
      )}
    </Body>
  );
};

export default ReadyToCollaborate;

import User from "./User";
import { v4 as uuidv4 } from "uuid";

export default class MeetingAttendee extends User {
  _participantSessionId;
  _remoteUser;
  _host;

  constructor(name, email, remoteUer, host, participantSessionId = uuidv4()) {
    super(name, email);
    this._participantSessionId = participantSessionId;
    this._remoteUser = remoteUer;
    this._host = host;
  }
  isRemoteUser = (_) => this._remoteUser;
  isHost = (_) => this._host;

  get participantSessionId() {
    return this._participantSessionId;
  }
}

import React, { Component } from "react";
import styled from "styled-components";
import basicOpsIcon from "../../../assets/images/basicops/basicops_icon.png";

const BasicOpsLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

/**
 *
 * A component that places the BasicOps icon next to the desired text. The desired text is optional.
 *  props
 *  {
 *      height: the height of the BasicOps icon
 *      width: the width of the BasicOps icon
 *      logoText: the logo text, which will appear to the right of the icon if given
 * }
 */
class BasicOpsLogo extends Component {
  state = {};

  height = () => (this.props.height ? this.props.height : "30px");
  width = () => (this.props.width ? this.props.width : "30px");

  render() {
    return (
      <BasicOpsLogoContainer>
        <img
          alt="BasicOps Icon"
          src={basicOpsIcon}
          style={{ height: this.height(), width: this.width() }}
        />
        {this.props.logoText ? (
          <div style={{ marginLeft: "5px", fontSize: "17px" }}>
            {this.props.logoText}
          </div>
        ) : null}
      </BasicOpsLogoContainer>
    );
  }
}

export default BasicOpsLogo;

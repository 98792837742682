import styled, { css } from "styled-components";

const SmallButtonBase = styled.button`
  height: 100%;
  width: 100%;
  color: #170f30;
  font-size: 14px;
  border-radius: 3px;
  cursor: ${(props) => (props.loading ? "progress" : "pointer")};
  border: none;
  && {
    ${(props) =>
      props.disabled &&
      css`
        // if button is disabled, override the background color.
        background-color: #f0f0f0;
      `}
  }
`;

export const SmallButtonWhite = styled(SmallButtonBase)`
  background-color: white;
`;

export const SmallButtonPurple = styled(SmallButtonBase)`
  color: white;
  background-color: #764ef2;
`;

export const SmallButtonGreen = styled(SmallButtonBase)`
  background-color: #39d0c5;
`;

export const SmallButtonLightGrey = styled(SmallButtonBase)`
  background-color: rgba(241, 247, 251, 0.85);
  color: rgba(118, 78, 242, 1);
`;

export default SmallButtonWhite;
